<template>
  <div id="vendorBlock" class="col-12">
    <div class="ue-1">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            status === 1 ? 'Block Vendor' : 'Unblock Vendor'
          }}</span>
        </v-card-title>

        <v-card-text>
          <small
            >Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla quo
            et iste praesentium, corporis, maxime, sit a quisquam repellendus
            laudantium nemo odio cum illo id at? Molestiae consequatur nihil
            perferendis.</small
          >
          <v-container>
            <v-alert dense outlined type="error" v-if="isFailed">
              {{ failMsg }}
            </v-alert>
            <v-row>
              <v-col cols="12" style=" padding:0 10px;">
                <v-container fluid>
                  <v-textarea
                    :clearable="perm.update_perm !== 1"
                    :readonly="perm.update_perm !== 1"
                    clear-icon="mdi-close-circle"
                    :label="
                      status === 1 ? 'Alasan blokir' : 'Alasan Buka blokiran'
                    "
                    v-model="form.reason"
                  ></v-textarea>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="status === 1"
            color="blue darken-1"
            text
            :disabled="perm.update_perm !== 1"
            @click="proccess"
          >
            Block
          </v-btn>
          <v-btn
            v-if="status === -1"
            color="blue darken-1"
            text
            :disabled="perm.update_perm !== 1"
            @click="unblock"
          >
            Unblock
          </v-btn>
          <v-btn color="blue darken-1" text @click="cancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-card
      v-if="getIsLoading === true"
      class="mx-auto"
      width="99%"
      height="260"
      style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5); z-index:55;"
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Please wait</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['vendorId', 'status', 'perm'],
  data() {
    return {
      isFailed: false,
      failMsg: 'Reason must be filled',
      form: {
        id: this.vendorId,
        reason: ''
      }
    }
  },
  components: {},
  mounted() {},
  computed: {
    ...mapGetters(['getVdrBlockStat', 'getIsLoading'])
  },
  methods: {
    ...mapActions(['vendorDataList', 'blockVendor', 'unBlockVendor']),
    ...mapMutations(['vendorBlock', 'setIsLoading']),
    proccess() {
      if (this.form.reason === '') {
        this.isFailed = true
        setTimeout(() => {
          this.isFailed = false
        }, 3000)
      } else {
        this.$swal
          .fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true
          })
          .then(result => {
            if (result.value) {
              this.setIsLoading(true)
              this.blockVendor(this.form)
                .then(result => {
                  this.setIsLoading(false)
                  if (result.data.status_code !== '-99') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 5000
                    })
                    this.vendorDataList(['', 0, 10, 'name', 'asc'])
                    this.vendorBlock(true)
                  } else {
                    console.log(result)
                    this.$swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 5000
                    })
                  }
                })
                .catch(error => {
                  this.setIsLoading(false)
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error,
                    showConfirmButton: true,
                    timer: 5000
                  })
                })
            }
          })
      }
    },
    unblock() {
      if (this.form.reason === '') {
        this.isFailed = true
        setTimeout(() => {
          this.isFailed = false
        }, 3000)
      } else {
        this.$swal
          .fire({
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            showConfirmButton: true
          })
          .then(result => {
            if (result.value) {
              this.setIsLoading(true)
              this.unBlockVendor(this.form)
                .then(result => {
                  this.setIsLoading(false)
                  if (result.data.status_code !== '-99') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 5000
                    })
                    this.vendorDataList(['', 0, 10, 'name', 'asc'])
                    this.vendorBlock(true)
                  } else {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 5000
                    })
                  }
                })
                .catch(error => {
                  this.setIsLoading(false)
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error,
                    showConfirmButton: true,
                    timer: 5000
                  })
                })
            }
          })
      }
    },
    cancel() {
      this.vendorBlock(true)
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/vendorManage.scss"></style>
