<template>
  <v-row>
    <v-col cols="6" class="form-pr-input-col">
      <div class="form-pr-input-col-div">
        <v-col cols="3" class="form-pr-input-col-div-col1">
          <p>
            Nama Pengadaan
          </p>
        </v-col>
        <v-col class="form-pr-input-col-div-col2" cols="6">
          <v-text-field :value="detail.name" dense readonly outlined />
        </v-col>
      </div>
    </v-col>
    <v-col cols="4" class="form-pr-input-col">
      <div class="form-pr-input-col-div">
        <v-col cols="6" class="form-pr-input-col-div-col1">
          <p>
            Periode Pengadaan
          </p>
        </v-col>
        <v-col cols="5" class="form-pr-input-col-div-col2">
          <v-text-field
            :value="convertDate(detail.period_start)"
            readonly
            dense
            outlined
          />
        </v-col>
      </div>
    </v-col>
    <v-col cols="2" class="form-pr-input-col">
      <div class="form-pr-input-col-div">
        <v-col cols="2" class="form-pr-input-col-div-col1">
          <p style="position:relative; left:-20px;">
            s/d
          </p>
        </v-col>
        <v-col cols="10" class="form-pr-input-col-div-col2">
          <v-text-field
            :value="convertDate(detail.period_end)"
            readonly
            dense
            outlined
          />
        </v-col>
      </div>
    </v-col>

    <v-col cols="6" class="form-pr-input-col">
      <div class="form-pr-input-col-div">
        <v-col cols="3" class="form-pr-input-col-div-col1">
          <p>
            Tahun Anggaran
          </p>
        </v-col>
        <v-col class="form-pr-input-col-div-col2" cols="3">
          <v-text-field
            dense
            outlined
            readonly
            :value="detail.year"
            style="margin:0;"
          />
        </v-col>
      </div>
    </v-col>
    <v-col cols="4" class="form-pr-input-col">
      <div class="form-pr-input-col-div">
        <v-col cols="6" class="form-pr-input-col-div-col1">
          <p>
            Item Pengadaan
          </p>
        </v-col>
        <v-col cols="12" class="form-pr-input-col-div-col2">
          <v-combobox
            dense
            outlined
            :value="arrJoin(detail.item)"
            multiple
            style="margin:0;"
          ></v-combobox>
        </v-col>
      </div>
    </v-col>

    <v-col cols="6" class="form-pr-input-col">
      <div class="form-pr-input-col-div">
        <v-col cols="3" class="form-pr-input-col-div-col1">
          <p>
            Total HPS
          </p>
        </v-col>
        <v-col class="form-pr-input-col-div-col2" cols="6">
          <v-text-field
            dense
            outlined
            :value="price(detail.total_hps)"
            readonly
            style="margin:0;"
          />
        </v-col>
      </div>
    </v-col>
    <v-col cols="2" class="form-pr-input-col"> </v-col>

    <v-col cols="6" class="form-pr-input-col">
      <div class="form-pr-input-col-div">
        <v-col cols="3" class="form-pr-input-col-div-col1">
          <p>
            Harga Pasaran
          </p>
        </v-col>
        <v-col class="form-pr-input-col-div-col2" cols="6">
          <v-text-field
            dense
            outlined
            :value="price(detail.price_on_market)"
            readonly
            style="margin:0;"
          />
        </v-col>
      </div>
    </v-col>
  </v-row>
</template>
<script>
// import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['detail'],
  data: () => ({
    period: {
      start: null,
      end: null
    }
    // product_id: []
  }),
  components: {},
  created() {},
  computed: {
    // ...mapGetters([])
  },
  methods: {
    // ...mapActions([]),
    // ...mapMutations([])
    convertDate(raw) {
      if (raw !== undefined) {
        const day = raw.slice(0, raw.indexOf('-'))
        let month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const year = raw.slice(raw.lastIndexOf('-') + 1)

        switch (month) {
          case '01':
            month = 'JAN'
            break
          case '02':
            month = 'FEB'
            break
          case '03':
            month = 'MAR'
            break
          case '04':
            month = 'APR'
            break
          case '05':
            month = 'MAY'
            break
          case '06':
            month = 'JUN'
            break
          case '07':
            month = 'JUL'
            break
          case '08':
            month = 'AGS'
            break
          case '09':
            month = 'SEP'
            break
          case '10':
            month = 'OKT'
            break
          case '11':
            month = 'NOV'
            break
          case '12':
            month = 'DES'
            break
        }
        return year + '-' + month + '-' + day
      }
    },
    arrJoin(arr) {
      const name = []
      // const code = []
      for (let i = 0; i < arr.length; i++) {
        name.push(arr[i].product.name)
        // code.push(Number(arr[i].product.id))
      }
      // this.product_id = JSON.stringify(code)
      return name
    },
    price(val) {
      if (val !== null || val !== undefined) {
        const reverse = val.toString()

        if (reverse.lastIndexOf('.') === -1) {
          const beforeComa1 = reverse
            .split('')
            .reverse()
            .join('')
          var ribuan1 = beforeComa1.match(/\d{1,3}/g)
          const result1 = ribuan1
            .join(',')
            .split('')
            .reverse()
            .join('')
          return result1
        } else {
          const beforeComa2 = reverse
            .slice(0, reverse.indexOf('.'))
            .split('')
            .reverse()
            .join('')
          var ribuan2 = beforeComa2.match(/\d{1,3}/g)
          const result2 = ribuan2
            .join(',')
            .split('')
            .reverse()
            .join('')
          const afterComa = reverse.slice(reverse.lastIndexOf('.') + 1)
          return result2 + ',' + afterComa
        }
      }
    }
  }
}
</script>

<style lang="scss" src="">
.form-pr-input-col {
  padding: 0;

  .form-pr-input-col-div {
    display: flex;
    flex-direction: row;
    .form-pr-input-col-div-col1 {
      padding-left: 0;
      padding-right: 0;

      p {
        font-size: 14px;
        margin: 0;
        padding-right: 20px;
        text-align: right;
      }
    }
    .form-pr-input-col-div-col2 {
      padding: 0;
    }
  }
}
</style>
