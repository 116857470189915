var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"fixed","top":"0","left":"0","bottom":"0","right":"0","background":"rgba(255,255,255,0.8)","z-index":"2","display":"flex","justify-content":"center","align-items":"center"}},[_c('v-card',{staticStyle:{"osition":"relative","top":"0","max-height":"98vh","overflow":"auto"},attrs:{"elevation":"6","width":_vm.getVdrCatalogueModalStat[1] === 'supply' ? 45 + '%' : 50 + '%'}},[(_vm.perm.create_perm === 1)?_c('div',[_c('v-card-title',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[(_vm.getVdrCatalogueModalStat[1] === 'supply')?_c('span',{staticClass:"headline"},[_vm._v("Form Add & Edit Penawaran")]):_vm._e(),(_vm.getVdrCatalogueModalStat[1] === 'rawspec')?_c('span',{staticClass:"headline"},[_vm._v("Form Add & Edit Spesifikasi Bahan Baku")]):_vm._e(),(_vm.getVdrCatalogueModalStat[1] === 'genspec')?_c('span',{staticClass:"headline"},[_vm._v("Form Add & Edit Spesifikasi Umum")]):_vm._e()])],1),_c('v-card-text',[_c('v-container',[(_vm.getVdrCatalogueModalStat[1] === 'supply')?_c('v-row',{staticClass:"justify-content-center"},[_c('v-col',{staticStyle:{"height":"65px","padding":"0 5px"},attrs:{"cols":"10"}},[_c('v-autocomplete',{staticStyle:{"margin-top":"5px"},attrs:{"prepend-icon":"mdi-file-tree-outline","items":_vm.getUnitDropdown,"label":"Unit","item-text":"name","item-value":"id","outlined":"","return-id":"","required":"","clearable":(_vm.getQuotationAct === 'add' && _vm.perm.create_perm !== 0) ||
                    (_vm.getQuotationAct === 'update' && _vm.perm.update_perm === 1),"readonly":(_vm.getQuotationAct === 'add' && _vm.perm.create_perm === 0) ||
                    (_vm.getQuotationAct === 'update' && _vm.perm.update_perm !== 1),"rules":_vm.rules.unitRules,"dense":""},on:{"change":_vm.supplyUnitName},model:{value:(_vm.form_supply.uom_name),callback:function ($$v) {_vm.$set(_vm.form_supply, "uom_name", $$v)},expression:"form_supply.uom_name"}})],1),_c('v-col',{staticStyle:{"height":"65px","padding":"0 5px"},attrs:{"cols":"10"}},[_c('v-text-field',{staticStyle:{"margin-top":"5px"},attrs:{"prepend-icon":"mdi-cash-usd-outline","label":"Harga Per Unit","required":"","rules":_vm.rules.priceRules,"readonly":(_vm.getQuotationAct === 'add' && _vm.perm.create_perm === 0) ||
                    (_vm.getQuotationAct === 'update' && _vm.perm.update_perm !== 1),"type":"text","outlined":"","dense":""},model:{value:(_vm.form_supply.price_per_unit),callback:function ($$v) {_vm.$set(_vm.form_supply, "price_per_unit", $$v)},expression:"form_supply.price_per_unit"}})],1),_c('v-col',{staticStyle:{"height":"65px","padding":"0 10px"},attrs:{"cols":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":(_vm.getQuotationAct === 'add' && _vm.perm.create_perm === 0) ||
                        (_vm.getQuotationAct === 'update' &&
                          _vm.perm.update_perm !== 1)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Periode Start","prepend-icon":"mdi-calendar","readonly":"","rules":_vm.rules.periodStartRules},model:{value:(_vm.form_supply.period_start),callback:function ($$v) {_vm.$set(_vm.form_supply, "period_start", $$v)},expression:"form_supply.period_start"}},'v-text-field',attrs,false),on))]}}],null,false,1364027596),model:{value:(_vm.menu.menu4),callback:function ($$v) {_vm.$set(_vm.menu, "menu4", $$v)},expression:"menu.menu4"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu.menu4 = false}},model:{value:(_vm.form_supply.period_start),callback:function ($$v) {_vm.$set(_vm.form_supply, "period_start", $$v)},expression:"form_supply.period_start"}})],1)],1)],1)],1),_c('v-col',{staticStyle:{"height":"65px","padding":"0 10px"},attrs:{"cols":"10"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":(_vm.getQuotationAct === 'add' && _vm.perm.create_perm === 0) ||
                        (_vm.getQuotationAct === 'update' &&
                          _vm.perm.update_perm !== 1)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Periode End","prepend-icon":"mdi-calendar","readonly":"","rules":_vm.rules.periodEndRules},model:{value:(_vm.form_supply.period_end),callback:function ($$v) {_vm.$set(_vm.form_supply, "period_end", $$v)},expression:"form_supply.period_end"}},'v-text-field',attrs,false),on))]}}],null,false,1929642403),model:{value:(_vm.menu.menu5),callback:function ($$v) {_vm.$set(_vm.menu, "menu5", $$v)},expression:"menu.menu5"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu.menu5 = false}},model:{value:(_vm.form_supply.period_end),callback:function ($$v) {_vm.$set(_vm.form_supply, "period_end", $$v)},expression:"form_supply.period_end"}})],1)],1)],1)],1),_c('v-col',{staticStyle:{"height":"65px","padding":"0 10px"},attrs:{"cols":"10"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start","align-items":"flex-start","padding":"0","margin":"0","height":"40px","position":"relative"}},[_c('v-file-input',{staticStyle:{"margin":"0"},attrs:{"clearable":_vm.perm.update_perm === 1,"label":_vm.form_supply.file_quotation || 'Upload File Pendukung',"outlined":"","disabled":(_vm.getQuotationAct === 'add' && _vm.perm.create_perm === 0) ||
                      (_vm.getQuotationAct === 'update' && _vm.perm.update_perm !== 1),"dense":"","required":"","rules":_vm.rules.fileRules},on:{"change":_vm.supplyUpload}})],1)])],1):_vm._e(),(_vm.getVdrCatalogueModalStat[1] === 'rawspec')?_c('v-row',{staticClass:"justify-content-center"},[_c('v-col',{staticStyle:{"height":"65px","padding":"0 5px"},attrs:{"cols":"6"}},[_c('v-autocomplete',{staticStyle:{"margin-top":"10px"},attrs:{"prepend-icon":"mdi-shape-outline","items":_vm.getSpecCategoryDropdown,"outlined":"","dense":"","label":"Kategori","placeholder":"Pilih Kategori Spesifikasi","item-text":"name","item-value":"id","clearable":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm !== 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm === 1),"readonly":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm === 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm !== 1),"return-id":""},on:{"change":_vm.initSpecAttributeDropdown},model:{value:(_vm.form_raw_spesification.spesification_category_id),callback:function ($$v) {_vm.$set(_vm.form_raw_spesification, "spesification_category_id", $$v)},expression:"form_raw_spesification.spesification_category_id"}})],1),_c('v-col',{staticStyle:{"height":"65px","padding":"0 5px"},attrs:{"cols":"6"}},[_c('v-autocomplete',{staticStyle:{"margin-top":"10px"},attrs:{"prepend-icon":"mdi-transit-connection-horizontal","items":_vm.getSpecAttributeDropdown,"outlined":"","dense":"","label":"Attribute","placeholder":"Pilih Attribute","item-text":"name","item-value":"id","return-id":"","clearable":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm !== 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm === 1),"readonly":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm === 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm !== 1)},model:{value:(_vm.form_raw_spesification.spesification_attribute_id),callback:function ($$v) {_vm.$set(_vm.form_raw_spesification, "spesification_attribute_id", $$v)},expression:"form_raw_spesification.spesification_attribute_id"}})],1),_c('v-col',{staticStyle:{"height":"65px","padding":"0 5px","margin-right":"auto"},attrs:{"cols":"6"}},[_c('v-autocomplete',{staticStyle:{"margin-top":"10px"},attrs:{"prepend-icon":"mdi-file-tree-outline","items":_vm.getUnitDropdown,"outlined":"","dense":"","label":"Unit","placeholder":"Pilih Unit","item-text":"name","item-value":"id","return-id":"","clearable":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm !== 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm === 1),"readonly":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm === 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm !== 1)},model:{value:(_vm.form_raw_spesification.unit_id),callback:function ($$v) {_vm.$set(_vm.form_raw_spesification, "unit_id", $$v)},expression:"form_raw_spesification.unit_id"}})],1),_c('v-col',{staticStyle:{"height":"65px","padding":"0 5px","margin-right":"auto"},attrs:{"cols":"6"}},[_c('v-autocomplete',{staticStyle:{"margin-top":"10px"},attrs:{"prepend-icon":"mdi-view-list-outline","items":[
                  { id: 1, name: 'Major' },
                  { id: 2, name: 'Kritikal' }
                ],"outlined":"","dense":"","label":"Kriteria","placeholder":"Pilih Kriteria","item-text":"name","item-value":"id","return-id":"","clearable":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm !== 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm === 1),"readonly":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm === 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm !== 1)},model:{value:(_vm.form_raw_spesification.criteria),callback:function ($$v) {_vm.$set(_vm.form_raw_spesification, "criteria", $$v)},expression:"form_raw_spesification.criteria"}})],1),_c('v-col',{staticStyle:{"height":"65px","padding":"0 5px"},attrs:{"cols":"12"}},[_c('v-text-field',{staticStyle:{"margin-top":"5px"},attrs:{"prepend-icon":"mdi-toy-brick-search-outline","label":"Metode Analisa","required":"","type":"text","outlined":"","dense":"","clearable":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm !== 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm === 1),"readonly":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm === 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm !== 1)},model:{value:(_vm.form_raw_spesification.analysis_method),callback:function ($$v) {_vm.$set(_vm.form_raw_spesification, "analysis_method", $$v)},expression:"form_raw_spesification.analysis_method"}})],1),_c('v-col',{staticStyle:{"min-height":"75px","padding":"0 5px","position":"relative"},attrs:{"cols":"12"}},[_c('v-textarea',{staticStyle:{"margin-top":"5px"},attrs:{"prepend-icon":"mdi-standard-definition","label":"Standard","required":"","type":"text","outlined":"","clearable":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm !== 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm === 1),"readonly":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm === 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm !== 1),"height":"80"},model:{value:(_vm.form_raw_spesification.standard),callback:function ($$v) {_vm.$set(_vm.form_raw_spesification, "standard", $$v)},expression:"form_raw_spesification.standard"}})],1),_c('v-col',{staticStyle:{"padding":"0","border-radius":"5px","background":"rgba(200,200,10,0.3)"},attrs:{"cols":"12"}},[_c('v-card-title',{attrs:{"cols":"12"}},[_c('v-icon',[_vm._v("mdi-waveform")]),_c('span',{staticStyle:{"font-size":"20px"}},[_vm._v(" Minimum Frequency ")])],1),_c('v-col',{staticStyle:{"height":"65px","padding":"0","margin":"0 auto"},attrs:{"cols":"11"}},[_c('v-text-field',{staticStyle:{"margin":"0px"},attrs:{"label":"Suplier","required":"","type":"text","outlined":"","dense":"","clearable":(_vm.getSpecificationAct === 'add' &&
                      _vm.perm.create_perm !== 0) ||
                      (_vm.getSpecificationAct === 'update' &&
                        _vm.perm.update_perm === 1),"readonly":(_vm.getSpecificationAct === 'add' &&
                      _vm.perm.create_perm === 0) ||
                      (_vm.getSpecificationAct === 'update' &&
                        _vm.perm.update_perm !== 1)},model:{value:(_vm.form_raw_spesification.min_frequency_supplier),callback:function ($$v) {_vm.$set(_vm.form_raw_spesification, "min_frequency_supplier", $$v)},expression:"form_raw_spesification.min_frequency_supplier"}})],1),_c('v-col',{staticStyle:{"height":"65px","padding":"0","margin":"0 auto"},attrs:{"cols":"11"}},[_c('v-text-field',{staticStyle:{"margin":"0px"},attrs:{"label":"Sanqua","required":"","type":"text","outlined":"","dense":"","clearable":(_vm.getSpecificationAct === 'add' &&
                      _vm.perm.create_perm !== 0) ||
                      (_vm.getSpecificationAct === 'update' &&
                        _vm.perm.update_perm === 1),"readonly":(_vm.getSpecificationAct === 'add' &&
                      _vm.perm.create_perm === 0) ||
                      (_vm.getSpecificationAct === 'update' &&
                        _vm.perm.update_perm !== 1)},model:{value:(_vm.form_raw_spesification.min_frequency_sanqua),callback:function ($$v) {_vm.$set(_vm.form_raw_spesification, "min_frequency_sanqua", $$v)},expression:"form_raw_spesification.min_frequency_sanqua"}})],1)],1)],1):_vm._e(),(_vm.getVdrCatalogueModalStat[1] === 'genspec')?_c('v-row',{staticClass:"justify-content-center"},[_c('v-col',{staticStyle:{"height":"65px","padding":"0 5px"},attrs:{"cols":"6"}},[_c('v-autocomplete',{staticStyle:{"margin-top":"10px"},attrs:{"prepend-icon":"mdi-shape-outline","items":_vm.getSpecCategoryDropdown,"outlined":"","dense":"","label":"Kategori","placeholder":"Pilih Kategori Spesifikasi","item-text":"name","item-value":"id","return-id":"","clearable":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm !== 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm === 1),"readonly":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm === 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm !== 1)},on:{"change":_vm.initSpecAttributeDropdown},model:{value:(_vm.form_gen_spesification.spesification_category_id),callback:function ($$v) {_vm.$set(_vm.form_gen_spesification, "spesification_category_id", $$v)},expression:"form_gen_spesification.spesification_category_id"}})],1),_c('v-col',{staticStyle:{"height":"65px","padding":"0 5px"},attrs:{"cols":"6"}},[_c('v-autocomplete',{staticStyle:{"margin-top":"10px"},attrs:{"prepend-icon":"mdi-transit-connection-horizontal","items":_vm.getSpecAttributeDropdown,"outlined":"","dense":"","label":"Attribute","placeholder":"Pilih Attribute","item-text":"name","item-value":"id","return-id":"","clearable":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm !== 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm === 1),"readonly":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm === 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm !== 1)},model:{value:(_vm.form_gen_spesification.spesification_attribute_id),callback:function ($$v) {_vm.$set(_vm.form_gen_spesification, "spesification_attribute_id", $$v)},expression:"form_gen_spesification.spesification_attribute_id"}})],1),_c('v-col',{staticStyle:{"min-height":"165px","padding":"0 5px"},attrs:{"cols":"12"}},[_c('v-textarea',{staticStyle:{"margin":"0px"},attrs:{"label":"Deskripsi","outlined":"","clear-icon":"mdi-close-circle","clearable":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm !== 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm === 1),"readonly":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm === 0) ||
                    (_vm.getSpecificationAct === 'update' &&
                      _vm.perm.update_perm !== 1)},model:{value:(_vm.form_gen_spesification.description),callback:function ($$v) {_vm.$set(_vm.form_gen_spesification, "description", $$v)},expression:"form_gen_spesification.description"}})],1)],1):_vm._e()],1)],1)],1):_c('v-card',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"flat":"","height":"45vh"}},[_c('v-toolbar-title',{staticClass:"overline"},[_vm._v(" You Can't Access This Page")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeForm}},[_vm._v(" Close ")]),(_vm.getVdrCatalogueModalStat[1] === 'supply')?_c('v-btn',{attrs:{"color":"blue darken-1","disabled":(_vm.getQuotationAct === 'add' && _vm.perm.create_perm === 0) ||
            (_vm.getQuotationAct === 'update' && _vm.perm.update_perm !== 1),"text":""},on:{"click":_vm.submitSupply}},[_vm._v(" Save ")]):_vm._e(),(_vm.getVdrCatalogueModalStat[1] === 'rawspec')?_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm === 0) ||
            (_vm.getSpecificationAct === 'update' && _vm.perm.update_perm !== 1)},on:{"click":_vm.submitRawSpec}},[_vm._v(" Save ")]):_vm._e(),(_vm.getVdrCatalogueModalStat[1] === 'genspec')?_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":(_vm.getSpecificationAct === 'add' && _vm.perm.create_perm === 0) ||
            (_vm.getSpecificationAct === 'update' && _vm.perm.update_perm !== 1)},on:{"click":_vm.submitGenSpec}},[_vm._v(" Save ")]):_vm._e()],1)],1),(_vm.getIsLoading === true)?_c('v-card',{staticClass:"mx-auto",staticStyle:{"display":"flex","justify-content":"center","align-items":"center","font-size":"12px","position":"absolute","top":"0","height":"100%","left":"0","right":"0","background":"rgba(255,255,255,0.5)"},attrs:{"width":"99%","height":"260"}},[_c('v-progress-circular',{attrs:{"size":170,"width":10,"color":"blue","indeterminate":""}},[_vm._v("Please wait")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }