<template>
  <div style="min-height:30vh;">
    <v-data-table
      :headers="headers"
      :items="itemData"
      class="elevation-1 mx-auto row-pointer"
      style="margin-bottom:10px; border:1px solid #e0e0e0;"
      :loading="loading"
      hide-default-footer
      :items-per-page="itemsPerPage"
      :options.sync="options"
      :server-items-length="totalItemData"
      @click:row="rowClick"
      show-select
      v-model="selectedItem"
    >
      <template v-slot:top>
        <div
          style="border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;padding:0 10px;"
        >
          <v-row>
            <v-col
              cols="12"
              style="display:flex; flex-direction:row; padding-top:0;padding-bottom:0;"
            >
              <div
                style="padding:10px; display:flex; justify-content: center; align-items:center; margin-right:20px; "
              >
                <p style="margin:0;">
                  +
                </p>

                <ItemTab
                  :param="param"
                  :id="procId"
                  :itemId="rowId"
                  @close="clear"
                  @reload="reLoad"
                />
              </div>
              <div
                v-if="selectedItem.length !== 0"
                style="padding:10px; display:flex; justify-content: center; align-items:center; margin-right:20px; "
              >
                <v-icon color="red" small style="margin-right:7px;">
                  mdi-delete
                </v-icon>
                <p @click="remove" class="deleteProcItem">Delete</p>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:[`item.product`]="{ item }">
        <div style="padding: 0; display: flex; flex-direction: row">
          {{ item.product.name }}
        </div>
      </template>
      <template v-slot:[`item.unit`]="{ item }">
        <div style="padding: 0; display: flex; flex-direction: row">
          {{ item.unit !== null ? item.unit.name : '-' }}
        </div>
      </template>
      <template v-slot:[`item.total`]="{ item }">
        <div style="padding: 0; display: flex; flex-direction: row">
          {{ price(item.total) }}
        </div>
      </template>
      <template v-slot:[`item.currency`]="{ item }">
        <div style="padding: 0; display: flex; flex-direction: row">
          {{ item.currency !== null ? item.currency.name : '-' }}
        </div>
      </template>
    </v-data-table>
    <div class="pr-items-total-box">
      <div>
        <p>Sub Total <span>:</span></p>
        <p>{{ data !== null ? price(data.sub_total) : '-' }}</p>
      </div>
      <div>
        <p>PPN 10% <span>:</span></p>
        <p>{{ data !== null ? price(data.ppn) : '-' }}</p>
      </div>
      <div>
        <p>Total <span>:</span></p>
        <p>{{ data !== null ? price(data.grand_total) : '-' }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters } from 'vuex'
import ItemTab from '../modal/procurement_tabs/Item_tab.vue'
export default {
  props: ['itemData', 'procId', 'data'],
  data: () => ({
    proc: buildType.apiURL('proc'),
    param: {},
    selectedItem: [],
    dataTable: [],
    itemsPerPage: 10,
    headers: [
      { text: 'Item', value: 'product', align: 'left' },
      { text: 'Qty', value: 'qty', align: 'left' },
      { text: 'Unit', value: 'unit', align: 'left' },
      { text: 'Currency', value: 'currency', align: 'left' },
      { text: 'Total harga', value: 'total', align: 'left' },
      { text: 'Deskripsi', value: 'spesifikasi', align: 'left' },
      { text: 'Created at', value: 'created_at', align: 'left' },
      { text: 'Created by', value: 'created_by_name', align: 'left' }
    ],
    totalItemData: 1,
    options: {},
    loading: false,
    rowId: null
  }),
  components: {
    ItemTab
  },
  created() {},
  computed: {
    ...mapGetters(['getProcListId'])
  },
  methods: {
    // ...mapActions([]),
    // ...mapMutations([]),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    rowClick(pItem) {
      this.rowId = pItem
      setTimeout(() => {
        document.getElementById('procItemTabForm').click()
      }, 500)
    },
    clear(val) {
      this.rowId = null
    },
    reLoad() {
      this.$emit('reload', this.getProcListId)
    },
    remove() {
      console.log(this.selectedItem)
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(res => {
        if (res.isConfirmed) {
          for (let i = 0; i < this.selectedItem.length; i++) {
            axios
              .delete(`${this.proc}item/delete/${this.selectedItem[i].id}`)
              .then(res => {
                console.log(res)
                if (res.data.status_code !== '-99') {
                  if (i === this.selectedItem.length - 1) {
                    this.showMsgDialog('success', res.data.status_msg, false)
                    this.selectedItem = []
                    this.reLoad(this.getProcListId)
                  }
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
              })
              .catch(err => {
                console.log(err)
                this.showMsgDialog(
                  'error',
                  'Something went wrong, please contact an Admin',
                  false
                )
              })
          }
        } else {
          this.actionValue = 0
        }
      })
    },
    price(val) {
      if (this.itemData.length !== 0) {
        if (val !== null || val !== undefined) {
          const reverse = val.toString()

          if (reverse.lastIndexOf('.') === -1) {
            const beforeComa1 = reverse
              .split('')
              .reverse()
              .join('')
            var ribuan1 = beforeComa1.match(/\d{1,3}/g)
            const result1 = ribuan1
              .join(',')
              .split('')
              .reverse()
              .join('')
            return result1
          } else {
            const beforeComa2 = reverse
              .slice(0, reverse.indexOf('.'))
              .split('')
              .reverse()
              .join('')
            var ribuan2 = beforeComa2.match(/\d{1,3}/g)
            const result2 = ribuan2
              .join(',')
              .split('')
              .reverse()
              .join('')
            const afterComa = reverse.slice(reverse.lastIndexOf('.') + 1)
            return result2 + ',' + afterComa
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" src="">
.table {
  border-radius: 3px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255);
}
.pr-items-add-new-line {
  width: 100%;
  font-size: 12px;
  display: flex;
  justify-content: flex-start;
  p {
    margin: 0;
    padding: 0 5px;
    cursor: pointer;
    color: #1976d2;
    &:hover {
      text-decoration: underline;
    }
  }
}
.pr-items-total-box {
  padding: 20px 10px;
  width: 400px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  div {
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    padding-top: 20px;
    display: flex;
    p {
      margin: 0;
      margin-right: 10px;
      min-width: 80px;
      position: relative;
      span {
        position: absolute;
        right: 0;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
.deleteProcItem {
  font-size: 12px;
  color: red;
  margin: 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
