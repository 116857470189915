import axios from 'axios'
import buildType from '../../services/buildType'
// import router from '../../router/index'
export default {
  state: {
    oAuth: buildType.apiURL('oauth'),
    month: [
      { id: 1, name: 'JAN' },
      { id: 2, name: 'FEB' },
      { id: 3, name: 'MAR' },
      { id: 4, name: 'APR' },
      { id: 5, name: 'MAY' },
      { id: 6, name: 'JUN' },
      { id: 7, name: 'JUL' },
      { id: 8, name: 'AGS' },
      { id: 9, name: 'SEP' },
      { id: 10, name: 'OKT' },
      { id: 11, name: 'NOV' },
      { id: 12, name: 'DES' }
    ],
    isLoading: false,
    production: process.env.VUE_APP_BUILD_TYPE !== 'development'
    // permission: {}
  },
  mutations: {
    setIsLoading(state, payload) {
      state.isLoading = payload
    }
    // setPermission(state, payload) {
    //   state.permission = payload
    // }
  },
  actions: {
    menu_access(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.oAuth}user_level_access/get_access?menu_id=${payload[0]}&level_id=${payload[1]}`
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    documentAuthority(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.approval_matrix !== null) {
          axios
            .get(
              `${context.state.oAuth}approval_matrix/document/allow_user?document_id=${payload.approval_matrix[0].document_id}`
            )
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              reject(error)
            })
        } else {
          const response = {
            data: {
              status_code: '-99',
              status_msg: 'Approval Matrix is Null'
            }
          }
          resolve(response.data)
        }
      })
    },

    approvalMatrixDocumentList(context, payload) {
      return new Promise((resolve, reject) => {
        if (payload.approval_matrix !== null) {
          axios
            .get(
              `${context.state.oAuth}approval_matrix/document/list?limit=100&offset=0&keyword=&application_id=2&table_name=${payload.approval_matrix[0].table_name}&document_id=${payload.approval_matrix[0].document_id}`
            )
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              reject(error)
            })
        } else {
          const response = {
            data: {
              status_code: '-99',
              status_msg: 'Approval Matrix is Null'
            }
          }
          resolve(response.data)
        }
      })
    },

    fetchMatrix(context, payload) {
      const form = {
        act: 'fetch_matrix',
        document_id: payload.approval_matrix[0].document_id,
        document_no: payload.procurement_no,
        application_id: 2,
        table_name: payload.approval_matrix[0].table_name
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.oAuth}approval_matrix/document/save`, form)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {
    // getPermission(state) {
    //   return state.permission
    // },
    getMonth(state) {
      return state.month
    },
    getIsLoading(state) {
      return state.isLoading
    },
    getProductImageLink(state) {
      if (state.production) {
        return 'https://e-sanqua.sanquawater.co.id/image/ecatalogue/'
      } else {
        return 'http://10.10.20.8:8020/'
      }
    }
  }
}
