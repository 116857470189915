<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="100%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          small
          tile
          color="#e0e0e0"
          class="ma-2 black--text"
          style="width:100px; position:relative;"
        >
          Import
          <v-icon small right dark>
            mdi-file-import
          </v-icon>
        </v-btn>
      </template>

      <v-card
        v-if="perm.read_perm === 1"
        style="min-height:100vh; padding:20px; background:#e0e0e0;"
      >
        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:10px 15px 20px 15px; width:95%; margin-top:30px;"
        >
          <v-row>
            <v-col cols="12">
              <v-toolbar flat style="border-radius:10px 10px 0 0;">
                <v-btn
                  class="mx-2 elevation-4"
                  dark
                  large
                  color="cyan"
                  style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                >
                  <v-icon large dark>
                    mdi-upload
                  </v-icon>
                </v-btn>
                <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
                <v-toolbar-title>Upload File</v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12" style="padding:0;">
              <p style="margin:0; font-size:14px; padding-left:35px;">
                Please download tempalate for upload <a href="#">here</a>
              </p>
            </v-col>
            <v-col cols="12" style="padding-left:35px;">
              <p style="border-top:5px solid cyan;"></p>
            </v-col>
            <v-col cols="12" style="padding: 0 0 30px 30px;">
              <v-row>
                <v-col cols="10">
                  <v-file-input
                    show-size
                    label="File input"
                    v-model="uploadFile"
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    tile
                    small
                    color="grey"
                    class="ma-2 white--text"
                    @click="productImport"
                    :disabled="uploadFile === null"
                  >
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:20px 10px; width:95%; margin-top:20px;"
        >
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="productData"
                class="elevation-1 mx-auto row-pointer"
                style="width:99%; margin-top: 20px; margin-bottom:20px;"
                :items-per-page="itemsPerPage"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                    style="height:100px; border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;"
                  >
                    <v-btn
                      class="mx-2 elevation-4"
                      dark
                      large
                      color="cyan"
                      style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                    >
                      <v-icon large dark>
                        mdi-clipboard-list-outline
                      </v-icon>
                    </v-btn>
                    <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
                    <v-toolbar-title>Product List Import</v-toolbar-title>
                  </v-toolbar>
                </template>
              </v-data-table>
              <v-col cols="12" style="padding:0 5px;">
                <v-btn
                  tile
                  style="margin-right:20px;"
                  color="success"
                  height="30px"
                  width="100px"
                  :disabled="!isImport"
                  @click="saveProductImport"
                >
                  Save Data
                </v-btn>
                <v-btn
                  tile
                  style="margin-right:20px;"
                  color="pink"
                  height="30px"
                  width="100px"
                  class="white--text"
                  :disabled="!isImport"
                  @click="remove"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-card>

      <v-card
        flat
        height="90vh"
        width="100%"
        v-else
        color="white"
        style="display:flex; justify-content:center; align-items:center; padding:20px; background:#e0e0e0;"
      >
        <v-toolbar-title class="overline">
          You Can't Access This Page</v-toolbar-title
        >
      </v-card>

      <v-card
        v-if="getIsLoading === true"
        class="mx-auto"
        width="99%"
        height="260"
        style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
      >
        <v-progress-circular :size="170" :width="10" color="blue" indeterminate
          >Please wait</v-progress-circular
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['perm'],
  data() {
    return {
      dialog: false,
      isUpload: false,
      uploadFile: null,
      isImport: false,
      itemsPerPage: 10,

      headers: [
        {
          text: 'No',
          value: 'no'
        },
        {
          text: 'Code',
          value: 'code'
        },
        {
          text: 'Kategori',
          value: 'category_id'
        },
        { text: 'Nama', value: 'name' },
        {
          text: 'Merk',
          value: 'merk'
        },
        {
          text: 'Unit',
          value: 'unit_id'
        },
        {
          text: 'Spesifikasi',
          value: 'spesification',
          width: '30%'
        }
      ],
      productData: []
    }
  },
  computed: {
    ...mapGetters(['getIsLoading'])
  },
  methods: {
    ...mapActions(['productFile', 'saveImportProduct']),
    ...mapMutations(['setIsLoading']),
    showMsgDialog(pModalType, pStatusMsg) {
      this.$swal
        .fire({
          // title:
          //   pModalType === 'success'
          //     ? '<strong>Success</strong>'
          //     : `<strong>${pModalType}</strong>`,
          // timer: 5000

          icon: pModalType,
          html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
          showConfirmButton: true,
          showCancelButton: true
        })
        .then(r => {})
    },
    remove() {
      this.productData = []
      this.isImport = false
    },
    clear() {
      this.uploadFile = null
    },
    productImport() {
      this.productData = []
      if (
        this.uploadFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.setIsLoading(true)
        const data = new FormData()
        data.append('file', this.uploadFile)
        console.log(this.uploadFile)

        this.productFile(data)
          .then(result => {
            console.log(result)
            if (result.data.status_code === '00') {
              for (let i = 0; i < result.data.data.length; i++) {
                if (result.data.data[i].name !== '') {
                  const param = {
                    no: 1 + i,
                    code: result.data.data[i].code,
                    name: result.data.data[i].name,
                    category_id: result.data.data[i].category_id,
                    unit_id: result.data.data[i].unit_id,
                    merk: result.data.data[i].merk,
                    spesification: result.data.data[i].spesification,
                    is_asset: result.data.data[i].is_asset
                  }
                  if (result.data.data[i].id !== undefined) {
                    Object.assign(param, {
                      id: result.data.data[i].id
                    })
                  }
                  this.productData.push(param)
                }
              }
              this.uploadFile = null
              this.isImport = true
              this.setIsLoading(false)
            } else {
              this.showMsgDialog('error', result.data)
              this.setIsLoading(false)
              this.productData = []
              this.isImport = false
            }
          })
          .catch(error => {
            this.setIsLoading(false)
            this.showMsgDialog('error', error)
            this.productData = []
            this.isImport = false
          })
      } else {
        this.showMsgDialog('error', 'File type must be .xlsx')
        this.clear()
      }
    },
    saveProductImport() {
      const form = {
        act: 'add',
        data: this.productData
      }
      this.setIsLoading(true)
      this.saveImportProduct(form)
        .then(result => {
          if (result.data.status_code === '00') {
            this.setIsLoading(false)
            if (result.data.err_msg === '') {
              this.showMsgDialog(
                'success',
                'All of data was ' + result.data.status_msg
              )
            } else {
              this.showMsgDialog('warning', result.data.err_msg)
            }
            this.productData = []
            this.isImport = false
          }
        })
        .catch(error => {
          console.log(error)
          this.setIsLoading(false)
          this.showMsgDialog('error', error)
          this.productData = []
          this.isImport = false
        })
    }
  }
}
</script>
<style></style>
