<template>
  <div>
    <div style="margin:0 0 20px 0;">
      <Navigation chosenpage="Master Export" @setDrawer="drawerSet" />
    </div>

    <v-card
      elevation="2"
      class=" mx-auto"
      style="padding:10px 10px; width:95%; margin-top:20px;"
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="classificationData"
            class="elevation-1 mx-auto row-pointer"
            style="width:99%; margin-top: 20px; margin-bottom:20px;"
            :items-per-page="itemsPerPage"
            @click:row="rowClick"
          >
            <template v-slot:top>
              <v-toolbar flat style="border-bottom:1px solid #e0e0e0;">
                <v-toolbar-title style="padding-left:10px; "
                  >Classification Export</v-toolbar-title
                >

                <v-divider class="mx-6" inset vertical></v-divider>
                <v-text-field
                  v-model="param.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  style="position: relative; top:15px;"
                ></v-text-field>
                <v-divider class="mx-6" inset vertical></v-divider>
                <v-btn
                  v-if="classificationSelection.length === 0"
                  small
                  tile
                  color="#e0e0e0"
                  class="ma-2 black--text"
                  style="width:100px; position:relative;"
                  @click="exportAll"
                >
                  Export All
                </v-btn>

                <v-btn
                  v-else
                  small
                  tile
                  color="#e0e0e0"
                  class="ma-2 black--text"
                  style="width:100px; position:relative;"
                  @click="exportFile"
                >
                  Export Now
                </v-btn>

                <!-- <router-link to="/master-vendor/quotation/import"> -->
                <div>
                  <ClassificationImport />
                </div>
                <!-- </router-link> -->
                <v-spacer></v-spacer>
                <v-card style="width:110px; position: absolute; right:-10px;">
                  <ClassificationEdit
                    :form="formEdit"
                    @formClose="close"
                    @refreshTable="exportTable"
                  />
                  <v-toolbar-title
                    style="font-size:12px; color: black; text-align:left; padding:3px 10px 0 5px;"
                  >
                    New Classification
                  </v-toolbar-title>
                </v-card>
              </v-toolbar>
            </template>

            <template v-slot:[`item.check`]="{ item }">
              <td>
                &nbsp;
                <v-checkbox
                  v-model="classificationSelection"
                  :value="item"
                  @click.stop
                ></v-checkbox>
              </td>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <td>
                {{ new Date(item.created_at).toLocaleDateString() }}
              </td>
            </template>

            <template v-slot:[`item.updated_at`]="{ item }">
              <td>
                {{ new Date(item.updated_at).toLocaleDateString() }}
              </td>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <td>
                <!-- &nbsp; -->
                <v-btn
                  small
                  elevation="0"
                  color="#e0e0e0"
                  class="black--text"
                  @click.stop="classificationDelete(item)"
                >
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import XLSX from 'xlsx'
import Navigation from '../../../components/navigation/Navigation'
import Drawer from '../../../components/navigation/Drawer'
import ClassificationImport from '../../../components/master/ClassificationImport'
import ClassificationEdit from '../../../components/master/edit/classificationEdit'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: [],
  data() {
    return {
      procAPI: buildType.apiURL('proc'),
      drawer: false,
      dialog: false,
      isImport: false,
      itemsPerPage: 10,
      param: {
        offset: 0,
        limit: 1000,
        keyword: ''
      },
      headers: [
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'check',
          width: '10%'
        },
        { text: 'Classification Name', value: 'name', align: 'left' },
        {
          text: 'Created at',
          sortable: false,
          value: 'created_at',
          align: 'left'
        },
        {
          text: 'Updated at',
          sortable: false,
          value: 'updated_at',
          align: 'left'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          align: 'left'
        }
      ],
      classificationData: [],
      classificationSelection: [],
      formEdit: {
        act: 'add',
        name: ''
      }
    }
  },
  components: {
    Navigation,
    Drawer,
    ClassificationImport,
    ClassificationEdit
  },
  watch: {
    'param.keyword'() {
      this.exportTable()
    }
  },
  computed: {
    ...mapGetters([])
  },
  created() {
    this.exportTable()
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    drawerSet(val) {
      this.drawer = val
    },
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            icon: pModalType,
            html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },

    rowClick(item) {
      this.formEdit = {
        act: 'update',
        id: item.id,
        name: item.name
      }
      document.getElementById('classificationEditBtn').click()
    },
    classificationDelete(item) {
      const id = item.id
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently'
      ).then(r => {
        if (r.isConfirmed) {
          axios
            .delete(`${this.procAPI}master/classification/delete/${id}`)
            .then(res => {
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg)
                this.exportTable()
              } else {
                this.showMsgDialog('error', res.data.status_msg)
              }
            })
            .catch(err => {
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong please contact an Admin'
                  : 'Something went wrong please contact an Admin'
              )
            })
        }
      })
    },
    exportTable() {
      axios
        .get(
          `${this.procAPI}master/classification/list?offset=${this.param.offset}&limit=${this.param.limit}&keyword=${this.param.keyword}`
        )
        .then(res => {
          this.classificationData = []
          const response = res.data.data
          this.classificationData = response
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
    exportAll() {
      this.classificationSelection = this.classificationData
    },
    exportFile() {
      this.showMsgDialog('question', 'Sertakan ID').then(res => {
        if (res.isConfirmed) {
          this.exportNow(1)
        } else if (res.dismiss === 'cancel') {
          this.exportNow(0)
        } else if (res.dismiss === 'backdrop') {
          this.classificationSelection = []
        }
      })
    },
    exportNow(num) {
      const arrData = []
      for (let i = 0; i < this.classificationSelection.length; i++) {
        const param = {
          name: this.classificationSelection[i].name
        }
        if (num === 1) {
          Object.assign(param, {
            id: this.classificationSelection[i].id
          })
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'classification.xlsx')

      setTimeout(() => {
        this.classificationSelection = []
      }, 1000)
    },
    close() {
      this.formEdit = {
        act: 'add',
        name: ''
      }
    }
  }
}
</script>
<style></style>
