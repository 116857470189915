import Vue from 'vue'
import Vuex from 'vuex'
import home from './modules/home'
import auth from './modules/auth'
// import userManage from './modules/userManage'
// import userLevel from './modules/userLevel'
import vendorManage from './modules/vendorManage'
import productManage from './modules/productManage'
import categoryProduct from './modules/categoryProduct'
import vendorCatalogue from './modules/vendorCatalogue'
import vendorDocument from './modules/vendorDocument'
import importService from './modules/import_service'
import procurement from './modules/procurement'
import dropdown from './modules/globalDropdown'
import fpb from './modules/fpb'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    home,
    auth,
    // userManage,
    // userLevel,
    vendorManage,
    productManage,
    categoryProduct,
    vendorCatalogue,
    vendorDocument,
    importService,
    procurement,
    dropdown,
    fpb
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  plugins: [
    createPersistedState({
      paths: [
        'auth.login',
        'auth.userProfile',
        // 'vendorCatalogue.routerLink',
        'vendorManage.vendorDetail',
        'vendorManage.vendorId',
        'vendorManage.vendorCode',
        'vendorCatalogue.act',
        'vendorCatalogue.catalogueData',
        // 'vendorCatalogue.catalogueId',
        'procurement.id',
        'procurement.procDetail',
        'fpb.fpbId'
      ]
      // storage: window.sessionStorage
    })
  ]
})
