import axios from 'axios'
import buildType from '../../services/buildType'
// import Swal from 'sweetalert2'
export default {
  state: {
    procAPI: buildType.apiURL('proc'),
    uploadAPI: buildType.apiURL('upload'),
    isModal: false,
    formName: 'supply',
    catalogueList: [],
    totalItems: 0,
    isResultId: false,
    act: '',
    act2: '',
    act3: '',
    catalogueId: null,
    catalogueForm: {},
    catalogueData: null,
    catalogueDetail: {},
    productDropdown: [],
    currencyDropdown: [],

    quotationList: [],
    quotationDetail: {},
    totalQuotItems: 0,
    quotationForm: {},

    specificationList: [],
    genSpecificationList: [],
    specType: 1,
    totalRawSpecItems: 0,
    totalGenSpecItems: 0,
    rawSpecDetail: {},
    genSpecDetail: {},
    rawSpecForm: {},
    genSpecForm: {},
    specCategory: [],
    specAttribute: [],
    tab: 'SIUP',
    vendorRatting: null,
    routerLink: null
  },
  mutations: {
    setVendorDetailRouter(state, payload) {
      state.routerLink = payload
    },
    setVendorRatting(state, payload) {
      if (payload.status_code === '00') {
        state.vendorRatting = payload.data
      } else if (payload.status_code === '-99') {
        state.vendorRatting = null
      } else {
        state.vendorRatting = null
      }
    },
    setVendorDocumentTab(state, payload) {
      state.tab = payload
    },
    setVendorCatalogueModal(state, payload) {
      state.isModal = payload[0]
      state.formName = payload[1]
      state.act2 = payload[2]
    },
    setCatalogueSupplyDetail(state, payload) {
      state.quotationDetail = payload
    },
    setCatalogueRawSpecDetail(state, payload) {
      state.rawSpecDetail = payload
    },
    setCatalogueGenSpecDetail(state, payload) {
      state.genSpecDetail = payload
    },
    setCatalogueList(state, payload) {
      state.catalogueList = []
      const response = payload.data
      if (response !== undefined) {
        state.totalItems = payload.total_record
        for (let i = 0; i < response.length; i++) {
          state.catalogueList.push({
            // no: 1 + i,
            id: response[i].id,
            product_name: response[i].product.name,
            product_code: response[i].product.code,
            merk: response[i].merk !== '' ? response[i].merk : '-',
            product_category: response[i].product.category,
            currency: response[i].currency,
            last_price: response[i].last_price,
            last_ordered:
              response[i].last_ordered !== null
                ? response[i].last_ordered
                : '-',
            last_purchase_plant:
              response[i].last_purchase_plant !== null ||
              response[i].last_purchase_plant !== ''
                ? response[i].last_purchase_plant
                : '-',
            product_detail: response[i].product
          })
        }
      } else {
        state.totalItems = 1
        state.catalogueList = []
      }
    },
    setCatalogueAct(state, payload) {
      state.act = payload[0]
      state.catalogueData = payload[1]
    },
    setIsResultId(state, payload) {
      state.isResultId = payload
    },
    setCatalogueAddId(state, payload) {
      state.catalogueId = payload
    },
    setFormCatalogueManage(state, payload) {
      console.log(payload)
      const form = {
        act: state.act,
        vendor_id: payload.vendor_id,
        product_id: payload.product_id,
        merk: payload.merk,
        uom_id: payload.uom.id,
        uom_name: payload.uom.name,
        purchase_uom_id: payload.purchase_uom.id,
        purchase_uom_name: payload.purchase_uom.name,
        file_brochure: payload.file_brochure,
        catalogue_type: payload.catalogue_type,
        description: '-',
        currency_id: payload.currency_id,
        last_price: payload.last_price,
        last_ordered: payload.last_ordered,
        last_purchase_plant: payload.last_purchase_plant,
        fpb_no: payload.fpb_no
      }

      if (state.act === 'update') {
        Object.assign(form, { id: state.catalogueData.id })
        Object.assign(form, { description: payload.description })
      }
      if (form.last_ordered === null || form.last_ordered === '') {
        // delete form.last_ordered
        form.last_ordered = null
      }
      console.log(form)
      state.catalogueForm = form
    },
    setCatalogueDetail(state, payload) {
      state.catalogueDetail = payload
    },
    setCatalogueDropdown(state, payload) {
      state.productDropdown = payload.data
    },
    setQuotationList(state, payload) {
      state.quotationList = []
      const response = payload.data
      if (response !== undefined) {
        state.totalQuotItems = payload.total_record
        for (let i = 0; i < response.length; i++) {
          state.quotationList.push({
            no: 1 + i,
            period: [response[i].period_start, response[i].period_end],
            unit_id: response[i].uom.id,
            unit_name: response[i].uom.name,
            unitPrice: response[i].price_per_unit,
            fileSupport: response[i].file_quotation,
            period_start: response[i].period_start,
            period_end: response[i].period_end,
            id: response[i].id,
            detail: response[i]
          })
        }
      } else {
        state.totalQuotItems = 1
        state.quotationList = []
      }
    },
    setQuotationForm(state, payload) {
      const form = {
        act: state.act2,
        period_start: payload.period_start,
        period_end: payload.period_end,
        uom_id: payload.uom_id,
        uom_name: payload.uom_name,
        file_quotation: payload.file_quotation,
        price_per_unit: Number(payload.price_per_unit)
      }
      if (state.act === 'add') {
        if (state.act2 === 'add') {
          Object.assign(form, { vendor_catalogue_id: state.catalogueId })
        } else {
          Object.assign(form, { vendor_catalogue_id: state.catalogueId })
          Object.assign(form, { id: state.quotationDetail.id })
        }
      }
      if (state.act === 'update') {
        if (state.act2 === 'add') {
          Object.assign(form, { vendor_catalogue_id: state.catalogueData.id })
        } else {
          Object.assign(form, { vendor_catalogue_id: state.catalogueData.id })
          Object.assign(form, { id: state.quotationDetail.id })
        }
      }

      state.quotationForm = form
    },
    setSpecType(state, payload) {
      state.specType = payload === 'Bahan Baku' ? 1 : 2
    },
    setSpecificationList(state, payload) {
      state.specificationList = []
      const response = payload.data
      if (response !== undefined) {
        state.totalRawSpecItems = payload.total_record
        for (let i = 0; i < response.length; i++) {
          state.specificationList.push({
            no: 1 + i,
            id: response[i].id,
            criteria: response[i].criteria,
            unit_detail: response[i].unit,
            unit_id: response[i].unit !== null ? response[i].unit.id : null,
            unit_name: response[i].unit !== null ? response[i].unit.name : '',
            description: response[i].description,
            standard: response[i].standard !== '' ? response[i].standard : '-',
            spesification_attribute_detail: response[i].spesification_attribute,
            spesification_attribute_id: response[i].spesification_attribute.id,
            spesification_attribute_name:
              response[i].spesification_attribute.name,
            spesification_category_id: response[i].spesification_category.id,
            spesification_category_name:
              response[i].spesification_category.name,
            spesification_category_detail: response[i].spesification_category
          })
        }
      } else {
        state.totalRawSpecItems = 1
        state.specificationList = []
      }
    },
    setSpesificationForm(state, payload) {
      const form = {
        act: state.act2,
        spesification_category_id: payload.spesification_category_id,
        spesification_attribute_id: payload.spesification_attribute_id,
        description: payload.description,
        spesification_type: state.specType,
        standard: payload.standard,
        unit_id: payload.unit_id,
        criteria: payload.criteria,
        analysis_method: payload.analysis_method,
        min_frequency_supplier: payload.min_frequency_supplier,
        min_frequency_sanqua: payload.min_frequency_sanqua
      }
      if (state.act === 'add') {
        if (state.act2 === 'add') {
          Object.assign(form, { vendor_catalogue_id: state.catalogueId })
        } else {
          Object.assign(form, { vendor_catalogue_id: state.catalogueId })
          Object.assign(form, { id: state.rawSpecDetail.id })
        }
      }
      if (state.act === 'update') {
        if (state.act2 === 'add') {
          Object.assign(form, { vendor_catalogue_id: state.catalogueData.id })
        } else {
          Object.assign(form, { vendor_catalogue_id: state.catalogueData.id })
          Object.assign(form, { id: state.rawSpecDetail.id })
        }
      }
      state.rawSpecForm = form
    },
    setGenAddAct(state, payload) {
      state.act2 = payload
    },
    setGenSpesificationForm(state, payload) {
      const form = {
        act: state.act2,
        spesification_category_id: payload.spesification_category_id,
        spesification_attribute_id: payload.spesification_attribute_id,
        description: payload.description,
        spesification_type: state.specType,
        standard: payload.standard,
        unit_id: payload.unit_id,
        criteria: payload.criteria,
        analysis_method: payload.analysis_method,
        min_frequency_supplier: payload.min_frequency_supplier,
        min_frequency_sanqua: payload.min_frequency_sanqua
      }
      if (state.act === 'add') {
        if (state.act2 === 'add') {
          Object.assign(form, { vendor_catalogue_id: state.catalogueId })
        } else {
          Object.assign(form, { vendor_catalogue_id: state.catalogueId })
          Object.assign(form, { id: state.genSpecDetail.id })
        }
      }
      if (state.act === 'update') {
        if (state.act2 === 'add') {
          Object.assign(form, { vendor_catalogue_id: state.catalogueData.id })
        } else {
          Object.assign(form, { vendor_catalogue_id: state.catalogueData.id })
          Object.assign(form, { id: state.genSpecDetail.id })
        }
      }
      state.genSpecForm = form
    },
    setSpecDropdownCategory(state, payload) {
      state.specCategory = payload.data
    },
    setSpecDropdownAttribute(state, payload) {
      state.specAttribute = payload.data
    },
    setGenSpecificationList(state, payload) {
      state.genSpecificationList = []
      const response = payload.data
      if (response !== undefined) {
        state.totalGenSpecItems = payload.total_record
        for (let i = 0; i < response.length; i++) {
          state.genSpecificationList.push({
            no: 1 + i,
            id: response[i].id,
            criteria: response[i].criteria,
            unit_detail: response[i].unit,
            description: response[i].description,
            standard: response[i].standard,
            specAttributeDetail: response[i].spesification_attribute,
            specCategoryDetail: response[i].spesification_category
          })
        }
      } else {
        state.totalGenSpecItems = 1
        state.genSpecificationList = []
      }
    },
    setNewGenLine(state, payload) {
      if (payload === 1) {
        state.genSpecificationList.push({
          no: 1 + state.genSpecificationList.length,
          id: null,
          criteria: null,
          unit_detail: null,
          unit_id: null,
          unit_name: '',
          description: '',
          standard: '',
          specAttributeDetail: null,
          specAttributeId: null,
          specAttributeName: '',
          specCategoryId: null,
          specCategoryName: '',
          specCategoryDetail: null
        })
      } else {
        state.genSpecificationList.pop()
      }
    },
    setCurrencyDropdown(state, payload) {
      state.currencyDropdown = payload.data
    }
  },
  actions: {
    loadRate(context, payload) {
      context.state.vendorRatting = null
      axios
        .get(
          `${context.state.procAPI}vendor/rate/list?keyword=&offset=0&limit=10&vendor_id=${payload}`
        )
        .then(response => {
          context.commit('setVendorRatting', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    saveRatting(context, payload) {
      const newForm = {
        act: 'add',
        vendor_id: payload[1],
        rate_date: new Date().toISOString().substr(0, 10),
        pic: payload[0].pic,
        harga: payload[0].harga,
        kualitas: payload[0].kualitas,
        pengiriman: payload[0].pengiriman,
        komunikatif: payload[0].komunikatif,
        kesesuaian_penawaran: payload[0].kesesuaian_penawaran
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/rate/save`, newForm)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    catalogueList(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.procAPI}vendor/catalogue/list?keyword=${
              payload[1]
            }&offset=${payload[2]}&limit=${payload[3]}&vendor_id=${payload[0]}${
              payload[4] ? '&is_asset=1' : ''
            }`
          )
          .then(response => {
            console.log(response)
            context.commit('setCatalogueList', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    catalogueManage(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}vendor/catalogue/save`,
            context.state.catalogueForm
          )
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    catalogueDelete(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.procAPI}vendor/catalogue/delete/${payload.id}`
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    catalogueDetail(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.procAPI}vendor/catalogue/detail/${context.state.catalogueData.id}&offset=0&limit=10&keyword=`
          )
          .then(response => {
            context.commit('setCatalogueDetail', response.data.data)
            resolve(response.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    brochureCatalogue(context, payload) {
      const data = new FormData()
      data.append('file', payload)
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.uploadAPI}upload/vendor_catalogue/brochure`,
            data
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    productDropdown(context, payload) {
      axios
        .get(
          `${context.state.procAPI}master/product/drop_down?keyword=${payload[0]}&offset=${payload[1]}&limit=${payload[2]}`
        )
        .then(response => {
          context.commit('setCatalogueDropdown', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    currencyDropdown(context) {
      axios
        .get(`${context.state.procAPI}master/currency/drop_down?keyword=`)
        .then(response => {
          context.commit('setCurrencyDropdown', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    quotationList(context, payload) {
      let catalogueId = ''
      if (context.state.act === 'update') {
        catalogueId = context.state.catalogueData.id
      } else if (context.state.act === 'add') {
        catalogueId = context.state.catalogueId
      }
      axios
        .get(
          `${context.state.procAPI}vendor/catalogue_quotation/list?offset=${payload[2]}&limit=${payload[3]}&keyword=${payload[1]}&vendor_catalogue_id=${catalogueId}`
        )
        .then(response => {
          context.commit('setQuotationList', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    manageQuotation(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}vendor/catalogue_quotation/save`,
            context.state.quotationForm
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    uploadQuotationFile(context, payload) {
      const data = new FormData()
      data.append('file', payload)
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.uploadAPI}upload/vendor_quotation/file`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    quotationFileDownload(context, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${context.state.uploadAPI}download/vendor_quotation/file/${payload}`,
          method: 'GET',
          responseType: 'blob'
        })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', payload)
            document.body.appendChild(link)
            link.click()
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    brochureDownload(context, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${context.state.uploadAPI}download/vendor_catalogue/brochure/${payload}`,
          method: 'GET',
          responseType: 'blob'
        })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', payload)
            document.body.appendChild(link)
            link.click()
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteQuotation(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.procAPI}vendor/catalogue_quotation/delete/${payload.id}`
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    spesificationCategoryDropdown(context, payload) {
      axios
        .get(
          `${context.state.procAPI}master/spesification_category/drop_down?keyword=`
        )
        .then(response => {
          context.commit('setSpecDropdownCategory', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    spesificationAttributeDropdown(context, payload) {
      context.state.specAttribute = []
      axios
        .get(
          `${context.state.procAPI}master/spesification_attribute/drop_down?keyword=&spesification_category_id=${payload[0]}`
        )
        .then(response => {
          context.commit('setSpecDropdownAttribute', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    specificationList(context, payload) {
      let catalogueId = ''
      if (context.state.act === 'update') {
        catalogueId = context.state.catalogueData.id
      } else if (context.state.act === 'add') {
        catalogueId = context.state.catalogueId
      }
      // &spesification_type=${context.state.specType}
      axios
        .get(
          `${context.state.procAPI}vendor/catalogue_spesification/list?offset=${payload[1]}&limit=${payload[2]}&keyword=${payload[0]}&vendor_catalogue_id=${catalogueId}`
        )
        .then(response => {
          if (context.state.specType === 1) {
            context.commit('setSpecificationList', response.data)
          } else if (context.state.specType === 2) {
            context.commit('setGenSpecificationList', response.data)
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    manageSpesification(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}vendor/catalogue_spesification/save`,
            context.state.rawSpecForm
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    manageGenSpesification(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}vendor/catalogue_spesification/save`,
            context.state.genSpecForm
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteRawSpesification(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.procAPI}vendor/catalogue_spesification/delete/${payload.id}`
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteGenSpesification(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.procAPI}vendor/catalogue_spesification/delete/${payload.id}`
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {
    getVendorRouterDetail(state) {
      return state.routerLink
    },
    getVendorRatting(state) {
      return state.vendorRatting
    },
    getVdrDocumentTab(state) {
      return state.tab
    },
    getVdrCatalogueModalStat(state) {
      const status = [state.isModal, state.formName]
      return status
    },
    getIsResultId(state) {
      return state.isResultId
    },
    getCatalogueList(state) {
      return state.catalogueList
    },
    getTotalCatalogue(state) {
      return state.totalItems
    },
    getCatalogueAct(state) {
      return state.act
    },
    getCatalogueDetail(state) {
      return state.catalogueDetail
    },
    getProductDropdown(state) {
      return state.productDropdown
    },
    getCurrencyDropdown(state) {
      return state.currencyDropdown
    },

    getQuotationList(state) {
      return state.quotationList
    },
    getQuotationDetail(state) {
      return state.quotationDetail
    },
    getTotalQuotItem(state) {
      return state.totalQuotItems
    },
    getQuotationAct(state) {
      return state.act2
    },
    getSpecificationAct(state) {
      return state.act2
    },
    getRawSpecDetail(state) {
      return state.rawSpecDetail
    },
    getTotalRawSpecItem(state) {
      return state.totalRawSpecItems
    },
    getRawSpecificationList(state) {
      return state.specificationList
    },
    getSpecCategoryDropdown(state) {
      return state.specCategory
    },
    getSpecAttributeDropdown(state) {
      return state.specAttribute
    },

    getTotalGenSpecItem(state) {
      return state.totalGenSpecItems
    },
    getGenSpecificationList(state) {
      return state.genSpecificationList
    },
    getGenSpecDetail(state) {
      return state.genSpecDetail
    },
    getSpecType(state) {
      return state.specType
    }
  }
}
