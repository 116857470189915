// import axios from 'axios'
import buildType from '../../services/buildType'
export default {
  state: {
    procAPI: buildType.apiURL('proc'),
    fpbId: null
  },
  mutations: {
    setFpbId(state, payload) {
      state.fpbId = payload
    }
  },
  actions: {},
  getters: {
    getFpbId(state) {
      return state.fpbId
    }
  }
}
