var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"margin":"0 0 20px 0"}},[_c('Navigation',{attrs:{"chosenpage":"Vendor Catalogue"}})],1),(_vm.pageStatus === true)?_c('v-row',{staticStyle:{"position":"relative","padding":"0","margin":"0 0 20px 0"},attrs:{"justify":"center"}},[_c('v-card',{staticStyle:{"width":"99%","padding":"0","margin":"0"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{staticStyle:{"color":"White","padding":"0 10px","margin-right":"20px"},attrs:{"tile":"","color":"pink"},on:{"click":function($event){$event.preventDefault();return _vm.backToProfile($event)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("unit mdi-arrow-left ")]),_vm._v(" Back ")],1),_c('v-toolbar-title',[_vm._v("Detail Vendor Catalogue")]),_c('v-spacer'),_c('v-btn',{staticStyle:{"color":"White","padding":"0 10px","margin-right":"20px"},attrs:{"tile":"","color":"warning","disabled":_vm.getCatalogueAct === 'update' && _vm.permission.update_perm !== 1},on:{"click":function($event){$event.preventDefault();return _vm.saveCatalogue($event)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-content-save-outline ")]),_vm._v(" Save ")],1)],1),(_vm.permission.read_perm === 1)?_c('div',[_c('v-list',{attrs:{"three-line":"","subheader":"","min-height":"40vh"}},[_c('v-subheader',{staticClass:"headline mb-2"},[_vm._v("#"+_vm._s(_vm.product_code)+" "+_vm._s(_vm.getVendorDetail.name))]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"loading":_vm.loading,"items":_vm.itemss,"search-input":_vm.search,"hide-no-data":"","hide-details":"","item-text":"name","item-value":"id","return-id":"","clearable":"","label":_vm.detailProduct !== null
                            ? _vm.detailProduct.product_name
                            : 'Product',"solo-inverted":"","disabled":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm !== 1},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.querySelections(_vm.search)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticStyle:{"background":"rgba(255,255,255,0.1)"}},[_vm._v(" "+_vm._s('(' + item.code + ')' + ' ' + item.name)+" ")])]}}],null,false,4070200950),model:{value:(_vm.formCatalogue.product_id),callback:function ($$v) {_vm.$set(_vm.formCatalogue, "product_id", $$v)},expression:"formCatalogue.product_id"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Merk","readonly":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm !== 1,"required":""},model:{value:(_vm.formCatalogue.merk),callback:function ($$v) {_vm.$set(_vm.formCatalogue, "merk", $$v)},expression:"formCatalogue.merk"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.getUnitDropdown,"label":"UOM","item-text":"name","item-value":"id","return-object":"","required":"","clearable":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm === 1,"readonly":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm !== 1,"rules":_vm.rules.uomRules},model:{value:(_vm.formCatalogue.uom),callback:function ($$v) {_vm.$set(_vm.formCatalogue, "uom", $$v)},expression:"formCatalogue.uom"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.getUnitDropdown,"label":"Purchase UOM","item-text":"name","item-value":"id","return-object":"","required":"","clearable":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm === 1,"readonly":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm !== 1,"rules":_vm.rules.puomRules},model:{value:(_vm.formCatalogue.purchase_uom),callback:function ($$v) {_vm.$set(_vm.formCatalogue, "purchase_uom", $$v)},expression:"formCatalogue.purchase_uom"}})],1),_c('v-col',{staticStyle:{"display":"flex","flex-direction":"column","padding":"0","height":"55px"},attrs:{"cols":"8"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","padding":"0 15px","height":"55px"}},[_c('v-file-input',{attrs:{"disabled":_vm.getCatalogueAct === 'update' &&
                              _vm.permission.update_perm !== 1,"chips":"","prepend-icon":"","append-icon":"mdi-attachment","truncate-length":"20","label":_vm.brochureLabel || 'Upload File Brosur',"outlined":""},on:{"change":_vm.brochureUpload}})],1)]),(_vm.permission.read_perm === 1)?_c('v-col',{staticStyle:{"padding":"0 45px","text-align":"right","position":"relative","bottom":"30px"},attrs:{"cols":"12"}},[(_vm.brochureLabel !== '')?_c('a',{staticStyle:{"paddingLeft":"35px"},attrs:{"href":"#"},on:{"click":function($event){return _vm.downloadBrochure(_vm.brochureLabel)}}},[_vm._v(" Download brosur ")]):_vm._e()]):_vm._e(),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":[
                          { id: 1, name: 'Bahan Baku' },
                          { id: 2, name: 'Umum' }
                        ],"label":"Spesification Type","item-text":"name","item-value":"id","return-id":"","required":"","clearable":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm === 1,"readonly":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm !== 1},on:{"change":_vm.catalogueType},model:{value:(_vm.formCatalogue.catalogue_type),callback:function ($$v) {_vm.$set(_vm.formCatalogue, "catalogue_type", $$v)},expression:"formCatalogue.catalogue_type"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-autocomplete',{attrs:{"label":"Purchased by","items":[
                          'IMP',
                          'TMP',
                          'GIT',
                          'KAJ',
                          'WIM',
                          'SMI',
                          'TMP BAWEN'
                        ],"required":"","clearable":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm === 1,"readonly":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm !== 1},model:{value:(_vm.formCatalogue.last_purchase_plant),callback:function ($$v) {_vm.$set(_vm.formCatalogue, "last_purchase_plant", $$v)},expression:"formCatalogue.last_purchase_plant"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Last Price","readonly":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm !== 1},model:{value:(_vm.formCatalogue.last_price),callback:function ($$v) {_vm.$set(_vm.formCatalogue, "last_price", $$v)},expression:"formCatalogue.last_price"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"items":_vm.getCurrencyDropdown,"label":"Currency","item-text":"code","item-value":"id","return-id":"","required":"","clearable":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm === 1,"readonly":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm !== 1},model:{value:(_vm.formCatalogue.currency_id),callback:function ($$v) {_vm.$set(_vm.formCatalogue, "currency_id", $$v)},expression:"formCatalogue.currency_id"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"Last Ordered (yyyy-mm-dd)","readonly":_vm.getCatalogueAct === 'update' &&
                            _vm.permission.update_perm !== 1,"type":"date","name":"date"},model:{value:(_vm.formCatalogue.last_ordered),callback:function ($$v) {_vm.$set(_vm.formCatalogue, "last_ordered", $$v)},expression:"formCatalogue.last_ordered"}})],1),(_vm.getCatalogueAct === 'update')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-textarea',{attrs:{"clearable":_vm.getCatalogueAct === 'update' &&
                              _vm.permission.update_perm === 1,"readonly":_vm.getCatalogueAct === 'update' &&
                              _vm.permission.update_perm !== 1,"outlined":"","clear-icon":"mdi-close-circle","label":"Keterangan","rules":_vm.rules.descriptionRules},model:{value:(_vm.formCatalogue.description),callback:function ($$v) {_vm.$set(_vm.formCatalogue, "description", $$v)},expression:"formCatalogue.description"}})],1)],1):_vm._e()],1)],1)],1)],1)],1)],1),_c('v-divider'),(_vm.getIsResultId === true)?_c('v-list',{staticStyle:{"padding":"0"},attrs:{"three-line":"","subheader":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"cyan","dark":"","flat":""}},[_c('v-tabs',{attrs:{"align-with-title":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"yellow"}}),_vm._l((_vm.items),function(item){return _c('v-tab',{key:item},[_vm._v(" "+_vm._s(item)+" ")])})],2)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab-item',{key:item},[_c('v-card',{staticStyle:{"min-height":"32vh"},attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-2 mx-auto",staticStyle:{"width":"99%","margin-top":"20px","margin-bottom":"20px"},attrs:{"headers":item === 'penawaran'
                        ? _vm.supplyHead
                        : _vm.specType === 'Bahan Baku'
                        ? _vm.rawSpecHead
                        : _vm.genSpecHead,"items":item === 'penawaran'
                        ? _vm.getQuotationList
                        : _vm.specType === 'Bahan Baku'
                        ? _vm.getRawSpecificationList
                        : _vm.getGenSpecificationList,"items-per-page":item === 'penawaran'
                        ? _vm.supplyPerPage
                        : _vm.specType === 'Bahan Baku'
                        ? _vm.rawPerPage
                        : _vm.genPerPage,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar-title',{staticStyle:{"padding":"0 0 0 10px"}},[_vm._v(_vm._s(item === 'penawaran' ? 'History Penawaran' : 'Spesifikasi'))]),(item === 'spesifikasi')?_c('v-toolbar',{staticStyle:{"margin-top":"20px"},attrs:{"elevation":"1"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"items":['Bahan Baku', 'Umum'],"clearable":"","label":"Tipe Spesifikasi","disabled":"","dense":""},on:{"change":_vm.spesificationTypeChange},model:{value:(_vm.specType),callback:function ($$v) {_vm.specType=$$v},expression:"specType"}})],1),_c('v-divider',{staticClass:"mx-6",attrs:{"inset":"","vertical":""}}),(_vm.specType === 'Bahan Baku')?_c('v-text-field',{staticStyle:{"position":"relative","top":"15px","width":"10px"},attrs:{"label":"Search here","type":"search","outlined":"","dense":""},model:{value:(_vm.invokeSrc2),callback:function ($$v) {_vm.invokeSrc2=$$v},expression:"invokeSrc2"}}):_vm._e(),(_vm.specType === 'Umum')?_c('v-text-field',{staticStyle:{"position":"relative","top":"15px","width":"10px"},attrs:{"label":"Search here","type":"search","outlined":"","dense":""},model:{value:(_vm.invokeSrc3),callback:function ($$v) {_vm.invokeSrc3=$$v},expression:"invokeSrc3"}}):_vm._e()],1):_vm._e()]},proxy:true},{key:"item.period",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.period[0],'DD MMM YYYY'))+" - "+_vm._s(_vm._f("moment")(item.period[1],'DD MMM YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [(_vm.specType === 'Umum' && item.id !== null)?_c('v-btn',{staticClass:"ma-1 white--text",attrs:{"color":"cyan","small":"","disabled":_vm.permission.read_perm !== 1},on:{"click":function($event){_vm.tab === 0
                            ? _vm.formEdit([true, 'supply', 'update', item])
                            : _vm.formEdit([true, 'genspec', 'update', item])}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1):_vm._e(),(_vm.specType === 'Umum' && item.id === null)?_c('v-btn',{staticClass:"ma-1 white--text",attrs:{"color":"success","small":"","disabled":_vm.permission.create_perm !== 1},on:{"click":function($event){return _vm.addNewLine(2)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-save ")])]}}],null,true)},[_c('span',[_vm._v("Save spec umum")])])],1):_vm._e(),(_vm.specType !== 'Umum')?_c('v-btn',{staticClass:"ma-1 white--text",attrs:{"color":"cyan","small":"","disabled":_vm.permission.read_perm !== 1},on:{"click":function($event){_vm.tab === 0
                            ? _vm.formEdit([true, 'supply', 'update', item])
                            : _vm.specType === 'Bahan Baku'
                            ? _vm.formEdit([true, 'rawspec', 'update', item])
                            : null}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1):_vm._e(),_c('v-btn',{staticClass:"ma-1 white--text",attrs:{"color":"error","small":"","disabled":_vm.permission.delete_perm !== 1},on:{"click":function($event){_vm.tab === 0
                            ? _vm.deleteSupply(item)
                            : _vm.specType === 'Bahan Baku'
                            ? _vm.deleteRawSpec(item)
                            : _vm.deleteGenSpec(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}},{key:"item.fileSupport",fn:function(ref){
                            var item = ref.item;
return [_c('v-btn',{staticStyle:{"cursor":"pointer","width":"100px","font-size":"10px"},attrs:{"disabled":item.fileSupport === null || item.fileSupport === ''},on:{"click":function($event){return _vm.quotationDownload(item.fileSupport)}}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" download ")],1)]}},{key:"item.unit_name",fn:function(ref){
                            var item = ref.item;
return [(item.id !== null)?_c('p',[_vm._v(" "+_vm._s(item.unit_name !== '' ? item.unit_name : '-')+" ")]):_vm._e()]}},{key:"item.unitPrice",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.unitPrice !== '' ? _vm.price(item.unitPrice) : '-')+" ")]}},(_vm.specType === 'Bahan Baku')?{key:"item.criteria",fn:function(ref){
                            var item = ref.item;
return [(item.id !== null)?_c('p',[_vm._v(" "+_vm._s(item.criteria === 1 ? 'Major' : item.criteria === 2 ? 'Kritikal' : '-')+" ")]):_vm._e()]}}:null,(_vm.specType === 'Umum')?{key:"item.specCategoryDetail",fn:function(ref){
                            var item = ref.item;
return [(item.id !== null)?_c('p',[_vm._v(" "+_vm._s(item.specCategoryDetail !== null ? item.specCategoryDetail.name : '-')+" ")]):_c('v-autocomplete',{attrs:{"items":_vm.getSpecCategoryDropdown,"outlined":"","dense":"","item-text":"name","item-value":"id","return-id":""},on:{"change":_vm.initSpecAttributeDropdown},model:{value:(_vm.genSpecForm.spesification_category_id),callback:function ($$v) {_vm.$set(_vm.genSpecForm, "spesification_category_id", $$v)},expression:"genSpecForm.spesification_category_id"}})]}}:null,(_vm.specType === 'Umum')?{key:"item.specAttributeDetail",fn:function(ref){
                            var item = ref.item;
return [(item.id !== null)?_c('p',[_vm._v(" "+_vm._s(item.specAttributeDetail !== null ? item.specAttributeDetail.name : '-')+" ")]):_c('v-autocomplete',{attrs:{"items":_vm.getSpecAttributeDropdown,"outlined":"","dense":"","item-text":"name","item-value":"id","return-id":"","disabled":_vm.permission.create_perm !== 1},model:{value:(_vm.genSpecForm.spesification_attribute_id),callback:function ($$v) {_vm.$set(_vm.genSpecForm, "spesification_attribute_id", $$v)},expression:"genSpecForm.spesification_attribute_id"}})]}}:null,(_vm.specType === 'Umum')?{key:"item.description",fn:function(ref){
                            var item = ref.item;
return [(item.id !== null)?_c('p',[_vm._v(" "+_vm._s(item.description)+" ")]):_c('v-textarea',{attrs:{"clearable":_vm.permission.create_perm === 1,"disabled":_vm.permission.create_perm !== 1,"outlined":"","dense":"","clear-icon":"mdi-close-circle","label":item ? 'Deskripsi' : 'Deskripsi'},model:{value:(_vm.genSpecForm.description),callback:function ($$v) {_vm.$set(_vm.genSpecForm, "description", $$v)},expression:"genSpecForm.description"}})]}}:null,{key:"footer",fn:function(){return [_c('v-toolbar',[(item === 'penawaran')?_c('v-btn',{staticClass:"ma-1 black--text",attrs:{"color":"#e0e0e0","small":"","disabled":_vm.permission.create_perm !== 1},on:{"click":function($event){return _vm.formAdd([true, 'supply', 'add'])}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("Add New")])],1):_vm._e(),(
                            item === 'spesifikasi' &&
                              _vm.specType === 'Bahan Baku'
                          )?_c('v-btn',{staticClass:"ma-1 black--text",attrs:{"color":"#e0e0e0","small":"","disabled":_vm.permission.create_perm !== 1},on:{"click":function($event){return _vm.formAdd([true, 'rawspec', 'add'])}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-plus ")]),_c('span',[_vm._v("Add New")])],1):_vm._e(),(item === 'spesifikasi' && _vm.specType === 'Umum')?_c('v-btn',{staticClass:"ma-1 black--text",attrs:{"color":"#e0e0e0","small":"","disabled":_vm.permission.create_perm !== 1},on:{"click":function($event){!_vm.isNewLine ? _vm.addNewLine(1) : _vm.addNewLine(0)}}},[(!_vm.isNewLine)?_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-plus ")]):_vm._e(),_vm._v(" "+_vm._s(!_vm.isNewLine ? 'Add New Line' : 'cancel ')+" "),(_vm.isNewLine)?_c('v-icon',{staticStyle:{"margin":"0 0 0 10px"},attrs:{"left":"","small":""}},[_vm._v(" mdi-window-close ")]):_vm._e()],1):_vm._e(),_c('v-spacer'),(item === 'penawaran')?_c('v-pagination',{staticStyle:{"margin-left":"auto"},attrs:{"length":Math.ceil(_vm.getTotalQuotItem / _vm.supplyPerPage) || 1,"total-visible":6},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),(
                            item === 'spesifikasi' &&
                              _vm.specType === 'Bahan Baku'
                          )?_c('v-pagination',{staticStyle:{"margin-left":"auto"},attrs:{"length":Math.ceil(_vm.getTotalRawSpecItem / _vm.rawPerPage) || 1,"total-visible":6},model:{value:(_vm.rawPage),callback:function ($$v) {_vm.rawPage=$$v},expression:"rawPage"}}):_vm._e(),(item === 'spesifikasi' && _vm.specType === 'Umum')?_c('v-pagination',{staticStyle:{"margin-left":"auto"},attrs:{"length":Math.ceil(_vm.getTotalGenSpecItem / _vm.genPerPage) || 1,"total-visible":6},model:{value:(_vm.genPage),callback:function ($$v) {_vm.genPage=$$v},expression:"genPage"}}):_vm._e(),_c('v-spacer'),_c('div',{staticStyle:{"padding":"0","margin":"0","display":"flex","flex-direction":"rows","justify-content":"center","align-items":"center"}},[_c('v-toolbar-title',{staticClass:"caption"},[_vm._v("Rows per page:")]),(item === 'penawaran')?_c('v-select',{staticStyle:{"position":"relative","margin-left":"10px","height":"28px","width":"50px","rightt":"20px","font-size":"12px"},attrs:{"items":[1, 2, 5, 10, 15, 20, 100],"dense":""},model:{value:(_vm.supplyPerPage),callback:function ($$v) {_vm.supplyPerPage=$$v},expression:"supplyPerPage"}}):_vm._e(),(
                              item === 'spesifikasi' &&
                                _vm.specType === 'Bahan Baku'
                            )?_c('v-select',{staticStyle:{"position":"relative","margin-left":"10px","height":"28px","width":"50px","rightt":"20px","font-size":"12px"},attrs:{"items":[1, 2, 5, 10, 15, 20, 100],"dense":""},model:{value:(_vm.rawPerPage),callback:function ($$v) {_vm.rawPerPage=$$v},expression:"rawPerPage"}}):_vm._e(),(
                              item === 'spesifikasi' && _vm.specType === 'Umum'
                            )?_c('v-select',{staticStyle:{"position":"relative","margin-left":"10px","height":"28px","width":"50px","rightt":"20px","font-size":"12px"},attrs:{"items":[1, 2, 5, 10, 15, 20, 100],"dense":""},model:{value:(_vm.genPerPage),callback:function ($$v) {_vm.genPerPage=$$v},expression:"genPerPage"}}):_vm._e()],1),_c('v-spacer')],1)]},proxy:true}],null,true)})],1)],1)}),1)],1)],1):_vm._e()],1):_c('v-card',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"flat":"","height":"75vh"}},[_c('v-toolbar-title',{staticClass:"overline"},[_vm._v(" You Can't Access This Page")])],1)],1)],1):_vm._e(),_c('div',[(
        _vm.getVdrCatalogueModalStat[0] &&
          _vm.getVdrCatalogueModalStat[1] === 'supply'
      )?_c('VdrSupplySpec',{attrs:{"perm":_vm.permission}}):_vm._e(),(
        _vm.getVdrCatalogueModalStat[0] &&
          _vm.getVdrCatalogueModalStat[1] === 'rawspec'
      )?_c('VdrSupplySpec',{attrs:{"perm":_vm.permission}}):_vm._e(),(
        _vm.getVdrCatalogueModalStat[0] &&
          _vm.getVdrCatalogueModalStat[1] === 'genspec'
      )?_c('VdrSupplySpec',{attrs:{"perm":_vm.permission}}):_vm._e()],1),(_vm.getIsLoading === true)?_c('v-card',{staticClass:"mx-auto",staticStyle:{"display":"flex","justify-content":"center","align-items":"center","font-size":"12px","position":"absolute","top":"0","height":"100%","left":"0","right":"0","background":"rgba(255,255,255,0.5)"},attrs:{"width":"99%","height":"260"}},[_c('v-progress-circular',{attrs:{"size":170,"width":10,"color":"blue","indeterminate":""}},[_vm._v("Please wait")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }