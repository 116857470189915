<template>
  <v-form ref="entryForm" @submit.prevent="submit" style="position: relative">
    <v-row>
      <v-col
        cols="12"
        class="form-pr-input-col "
        style=" height:35px; margin-bottom:20px;"
      >
        <v-toolbar-title class="overline">
          <p style="font-size:18px;">
            #{{ detail !== null ? detail.procurement_no : '' }}
          </p>
        </v-toolbar-title>
      </v-col>

      <v-col cols="6" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="3" class="form-pr-input-col-div-col1">
            <p>
              Nama Pengadaan
            </p>
          </v-col>
          <v-col class="form-pr-input-col-div-col2" cols="6">
            <v-text-field
              v-model="param.name"
              :rules="procName"
              dense
              outlined
              :readonly="getProcListId !== null && allowEdit === false"
              style="margin:0;"
            />
          </v-col>
        </div>
      </v-col>
      <v-col cols="4" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="6" class="form-pr-input-col-div-col1">
            <p>
              Periode Pengadaan
            </p>
          </v-col>
          <v-col cols="5" class="form-pr-input-col-div-col2">
            <v-text-field
              v-if="getProcListId !== null && !allowEdit"
              :value="convertDate(param.period_start)"
              dense
              outlined
              readonly
              style="margin:0;"
            />
            <v-menu
              v-else
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="periodStart"
                  v-model="param.period_start"
                  append-icon="mdi-calendar"
                  dense
                  outlined
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker v-model="param.period_start" />
            </v-menu>
          </v-col>
          <p style="margin:0;padding:8px 30px;">s/d</p>
        </div>
      </v-col>
      <v-col cols="2" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="2" class="form-pr-input-col-div-col1"> </v-col>
          <v-col cols="10" class="form-pr-input-col-div-col2">
            <v-text-field
              v-if="getProcListId !== null && !allowEdit"
              :value="convertDate(param.period_end)"
              dense
              outlined
              readonly
              style="margin:0;"
            />
            <v-menu
              v-else
              :close-on-content-click="true"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="periodEnd"
                  v-model="param.period_end"
                  append-icon="mdi-calendar"
                  dense
                  outlined
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker v-model="param.period_end" />
            </v-menu>
          </v-col>
        </div>
      </v-col>

      <v-col cols="6" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="3" class="form-pr-input-col-div-col1">
            <p>
              Tahun Anggaran
            </p>
          </v-col>
          <v-col class="form-pr-input-col-div-col2" cols="3">
            <v-text-field
              v-model="param.year"
              :rules="year"
              dense
              outlined
              :readonly="getProcListId !== null && allowEdit === false"
              style="margin:0;"
            />
          </v-col>
        </div>
      </v-col>
      <v-col cols="4" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="6" class="form-pr-input-col-div-col1">
            <p>
              Total Hari Kerja
            </p>
          </v-col>
          <v-col cols="5" class="form-pr-input-col-div-col2">
            <v-text-field
              v-model="param.total_working_days"
              :rules="totalWorkinghours"
              dense
              outlined
              type="number"
              style="margin:0;"
              :readonly="getProcListId !== null && allowEdit === false"
            />
          </v-col>
          <p style="margin:0;padding:8px 30px;">hari</p>
        </div>
      </v-col>
      <v-col cols="2" class="form-pr-input-col"> </v-col>

      <v-col cols="6" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="3" class="form-pr-input-col-div-col1">
            <p>
              Total HPS
            </p>
          </v-col>
          <v-col class="form-pr-input-col-div-col2" cols="6">
            <v-text-field
              v-if="getProcListId !== null && !allowEdit"
              :value="
                param.total_hps !== undefined ? price(param.total_hps) : 0
              "
              dense
              outlined
              style="margin:0;"
              readonly
            />
            <v-text-field
              v-else
              v-model="param.total_hps"
              :rules="totalHps"
              type="number"
              dense
              outlined
              style="margin:0;"
            />
          </v-col>
        </div>
      </v-col>
      <v-col cols="4" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="6" class="form-pr-input-col-div-col1">
            <p>
              Masa Berlaku penawaran
            </p>
          </v-col>
          <v-col cols="5" class="form-pr-input-col-div-col2">
            <v-text-field
              v-model="param.validity_period_offer"
              :rules="validityPeriodOffer"
              dense
              outlined
              type="number"
              style="margin:0;"
              :readonly="getProcListId !== null && allowEdit === false"
            />
          </v-col>
          <p style="margin:0;padding:8px 30px;">hari</p>
        </div>
      </v-col>
      <v-col cols="2" class="form-pr-input-col"> </v-col>

      <v-col cols="6" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="3" class="form-pr-input-col-div-col1">
            <p>
              Harga Pasaran
            </p>
          </v-col>
          <v-col class="form-pr-input-col-div-col2" cols="6">
            <v-text-field
              v-if="getProcListId !== null && !allowEdit"
              :value="
                param.price_on_market !== undefined
                  ? price(param.price_on_market)
                  : 0
              "
              dense
              outlined
              style="margin:0;"
              readonly
            />
            <v-text-field
              v-else
              v-model="param.price_on_market"
              :rules="marketPrice"
              dense
              outlined
              type="number"
              style="margin:0;"
            />
          </v-col>
        </div>
      </v-col>

      <v-col cols="2" class="form-pr-input-col"> </v-col>

      <v-col cols="6" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="3" class="form-pr-input-col-div-col1">
            <p>
              Syarat Kualifikasi
            </p>
          </v-col>
          <v-col class="form-pr-input-col-div-col2" cols="9">
            <v-textarea
              v-model="param.qualification_requirements"
              :rules="qualification"
              outlined
              style="margin:0;"
              name="input-7-4"
              label="Type Here"
              :readonly="getProcListId !== null && allowEdit === false"
            ></v-textarea>
          </v-col>
        </div>
      </v-col>
      <v-col cols="4" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="6" class="form-pr-input-col-div-col1">
            <p>
              File Pendukung
            </p>
          </v-col>
          <v-col cols="5" class="form-pr-input-col-div-col2">
            <v-file-input
              disabled
              dense
              style="margin:0;"
              outlined
              label="click here"
              prepend-icon=""
              append-icon="mdi-file-outline"
              truncate-length="10"
            ></v-file-input>
          </v-col>
        </div>
      </v-col>

      <v-col cols="2" class="form-pr-input-col"> </v-col>

      <v-col cols="6" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="3" class="form-pr-input-col-div-col1">
            <p>
              Company Code
            </p>
          </v-col>
          <v-col class="form-pr-input-col-div-col2" cols="6">
            <v-autocomplete
              style="margin:0;"
              v-model="param.company"
              :items="comp"
              item-text="alias"
              item-value="id"
              return-object
              readonly
              dense
              outlined
            ></v-autocomplete>
          </v-col>
        </div>
      </v-col>
      <v-col cols="4" class="form-pr-input-col">
        <div class="form-pr-input-col-div">
          <v-col cols="6" class="form-pr-input-col-div-col1">
            <p>
              Department
            </p>
          </v-col>
          <v-col cols="5" class="form-pr-input-col-div-col2">
            <v-autocomplete
              style="margin:0;"
              v-model="param.department"
              :items="dept"
              item-text="name"
              item-value="id"
              return-object
              dense
              outlined
              readonly
            ></v-autocomplete>
          </v-col>
        </div>
      </v-col>
    </v-row>
    <button type="submit" id="procFormSubmitBtn"></button>
  </v-form>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters, mapMutations } from 'vuex'
export default {
  props: ['detail', 'param', 'dept', 'comp', 'allowEdit'],
  data: () => ({
    proc: buildType.apiURL('proc'),
    period: {
      start: null,
      end: null
    },
    procName: [],
    periodStart: [],
    periodEnd: [],
    year: [],
    totalWorkinghours: [],
    totalHps: [],
    validityPeriodOffer: [],
    marketPrice: [],
    qualification: []
  }),
  // watch: {
  //   'param.name'() {
  //     console.log(this.param)
  //   }
  // },
  // components: {},
  created() {},
  computed: {
    ...mapGetters(['getProcListId'])
  },
  methods: {
    // ...mapActions([]),
    ...mapMutations(['setProcListId']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    submit() {
      this.procName = [v => !!v || 'Procurement name is required']
      this.periodStart = [v => !!v || 'Period start is required']
      this.periodEnd = [v => !!v || 'Period end is required']
      this.year = [v => !!v || 'Year is required']
      this.totalWorkinghours = [v => !!v || 'Total working hours is required']
      this.totalHps = [v => !!v || 'Total HPS is required']
      this.validityPeriodOffer = [
        v => !!v || 'Validity period offer is required'
      ]
      this.marketPrice = [v => !!v || 'Market Price is required']
      this.qualification = [v => !!v || 'Qualification is required']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {
        name: this.param.name,
        year: this.param.year,
        total_hps: this.param.total_hps,
        file: this.param.file,
        price_on_market: this.param.price_on_market,
        period_start: this.param.period_start,
        period_end: this.param.period_end,
        total_working_days: this.param.total_working_days,
        validity_period_offer: this.param.validity_period_offer,
        qualification_requirements: this.param.qualification_requirements,

        company_id: this.param.company.id,
        company_code: this.param.company.alias,
        company_name: this.param.company.name,

        department_id: this.param.department.id,
        department_name: this.param.department.name
      }

      if (this.param.act === 'update') {
        Object.assign(form, {
          act: this.param.act,
          id: this.param.id
        })
      } else {
        Object.assign(form, {
          act: this.param.act
        })
      }

      this.save(form)
    },
    save(form) {
      axios
        .post(`${this.proc}save`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            if (this.getProcListId === null) {
              this.setProcListId(res.data.created_id)
              this.$emit('reload', res.data.created_id)
            } else {
              this.$emit('reload', this.getProcListId)
            }
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    convertDate(raw) {
      if (raw !== undefined) {
        const day = raw.slice(0, raw.indexOf('-'))
        let month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const year = raw.slice(raw.lastIndexOf('-') + 1)

        switch (month) {
          case '01':
            month = 'JAN'
            break
          case '02':
            month = 'FEB'
            break
          case '03':
            month = 'MAR'
            break
          case '04':
            month = 'APR'
            break
          case '05':
            month = 'MAY'
            break
          case '06':
            month = 'JUN'
            break
          case '07':
            month = 'JUL'
            break
          case '08':
            month = 'AGS'
            break
          case '09':
            month = 'SEP'
            break
          case '10':
            month = 'OKT'
            break
          case '11':
            month = 'NOV'
            break
          case '12':
            month = 'DES'
            break
        }
        return year + '-' + month + '-' + day
      }
    },

    price(val) {
      if (val !== null || val !== undefined) {
        const reverse = val.toString()
        if (reverse.lastIndexOf('.') === -1) {
          const beforeComa1 = reverse
            .split('')
            .reverse()
            .join('')
          var ribuan1 = beforeComa1.match(/\d{1,3}/g)
          const result1 = ribuan1
            .join(',')
            .split('')
            .reverse()
            .join('')
          return result1
        } else {
          const beforeComa2 = reverse
            .slice(0, reverse.indexOf('.'))
            .split('')
            .reverse()
            .join('')
          var ribuan2 = beforeComa2.match(/\d{1,3}/g)
          const result2 = ribuan2
            .join(',')
            .split('')
            .reverse()
            .join('')
          const afterComa = reverse.slice(reverse.lastIndexOf('.') + 1)
          return result2 + ',' + afterComa
        }
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss" src="">
.form-pr-input-col {
  padding: 0;

  .form-pr-input-col-div {
    display: flex;
    flex-direction: row;
    .form-pr-input-col-div-col1 {
      padding-left: 0;
      padding-right: 0;

      p {
        font-size: 14px;
        margin: 0;
        padding-right: 20px;
        text-align: right;
      }
    }
    .form-pr-input-col-div-col2 {
      padding: 0;
    }
  }
}
</style>
