<template>
  <div class="row justify-content-center" id="Login">
    <div v-if="verifiedToken" class="col-md-6 log-form">
      <p
        style="border-bottom:1px solid black; fontSize: 12px; padding: 10px 10px; border-radius: 5px;"
      >
        Please change your password here
      </p>
      <b-form @submit.stop.prevent>
        <label
          for="new-password"
          style="width:100%; text-align:left; padding-left: 10px"
          >New Password</label
        >
        <b-form-input
          v-model="form.password"
          type="password"
          id="new-password"
          style="margin-bottom: 20px;"
        ></b-form-input>

        <label
          for="confirm-password"
          style="width:100%; text-align:left; padding-left: 10px"
          >Confirm New Password</label
        >
        <b-form-input
          v-model="form.newPassword"
          type="password"
          id="confirm-password"
          style="margin-bottom: 20px;"
        ></b-form-input>
        <div class="col-12" style="text-align: right; padding: 0;;">
          <button @click="submitChange">Submit</button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'reset',
  data() {
    return {
      verifiedToken: false,
      token: null,
      email: null,
      form: {
        password: '',
        newPassword: ''
      }
    }
  },
  mounted() {
    this.verifiedToken = false
    this.token = null
    this.email = null
    if (this.$route.params.token !== null) {
      this.verifyToken(this.$route.params.token)
    } else {
      this.token = null
      this.email = null
      this.$swal.fire({
        position: 'center',
        icon: 'warning',
        title: 'Permission needed, please resend your email',
        showConfirmButton: true,
        showCancelButton: false,
        timer: 5000
      })
      this.$router.push('/forgot-password')
    }
  },
  components: {},
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['tokenValidation', 'verifyData', 'changePasswordNow']),
    ...mapMutations([]),
    verifyToken(token) {
      this.tokenValidation(token)
        .then(response => {
          if (response.data.status_code === '00') {
            this.verifyData(token)
              .then(response => {
                this.verifiedToken = true
                this.token = token
                this.email = response.data.result_verify.email
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'token verified, please type your new password',
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              })
              .catch(response => {
                this.token = null
                this.email = null
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'invalid token, please resend your email',
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              })
          } else {
            this.token = null
            this.email = null
            this.$swal.fire({
              position: 'center',
              icon: 'warning',
              title: response.data.status_msg + ' please resend your email',
              showConfirmButton: true,
              showCancelButton: false,
              timer: 5000
            })
            this.$router.push('/forgot-password')
          }
        })
        .catch(error => {
          this.token = null
          this.email = null
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error
              ? 'Access denied, Please try again'
              : 'Access denied, Please try again',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })

          // this.$router.push('/forgot-password')
        })
    },
    submitChange() {
      const payload = {
        token: this.token,
        email: this.email,
        password: this.form.password,
        newPassword: this.form.newPassword
      }

      if (this.form.password.length < 8) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'min password karakter = 8',
          showConfirmButton: true,
          showCancelButton: false,
          timer: 5000
        })
        this.form.password = ''
        this.form.newPassword = ''
      } else {
        if (this.form.newPassword !== this.form.password) {
          this.$swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'password doesnt match',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          this.form.password = ''
          this.form.newPassword = ''
        } else {
          this.$swal
            .fire({
              icon: 'warning',
              title: 'Process now?',
              showCancelButton: true
            })
            .then(result => {
              if (result.value) {
                this.changePasswordNow(payload)
                  .then(response => {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: response.data.status_msg,
                      showConfirmButton: true,
                      showCancelButton: false,
                      timer: 5000
                    })

                    this.form.password = ''
                    this.form.newPassword = ''

                    this.$router.push('/login')
                  })
                  .catch(error => {
                    console.log(error)
                  })
              }
            })
        }
      }
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/login.scss"></style>
<style scoped></style>
