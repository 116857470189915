<template>
  <div class="row justify-content-center" id="Login">
    <div class="col-md-12 log-form">
      <v-img
        style="width: 100%; height: 100%; position: absolute;"
        src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
      ></v-img>
      <div
        style="position: relative; right:100px; z-index:2; display: flex; flex-direction: row;"
      >
        <v-btn
          @click.prevent="home"
          style="height:50px;width:50px; margin-right: 10px;"
          elevation="5"
          fab
          x-large
          color="white"
          class=" rounded-circle"
          ><v-img
            alt="sanqua multi international"
            class=" rounded-circle"
            contain
            src="../../../public/sanquamulti.png"
            transition="scale-transition"
            style="height:50px;width:50px; "
        /></v-btn>
        <h5 style="line-height:20px; margin-top:12px; color: white;">
          Sanqua
          <h4 style="color:#37474F">Authentification</h4>
        </h5>
      </div>
      <v-card
        elevation="10"
        class="mx-auto"
        max-width="444"
        style="padding:30px;"
      >
        <h3
          style="display: flex; justify-content: flex-start; align-items:center; width: 130px;"
        >
          Sign in
        </h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum,
          repellendus.
        </p>
        <v-alert dense outlined type="error" v-if="isFailed">
          {{ failMsg }}
        </v-alert>
        <b-form @submit.prevent="login">
          <label
            for="text-email"
            style="width:100%; text-align:left; padding-left: 10px"
            >Email</label
          >
          <b-form-input
            type="email"
            id="text-email"
            v-model="form.email"
            style="margin-bottom: 20px;"
          ></b-form-input>
          <label
            for="text-password"
            style="width:100%; text-align:left; padding-left: 10px"
            >Password</label
          >
          <b-form-input
            type="password"
            id="text-password"
            style="margin-bottom: 20px;"
            v-model="form.password"
          ></b-form-input>
          <div class="col-12" style="text-align: right; padding: 0;;">
            <button type="submit">Sign in</button>
            <router-link to="/forgot-password">Forgot Password?</router-link>
          </div>
        </b-form>
      </v-card>
      <hr />
      <!-- <p
        style="text-align: center; font-size: 14px; position: relative; z-index:2; color: red;"
      >
        Doesn't have an account?
        <router-link to="/register" style="font-size: 16px; color:white;"
          >Create now</router-link
        >
      </p> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'login',
  data() {
    return {
      isFailed: false,
      failMsg: '',
      form: {
        email: '',
        password: ''
      }
    }
  },
  components: {},
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['adminLogin']),
    ...mapMutations([]),
    login() {
      if (!this.form.email && !this.form.password) {
        this.isFailed = true
        this.failMsg = 'Email & Password is required'
        setTimeout(() => {
          this.isFailed = false
        }, 3000)
      } else {
        this.adminLogin(this.form)
          .then(results => {
            if (results.data.status_code === '-99') {
              this.isFailed = true
              this.failMsg = results.data.status_msg

              this.form.email = ''
              this.form.password = ''

              setTimeout(() => {
                this.isFailed = false
              }, 3000)
            } else {
              this.form.email = ''
              this.form.password = ''
            }
          })
          .catch(error => {
            this.isFailed = true
            this.failMsg = error
              ? 'Cant connect to server'
              : 'Cant connect to server'

            setTimeout(() => {
              this.isFailed = false
            }, 3000)
            localStorage.removeItem('token')
            // this.form.email = ''
            // this.form.password = ''
          })
      }
    },
    home() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/login.scss"></style>
