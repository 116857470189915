<template>
  <div>
    <div style="margin-bottom:30px;">
      <Navigation chosenpage="Vendor Profile" @setDrawer="drawerSet" />
    </div>
    <div v-if="pageStatus === true">
      <div v-if="permission.read_perm === 1">
        <v-card
          style="margin-bottom:30px; position:relative;"
          class="mx-auto"
          height="100%"
          width="99%"
          outlined
        >
          <v-list-item
            three-line
            style=" padding:0 10px; display:flex; flex-direction;row; justify-content:flex-start;align-items:flex-start;"
          >
            <v-col cols="3" style="padding: 10px 50px;">
              <v-list-item-content
                cols="12"
                style="text-align:center; padding:0; margin:0; "
              >
              </v-list-item-content>

              <v-btn
                cols="12"
                color="pink"
                fab
                dark
                small
                absolute
                top
                left
                style="left:-10px;"
                @click="backToVendorList"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>

              <v-img
                height="150"
                aspect-ratio="1.7"
                contain
                cols="12"
                lazy-src
                :src="
                  `${getVendorDetail.logo.replace(
                    'localhost/uploads',
                    '10.10.20.8:8020'
                  )}`
                "
                alt="Company Image: undetected"
              >
              </v-img>

              <div
                cols="12"
                class="text-center"
                style="display:flex; flex-direction:row; justify-content:center; align-items:center;"
              >
                <v-rating
                  small
                  readonly
                  :value="
                    getVendorRatting !== null
                      ? Math.round(
                          getVendorRatting[getVendorRatting.length - 1]
                            .avg_rate / 2
                        )
                      : 0
                  "
                  color="yellow darken-3"
                  background-color="grey darken-1"
                  empty-icon="$ratingFull"
                  half-increments
                  hover
                ></v-rating>
                <div style="font-size:12px; position:relative; left:7px;">
                  {{
                    getVendorRatting !== null
                      ? (
                          getVendorRatting[getVendorRatting.length - 1]
                            .avg_rate / 2
                        ).toFixed(1)
                      : '0.0'
                  }}
                </div>
              </div>

              <v-list-item-content
                cols="12"
                style="text-align:right; padding:0; margin:0; "
              >
                <v-list-item-subtitle>
                  {{ getVendorDetail.phone1 }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content
                cols="12"
                style="text-align:right; height:20px; padding:0; margin:0; position:relative;"
              >
                <v-list-item-subtitle
                  style="height:25px; position:absolute; top:0; right:0;"
                >
                  <EditRatting
                    v-bind:items="getVendorDetail.id"
                    :perm="permission"
                /></v-list-item-subtitle>
              </v-list-item-content>
            </v-col>
            <v-col cols="4" style="padding:0;">
              <v-list-item-content style="padding:0; height:200px; ">
                <v-list-item-title
                  class=" title mb-1"
                  style="font-size:12px;padding:0;"
                >
                  {{ getVendorDetail.name.toUpperCase() }}
                </v-list-item-title>
                <v-list-item-title class="overline mb-1">
                  Alamat
                </v-list-item-title>
                <v-list-item-subtitle class=" mb-2">
                  {{ getVendorDetail.address }}
                </v-list-item-subtitle>
                <div>
                  <v-list-item-title class="overline mb-1">
                    Email
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ getVendorDetail.email }}
                  </v-list-item-subtitle>
                </div>
              </v-list-item-content>
            </v-col>

            <v-col cols="4" style="padding:0;">
              <v-list-item-content style="padding:0; height:200px; ">
                <v-col cols="12">
                  <v-list-item-title class="overline mb-1">
                    Klasifikasi
                  </v-list-item-title>
                </v-col>
                <v-col cols="6">
                  <v-list-item-title class="overline mb-1">
                    Bidang
                  </v-list-item-title>
                  <v-list-item-subtitle class=" mb-2">
                    {{
                      getVendorDetail.classification !== null
                        ? getVendorDetail.classification.name
                        : ''
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class=" mb-2">
                    -
                  </v-list-item-subtitle>
                </v-col>

                <v-col cols="6">
                  <v-list-item-title class="overline mb-1">
                    Sub Bidang
                  </v-list-item-title>
                  <v-list-item-subtitle class=" mb-2">
                    {{
                      getVendorDetail.sub_classification !== null
                        ? getVendorDetail.sub_classification.name
                        : '-'
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class=" mb-2">
                    -
                  </v-list-item-subtitle>
                </v-col>
              </v-list-item-content>
            </v-col>

            <v-col cols="1" style="padding:0;">
              <v-card-actions style="height:200px; ">
                <v-btn
                  outlined
                  rounded
                  text
                  color="info"
                  @click="editProfileVendor(false, 'update', getVendorDetail)"
                >
                  Detail
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-list-item>
        </v-card>

        <v-list>
          <v-card
            v-if="
              getDocument.akta !== null ||
                getDocument.siup !== null ||
                getDocument.tdp !== null ||
                getDocument.npwp !== null ||
                getDocument.domisili !== null
            "
            class="mx-auto"
            width="99%"
          >
            <v-toolbar flat color="warning" dark height="30px">
              <v-card-title
                class="title font-weight-regular justify-space-between"
              >
                <span>{{ headtitle /*getVdrDocumentTab*/ }}</span>
              </v-card-title>
            </v-toolbar>
            <v-tabs vertical>
              <v-tab @click="subFormTitle('SIUP')">
                SIUP <br />
                {{ documentStatus('siup') }}
              </v-tab>
              <v-tab @click="subFormTitle('NPWP')">
                NPWP <br />
                {{ documentStatus('npwp') }}
              </v-tab>
              <v-tab @click="subFormTitle('DOMISILI')">
                DOMISILI <br />
                {{ documentStatus('domisili') }}
              </v-tab>
              <v-tab @click="subFormTitle('TDP')">
                TDP <br />
                {{ documentStatus('tdp') }}
              </v-tab>
              <v-tab @click="subFormTitle('AKTA')">
                AKTA <br />
                {{ documentStatus('akta') }}
              </v-tab>
              <v-tab @click="subFormTitle('PENGALAMAN', getVendorId)">
                PENGALAMAN <br />
                {{ documentStatus('experience') }}
              </v-tab>
              <v-tab @click="subFormTitle('CATALOGUE')">
                CATALOGUE
              </v-tab>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:250px;">
                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. SIUP"
                        :readonly="permission.update_perm !== 1"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        type="text"
                        v-model="formDocument.instance"
                        :readonly="permission.update_perm !== 1"
                        label="Instansi Pemberi"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :disabled="permission.update_perm !== 1"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. SIUP"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu1 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu2"
                            :disabled="permission.update_perm !== 1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-autocomplete
                        v-model="formDocument.siup_qualification"
                        :items="[
                          { id: 1, name: 'Kecil' },
                          { id: 2, name: 'Menengah' },
                          { id: 3, name: 'Besar' },
                          { id: 4, name: 'Mikro' }
                        ]"
                        :readonly="permission.update_perm !== 1"
                        item-text="name"
                        item-value="id"
                        return-id
                        label="Kualifikasi"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        :disabled="permission.update_perm !== 1"
                        style="margin-top:10px;height:40px;"
                        :label="
                          formDocument.file === ''
                            ? 'Upload Document'
                            : formDocument.file
                        "
                        outlined
                        prepend-icon=""
                        append-icon="mdi-attachment"
                        dense
                      ></v-file-input>
                      <a
                        v-if="formDocument.file !== ''"
                        href="#"
                        style="font-size:12px; padding: 0 5px; position:relative;"
                        @click="downloadDocument(['siup', formDocument.file])"
                        >download dokument</a
                      >
                    </v-col>
                  </v-row>
                  <v-btn
                    :disabled="permission.update_perm !== 1"
                    outlined
                    depressed
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(1)"
                  >
                    Simpan SIUP
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:250px;">
                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. NPWP"
                        :readonly="permission.update_perm !== 1"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-textarea
                        v-model="formDocument.address"
                        no-resize
                        height="65px"
                        outlined
                        clear-icon="mdi-close-circle"
                        name="input-7-4"
                        label="Alamat"
                        :clearable="permission.update_perm === 1"
                        :readonly="permission.update_perm !== 1"
                        type="text"
                        style="margin-top:10px;"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="permission.update_perm !== 1"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu3 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu8"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="permission.update_perm !== 1"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu8 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        type="text"
                        v-model="formDocument.instance"
                        :readonly="permission.update_perm !== 1"
                        label="KPP"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        :disabled="permission.update_perm !== 1"
                        style="margin-top:10px;height:40px;"
                        :label="
                          formDocument.file === ''
                            ? 'Upload Document'
                            : formDocument.file
                        "
                        outlined
                        prepend-icon=""
                        append-icon="mdi-attachment"
                        dense
                      ></v-file-input>
                      <a
                        v-if="formDocument.file !== ''"
                        href="#"
                        style="font-size:12px; padding: 0 5px; position:relative;"
                        @click="downloadDocument(['npwp', formDocument.file])"
                        >download dokument</a
                      >
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    :disabled="permission.update_perm !== 1"
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(2)"
                  >
                    Simpan NPWP
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:150px; margin-top:20px;">
                    <v-col cols="6" style="height:65px; padding:0 10px; ">
                      <v-text-field
                        v-model="formDocument.document_no"
                        :readonly="permission.update_perm !== 1"
                        label="No. Surat"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu4"
                            :disabled="permission.update_perm !== 1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu4 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu9"
                            :disabled="permission.update_perm !== 1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu9 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        :disabled="permission.update_perm !== 1"
                        style="margin-top:10px;height:40px;"
                        :label="
                          formDocument.file === ''
                            ? 'Upload Document'
                            : formDocument.file
                        "
                        outlined
                        prepend-icon=""
                        append-icon="mdi-attachment"
                        dense
                      ></v-file-input>
                      <a
                        v-if="formDocument.file !== ''"
                        href="#"
                        style="font-size:12px; padding: 0 5px; position:relative;"
                        @click="
                          downloadDocument(['domisili', formDocument.file])
                        "
                        >download dokument</a
                      >
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    :disabled="permission.update_perm !== 1"
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(3)"
                  >
                    Simpan Domisili
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:190px; margin-top:20px;">
                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        v-model="formDocument.document_no"
                        :readonly="permission.update_perm !== 1"
                        label="No. TDP"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu5"
                            :disabled="permission.update_perm !== 1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu5 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu10"
                            :disabled="permission.update_perm !== 1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu10 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        :disabled="permission.update_perm !== 1"
                        style="margin-top:10px;height:40px;"
                        :label="
                          formDocument.file === ''
                            ? 'Upload Document'
                            : formDocument.file
                        "
                        outlined
                        prepend-icon=""
                        append-icon="mdi-attachment"
                        dense
                      ></v-file-input>
                      <a
                        v-if="formDocument.file !== ''"
                        href="#"
                        style="font-size:12px; padding: 0 5px; position:relative;"
                        @click="downloadDocument(['tdp', formDocument.file])"
                        >download dokument</a
                      >
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    :disabled="permission.update_perm !== 1"
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(4)"
                  >
                    Simpan TDP
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:150px; margin-top:20px;">
                    <v-col cols="6" style="height:65px; padding:0 10px; ">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. Akta"
                        :readonly="permission.update_perm !== 1"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu6"
                            :disabled="permission.update_perm !== 1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu6 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu11"
                            :disabled="permission.update_perm !== 1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu11 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        :disabled="permission.update_perm !== 1"
                        style="margin-top:10px;height:40px;"
                        :label="
                          formDocument.file === ''
                            ? 'Upload Document'
                            : formDocument.file
                        "
                        outlined
                        prepend-icon=""
                        append-icon="mdi-attachment"
                        dense
                      ></v-file-input>
                      <a
                        v-if="formDocument.file !== ''"
                        href="#"
                        style="font-size:12px; padding: 0 5px; position:relative;"
                        @click="downloadDocument(['akta', formDocument.file])"
                        >download dokument</a
                      >
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    :disabled="permission.update_perm !== 1"
                    depressed
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(9)"
                  >
                    Simpan Akta
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-data-table
                  :headers="expHeaders"
                  :items="getExperienceList"
                  :items-per-page="expPerPage"
                  class="elevation-6 mx-auto"
                  style="width:90%; margin-top: 20px; margin-bottom:20px;"
                  dense
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-toolbar>
                      <v-icon style="margin-right: 10px;">mdi-cards</v-icon>
                      <v-toolbar-title>Experience</v-toolbar-title>
                      <v-divider class="mx-6" inset vertical></v-divider>
                      <v-text-field
                        v-model="invokeSrc2"
                        label="Search here"
                        type="search"
                        outlined
                        dense
                        style="position: relative; top:15px; width:10px;"
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.no`]="{ item }">
                    <td>
                      {{ item.no + expOffset * expPerPage }}
                    </td>
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    <v-list-item v-if="item.id !== null">
                      {{ item.name }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      v-model="formAddExp.name"
                      type="text"
                      :disabled="permission.create_perm !== 1"
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.type`]="{ item }">
                    <v-list-item v-if="item.id !== null">
                      {{ item.type }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      v-model="formAddExp.type"
                      type="text"
                      :disabled="permission.create_perm !== 1"
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.location`]="{ item }">
                    <v-list-item v-if="item.id !== null">
                      {{ item.location }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      type="text"
                      v-model="formAddExp.location"
                      dense
                      :disabled="permission.create_perm !== 1"
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.month`]="{ item }">
                    <v-list-item v-if="item.id !== null">
                      {{ month(item.month) }}
                    </v-list-item>
                    <v-autocomplete
                      v-else
                      v-model="formAddExp.month"
                      :items="getMonth"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      :disabled="permission.create_perm !== 1"
                    ></v-autocomplete>
                  </template>

                  <template v-slot:[`item.year`]="{ item }">
                    <v-list-item v-if="item.id !== null">
                      {{ item.year }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      v-model="formAddExp.year"
                      :disabled="permission.create_perm !== 1"
                      type="number"
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      v-if="item.id === null"
                      color="success"
                      class="ma-1 white--text"
                      small
                      :disabled="permission.create_perm !== 1"
                      @click="addNewExp(2)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small>
                            mdi-content-save
                          </v-icon>
                        </template>
                        <span>Save</span>
                      </v-tooltip>
                    </v-btn>
                    <v-btn
                      v-else
                      color="cyan"
                      class="ma-1 white--text"
                      small
                      :disabled="permission.update_perm !== 1"
                      @click="editExp(item)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small>
                            mdi-pencil
                          </v-icon>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                    </v-btn>
                    <v-btn
                      color="error"
                      class="ma-1 white--text"
                      small
                      :disabled="permission.delete_perm !== 1"
                      @click="deleteExp(item)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small>
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </v-btn>
                  </template>

                  <template v-slot:footer>
                    <v-toolbar>
                      <v-btn
                        :disabled="permission.create_perm !== 1"
                        small
                        @click="!isNewLine ? addNewExp(1) : addNewExp(0)"
                      >
                        <v-icon v-if="!isNewLine" left small>
                          mdi-plus
                        </v-icon>
                        {{ !isNewLine ? 'Add New Line' : 'cancel ' }}

                        <v-icon
                          v-if="isNewLine"
                          left
                          small
                          style="margin:0 0 0 10px;"
                        >
                          mdi-window-close
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-pagination
                        style="margin-left:auto;"
                        v-model="expPage"
                        :length="Math.ceil(getExpTotalItems / expPerPage) || 1"
                        :total-visible="6"
                      ></v-pagination>
                      <v-spacer></v-spacer>
                      <div
                        style="padding:0; margin:0;display: flex; flex-direction:rows; justify-content:center; align-items:center;"
                      >
                        <v-toolbar-title class="caption"
                          >Rows per page:</v-toolbar-title
                        >
                        <v-select
                          style="position: relative; margin-left:10px;height:28px; width:50px; rightt:20px; font-size:12px;"
                          v-model="expPerPage"
                          :items="[1, 5, 10, 15, 20, 100]"
                          dense
                        ></v-select>
                      </div>
                    </v-toolbar>
                  </template>
                </v-data-table>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-data-table
                  :headers="catalogueHead"
                  :items="getCatalogueList"
                  :items-per-page="itemsPerPage"
                  class="elevation-6 mx-auto"
                  style="width:90%; margin-top: 20px; margin-bottom:20px;"
                  dense
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-toolbar>
                      <v-icon style="margin-right: 10px;">mdi-cards</v-icon>
                      <v-toolbar-title>Catalogue List</v-toolbar-title>
                      <v-divider class="mx-6" inset vertical></v-divider>
                      <div
                        cols="12"
                        style="padding: 0; justify-content:flex-start; align-items:center; display:flex; margin:5px;"
                      >
                        <p style="margin:0 5px 0 0; color: rgba(0, 0, 0, 0.5);">
                          Investasi
                        </p>
                        <v-checkbox
                          v-model="isAsset"
                          style=" margin:0; height:25px; padding:0;"
                          @change="getAsset"
                        ></v-checkbox>
                      </div>
                      <v-text-field
                        v-model="invokeSrc"
                        label="Search here"
                        type="search"
                        outlined
                        dense
                        style="position: relative; top:15px; width:10px;"
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.no`]="{ index }">
                    <td>
                      {{ index + 1 * page }}
                    </td>
                  </template>
                  <template v-slot:[`item.currency`]="{ item }">
                    <td>
                      {{ item.currency !== null ? item.currency.code : '-' }}
                    </td>
                  </template>
                  <template v-slot:[`item.merk`]="{ item }">
                    <v-list-item-subtitle>
                      {{ item.merk }}
                    </v-list-item-subtitle>
                  </template>
                  <template v-slot:[`item.last_price`]="{ item }">
                    <td v-if="item.last_price !== null">
                      {{
                        (item.currency !== null ? item.currency.symbol : '') +
                          ' ' +
                          price(item.last_price)
                      }}
                    </td>
                  </template>
                  <template v-slot:[`item.last_ordered`]="{ item }">
                    {{ item.last_ordered | moment('DD/MM/YYYY') }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      color="success"
                      class="ma-1 white--text"
                      small
                      @click.prevent="editCatalogue(['update', item])"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small>
                            {{
                              permission.update_perm !== 1
                                ? 'mdi-file-find'
                                : 'mdi-pencil'
                            }}
                          </v-icon>
                        </template>
                        <span>{{
                          permission.update_perm !== 1 ? 'Detail' : 'Edit'
                        }}</span>
                      </v-tooltip>
                    </v-btn>
                    <v-btn
                      color="error"
                      class="ma-1 white--text"
                      small
                      :disabled="
                        permission.update_perm !== 1 ||
                          permission.delete_perm !== 1
                      "
                      @click="deleteCatalogue(item)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small>
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </v-btn>
                  </template>

                  <template v-slot:footer>
                    <v-toolbar>
                      <v-btn
                        color="#e0e0e0"
                        class="ma-1 black--text"
                        small
                        :disabled="permission.create_perm !== 1"
                        @click.prevent="editCatalogue(['add', null])"
                      >
                        <v-icon left small>
                          mdi-plus
                        </v-icon>
                        Add New Line
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-pagination
                        style="margin-left:auto;"
                        v-model="page"
                        :length="
                          Math.ceil(getTotalCatalogue / itemsPerPage) || 1
                        "
                        :total-visible="6"
                      ></v-pagination>
                      <v-spacer></v-spacer>
                      <div
                        style="padding:0; margin:0;display: flex; flex-direction:rows; justify-content:center; align-items:center;"
                      >
                        <v-toolbar-title class="caption"
                          >Rows per page:</v-toolbar-title
                        >
                        <v-select
                          style="position: relative; margin-left:10px;height:28px; width:50px; rightt:20px; font-size:12px;"
                          v-model="itemsPerPage"
                          :items="[1, 2, 5, 10, 15, 20, 100]"
                          dense
                        ></v-select>
                      </div>
                    </v-toolbar>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-card>
          <v-card
            v-else
            class="mx-auto"
            width="99%"
            height="260"
            style="display:flex; justify-content: center; align-items:center;"
          >
            <v-progress-circular
              :size="170"
              :width="10"
              color="blue"
              indeterminate
              >Getting files</v-progress-circular
            >
          </v-card>
        </v-list>
      </div>

      <v-card
        flat
        height="75vh"
        v-if="permission.read_perm === 0"
        style="display:flex; justify-content:center; align-items:center;"
      >
        <v-btn
          cols="12"
          color="pink"
          fab
          dark
          small
          absolute
          top
          left
          style="left:10px;"
          @click="backToVendorList"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="overline">
          You Can't Access This Page</v-toolbar-title
        >
      </v-card>
    </div>

    <div>
      <VendorAdd v-if="!getVdrAddStat" :perm="permission" />
      <EditExperience v-if="getModalEditExp" />
    </div>
    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>

    <v-card
      v-if="getIsLoading === true"
      class="mx-auto"
      width="99%"
      height="260"
      style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Please wait</v-progress-circular
      >
    </v-card>
  </div>
</template>

<script>
import Navigation from '../../components/navigation/Navigation'
import Drawer from '../../components/navigation/Drawer'
import VendorAdd from '../../components/vendor/VendorAdd'
import EditExperience from '../../components/vendor/ExperienceEdit'
import EditRatting from '../../components/vendor/VendorRate'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'vendorProfile',
  data() {
    return {
      drawer: false,
      pageStatus: true,
      path: this.$route.fullPath,
      rating: 1.5,
      page: 1,
      expPage: 1,
      offset: 0,
      expOffset: 0,
      expPerPage: 5,
      itemsPerPage: 5,
      searchCatalogue: '',
      searchExp: '',
      invokeSrc: '',
      invokeSrc2: '',
      headtitle: 'SIUP',
      formDocument: {
        vendor_id: '',
        document_type_id: 1,
        document_no: '',
        date: '',
        expire_date: '',
        file: '',
        description: '',
        instance: '',
        siup_qualification: 1,
        address: ''
      },
      formAddExp: {
        name: '',
        type: '',
        location: '',
        month: null,
        year: null
      },
      step: 1,
      date: new Date().toISOString().substr(0, 10),
      menu: {
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        menu5: false,
        menu6: false,
        menu7: false,
        menu8: false,
        menu9: false,
        menu10: false,
        menu11: false
      },

      expHeaders: [
        {
          text: 'No',
          align: 'start',
          sortable: false,
          value: 'no',
          width: '5%'
        },
        {
          text: 'Nama Pekerjaan',
          sortable: false,
          value: 'name',
          width: '20%'
        },
        { text: 'Bidang', sortable: false, value: 'type', width: '20%' },
        { text: 'Lokasi', sortable: false, value: 'location', width: '15%' },
        { text: 'Bulan', sortable: false, value: 'month', width: '10%' },
        { text: 'Tahun', sortable: false, value: 'year', width: '10%' },
        {
          text: 'Actions',
          sortable: false,
          value: 'actions',
          width: '20%',
          align: 'center'
        }
      ],
      experience: [],

      catalogueHead: [
        { text: 'No', sortable: false, value: 'no' },
        { text: 'Product Code', sortable: false, value: 'product_code' },
        {
          text: 'Product Name',
          sortable: false,
          value: 'product_name',
          width: '30%'
        },
        { text: 'Merk', sortable: false, value: 'merk' },
        { text: 'Category', sortable: false, value: 'product_category' },
        { text: 'Currency', sortable: false, value: 'currency' },
        { text: 'Last Price', sortable: false, value: 'last_price' },
        { text: 'Last Ordered', sortable: false, value: 'last_ordered' },
        { text: 'Ordered by', sortable: false, value: 'last_purchase_plant' },
        { text: 'Actions', sortable: false, value: 'actions', align: 'center' }
      ],
      catalogue: [],
      isNewLine: false,
      permission: {},
      menu_key: 17,
      application: {
        id: 2,
        name: 'eProcurement'
      },
      isAsset: false
    }
  },
  components: {
    Navigation,
    EditExperience,
    VendorAdd,
    EditRatting,
    Drawer
  },
  created() {
    // this.setIsLoading(true)
    // this.menu_access([this.menu_key, this.getUserProfile.level[0].id])
    //   .then(result => {
    //     this.permission = result.data
    //     setTimeout(() => {
    //       this.loadPage()
    //       this.setIsLoading(false)
    //     }, 500)
    //   })
    //   .catch(error => {
    //     if (error) {
    //       this.permission = {
    //         create_perm: 0,
    //         read_perm: 0,
    //         update_perm: 0,
    //         delete_perm: 0
    //       }
    //       this.loadPage()
    //       this.setIsLoading(false)
    //     }
    //   })

    this.setIsLoading(true)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            setTimeout(() => {
              this.loadPage()
              this.setIsLoading(false)
            }, 1500)
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              setTimeout(() => {
                this.loadPage()
                this.setIsLoading(false)
              }, 1500)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        setTimeout(() => {
          this.setIsLoading(false)
        }, 1500)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getVendorDetail',
      'getVendorUrlParam',
      'getVendorId',
      'getExperienceList',
      'getModalEditExp',
      'getDocument',
      'getTotalCatalogue',
      'getCatalogueList',
      'getMonth',
      'getExpTotalItems',
      'getVdrAddStat',
      'getIsLoading',
      'getVdrDocumentTab',
      'getVendorRatting'
      // 'getVendorRouterDetail'
    ])
  },
  watch: {
    invokeSrc() {
      this.searchCatalogue = this.invokeSrc
      this.offset = 0
      this.initCatalogue()
    },
    page() {
      this.offset = this.page - 1
      this.initCatalogue()
    },
    itemsPerPage() {
      this.page = 1
      this.offset = 0
      this.initCatalogue()
    },

    invokeSrc2() {
      this.searchExp = this.invokeSrc2
      this.expOffset = 0
      this.experienceInit(this.getVendorId)
    },
    expPage() {
      this.expOffset = this.expPage - 1
      this.experienceInit(this.getVendorId)
    },
    expPerPage() {
      this.expPage = 1
      this.expOffset = 0
      this.experienceInit(this.getVendorId)
    }
  },
  methods: {
    ...mapActions([
      'loadRate',
      'vendorDocumentUpload',
      'vendorExperienceList',
      'deleteExperience',
      'addNewExperience',
      'vendorSiup',
      'vendorNpwp',
      'vendorTdp',
      'vendorDomisili',
      'vendorAkta',
      'manageDocument',
      'catalogueList',
      'catalogueDelete',
      'detailVendor',
      'businessEntity',
      'currency',
      'province',
      'classification',
      'menu_access',
      'documentDownload'
    ]),
    ...mapMutations([
      'setNewExpLine',
      'setModalEditExp',
      'setCatalogueAct',
      'setResultId',
      'vendorAdd',
      'setVendorProfileeditCon',
      'setIsLoading',
      'setVendorDocumentTab'
      // 'setVendorDetailRouter'
    ]),
    loadPage() {
      // this.verifyPath()
      this.loadRate(this.getVendorId)
      this.initDocument()
      this.initCatalogue()

      this.experienceInit(this.getVendorId)
      this.documentLoad()
    },
    drawerSet(val) {
      this.drawer = val
    },
    editProfileVendor(param1, param2, param3) {
      this.dropDownInit()
      this.vendorAdd([param1, param2, param3.id])
      this.detailVendor(param3.id)
      this.setVendorProfileeditCon(true)
    },
    dropDownInit() {
      this.businessEntity()
      this.currency()
      this.province()
      this.classification()
    },
    subFormTitle(value, paramid) {
      this.headtitle = value
      this.setVendorDocumentTab(value)
      this.documentLoad()
      if (value === 'PENGALAMAN') {
        this.experienceInit(paramid)
      } else if (value === 'CATALOGUE') {
      }
    },

    saveDocument(val) {
      if (val === 1) {
        this.formDocument.document_type_id = 1
      } else if (val === 2) {
        this.formDocument.document_type_id = 2
      } else if (val === 3) {
        this.formDocument.document_type_id = 3
      } else if (val === 4) {
        this.formDocument.document_type_id = 4
      } else if (val === 9) {
        this.formDocument.document_type_id = 9
      }

      this.formDocument.vendor_id = this.getVendorId
      console.log(this.formDocument)

      if (this.formDocument.date === null || this.formDocument.date === '') {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Tanggal terdaftar tidak boleh kosong',
          showConfirmButton: true,
          timer: 4500
        })
      } else if (
        this.formDocument.expire_date === null ||
        this.formDocument.expire_date === ''
      ) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Tanggal Kadaluarsa tidak boleh kosong',
          showConfirmButton: true,
          timer: 4500
        })
      } else {
        this.$swal
          .fire({
            title: 'Simpan perubahan',
            icon: 'question',
            showCancelButton: true
          })
          .then(result => {
            if (result.value) {
              this.setIsLoading(true)
              this.manageDocument(this.formDocument)
                .then(result => {
                  this.setIsLoading(false)
                  if (result.data.status_code === '00') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 4500
                    })
                    this.formDocument.file = ''
                    this.documentLoad()
                    this.initDocument()
                  } else {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'error',
                      title:
                        'Error data has occurred with status code ' +
                        result.data.status_code +
                        ', please contact an Admin',
                      showConfirmButton: true,
                      timer: 4500
                    })
                  }
                })
                .catch(error => {
                  this.setIsLoading(false)
                  console.log(error)
                })
            }
          })
      }
    },

    uploadDoc(event) {
      if (event !== null) {
        this.setIsLoading(true)
        this.vendorDocumentUpload([this.headtitle.toLowerCase(), event])
          .then(result => {
            this.setIsLoading(false)
            if (result.data.status === false) {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false,
                timer: 4500
              })
            } else if (result.data.status === true) {
              this.$swal.fire({
                position: 'center',
                icon: 'success',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false,
                timer: 4500
              })
              this.formDocument.file = result.data.data.name
            } else {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false,
                timer: 4500
              })
            }
          })
          .catch(error => {
            this.setIsLoading(false)
            this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: error
                ? 'Somthing went wrong, please contact an admin'
                : 'Somthing went wrong, please contact an admin',
              showConfirmButton: true,
              showCancelButton: false,
              timer: 4500
            })
          })
      } else {
        this.formDocument.file = ''
      }
    },

    addNewExp(val) {
      if (val === 1) {
        this.setNewExpLine(1)
        this.isNewLine = true
      } else if (val === 0) {
        this.setNewExpLine(0)
        this.isNewLine = false
      } else {
        const addExpForm = {
          act: 'add',
          vendor_id: this.getVendorId,
          name: this.formAddExp.name,
          type: this.formAddExp.type,
          location: this.formAddExp.location,
          month: Number(this.formAddExp.month),
          year: Number(this.formAddExp.year)
        }
        this.$swal
          .fire({
            title: 'Save now',
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true
          })
          .then(result => {
            if (result.value) {
              this.setIsLoading(true)
              this.addNewExperience(addExpForm)
                .then(result => {
                  this.setIsLoading(false)
                  if (result.data.status_code !== '-99') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 5000
                    })
                    this.isNewLine = false
                    this.experienceInit(this.getVendorId)
                    this.formAddExp = {
                      name: '',
                      type: '',
                      location: '',
                      month: null,
                      year: null
                    }
                  } else {
                    if (
                      result.data.status_msg === 'Parameter value has problem'
                    ) {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Name/ type/ location can not be empty',
                        showConfirmButton: true,
                        timer: 5000
                      })
                    } else {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: result.data.status_msg,
                        showConfirmButton: true,
                        timer: 5000
                      })
                    }
                  }
                })
                .catch(error => {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error,
                    showConfirmButton: true,
                    timer: 5000
                  })
                })
            }
          })
      }
    },
    editExp(item) {
      this.setModalEditExp([true, item])
    },
    deleteExp(item) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true
        })
        .then(result => {
          if (result.value) {
            this.setIsLoading(true)
            this.deleteExperience(item)
              .then(result => {
                this.setIsLoading(false)
                if (result.data.status_code !== '-99') {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })
                  this.experienceInit(this.getVendorId)
                } else {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })
                }
              })
              .catch(error => {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: error,
                  showConfirmButton: true,
                  timer: 5000
                })
              })
          }
        })
    },
    experienceInit(id) {
      this.vendorExperienceList([
        id,
        this.searchExp,
        this.expOffset * this.expPerPage,
        this.expPerPage
      ])
    },
    backToVendorList() {
      // this.setVendorDetailRouter(null)
      this.$router.push('/master-vendor')
    },
    initDocument() {
      this.vendorSiup()
      this.vendorNpwp()
      this.vendorTdp()
      this.vendorDomisili()
      this.vendorAkta()
    },
    documentStatus(value) {
      if (value === 'siup') {
        if (this.getDocument.siup !== null) {
          if (this.getDocument.siup.status_msg === 'Data not found') {
            return '(Tidak Lengkap)'
          } else {
            if (
              this.getDocument.siup.data.document_no.length === 0 ||
              this.getDocument.siup.data.file === '' ||
              this.getDocument.siup.data.date.length === 0 ||
              this.getDocument.siup.data.expire_date.length === 0 ||
              this.getDocument.siup.data.instance === null ||
              this.getDocument.siup.data.siup_qualification === null
            ) {
              return '(Tidak Lengkap)'
            } else {
              return '(Lengkap)'
            }
          }
        } else {
          return '........'
        }
      } else if (value === 'npwp') {
        if (this.getDocument.npwp !== null) {
          if (this.getDocument.npwp.status_msg === 'Data not found') {
            return '(Tidak Lengkap)'
          } else {
            if (
              this.getDocument.npwp.data.document_no.length === 0 ||
              this.getDocument.npwp.data.file === '' ||
              this.getDocument.npwp.data.date.length === 0 ||
              this.getDocument.npwp.data.expire_date.length === 0 ||
              this.getDocument.npwp.data.instance === null ||
              this.getDocument.npwp.data.address.length === 0
            ) {
              return '(Tidak Lengkap)'
            } else {
              return '(Lengkap)'
            }
          }
        } else {
          return '........'
        }
      } else if (value === 'domisili') {
        if (this.getDocument.domisili !== null) {
          if (this.getDocument.domisili.status_msg === 'Data not found') {
            return '(Tidak Lengkap)'
          } else {
            if (
              this.getDocument.domisili.data.document_no.length === 0 ||
              this.getDocument.domisili.data.file === '' ||
              this.getDocument.domisili.data.date.length === 0 ||
              this.getDocument.domisili.data.expire_date.length === 0
            ) {
              return '(Tidak Lengkap)'
            } else {
              return '(Lengkap)'
            }
          }
        } else {
          return '........'
        }
      } else if (value === 'tdp') {
        if (this.getDocument.tdp !== null) {
          if (this.getDocument.tdp.status_msg === 'Data not found') {
            return '(Tidak Lengkap)'
          } else {
            if (
              this.getDocument.tdp.data.document_no.length === 0 ||
              this.getDocument.tdp.data.file === '' ||
              this.getDocument.tdp.data.date.length === 0 ||
              this.getDocument.tdp.data.expire_date.length === 0
            ) {
              return '(Tidak Lengkap)'
            } else {
              return '(Lengkap)'
            }
          }
        } else {
          return '........'
        }
      } else if (value === 'akta') {
        if (this.getDocument.akta !== null) {
          if (this.getDocument.akta.status_msg === 'Data not found') {
            return '(Tidak Lengkap)'
          } else {
            if (
              this.getDocument.akta.data.document_no.length === 0 ||
              this.getDocument.akta.data.file === '' ||
              this.getDocument.akta.data.date.length === 0 ||
              this.getDocument.akta.data.expire_date.length === 0
            ) {
              return '(Tidak Lengkap)'
            } else {
              return '(Lengkap)'
            }
          }
        } else {
          return '........'
        }
      } else if (value === 'experience') {
        if (this.getExperienceList.length > 0) {
          return '(Lengkap)'
        } else {
          return '(Tidak Lengkap)'
        }
      }
    },
    documentLoad() {
      if (this.headtitle === 'SIUP') {
        setTimeout(() => {
          if (this.getDocument.siup != null) {
            if (this.getDocument.siup.status_code === '00') {
              this.formDocument = {
                vendor_id: '',
                document_type_id: 1,
                document_no: this.getDocument.siup.data.document_no,
                date: this.getDocument.siup.data.date,
                expire_date: this.getDocument.siup.data.expire_date,
                file: this.getDocument.siup.data.file,
                description: '',
                instance: this.getDocument.siup.data.instance,
                siup_qualification: this.getDocument.siup.data
                  .siup_qualification,
                address: ''
              }
            } else {
              this.formDocument = {
                vendor_id: '',
                document_type_id: 1,
                document_no: '',
                date: '',
                expire_date: '',
                file: '',
                description: '',
                instance: '',
                siup_qualification: null,
                address: ''
              }
            }
          }
        }, 1000)
      } else if (this.headtitle === 'NPWP') {
        if (this.getDocument.npwp.status_code === '00') {
          this.formDocument = {
            vendor_id: '',
            document_type_id: 1,
            document_no: this.getDocument.npwp.data.document_no,
            date: this.getDocument.npwp.data.date,
            expire_date: this.getDocument.npwp.data.expire_date,
            file: this.getDocument.npwp.data.file,
            description: '',
            instance: this.getDocument.npwp.data.instance,
            siup_qualification: null,
            address: this.getDocument.npwp.data.address
          }
        } else {
          this.formDocument = {
            vendor_id: '',
            document_type_id: 1,
            document_no: '',
            date: '',
            expire_date: '',
            file: '',
            description: '',
            instance: '',
            siup_qualification: null,
            address: ''
          }
        }
      } else if (this.headtitle === 'DOMISILI') {
        if (this.getDocument.domisili.status_code === '00') {
          this.formDocument = {
            vendor_id: '',
            document_type_id: 1,
            document_no: this.getDocument.domisili.data.document_no,
            date: this.getDocument.domisili.data.date,
            expire_date: this.getDocument.domisili.data.expire_date,
            file: this.getDocument.domisili.data.file,
            description: '',
            instance: '',
            siup_qualification: null,
            address: ''
          }
        } else {
          this.formDocument = {
            vendor_id: '',
            document_type_id: 1,
            document_no: '',
            date: '',
            expire_date: '',
            file: '',
            description: '',
            instance: '',
            siup_qualification: null,
            address: ''
          }
        }
      } else if (this.headtitle === 'TDP') {
        if (this.getDocument.tdp.status_code === '00') {
          this.formDocument = {
            vendor_id: '',
            document_type_id: 1,
            document_no: this.getDocument.tdp.data.document_no,
            date: this.getDocument.tdp.data.date,
            expire_date: this.getDocument.tdp.data.expire_date,
            file: this.getDocument.tdp.data.file,
            description: '',
            instance: '',
            siup_qualification: null,
            address: ''
          }
        } else {
          this.formDocument = {
            vendor_id: '',
            document_type_id: 1,
            document_no: '',
            date: '',
            expire_date: '',
            file: '',
            description: '',
            instance: '',
            siup_qualification: null,
            address: ''
          }
        }
      } else if (this.headtitle === 'AKTA') {
        if (this.getDocument.akta.status_code === '00') {
          this.formDocument = {
            vendor_id: '',
            document_type_id: 1,
            document_no: this.getDocument.akta.data.document_no,
            date: this.getDocument.akta.data.date,
            expire_date: this.getDocument.akta.data.expire_date,
            file: this.getDocument.akta.data.file,
            description: '',
            instance: '',
            siup_qualification: null,
            address: ''
          }
        } else {
          this.formDocument = {
            vendor_id: '',
            document_type_id: 1,
            document_no: '',
            date: '',
            expire_date: '',
            file: '',
            description: '',
            instance: '',
            siup_qualification: null,
            address: ''
          }
        }
      }
    },
    getAsset(val) {
      this.initCatalogue()
    },
    initCatalogue() {
      this.catalogueList([
        this.getVendorId,
        this.searchCatalogue,
        this.offset * this.itemsPerPage,
        this.itemsPerPage,
        this.isAsset
      ])
        .then(result => {})
        .catch(error => {
          console.log(error)
        })
    },
    editCatalogue(arr) {
      console.log(arr)
      this.setCatalogueAct(arr)
      this.$router.push('/vendor-catalogue')
      // this.$router.push(
      //   `/vendor-catalogue/?${this.getVendorUrlParam.id}=${this.getVendorUrlParam.name}`
      // )
    },
    deleteCatalogue(item) {
      this.$swal
        .fire({
          title: 'Are you sure, this item will be deleted permanently',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true
        })
        .then(result => {
          if (result.value) {
            this.setIsLoading(true)
            this.catalogueDelete(item)
              .then(result => {
                console.log(result)
                this.setIsLoading(false)
                if (result.data.status_code !== '-99') {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })

                  this.initCatalogue()
                } else {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })
                }
              })
              .catch(error => {
                console.log(error)
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: error,
                  showConfirmButton: true,
                  timer: 5000
                })
              })
          }
        })
    },
    downloadDocument(value) {
      this.documentDownload(value)
        .then(result => {
          console.log(result)
          // this.setIsLoading(false)
        })
        .catch(error => {
          // this.setIsLoading(false)
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error
              ? 'Somthing went wrong, please contact an admin'
              : 'Somthing went wrong, please contact an admin',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 4500
          })
        })
    },

    price(val) {
      const reverse = val
        .toString()
        .split('')
        .reverse()
        .join('')
      var ribuan = reverse.match(/\d{1,3}/g)
      return ribuan
        .join(',')
        .split('')
        .reverse()
        .join('')
    },
    month(val) {
      switch (val) {
        case 1:
          return 'JAN'
        case 2:
          return 'FEB'
        case 3:
          return 'MAR'
        case 4:
          return 'APR'
        case 5:
          return 'MAY'
        case 6:
          return 'JUN'
        case 7:
          return 'JUL'
        case 8:
          return 'AGS'
        case 9:
          return 'SEP'
        case 10:
          return 'OKT'
        case 11:
          return 'NOV'
        case 12:
          return 'DES'
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
