<template>
  <div>
    <v-list-item>
      <div>
        <v-list-item-content>
          <v-list-item-title class="headline ">
            {{ $store.state.procurement.procDetail.procurement_no }}
          </v-list-item-title>
        </v-list-item-content>
      </div>
    </v-list-item>

    <v-list-item style="padding:0 15px;">
      <div style="width:20%; height:70px;">
        <v-list-item-content>
          <v-list-item-subtitle>
            <p style="font-size:16px; margin:0; color:black;">
              Nama Pengadaan :
            </p>
            <span style="color:black; font-weight:bold;">
              {{ $store.state.procurement.procDetail.name }}</span
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </div>

      <div style="width:20%;height:70px;">
        <v-list-item-content>
          <v-list-item-subtitle>
            <p style="font-size:16px; margin:0; color:black;">
              Tahun Anggaran :
            </p>
            <span style="color:black; font-weight:bold;">
              {{ $store.state.procurement.procDetail.year }}</span
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </div>

      <div style="width:20%;height:70px;">
        <v-list-item-content>
          <v-list-item-subtitle>
            <p style="font-size:16px; margin:0; color:black;">
              Total HPS :
            </p>
            <span style="color:black; font-weight:bold;">
              {{ price($store.state.procurement.procDetail.total_hps) }}</span
            >
          </v-list-item-subtitle>
        </v-list-item-content>
      </div>
    </v-list-item>
  </div>
</template>
<script>
export default {
  methods: {
    price(val) {
      if (val !== null || val !== undefined) {
        const reverse = val.toString()

        if (reverse.lastIndexOf('.') === -1) {
          const beforeComa1 = reverse
            .split('')
            .reverse()
            .join('')
          var ribuan1 = beforeComa1.match(/\d{1,3}/g)
          const result1 = ribuan1
            .join(',')
            .split('')
            .reverse()
            .join('')
          return result1
        } else {
          const beforeComa2 = reverse
            .slice(0, reverse.indexOf('.'))
            .split('')
            .reverse()
            .join('')
          var ribuan2 = beforeComa2.match(/\d{1,3}/g)
          const result2 = ribuan2
            .join(',')
            .split('')
            .reverse()
            .join('')
          const afterComa = reverse.slice(reverse.lastIndexOf('.') + 1)
          return result2 + ',' + afterComa
        }
      }
    }
  }
}
</script>
