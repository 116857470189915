var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-height":"30vh"}},[_c('v-data-table',{staticClass:"elevation-1 mx-auto row-pointer",staticStyle:{"margin-bottom":"10px","border":"1px solid #e0e0e0"},attrs:{"headers":_vm.headers,"items":_vm.itemData,"loading":_vm.loading,"hide-default-footer":"","items-per-page":_vm.itemsPerPage,"options":_vm.options,"server-items-length":_vm.totalItemData,"show-select":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticStyle:{"border-radius":"10px 10px 0 0","border-bottom":"1px solid #e0e0e0","padding":"0 10px"}},[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","flex-direction":"row","padding-top":"0","padding-bottom":"0"},attrs:{"cols":"12"}},[_c('div',{staticStyle:{"padding":"10px","display":"flex","justify-content":"center","align-items":"center","margin-right":"20px"}},[_c('p',{staticStyle:{"margin":"0"}},[_vm._v(" + ")]),_c('ItemTab',{attrs:{"param":_vm.param,"id":_vm.procId,"itemId":_vm.rowId},on:{"close":_vm.clear,"reload":_vm.reLoad}})],1),(_vm.selectedItem.length !== 0)?_c('div',{staticStyle:{"padding":"10px","display":"flex","justify-content":"center","align-items":"center","margin-right":"20px"}},[_c('v-icon',{staticStyle:{"margin-right":"7px"},attrs:{"color":"red","small":""}},[_vm._v(" mdi-delete ")]),_c('p',{staticClass:"deleteProcItem",on:{"click":_vm.remove}},[_vm._v("Delete")])],1):_vm._e()])],1)],1)]},proxy:true},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(item.product.name)+" ")])]}},{key:"item.unit",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(item.unit !== null ? item.unit.name : '-')+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(_vm.price(item.total))+" ")])]}},{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"padding":"0","display":"flex","flex-direction":"row"}},[_vm._v(" "+_vm._s(item.currency !== null ? item.currency.name : '-')+" ")])]}}],null,true),model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}}),_c('div',{staticClass:"pr-items-total-box"},[_c('div',[_vm._m(0),_c('p',[_vm._v(_vm._s(_vm.data !== null ? _vm.price(_vm.data.sub_total) : '-'))])]),_c('div',[_vm._m(1),_c('p',[_vm._v(_vm._s(_vm.data !== null ? _vm.price(_vm.data.ppn) : '-'))])]),_c('div',[_vm._m(2),_c('p',[_vm._v(_vm._s(_vm.data !== null ? _vm.price(_vm.data.grand_total) : '-'))])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("Sub Total "),_c('span',[_vm._v(":")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("PPN 10% "),_c('span',[_vm._v(":")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("Total "),_c('span',[_vm._v(":")])])}]

export { render, staticRenderFns }