<template>
  <div id="productEdit" class="col-12">
    <div class="ue-1" v-if="perm.read_perm === 1">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            perm.update_perm === 1 ? 'Product Edit' : 'Product Detail'
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <!-- <v-col cols="12" style="height:65px; padding:0 10px;"> -->
              <v-col
                cols="12"
                style="padding: 0 15px; height:45px; justify-content:flex-start; align-items:center; display:flex;"
              >
                <p
                  style="
                      margin:0;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Investasi
                </p>
                <v-checkbox
                  v-model="form.is_asset"
                  style=" margin:0 0 0 10px; height:25px; padding:0;"
                  :true-value="1"
                  :false-value="0"
                ></v-checkbox>
              </v-col>
              <!-- </v-col> -->
              <v-col cols="12" style="padding:0 10px;">
                <v-textarea
                  prepend-icon="mdi-ab-testing"
                  v-model="form.name"
                  label="Name*"
                  required
                  outlined
                  :readonly="perm.update_perm !== 1"
                  dense
                  :rules="rules.nameRules"
                  style="margin-top:10px;"
                  clear-icon="mdi-close-circle"
                  auto-grow
                ></v-textarea>
                <!-- <v-text-field
                  prepend-icon="mdi-ab-testing"
                  v-model="form.name"
                  label="Name*"
                  required
                  outlined
                  :readonly="perm.update_perm !== 1"
                  dense
                  :rules="rules.nameRules"
                  style="margin-top:10px;"
                ></v-text-field> -->
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-text-field
                  prepend-icon="mdi-ab-testing"
                  v-model="form.code"
                  label="Code"
                  required
                  outlined
                  :readonly="perm.update_perm !== 1"
                  dense
                  style="margin-top:10px;"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <!-- @change="categorySelect" -->
                <v-autocomplete
                  prepend-icon="mdi-shape-outline"
                  v-model="form.category_id"
                  :items="getCategoryDropdown"
                  outlined
                  dense
                  label="Kategori*"
                  style="margin-top:10px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  :rules="rules.categoryRules"
                  required
                  :readonly="perm.update_perm !== 1"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <!-- @change="unitSelect" -->
                <v-autocomplete
                  prepend-icon="mdi-unity"
                  :items="getUnitDropdown"
                  v-model="form.unit_id"
                  outlined
                  dense
                  label="Unit Satuan*"
                  style="margin-top:10px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  :rules="rules.unitRules"
                  required
                  :readonly="perm.update_perm !== 1"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-text-field
                  prepend-icon="mdi-watermark"
                  v-model="form.merk"
                  label="Merk"
                  required
                  outlined
                  dense
                  style="margin-top:10px;"
                  :readonly="perm.update_perm !== 1"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-textarea
                  prepend-icon="mdi-clipboard-list-outline"
                  v-model="form.spesification"
                  no-resize
                  height="65px"
                  outlined
                  dense
                  clear-icon="mdi-close-circle"
                  name="input-7-4"
                  label="Spesifikasi"
                  :clearable="perm.update_perm === 1"
                  :readonly="perm.update_perm !== 1"
                  style="margin-top:10px;"
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-file-input
                  :rules="rules.rules1"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  style="margin-top:30px;"
                  :label="this.form.photo_1 || 'Foto_1'"
                  outlined
                  dense
                  @change="upload"
                  :disabled="perm.update_perm !== 1"
                ></v-file-input>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-file-input
                  :rules="rules.rules1"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  style="margin-top:30px;"
                  :label="this.form.photo_2 || 'Foto_2'"
                  outlined
                  dense
                  @change="upload2"
                  :disabled="perm.update_perm !== 1"
                ></v-file-input>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-file-input
                  :rules="rules.rules1"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  style="margin-top:30px;"
                  :label="this.form.photo_3 || 'Foto_3'"
                  outlined
                  dense
                  @change="upload3"
                  :disabled="perm.update_perm !== 1"
                ></v-file-input>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-file-input
                  :rules="rules.rules1"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  style="margin-top:30px;"
                  :label="this.form.photo_4 || 'Foto_4'"
                  outlined
                  dense
                  @change="upload4"
                  :disabled="perm.update_perm !== 1"
                ></v-file-input>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-file-input
                  :rules="rules.rules1"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  style="margin-top:30px;"
                  :label="this.form.photo_5 || 'Foto_5'"
                  outlined
                  dense
                  ref="gambar"
                  @change="upload5"
                  :disabled="perm.update_perm !== 1"
                ></v-file-input>
              </v-col>

              <v-card
                v-if="getIsLoading === true"
                class="mx-auto"
                width="99%"
                height="260"
                style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
              >
                <v-progress-circular
                  :size="170"
                  :width="10"
                  color="blue"
                  indeterminate
                  >Please wait</v-progress-circular
                >
              </v-card>
            </v-row>
          </v-container>
          <div
            style="display: flex; flex-direction:column; padding:10px 0 0 40px;"
          >
            <small>- * indicates required field</small>
            <small>- Max image size = 2MB</small>
            <small>- Image resolution must be 1920x1080 px</small>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel">
            Close
          </v-btn>
          <v-btn
            v-if="perm.update_perm === 1"
            color="blue darken-1"
            text
            @click="save"
            :loading="getIsLoading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <v-card
      flat
      height="97vh"
      v-else
      color="white"
      style="position:fixed; width: 50%; display:flex; justify-content:center; align-items:center; padding:20px; background:#e0e0e0;"
    >
      <v-toolbar-title class="overline">
        You Can't Access This Page</v-toolbar-title
      >
      <v-btn
        color="grey"
        style="position:absolute; top:10px; left:10px;color:white;"
        @click="cancel"
      >
        <v-icon left>
          mdi-arrow-left-circle
        </v-icon>
        Close
      </v-btn>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['perm', 'keyword', 'orderType', 'orderBy'],
  data() {
    return {
      loading: false,
      categorySearch: '',
      unitSearch: '',
      form: {},
      rules: {
        rules1: [
          value =>
            !value ||
            value.size < 2000000 ||
            'File size should be less than 2 MB!'
        ],
        nameRules: [v => !!v || 'Name is required'],
        categoryRules: [v => !!v || 'Category is required'],
        unitRules: [v => !!v || 'Unit is required']
      }
    }
  },
  components: {},
  created() {
    setTimeout(() => {
      this.form = {
        name: this.getProductDetail.name,
        code: this.getProductDetail.code,
        category_id:
          this.getProductDetail.category !== null
            ? this.getProductDetail.category.id
            : null,
        unit_id:
          this.getProductDetail.unit !== null
            ? this.getProductDetail.unit.id
            : null,
        merk:
          this.getProductDetail.merk !== null ? this.getProductDetail.merk : '',
        spesification:
          this.getProductDetail.spesification !== null
            ? this.getProductDetail.spesification
            : '',
        photo_1: this.imageName(this.getProductDetail.photo_1),
        photo_2: this.imageName(this.getProductDetail.photo_2),
        photo_3: this.imageName(this.getProductDetail.photo_3),
        photo_4: this.imageName(this.getProductDetail.photo_4),
        photo_5: this.imageName(this.getProductDetail.photo_5),
        is_asset: this.getProductDetail.is_asset
      }
    }, 2000)
    this.dropDownProduct()
    this.dropDownUnit()
  },
  computed: {
    ...mapGetters([
      'getProductEditStat',
      'getCategoryDropdown',
      'getUnitDropdown',
      'getProductDetail',
      'getDetailProduct',
      'getIsLoading'
    ])
  },
  methods: {
    ...mapActions([
      'adminProductEdit',
      'adminProductList',
      'initProduct',
      'uploadImgMasterProduct',
      'categoryProduct',
      'unitProduct'
    ]),
    ...mapMutations(['productEdit', 'setIsLoading']),
    imageName(link) {
      if (link === null || link === '') {
        return ''
      } else {
        const lastIndxOf = link.lastIndexOf('/')
        const str = link.substring(lastIndxOf + 1)
        if (str === 'null') {
          return ''
        } else {
          return str
        }
      }
    },
    upload(event) {
      if (event !== null) {
        if (event.size <= 1024 * 1024) {
          const data = new FormData()
          data.append('photo', event)
          data.append('index', 1)

          this.uploadImgMasterProduct(data)
            .then(results => {
              if (results.data.message === 'No file uploaded') {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              } else {
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              }

              this.form.photo_1 = results.data.data.name
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.$swal.fire({
            position: 'center',
            title: 'Max size = 1MB',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          this.form.photo_1 = null
        }
      } else {
        this.form.photo_1 = null
      }
    },
    dropDownProduct() {
      this.categoryProduct(this.categorySearch)
    },
    dropDownUnit() {
      this.unitProduct(this.unitSearch)
    },
    // categorySelect(value) {
    //   this.form.category_id = value.id
    // },
    // unitSelect(value) {
    //   this.form.unit_id = value.id
    // },
    cancel() {
      if (!this.getProductEditStat) {
        this.productEdit(true)
      } else {
        this.productEdit(false)
      }
    },
    save() {
      if (this.form.name.length === 0) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Name must be filled',
          showConfirmButton: true,
          showCancelButton: false,
          timer: 4500
        })
      } else {
        this.$swal
          .fire({ title: 'Edit now', icon: 'question', showCancelButton: true })
          .then(result => {
            if (result.value) {
              this.setIsLoading(true)
              this.adminProductEdit(this.form)
                .then(response => {
                  if (response.data.status_code === '-99') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: response.data.status_msg,
                      showConfirmButton: true,
                      showCancelButton: false,
                      timer: 4500
                    })
                  } else {
                    this.form = {
                      photo: '',
                      name: '',
                      code: '',
                      category_id: null,
                      unit_id: null,
                      merk: '',
                      spesification: ''
                    }
                    this.adminProductList([
                      this.keyword,
                      0,
                      10,
                      this.orderBy,
                      this.orderType
                    ])
                      .then(results => {
                        this.initProduct(results)
                      })
                      .catch(error => {
                        console.log(error)
                      })

                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'edit data success',
                      showConfirmButton: false,
                      showCancelButton: false,
                      timer: 1500
                    })

                    this.productEdit(true)
                  }

                  setTimeout(() => {
                    this.setIsLoading(false)
                  }, 200)
                })
                .catch(error => {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error,
                    showConfirmButton: true,
                    showCancelButton: false,
                    timer: 4500
                  })

                  setTimeout(() => {
                    this.setIsLoading(false)
                  }, 200)
                })
            }
          })
      }
    },
    upload2(event) {
      if (event !== null) {
        if (event.size <= 1024 * 1024) {
          const data = new FormData()
          data.append('photo', event)
          data.append('index', 2)

          this.uploadImgMasterProduct(data)
            .then(results => {
              if (results.data.message === 'No file uploaded') {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              } else {
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              }

              this.form.photo_2 = results.data.data.name
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.$swal.fire({
            position: 'center',
            title: 'Max size = 1MB',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          this.form.photo_2 = null
        }
      } else {
        this.form.photo_2 = null
      }
    },
    upload3(event) {
      if (event !== null) {
        if (event.size <= 1024 * 1024) {
          const data = new FormData()
          data.append('photo', event)
          data.append('index', 3)

          this.uploadImgMasterProduct(data)
            .then(results => {
              if (results.data.message === 'No file uploaded') {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              } else {
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              }

              this.form.photo_3 = results.data.data.name
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.$swal.fire({
            position: 'center',
            title: 'Max size = 1MB',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          this.form.photo_3 = null
        }
      } else {
        this.form.photo_3 = null
      }
    },
    upload4(event) {
      if (event !== null) {
        if (event.size <= 1024 * 1024) {
          const data = new FormData()
          data.append('photo', event)
          data.append('index', 4)

          this.uploadImgMasterProduct(data)
            .then(results => {
              if (results.data.message === 'No file uploaded') {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              } else {
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              }

              this.form.photo_4 = results.data.data.name
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.$swal.fire({
            position: 'center',
            title: 'Max size = 1MB',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          this.form.photo_4 = null
        }
      } else {
        this.form.photo_4 = null
      }
    },
    upload5(event) {
      if (event !== null) {
        if (event.size <= 1024 * 1024) {
          const data = new FormData()
          data.append('photo', event)
          data.append('index', 5)

          this.uploadImgMasterProduct(data)
            .then(results => {
              if (results.data.message === 'No file uploaded') {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              } else {
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              }

              this.form.photo_5 = results.data.data.name
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.$swal.fire({
            position: 'center',
            title: 'Max size = 1MB',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          this.form.photo_5 = null
        }
      } else {
        this.form.photo_5 = null
      }
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/productManage.scss"></style>
