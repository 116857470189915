<template>
  <div class="row justify-content-center" id="Login">
    <div class="col-md-12 log-form">
      <v-img
        style="width: 100%; height: 100%; position: absolute;"
        src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
      ></v-img>
      <div
        style="position: relative; right:80px; z-index:2; display: flex; flex-direction: row;"
      >
        <v-btn
          @click.prevent="home"
          style="height:50px;width:50px; margin-right: 10px;"
          elevation="5"
          fab
          x-large
          color="white"
          class=" rounded-circle"
          ><v-img
            alt="sanqua multi international"
            class=" rounded-circle"
            contain
            src="../../../public/sanquamulti.png"
            transition="scale-transition"
            style="height:50px;width:50px; "
        /></v-btn>
        <h5 style="line-height:20px; margin-top:12px; color: white;">
          Sanqua
          <h4 style="color:#37474F">Forgoten password</h4>
        </h5>
      </div>
      <v-card
        elevation="10"
        class="mx-auto"
        max-width="444"
        style="padding:30px;"
      >
        <h3
          style="display: flex; justify-content: flex-start; align-items:center; width: 130px;"
        >
          Forgot Password
        </h3>
        <p style="font-size: 12px; color: rgba(0, 0, 0, 0.5">
          Please enter your email address. After you submit, we will send you
          the link that you can reset your password.
        </p>
        <b-form @submit.prevent="forgot">
          <label
            for="text-email"
            style="width:100%; text-align:left; padding-left: 10px"
            >Email</label
          >
          <b-form-input
            type="email"
            id="text-email"
            v-model="form.email"
            style="margin-bottom: 20px;"
          ></b-form-input>

          <div class="col-12" style="text-align: right; padding: 0;;">
            <button type="submit">Send request</button>
            <router-link to="/login">Sign in now!</router-link>
          </div>
        </b-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'reset',
  data() {
    return {
      form: {
        email: ''
      }
    }
  },
  components: {},
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions(['emailForgot']),
    ...mapMutations([]),
    forgot() {
      if (!this.form.email) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Input must be filled',
          showConfirmButton: true,
          showCancelButton: false,
          timer: 5000
        })
      } else {
        this.emailForgot(this.form)
          .then(results => {
            if (results.data.status_code === '-99') {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: results.data.status_msg,
                showConfirmButton: true,
                showCancelButton: false,
                timer: 5000
              })
              this.form.email = ''
            } else {
              if (results.data === '') {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Email not registered',
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
                this.form.email = ''
              } else {
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title:
                    'Request code has been send to your email, please check your email to change your password',
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
                this.form.email = ''
              }
            }
          })
          .catch(error => {
            this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: error,
              showConfirmButton: true,
              showCancelButton: false,
              timer: 5000
            })
            this.form.email = ''
          })
      }
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/login.scss"></style>
