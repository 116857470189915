import axios from 'axios'
import buildType from '../../services/buildType'
export default {
  state: {
    procAPI: buildType.apiURL('proc')
  },
  mutations: {},
  actions: {
    globalDropdown(context, payload) {
      console.log(payload)
      return new Promise(resolve => {
        axios
          .get(
            `${context.state.procAPI}globalmaster/${payload[0]}/dropdown?keyword=${payload[1]}`
          )
          .then(response => {
            resolve(response)
          })
      })
    }
  },
  getters: {}
}
