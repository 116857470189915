<template>
  <div id="categoryEdit" class="col-12">
    <div class="ue-1">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            getCategoryEditAct === 'edit' ? 'Category Edit' : 'Add New Category'
          }}</span>
        </v-card-title>

        <v-card-text v-if="perm.read_perm === 1">
          <v-container>
            <v-row>
              <v-col
                class="d-flex justify-content-start align-center"
                md="12"
                style="padding: 0 0 0 10px; margin-bottom:0;"
              >
                <v-radio-group>
                  <v-col
                    cols="12"
                    style="padding: 0; justify-content:flex-start; align-items:center; display:flex;"
                  >
                    <v-checkbox
                      v-model="form.is_investment"
                      style=" margin:0; height:25px; padding:0;"
                    ></v-checkbox>
                    <p
                      style="
                      margin:0;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Investasi
                    </p>
                  </v-col>
                </v-radio-group>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-text-field
                  prepend-icon="mdi-ab-testing"
                  v-model="form.name"
                  label="Name*"
                  required
                  outlined
                  :readonly="
                    (getCategoryEditAct === 'add' && perm.create_perm === 0) ||
                      (getCategoryEditAct === 'edit' && perm.update_perm !== 1)
                  "
                  dense
                  style="margin-top:10px;"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>

        <v-card
          flat
          height="30vh"
          v-else
          color="transparent"
          style="display:flex; justify-content:center; align-items:center;"
        >
          <v-toolbar-title class="overline">
            You Can't Access This Page</v-toolbar-title
          >
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel">
            Close
          </v-btn>
          <v-btn
            v-if="perm.read_perm === 1"
            color="blue darken-1"
            text
            :disabled="
              (getCategoryEditAct === 'add' && perm.create_perm === 0) ||
                (getCategoryEditAct === 'edit' && perm.update_perm !== 1)
            "
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['perm'],
  data() {
    return {
      categorySearch: '',
      unitSearch: '',
      form: {
        name: '',
        photo: '',
        is_investment: false
      },
      rules: {
        rules1: [
          value =>
            !value ||
            value.size < 2000000 ||
            'File size should be less than 2 MB!'
        ]
      }
    }
  },
  components: {},
  created() {
    if (this.getCategoryEditAct === 'edit') {
      console.log(this.getCategoryData)
      this.form.name = this.getCategoryData.name
      this.form.photo = this.getCategoryData.image
      if (this.getCategoryData.is_investment === 1) {
        this.form.is_investment = true
      } else {
        this.form.is_investment = false
      }
    }
  },
  computed: {
    ...mapGetters([
      'getCategoryEditStat',
      'getCategoryEditAct',
      'getCategoryData'
    ])
  },
  methods: {
    ...mapActions([
      'adminCategoryEdit',
      'adminCategoryList',
      'initCategory',
      'uploadImgEditCategory'
    ]),
    ...mapMutations(['categoryEdit']),
    showMsgDialog(pModalType, pStatusMsg) {
      this.$swal
        .fire({
          // title:
          //   pModalType === 'success'
          //     ? '<strong>Success</strong>'
          //     : `<strong>${pModalType}</strong>`,
          // icon: pModalType,
          // html: pStatusMsg,
          // timer: 5000
          icon: pModalType,
          html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
          showConfirmButton: true,
          showCancelButton: true
        })
        .then(r => {})
    },

    upload(event) {
      const data = new FormData()
      data.append('photo', event)

      this.uploadImgEditCategory(data)
        .then(results => {
          if (results.data.message === 'No file uploaded') {
            this.showMsgDialog('error', results.data.message)
          } else {
            this.showMsgDialog('success', results.data.message)
          }

          this.form.photo = results.data.data.name
        })
        .catch(error => {
          this.showMsgDialog(
            error ? 'error' : 'error',
            'Something went wrong, please contact an admin'
          )
        })
    },

    cancel() {
      // if (!this.getCategoryEditStat) {
      this.categoryEdit([true])
      // } else {
      // this.categoryEdit([false])
      // }

      this.form = {
        name: '',
        photo: ''
      }
    },
    save() {
      if (this.form.name.length === 0) {
        this.showMsgDialog('warning', 'Name must be filled')
      } else {
        this.$swal
          .fire({ title: 'Edit now', icon: 'question', showCancelButton: true })
          .then(result => {
            if (result.value) {
              this.adminCategoryEdit(this.form)
                .then(response => {
                  if (response.data.status_code === '-99') {
                    this.showMsgDialog('warning', response.data.status_msg)
                  } else {
                    this.form = {
                      photo: '',
                      name: '',
                      is_investment: false
                    }

                    this.adminCategoryList(['', 0, 10])
                      .then(results => {
                        this.initCategory(results)
                      })
                      .catch(error => {
                        this.showMsgDialog(
                          error ? 'error' : 'error',
                          'Something went wrong, please contact an admin'
                        )
                      })

                    if (this.getCategoryEditAct === 'edit') {
                      this.showMsgDialog('success', 'edit data success')

                      this.categoryEdit([true])
                    } else {
                      this.$swal
                        .fire({
                          position: 'center',
                          icon: 'success',
                          title: 'submit data success',
                          showConfirmButton: true,
                          timer: 4500
                        })
                        .then(result => {
                          this.$swal
                            .fire({
                              title: 'Submit again',
                              icon: 'question',
                              showCancelButton: true
                            })
                            .then(result => {
                              if (!result.value) {
                                this.categoryEdit([true])
                              }
                            })
                        })
                    }
                  }
                })
                .catch(error => {
                  this.showMsgDialog(
                    error ? 'error' : 'error',
                    'Something went wrong, please contact an admin'
                  )
                })
            }
          })
      }
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/productManage.scss"></style>
