<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="100%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          small
          tile
          color="#e0e0e0"
          class="ma-2 black--text"
          style="width:100px; position:relative;"
        >
          Import
          <v-icon small right dark>
            mdi-file-import
          </v-icon>
        </v-btn>
      </template>

      <v-card style="min-height:100vh; padding:20px; background:#e0e0e0;">
        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:10px 15px 20px 15px; width:95%; margin-top:30px;"
        >
          <v-row>
            <v-col cols="12">
              <v-toolbar flat style="border-radius:10px 10px 0 0;">
                <v-btn
                  class="mx-2 elevation-4"
                  dark
                  large
                  color="cyan"
                  style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                >
                  <v-icon large dark>
                    mdi-upload
                  </v-icon>
                </v-btn>
                <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
                <v-toolbar-title>Upload File</v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12" style="padding:0;">
              <p style="margin:0; font-size:14px; padding-left:35px;">
                Please download tempalate for upload <a href="#">here</a>
              </p>
            </v-col>
            <v-col cols="12" style="padding-left:35px;">
              <p style="border-top:5px solid cyan;"></p>
            </v-col>
            <v-col cols="12" style="padding: 0 0 30px 30px;">
              <v-row>
                <v-col cols="10">
                  <v-file-input
                    show-size
                    label="File input"
                    v-model="uploadFile"
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    tile
                    small
                    color="grey"
                    class="ma-2 white--text"
                    @click="unitImport"
                    :disabled="uploadFile === null"
                  >
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:20px 10px; width:95%; margin-top:20px;"
        >
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="headers"
                :items="unitData"
                class="elevation-1 mx-auto row-pointer"
                style="width:99%; margin-top: 20px; margin-bottom:20px;"
                :items-per-page="itemsPerPage"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                    style="height:100px; border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;"
                  >
                    <v-btn
                      class="mx-2 elevation-4"
                      dark
                      large
                      color="cyan"
                      style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                    >
                      <v-icon large dark>
                        mdi-clipboard-list-outline
                      </v-icon>
                    </v-btn>
                    <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
                    <v-toolbar-title
                      >Spesification Category Import</v-toolbar-title
                    >
                  </v-toolbar>
                </template>
              </v-data-table>
              <v-col cols="12" style="padding:0 5px;">
                <v-btn
                  tile
                  style="margin-right:20px;"
                  color="success"
                  height="30px"
                  width="100px"
                  :disabled="!isImport"
                  @click="saveUnitImport"
                >
                  Save Data
                </v-btn>
                <v-btn
                  tile
                  style="margin-right:20px;"
                  color="pink"
                  height="30px"
                  width="100px"
                  class="white--text"
                  :disabled="!isImport"
                  @click="remove"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-card>

      <v-card
        v-if="getIsLoading === true"
        class="mx-auto"
        width="99%"
        height="260"
        style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
      >
        <v-progress-circular :size="170" :width="10" color="blue" indeterminate
          >Please wait</v-progress-circular
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: [],
  data() {
    return {
      dialog: false,
      isUpload: false,
      uploadFile: null,
      isImport: false,
      itemsPerPage: 10,

      headers: [{ text: 'Unit Name', value: 'name', align: 'center' }],
      unitData: []
    }
  },
  computed: {
    ...mapGetters(['getIsLoading'])
  },
  methods: {
    ...mapActions(['unitFile', 'saveImportUnit']),
    ...mapMutations(['setIsLoading']),
    showMsgDialog(pModalType, pStatusMsg) {
      this.$swal
        .fire({
          icon: pModalType,
          html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
          showConfirmButton: true,
          showCancelButton: true,
          cancelButtonText: 'No'
        })
        .then(r => {})
    },
    remove() {
      this.unitData = []
      this.isImport = false
    },
    clear() {
      this.uploadFile = null
    },
    unitImport() {
      this.unitData = []
      if (
        this.uploadFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.setIsLoading(true)
        const data = new FormData()
        data.append('file', this.uploadFile)
        console.log(this.uploadFile)

        this.unitFile(data)
          .then(result => {
            if (result.data.status_code === '00') {
              for (let i = 0; i < result.data.data.length; i++) {
                if (result.data.data[i].name !== '') {
                  const param = {
                    // no: 1 + i,
                    name: result.data.data[i].name
                  }
                  if (result.data.data[i].id !== undefined) {
                    Object.assign(param, {
                      id: result.data.data[i].id
                    })
                  }
                  this.unitData.push(param)
                }
              }
              this.uploadFile = null
              this.isImport = true
              this.setIsLoading(false)
            } else {
              this.showMsgDialog('error', result.data)
              this.setIsLoading(false)
              this.unitData = []
              this.isImport = false
            }
          })
          .catch(error => {
            this.setIsLoading(false)
            this.showMsgDialog('error', error)
            this.unitData = []
            this.isImport = false
          })
      } else {
        this.showMsgDialog('error', 'File type must be .xlsx')
        this.clear()
      }
    },
    saveUnitImport() {
      const form = {
        act: 'add',
        data: this.unitData
      }
      console.log(form)
      this.setIsLoading(true)
      this.saveImportUnit(form)
        .then(result => {
          if (result.data.status_code === '00') {
            this.setIsLoading(false)
            this.showMsgDialog('success', result.data.status_msg)
            this.unitData = []
            this.isImport = false
          }
        })
        .catch(error => {
          console.log(error)
          this.setIsLoading(false)
          this.showMsgDialog('error', error)
          this.unitData = []
          this.isImport = false
        })
    }
  }
}
</script>
<style></style>
