import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/home/Home.vue'
import Login from '../views/auth/Login.vue'
import Forgot from '../views/auth/Forgot.vue'
import Reset from '../views/auth/Reset.vue'
import Register from '../views/auth/Register.vue'
import Change from '../views/auth/Change.vue'
import MasterVendor from '../views/vendorManage/vendorManage.vue'
import VendorProfile from '../views/vendorManage/vendorProfile.vue'
import VendorCatalogue from '../views/vendorManage/vendorCatalogue.vue'
import VendorBlacklist from '../views/vendorManage/vendorBlacklist.vue'
import VendorCatalogueImport from '../views/vendorManage/catalogueImport.vue'
import VendorCatalogueExport from '../views/export/catalogueExport.vue'
import VendorQuotationImport from '../views/vendorManage/quotationImport.vue'
import VendorQuotationExport from '../views/export/quotationExport.vue'
import VendorSpesificationImport from '../views/vendorManage/spesificationImport.vue'
import VendorSpesificationExport from '../views/export/spesificationExport.vue'
import MasterProduct from '../views/productManage/productManage.vue'
import ProductCategory from '../views/productManage/productCategory.vue'

import MasterSpesificationCategory from '../views/export/master/spesificationCategory.vue'
import MasterSpesificationAttribute from '../views/export/master/spesificationAttribute.vue'
import MasterUnit from '../views/export/master/unit.vue'
import MasterBusinessEntity from '../views/export/master/businessEntity.vue'
import MasterClassification from '../views/export/master/classification.vue'
import MasterSubClassification from '../views/export/master/subClassification.vue'
import MasterProvince from '../views/export/master/province.vue'
import MasterCity from '../views/export/master/city.vue'
import MasterCurrency from '../views/export/master/currency.vue'

import ProcurementList from '../views/procurement/procurement_list/procurementList'
import ProcurementForm from '../views/procurement/procurement_form/procurementForm'
import InvitationForm from '../views/procurement/invitation/invitationForm'
import Participants from '../views/procurement/participants/participantsList'

import FpbList from '../views/fpb/FpbList'
import Fpb from '../views/fpb/Fpb'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresVisitor: true }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: { requiresVisitor: true }
  },
  {
    path: '/forgot-password',
    name: 'forgot',
    component: Forgot,
    meta: { requiresVisitor: true }
  },
  {
    path: '/reset-password/:token',
    name: 'reset',
    component: Reset,
    meta: { requiresVisitor: true }
  },
  {
    path: '/change-password',
    name: 'change',
    component: Change,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-vendor',
    name: 'master-vendor',
    component: MasterVendor,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-product',
    name: 'master-product',
    component: MasterProduct,
    meta: { requiresAuth: true }
  },
  {
    path: '/product-category',
    name: 'product-category',
    component: ProductCategory,
    meta: { requiresAuth: true }
  },
  {
    path: '/vendor-profile',
    name: 'vendor-profile',
    component: VendorProfile,
    meta: { requiresAuth: true }
  },
  {
    path: '/vendor-catalogue',
    name: 'vendor-catalogue',
    component: VendorCatalogue,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-vendor/blacklist',
    name: 'vendor-blacklist',
    component: VendorBlacklist,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-vendor/catalogue/import',
    name: 'vendor-catalogue-import',
    component: VendorCatalogueImport,
    meta: { requiresAuth: true }
  },

  {
    path: '/master-vendor/catalogue/export',
    name: 'vendor-catalogue-export',
    component: VendorCatalogueExport,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-vendor/quotation/import',
    name: 'vendor-quotation-import',
    component: VendorQuotationImport,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-vendor/quotation/export',
    name: 'vendor-quotation-export',
    component: VendorQuotationExport,
    meta: { requiresAuth: true }
  },

  {
    path: '/master-vendor/spesification/import',
    name: 'vendor-spesification-import',
    component: VendorSpesificationImport,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-vendor/spesification/export',
    name: 'vendor-spesification-export',
    component: VendorSpesificationExport,
    meta: { requiresAuth: true }
  },
  {
    path: '/e-procurement/procurement-list',
    name: 'procurement-list',
    component: ProcurementList,
    meta: { requiresAuth: true }
  },
  {
    path: '/e-procurement/procurement-form',
    name: 'procurement-form',
    component: ProcurementForm,
    meta: { requiresAuth: true }
  },
  {
    path: '/e-procurement/invitation-form',
    name: 'invitation-form',
    component: InvitationForm,
    meta: { requiresAuth: true }
  },
  {
    path: '/e-procurement/participants',
    name: 'participants',
    component: Participants,
    meta: { requiresAuth: true }
  },

  {
    path: '/master-data/spesification-category',
    name: 'master-spesification-category',
    component: MasterSpesificationCategory,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/spesification-attribute',
    name: 'master-spesification-attribute',
    component: MasterSpesificationAttribute,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/unit',
    name: 'master-unit',
    component: MasterUnit,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/business-entity',
    name: 'master-business-entity',
    component: MasterBusinessEntity,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/classification',
    name: 'master-classification',
    component: MasterClassification,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/sub-classification',
    name: 'master-sub-classification',
    component: MasterSubClassification,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/province',
    name: 'master-province',
    component: MasterProvince,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/city',
    name: 'master-city',
    component: MasterCity,
    meta: { requiresAuth: true }
  },
  {
    path: '/master-data/currency',
    name: 'master-currency',
    component: MasterCurrency,
    meta: { requiresAuth: true }
  },
  {
    path: '/fpb-list',
    name: 'fpb-list',
    component: FpbList,
    meta: { requiresAuth: true }
  },
  {
    path: '/fpb-list/fpb',
    name: 'fpb',
    component: Fpb,
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.getLogin) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters.getLogin) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
