<template>
  <div>
    <div style="margin:0 0 20px 0;">
      <Navigation chosenpage="Master Export" @setDrawer="drawerSet" />
    </div>

    <v-card
      v-if="!getIsLoading"
      elevation="2"
      class=" mx-auto"
      style="padding:10px 10px; width:95%; margin-top:20px;"
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headers"
            :items="currencyData"
            class="elevation-1 mx-auto row-pointer"
            style="width:99%; margin-top: 20px; margin-bottom:20px;"
            :items-per-page="itemsPerPage"
            @click:row="rowClick"
          >
            <template v-slot:top>
              <v-toolbar flat style="border-bottom:1px solid #e0e0e0;">
                <v-toolbar-title style="padding-left:10px; "
                  >Currency Export</v-toolbar-title
                >

                <v-divider class="mx-6" inset vertical></v-divider>
                <v-text-field
                  v-model="param.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  style="position: relative; top:15px;"
                ></v-text-field>
                <v-divider class="mx-6" inset vertical></v-divider>
                <v-btn
                  v-if="currencySelection.length === 0"
                  small
                  tile
                  color="#e0e0e0"
                  class="ma-2 black--text"
                  style="width:100px; position:relative;"
                  @click="exportAll"
                >
                  Export All
                </v-btn>

                <v-btn
                  v-else
                  small
                  tile
                  color="#e0e0e0"
                  class="ma-2 black--text"
                  style="width:100px; position:relative;"
                  @click="exportFile"
                >
                  Export Now
                </v-btn>

                <!-- <router-link to="/master-vendor/quotation/import"> -->
                <div>
                  <CurrencyImport />
                </div>
                <!-- </router-link> -->
                <v-spacer></v-spacer
                ><v-card style="width:110px; position: absolute; right:-10px;">
                  <CurrencyEdit
                    :form="formEdit"
                    @formClose="close"
                    @refreshTable="exportTable"
                  />
                  <v-toolbar-title
                    style="font-size:12px; color: black; text-align:left; padding:3px 10px 0 5px;"
                  >
                    New Currency
                  </v-toolbar-title></v-card
                >
              </v-toolbar>
            </template>

            <template v-slot:[`item.check`]="{ item }">
              <td
                style=" height:40px; display:flex; justify-conten:center; align-items:center;"
              >
                <v-checkbox
                  v-model="currencySelection"
                  :value="item"
                ></v-checkbox>
              </td>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <td>
                &nbsp;
                <v-btn
                  small
                  elevation="0"
                  color="#e0e0e0"
                  class="black--text"
                  @click.stop="currencyDelete(item)"
                >
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <Loader v-else />
    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import XLSX from 'xlsx'
import Navigation from '../../../components/navigation/Navigation'
import Drawer from '../../../components/navigation/Drawer'
import CurrencyImport from '../../../components/master/CurrencyImport'
import CurrencyEdit from '../../../components/master/edit/currencyEdit'
import Loader from '../../../components/common/loader/CircularProgress'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      procAPI: buildType.apiURL('proc'),
      drawer: false,
      dialog: false,
      isImport: false,
      itemsPerPage: 10,
      param: {
        offset: 0,
        limit: 'all',
        keyword: '',
        category_id: ''
      },
      headers: [
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'check',
          width: '10%'
        },
        { text: 'Currency Name', value: 'name', align: 'left' },
        { text: 'Code', value: 'code', align: 'left' },
        {
          text: 'Symbol',
          sortable: false,
          value: 'symbol',
          align: 'left'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'action',
          align: 'left'
        }
      ],
      currencyData: [],
      currencySelection: [],
      formEdit: {
        act: 'add',
        name: '',
        code: '',
        symbol: ''
      }
    }
  },
  components: { Navigation, Drawer, CurrencyImport, CurrencyEdit, Loader },
  watch: {
    'param.keyword'() {
      this.exportTable()
    }
  },
  computed: {
    ...mapGetters(['getIsLoading'])
  },
  created() {
    this.exportTable()
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(['setIsLoading']),
    drawerSet(val) {
      this.drawer = val
    },
    rowClick(item) {
      this.formEdit = {
        act: 'update',
        id: item.id,
        name: item.name,
        code: item.code,
        symbol: item.symbol
      }
      document.getElementById('currencyEditBtn').click()
    },
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            icon: pModalType,
            html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    exportTable() {
      this.setIsLoading(true)
      axios
        .get(
          `${this.procAPI}master/currency/list?offset=${this.param.offset}&limit=${this.param.limit}&keyword=${this.param.keyword}`
        )
        .then(res => {
          this.currencyData = []
          const response = res.data.data
          this.currencyData = response
          console.log(res)
          setTimeout(() => {
            this.setIsLoading(false)
          }, 500)
        })
        .catch(error => {
          this.setIsLoading(false)
          console.log(error)
        })
    },
    exportAll() {
      this.currencySelection = this.currencyData
    },
    exportFile() {
      this.showMsgDialog('question', 'Export now').then(res => {
        if (res.isConfirmed) {
          this.showMsgDialog('question', 'Sertakan ID').then(res => {
            if (res.isConfirmed) {
              this.exportNow(1)
            } else if (res.dismiss === 'cancel') {
              this.exportNow(0)
            } else if (res.dismiss === 'backdrop') {
              this.currencySelection = []
            }
          })
        } else if (res.dismiss === 'cancel') {
          this.currencySelection = []
        } else if (res.dismiss === 'backdrop') {
          this.currencySelection = []
        }
      })
    },
    exportNow(num) {
      const arrData = []
      for (let i = 0; i < this.currencySelection.length; i++) {
        const param = {
          name: this.currencySelection[i].name,
          code: this.currencySelection[i].code,
          symbol: this.currencySelection[i].symbol
        }
        if (num === 1) {
          Object.assign(param, {
            id: this.currencySelection[i].id
          })
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'currency.xlsx')

      setTimeout(() => {
        this.currencySelection = []
      }, 1000)
    },
    currencyDelete(item) {
      const id = item.id
      axios
        .delete(`${this.procAPI}master/currency/delete/${id}`)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg)
            this.exportTable()
          } else {
            this.showMsgDialog('error', res.data.status_msg)
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong please contact an Admin'
              : 'Something went wrong please contact an Admin'
          )
        })
    },
    close() {
      this.formEdit = {
        act: 'add',
        name: '',
        code: '',
        symbol: ''
      }
    }
  }
}
</script>
<style></style>
