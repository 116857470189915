<template>
  <div style="padding:0;">
    <div>
      <Navigation chosenpage="Master Product" @setDrawer="drawerSet" />
    </div>
    <div v-if="permission.read_perm === 1" style="margin-bottom:20px; ">
      <v-data-table
        :headers="headers"
        :items="getProductData"
        class="elevation-6 mx-auto"
        :loading="loading"
        loading-text="Finding data... Please wait"
        hide-default-footer
        :items-per-page="itemsPerPage"
        v-model="productSelection"
        :single-select="false"
        dense
        style="width:90%; margin-top: 20px; margin-bottom:20px;"
      >
        <template v-slot:top>
          <v-toolbar style="height:100px;"
            ><v-icon style="margin-right: 10px;">mdi-account-details</v-icon>
            <v-toolbar-title>Product Data</v-toolbar-title>
            <v-divider class="mx-6" inset vertical></v-divider>
            <v-text-field
              v-model="invokeSrc"
              label="Search here"
              type="search"
              outlined
              dense
              style="position: relative; top:15px; width:5px;"
            ></v-text-field>
            <div v-if="productSelection.length > 0">
              <v-btn
                small
                tile
                color="#e0e0e0"
                class="black--text"
                style="margin-left: 20px"
                @click="hideData"
              >
                Hide

                <v-icon small right dark>
                  mdi-folder-hidden
                </v-icon>
              </v-btn>
            </div>
            <div
              v-if="permission.create_perm === 1"
              style="position:absolute; right:133px; "
            >
              <ProductImport :perm="permission" />
            </div>

            <div
              style="position:absolute; right:270px; "
              v-if="productSelection.length === 0"
            >
              <v-btn
                small
                tile
                color="#e0e0e0"
                class="ma-2 black--text"
                style="width:100px; position:relative;"
                @click="exportAll"
              >
                Export All
              </v-btn>
            </div>
            <div v-else style="position:absolute; right:270px; ">
              <v-btn
                small
                tile
                color="#e0e0e0"
                class="ma-2 black--text"
                style="position:relative;"
                @click="exportFile"
              >
                Export Now

                <v-icon small right dark>
                  mdi-file-export
                </v-icon>
              </v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-card
              v-if="permission.create_perm === 1"
              style="width:110px; position: absolute; right:-10px;"
            >
              <v-btn
                color="pink"
                fab
                dark
                absolute
                small
                @click="productManageAdd"
                style="z-index:1; right:-15px; top:-10px;"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-toolbar-title
                style="font-size:12px; color: black; text-align:left; padding:3px 10px 0 5px ;"
              >
                New Product
              </v-toolbar-title></v-card
            >
            <div
              style="display:flex;justify-content:space-evenly; align-items:center; width:300px;height:35px; position:absolute; bottom:-30px; left:190px;"
            >
              <p style="margin:0; font-weight:bold;">Sort by</p>
              <p class="productSortIcon" @click="vCode">
                code:
                <v-icon small style=" box-shadow: 1px 1px 5px black;">{{
                  vCodeIcon
                }}</v-icon>
              </p>
              <p class="productSortIcon" @click="vName">
                name:
                <v-icon small style=" box-shadow: 1px 1px 5px black;">{{
                  vNameIcon
                }}</v-icon>
              </p>
            </div>
          </v-toolbar>
        </template>
        <template v-slot:[`item.compare`]="{ item }">
          <td>
            <v-checkbox v-model="productSelection" :value="item"></v-checkbox>
          </td>
        </template>
        <template v-slot:[`item.no`]="{ item }">
          <td>
            {{ item.no + offset * itemsPerPage }}
          </td>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <td>
            {{ item.name }}
          </td>
        </template>
        <template v-slot:[`item.unit`]="{ item }">
          <td>
            {{ item.unit !== null ? item.unit.name : '-' }}
          </td>
        </template>
        <template v-slot:[`item.category`]="{ item }">
          <td>
            {{ item.category.name }}
          </td>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          <td>
            {{ convertDate(item.created) }}
          </td>
        </template>
        <template v-slot:[`item.last_update`]="{ item }">
          <td>
            {{ convertDate(item.last_update) }}
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-btn
            color="warning"
            class="ma-1 white--text"
            small
            @click="productManageDetail(item)"
          > -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="margin-right:5px;"
                @click="productManageDetail(item)"
                v-bind="attrs"
                v-on="on"
                small
                color="success"
              >
                mdi-file-find
              </v-icon>
            </template>
            <span>View Detail</span>
          </v-tooltip>
          <!-- </v-btn> -->
          <!-- <v-btn
            color="cyan"
            class="ma-1 white--text"
            small
            @click="productManageEdit(item)"
          > -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="margin-right:5px;"
                color="cyan"
                @click="productManageEdit(item)"
                v-bind="attrs"
                v-on="on"
                small
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <!-- </v-btn> -->
          <!-- <v-btn
            v-if="permission.delete_perm === 1"
            color="error"
            class="ma-1 white--text"
            small
            @click="productManageDelete(item)"
          > -->
          <v-tooltip bottom v-if="permission.delete_perm === 1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="error"
                @click="productManageDelete(item)"
                v-bind="attrs"
                v-on="on"
                small
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <!-- </v-btn> -->
        </template>
        <template v-slot:footer>
          <v-toolbar style="border-top:1px solid rgba(0,0,0,.15);">
            <v-spacer></v-spacer>
            <v-pagination
              style="margin-left:auto;"
              v-model="page"
              :length="Math.ceil(getTotalItems / itemsPerPage) || 1"
              :total-visible="6"
            ></v-pagination>
            <v-spacer></v-spacer>
            <div
              style="padding:0; margin:0;display: flex; flex-direction:rows; justify-content:center; align-items:center;"
            >
              <v-toolbar-title class="caption">Rows per page:</v-toolbar-title>
              <v-select
                style="position: relative; margin-left:10px;height:28px; width:50px; rightt:20px; font-size:12px;"
                v-model="itemsPerPage"
                :items="[5, 10, 15, 20, 100]"
                dense
              ></v-select>
            </div>
          </v-toolbar>
        </template>
      </v-data-table>

      <v-card
        elevation="7"
        width="90%"
        style="margin: 10px auto;"
        align-center
        small
        dense
      >
        <v-toolbar>
          <v-icon style="margin-right: 10px;">mdi-compare</v-icon>
          <v-toolbar-title>Product Compare</v-toolbar-title>
        </v-toolbar>

        <v-main
          elevation="0"
          style="text-align: center; min-height: 100px;"
          class="justify-content-center align-items-center"
        >
          <p
            v-if="productSelection.length === 0 || !exportall"
            class="text-center"
            style="font-size: 16px; color:rgba(0,0,0,0.5); margin:0;"
          >
            No product was selected
          </p>
          <div v-if="!exportall">
            <v-chip
              style=" margin:0;"
              v-for="(item, index) in productSelection"
              :key="index"
              class="ma-2"
              close
              @click:close="remove(index)"
            >
              {{ truncateString(item) }}
            </v-chip>
          </div>
        </v-main>

        <v-footer
          v-if="!exportall"
          style="border-top:1px solid rgba(0,0,0, .15);"
          class="align-items-center justify-content-center"
        >
          <v-btn
            :disabled="productSelection.length > 1 ? false : true"
            small
            style="margin-right:10px; color:white;"
            dark
            color="#00bcd4"
            @click="compareItems"
          >
            Compare now
          </v-btn>
          <v-btn
            :disabled="productSelection.length > 0 ? false : true"
            small
            style="margin-left:10px;"
            dark
            color="error"
            @click="removeAllCompareItems"
          >
            Remove all
          </v-btn>
        </v-footer>
      </v-card>
    </div>
    <v-card
      flat
      height="70vh"
      v-else
      color="transparent"
      style="display:flex; justify-content:center; align-items:center;"
    >
      <v-toolbar-title class="overline">
        You Can't Access This Page</v-toolbar-title
      >
    </v-card>

    <div>
      <ProductEdit
        v-if="!getProductEditStat"
        v-bind:keyword="searchProduct"
        :perm="permission"
        :orderType="orderType"
        :orderBy="orderBy"
      />
      <ProductAdd
        v-if="!getProductAddStat"
        :perm="permission"
        :keyword="searchProduct"
        :orderType="orderType"
        :orderBy="orderBy"
      />
      <ProductCompare v-if="!getProductCompareStat" :perm="permission" />
      <ProductDetail v-if="getProductDetailStat" :perm="permission" />
    </div>
    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Navigation from '../../components/navigation/Navigation'
import Drawer from '../../components/navigation/Drawer'
import ProductEdit from '../../components/product/ProductEdit'
import ProductAdd from '../../components/product/ProductAdd'
import ProductCompare from '../../components/product/ProductCompare'
import ProductDetail from '../../components/product/ProductDetail'
import ProductImport from '../../components/product/ProductImport'
import axios from 'axios'
import buildType from '../../services/buildType'
import XLSX from 'xlsx'
export default {
  name: 'MasterProduct',
  data: () => ({
    procAPI: buildType.apiURL('proc'),
    permission: {},
    menu_key: 20,
    application: {
      id: 2,
      name: 'eProcurement'
    },
    drawer: false,
    page: 1,
    offset: 0,
    loading: false,
    itemsPerPage: 10,
    searchProduct: '',
    invokeSrc: '',
    vCodeIcon: 'mdi-arrow-up',
    vNameIcon: 'mdi-arrow-up',
    orderBy: 'name',
    orderType: 'asc',
    items: [
      {
        title: 'User Manage',
        path: '/user-manage',
        icon: 'mdi-account-edit-outline'
      },
      {
        title: 'User Access',
        path: '/user-access',
        icon: 'mdi-badge-account-outline'
      },
      {
        title: 'Vendor',
        path: '/master-vendor',
        icon: 'mdi-handshake-outline'
      },
      { title: 'Product', path: '/master-product', icon: 'mdi-shape-outline' },
      {
        title: 'Product Category',
        path: '/product-category',
        icon: 'mdi-file-tree-outline'
      }
    ],
    headers: [
      {
        text: '',
        align: 'left',
        sortable: false,
        value: 'compare'
        // width: '5%'
      },
      {
        text: 'No',
        align: 'left',
        sortable: false,
        value: 'no'
        // width: '5%'
      },
      {
        text: 'Code',
        align: 'left',
        sortable: false,
        value: 'code'
        // width: '10%'
      },
      {
        text: 'Kategori',
        sortable: false,
        value: 'category',
        // width: '25%',
        align: 'left'
      },
      {
        text: 'Nama',
        value: 'name',
        // width: '20%',
        align: 'left',
        sortable: false
      },
      {
        text: 'Unit',
        sortable: false,
        value: 'unit',
        // width: '10%',
        align: 'center'
      },
      {
        text: 'Created at',
        sortable: false,
        value: 'created',
        // width: '10%',
        align: 'center'
      },
      {
        text: 'Updated at',
        sortable: false,
        value: 'last_update',
        // width: '10%',
        align: 'center'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'center'
        // width: '30%'
      }
    ],
    productSelection: [],
    routerLink: {
      text: 'Vendor blacklist menu',
      link: '/master-vendor/blacklist'
    },
    // isExport: false,
    isJsonEdit: false,
    exportall: false
  }),
  watch: {
    invokeSrc() {
      this.searchProduct = this.invokeSrc
      this.offset = 0
      this.loadDataProduct()
    },
    page() {
      this.offset = this.page - 1
      this.loadDataProduct()
    },
    itemsPerPage() {
      this.page = 1
      this.offset = 0
      this.loadDataProduct()
    },

    orderBy() {
      this.loadDataProduct()
    },
    orderType() {
      this.loadDataProduct()
    }
  },
  components: {
    ProductEdit,
    ProductAdd,
    Navigation,
    Drawer,
    ProductCompare,
    ProductDetail,
    ProductImport
  },
  created() {
    // this.setIsLoading(true)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            setTimeout(() => {
              this.loadDataProduct()
              // this.setIsLoading(false)
            }, 1500)
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              setTimeout(() => {
                this.loadDataProduct()
                // this.setIsLoading(false)
              }, 1500)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        setTimeout(() => {
          // this.setIsLoading(false)
        }, 1500)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getIsLoading',
      'getProductEditStat',
      'getProductAddStat',
      'getProductData',
      'getTotalItems',
      'getProductCompareStat',
      'getProductDetailStat'
    ])
  },
  methods: {
    ...mapActions([
      'logout',
      'menu_access',
      'adminProductList',
      'initProduct',
      'adminProductDelete',
      'productViewDetail',
      'compareProduct'
    ]),
    ...mapMutations([
      'productEdit',
      'productAdd',
      'setProductData',
      'productCompare',
      'setProductDetail',
      'setIsLoading'
    ]),
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            // title:
            //   pModalType === 'success'
            //     ? '<strong>Success</strong>'
            //     : `<strong>${pModalType}</strong>`,
            // icon: 'question',
            // html: pStatusMsg,
            icon: pModalType,
            html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    drawerSet(val) {
      this.drawer = val
    },
    vCode() {
      this.orderBy = 'code'
      if (this.vCodeIcon === 'mdi-arrow-up') {
        this.vCodeIcon = 'mdi-arrow-down'
        this.orderType = 'desc'
      } else {
        this.vCodeIcon = 'mdi-arrow-up'
        this.orderType = 'asc'
      }
    },
    vName() {
      this.orderBy = 'name'
      if (this.vNameIcon === 'mdi-arrow-up') {
        this.vNameIcon = 'mdi-arrow-down'
        this.orderType = 'desc'
      } else {
        this.vNameIcon = 'mdi-arrow-up'
        this.orderType = 'asc'
      }
    },
    loadDataProduct() {
      this.loading = true
      this.adminProductList([
        this.searchProduct,
        this.offset * this.itemsPerPage,
        this.itemsPerPage,
        this.orderBy,
        this.orderType
      ])
        .then(results => {
          this.initProduct(results).then(response => {
            this.loading = false
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    productManageEdit(item) {
      // if (!this.getProductEditStat) {
      //   this.productEdit(true)
      // } else {
      this.setIsLoading(true)
      this.productEdit(false)
      // }
      this.productViewDetail(item)
        .then(result => {
          setTimeout(() => {
            this.setIsLoading(false)
          }, 2000)
        })
        .catch(error => {
          console.log(error)
        })
      // this.setProductData(item)
    },
    productManageAdd() {
      this.searchProduct = ''
      this.invokeSrc = ''
      // if (!this.getProductAddStat) {
      //   this.productAdd(true)
      // } else {
      this.productAdd(false)
      // }
    },
    productManageDetail(item) {
      this.setIsLoading(true)
      this.setProductDetail(true)
      this.productViewDetail(item)
        .then(response => {
          setTimeout(() => {
            this.setIsLoading(false)
          }, 1000)
        })
        .catch(error => {
          console.log(error)
        })
    },
    productManageCompare(item) {
      this.productSelection.push(item)
    },
    productManageDelete(item) {
      this.$swal
        .fire({
          title: 'This data will be deleted permanently. Are you sure?',
          showCancelButton: true,
          icon: 'warning'
        })
        .then(result => {
          if (result.value) {
            this.adminProductDelete(item)
              .then(response => {
                this.searchProduct = ''
                this.invokeSrc = ''
                this.adminProductList([
                  this.searchProduct,
                  0,
                  this.itemsPerPage,
                  this.orderBy,
                  this.orderType
                ])
                  .then(results => {
                    this.initProduct(results)
                  })
                  .catch(error => {
                    console.log(error)
                  })
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Product delete success',
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              })
              .catch(error => {
                console.log(error)
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: error,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 3000
                })
              })
          }
        })
    },
    removeAllCompareItems() {
      this.productSelection = []
    },
    remove(index) {
      this.productSelection.splice(index, 1)
    },
    compareItems() {
      this.productCompare([false, this.productSelection])
      this.compareProduct(this.productSelection)
    },

    home() {
      this.$router.push('/')
    },

    truncateString(item) {
      const name = item.name.toLowerCase().split(' ')
      // .join('_')
      const truncate = name[0].split('-').join('')
      return truncate + ' ' + (name[2] !== undefined ? name[2] : '')
    },
    exportAll() {
      this.exportall = true
      axios
        .get(
          `${this.procAPI}master/product/list?offset=0&limit=all&keyword=&order_by=name&order_type=asc`
        )
        .then(res => {
          this.productSelection = []
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              this.productSelection.push({
                code: response[i].code,
                name: response[i].name,
                id: response[i].id,
                merk: response[i].merk,
                unit: response[i].unit !== null ? response[i].unit : 0,
                spesification: response[i].spesification,
                category: response[i].category,
                created: response[i].created_at,
                last_update: response[i].updated_at
              })
            }
          } else {
            this.productSelection = []
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    exportFile() {
      this.showMsgDialog('question', 'Sertakan ID').then(res => {
        if (res.isConfirmed) {
          this.exportNow(1)
        } else if (res.dismiss === 'cancel') {
          this.exportNow(0)
        } else if (res.dismiss === 'backdrop') {
          this.productSelection = []
        }
      })
    },
    exportNow(num) {
      const arrData = []
      for (let i = 0; i < this.productSelection.length; i++) {
        const param = {
          code: this.productSelection[i].code,
          name: this.productSelection[i].name,
          merk: this.productSelection[i].merk,
          unit_id: this.productSelection[i].unit.id,
          unit_name: this.productSelection[i].unit.name,
          spesification: this.productSelection[i].spesification,
          category_id: this.productSelection[i].category.id,
          category_name: this.productSelection[i].category.name,
          created_at: this.convertDate(this.productSelection[i].created),
          updated_at: this.convertDate(this.productSelection[i].last_update)
        }
        if (num === 1) {
          Object.assign(param, {
            id: this.productSelection[i].id
          })
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'master_product.xlsx')

      setTimeout(() => {
        this.productSelection = []
        this.exportall = false
      }, 1000)
    },

    convertDate(val) {
      const date = new Date(val)
      const str = date.toLocaleString()

      const day = str.slice(0, str.indexOf('/'))
      const month = str.slice(str.indexOf('/') + 1, str.lastIndexOf('/'))
      const year = str.slice(str.lastIndexOf('/') + 1, str.lastIndexOf(','))
      return day + '-' + month + '-' + year
    },
    hideData() {
      alert('Coming Soon')
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/productManage.scss"></style>
<style lang="scss" scoped>
.productSortIcon {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.486);
  padding: 2px 5px;
  border-radius: 3px;
  cursor: pointer;
}
</style>
