<template>
  <div id="home">
    <nav style="position: sticky; top:0; z-index:2;">
      <Navigation chosenpage="eProcurement" @setDrawer="drawerSet" />
    </nav>
    <v-main>
      <Content />
    </v-main>

    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>
  </div>
</template>
<script>
import Navigation from '../../components/navigation/Navigation'
import Drawer from '../../components/navigation/Drawer'
import Content from '../../components/home/home'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data: () => ({
    manageType: 'vendor',
    drawer: false,
    icons: ['mdi-facebook', 'mdi-twitter', 'mdi-linkedin', 'mdi-instagram']
  }),
  components: { Navigation, Drawer, Content },
  created() {
    if (!this.getLogin) {
      this.$router.push('/login')
    }
  },
  computed: {
    ...mapGetters(['getLogin', 'getVdrAddStat', 'getVdrEditStat'])
  },
  methods: {
    ...mapActions(['logout']),
    ...mapMutations([]),
    drawerSet(val) {
      this.drawer = val
    },
    signOut() {
      this.$swal
        .fire({
          title: 'Sign out now',
          icon: 'question',
          showCancelButton: true
        })
        .then(result => {
          if (result.value) {
            this.logout()
              .then(response => {})
              .catch(error => {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: error,
                  showConfirmButton: false,
                  showCancelButton: false,
                  timer: 1000
                })
              })
          }
        })
    }
  }
}
</script>

<style lang="scss" src=""></style>
