<template>
  <v-card flat class=" mx-auto" style="padding:20px 10px; width:100%;">
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="dTable"
          class="elevation-1 mx-auto row-pointer"
          style="width:100%; margin-bottom:20px;"
          :items-per-page="itemsPerPage"
          @click:row="rowClick"
        >
          <template v-slot:[`item.vendor`]="{ item }">
            <td>{{ item.vendor.name }}</td>
          </template>
          <template v-slot:[`item.confirmation_status`]="{ item }">
            <td>{{ item.confirmation_status.name }}</td>
          </template>
          <template v-slot:[`item.action1`]="{ item }">
            <div style="text-align:center;">
              <Support :value="item" @reload="reload" />
              <Provider :value="item" />
              <Offering :value="item" />
            </div>
          </template>
          <template v-slot:[`item.action2`]="{ item }">
            <div style="text-align:center;">
              <Evaluation :value="item" />
              <v-btn
                style="margin-left:5px;font-size:8px; padding:0 2px;"
                color="white"
                dark
                elevation="1"
                class="black--text"
                small
                >Keluarkan
              </v-btn>
              <Negotiate :value="item" />
            </div>
          </template>
          <template v-slot:[`item.action3`]="{ item }">
            <div style="text-align:center;">
              <v-btn
                style="margin-left:5px;font-size:8px; padding:0;"
                color="white"
                dark
                elevation="1"
                class="black--text"
                small
                @click.stop="select(item)"
              >
                Pilih
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
// import { mapActions, mapGetters, mapMutations } from 'vuex'
import Support from '../../../components/procurement/participants/supportDialog'
import Provider from '../../../components/procurement/participants/providerDialog'
import Offering from '../../../components/procurement/participants/offeringDialog'
import Negotiate from '../../../components/procurement/participants/negotiateDialog'
import Evaluation from '../../../components/procurement/participants/evaluationDialog'
export default {
  props: ['dTable'],
  data: () => ({
    drawer: false,
    dataTable: [],
    itemsPerPage: 10,
    headers: [
      {
        text: 'Badan Usaha',
        value: 'bussiness_entity',
        align: 'left'
      },
      {
        text: 'Nama Perusahaan',
        value: 'vendor',
        align: 'left'
      },
      {
        text: 'Total Penawaran',
        value: 'quotation_total',
        align: 'left'
      },
      {
        text: 'Total Setelah Nego',
        value: 'after_bargain_total',
        align: 'left'
      },
      {
        text: 'Status',
        value: 'confirmation_status',
        align: 'left',
        width: '7%'
      },
      { text: 'Bhn. Evaluasi', value: 'action1', align: 'center' },
      { text: '', value: 'action2', align: 'left' },
      { text: '', value: 'action3', align: 'left' }
    ],
    date: null,
    totalData: 1,
    options: {},
    loading: false
  }),
  components: { Support, Provider, Offering, Negotiate, Evaluation },
  created() {},
  computed: {
    // ...mapGetters([])
  },
  methods: {
    // ...mapActions([]),
    // ...mapMutations([]),
    drawerSet(val) {
      this.drawer = val
    },
    rowClick(pItem) {
      console.log(pItem)
    },
    reload() {
      this.$emit('reload')
    },
    select(pValue) {
      console.log(pValue)
    }
  }
}
</script>

<style lang="scss" src=""></style>
<style>
.table {
  border-radius: 3px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255);
}
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
</style>
