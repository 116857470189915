import { render, staticRenderFns } from "./Drawer.vue?vue&type=template&id=3fbf00a7&scoped=true&"
import script from "./Drawer.vue?vue&type=script&lang=js&"
export * from "./Drawer.vue?vue&type=script&lang=js&"
import style0 from "./Drawer.vue?vue&type=style&index=0&id=3fbf00a7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fbf00a7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBtn,VDivider,VIcon,VImg,VList,VListItem,VListItemAvatar,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle})
