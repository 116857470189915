<template>
  <v-dialog v-model="dialog" width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="grey"
        small
        tile
        class="ma-2"
        style="color:white;"
      >
        Undang
      </v-btn>
    </template>

    <v-card style=" padding:20px; " class="text-center">
      <v-row>
        <v-col
          cols="12"
          style="border-bottom:1px solid #e0e0e0; font-size:22px; font-weight:bold;"
        >
          PAKTA INTEGRITAS
        </v-col>

        <v-col cols="12" style="text-align:left;">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Porro eos
          facilis rerum possimus debitis, exercitationem quam sunt itaque dolore
          odio numquam perspiciatis veritatis quia reiciendis saepe molestias
          omnis cumque culpa beatae necessitatibus. Reiciendis veritatis rem
          cupiditate corporis maiores sint magni dolorum quis eveniet numquam
          inventore molestiae ducimus incidunt dolorem ex at ullam sequi
          molestias nam vero, modi pariatur assumenda! Veniam ut, ea numquam
          corporis aut asperiores ad consequatur ratione cupiditate illum
          perferendis deserunt necessitatibus architecto, reiciendis hic fuga
          magnam quam aspernatur? Quis corrupti, quos vero exercitationem fugit
          voluptatum quidem rerum eius, consequuntur corporis illo quae
          reiciendis quam perferendis magnam ut?
        </v-col>
        <v-col cols="12">
          <v-btn
            small
            color="grey"
            tile
            class="ma-2"
            style="color:white;"
            width="120"
            @click="agree"
          >
            SETUJU
          </v-btn>
          <v-btn
            width="120"
            small
            color="grey"
            tile
            class="ma-2"
            style="color:white;"
            @click="dialog = false"
          >
            TIDAK SETUJU
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters } from 'vuex'
export default {
  props: ['detail'],
  data() {
    return {
      proc: buildType.apiURL('proc'),
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['getProcListId'])
  },
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    agree() {
      console.log(this.detail)
      const form = {
        id: this.getProcListId,
        vendor_name: this.detail.name,
        email: this.detail.email,
        vendor_id: this.detail.id
      }
      this.save(form)
    },
    save(form) {
      axios
        .post(`${this.proc}invite_vendor`, form)
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.dialog = false
            this.$emit('reload')
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    }
  }
}
</script>
<style></style>
