<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }"
        ><v-btn
          v-bind="attrs"
          v-on="on"
          cols="12"
          color="pink"
          fab
          dark
          small
          style="position:relative; top:10px; left:10px;"
          @click="$router.push('/master-vendor')"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </template>
      <span>Back to master vendor</span>
    </v-tooltip>

    <v-card
      flat
      style=" padding:40px 20px; background:#eeeeee;  margin-top: 30px;"
    >
      <v-card
        elevation="2"
        class=" mx-auto"
        style="padding:20px 10px; width:98%;"
      >
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="vendorData"
              class="elevation-1 mx-auto row-pointer"
              style="width:99%; margin-top: 20px; margin-bottom:20px;"
              :items-per-page="itemsPerPage"
            >
              <template v-slot:top>
                <v-toolbar
                  flat
                  style="height:100px; border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;"
                >
                  <v-btn
                    class="mx-2 elevation-4"
                    dark
                    large
                    color="warning dark"
                    style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                  >
                    <v-icon large dark>
                      mdi-block-helper
                    </v-icon>
                  </v-btn>
                  <v-toolbar-title>Vendor Blacklist</v-toolbar-title>
                  <v-divider class="mx-6" inset vertical></v-divider>
                  <v-text-field
                    label="Search here"
                    type="search"
                    outlined
                    dense
                    style="position: relative; top:15px; width:10px;"
                  ></v-text-field>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-card>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      isUpload: true,
      isImport: true,
      itemsPerPage: 10,
      headers: [
        {
          text: 'Code',
          sortable: false,
          value: 'code',
          align: 'center'
        },
        { text: 'Nama', value: 'name', align: 'left' },
        { text: 'Email', value: 'email', align: 'left' },
        { text: 'Phone', value: 'phone', align: 'left' },
        { text: 'Badan usaha', value: 'business_entity', align: 'left' },
        { text: 'Status', value: 'status', align: 'left' }
      ],
      vendorData: [
        {
          no: 1,
          name: 'INDOPOTEN PRATAMA',
          code: 'V-A91',
          email: 'Indopoten@pratama.co.id',
          phone: '+629772864152',
          status: 'Active',
          business_entity: 'PT'
        }
      ]
    }
  },
  components: {},
  methods: {}
}
</script>
<style>
.table {
  border-radius: 3px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255);
}
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
</style>
