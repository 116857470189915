<template>
  <v-list>
    <v-list-item>
      <v-list-item-avatar>
        <v-img src="../../assets/image/account.png"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{
          getUserProfile !== null ? getUserProfile.username : 'Administrator'
        }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item-group active-class="deep-purple--text text--accent-4">
        <v-list-item
          class="drawer-li-item"
          v-for="(item, index) in items"
          :key="index"
          link
        >
          <router-link
            v-if="isAdmin === true"
            class="drawer-router-link"
            :to="item.path"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="drawer-li-title">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </router-link>

          <router-link
            v-if="index < 1 && isAdmin === false"
            class="drawer-router-link"
            :to="item.path"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </router-link>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <div class="pa-2">
      <v-btn class="pa-2-logout" block @click="signOut" style="width:50%;">
        <v-icon class="drawer-logout-icon">mdi-power</v-icon>
        <div class="drawer-logout-text">
          Sign out
        </div>
      </v-btn>
    </div>
  </v-list>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Drawer',
  props: [],
  data: () => ({
    menu_key: 0,
    permission: {},
    isAdmin: false,
    admin: {
      id: '1',
      name: 'Administrator',
      application: {
        id: 1,
        name: 'All'
      }
    },
    items: [
      {
        title: 'Change password',
        path: '/change-password',
        icon: 'mdi-key-change'
      },
      {
        title: 'Master Spesification Category Import',
        icon: 'mdi-database-import-outline',
        path: '/master-data/spesification-category'
      },
      {
        title: 'Master Spesification Attribute Import',
        icon: 'mdi-database-import-outline',
        path: '/master-data/spesification-attribute'
      },
      {
        title: 'Master Unit Import',
        icon: 'mdi-database-import-outline',
        path: '/master-data/unit'
      },
      {
        title: 'Master Business Entity Import',
        icon: 'mdi-database-import-outline',
        path: '/master-data/business-entity'
      },
      {
        title: 'Master Classification Import',
        icon: 'mdi-database-import-outline',
        path: '/master-data/classification'
      },
      {
        title: 'Master Sub-Classification Import',
        icon: 'mdi-database-import-outline',
        path: '/master-data/sub-classification'
      },
      {
        title: 'Master Province Import',
        icon: 'mdi-database-import-outline',
        path: '/master-data/province'
      },
      {
        title: 'Master City Import',
        icon: 'mdi-database-import-outline',
        path: '/master-data/city'
      },
      {
        title: 'Master Currency Import',
        icon: 'mdi-database-import-outline',
        path: '/master-data/currency'
      }
    ]
  }),
  components: {},
  created() {
    if (this.getUserProfile !== null) {
      var arr = this.getUserProfile.level
      console.log(arr)
      // arr.find(({ id }) => id === '1' || id === '3' || id === '12') !==
      //   undefined
      if (
        arr.find(({ id }) => id === '1' || id === '3' || id === '12') !==
        undefined
      ) {
        this.isAdmin = true
      } else {
        this.isAdmin = false
      }
      // for (let i = 0; i < arr.length; i++) {
      //   if (arr[i].id === '1' || arr[i].id === '3' || arr[i].id === '12') {
      //     console.log('true')
      //     this.isAdmin = true
      //   } else {
      //     this.isAdmin = false
      //   }
      // }
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapActions(['logout']),
    ...mapMutations(['setCatalogueDetail']),

    signOut() {
      this.$swal
        .fire({
          title: 'Sign out now',
          icon: 'question',
          showCancelButton: true
        })
        .then(result => {
          if (result.value) {
            this.logout()
              .then(response => {
                this.setCatalogueDetail({})
              })
              .catch(error => {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: error,
                  showConfirmButton: false,
                  showCancelButton: false,
                  timer: 1000
                })
              })
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.pa-2 {
  width: 100%;
  position: absolute;
  bottom: 10px;
  border-top: 1px solid #e0e0e0;

  .pa-2-logout {
    border: 1px solid rgba(255, 37, 73, 0.568);
    &:hover {
      background: rgba(255, 37, 73, 0.568);
    }

    .drawer-logout-text {
      margin-right: 10px;
      color: rgba(255, 37, 73, 0.568);
    }
    .drawer-logout-icon {
      color: rgba(255, 37, 73, 0.568);
    }
    &:hover .drawer-logout-text {
      color: white;
    }
    &:hover .drawer-logout-icon {
      color: white;
    }
  }
}
.drawer-li-item {
  &:hover {
    background: rgba(201, 201, 201, 0.685);
    color: white;
  }
  .drawer-router-link {
    width: 100%;
    display: flex;
    text-decoration: none;
    // &:hover {
    //   background: deepskyblue;
    // }
  }
  .drawer-li-title {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
