<template>
  <div>
    <div style="margin:0 0 20px 0;">
      <Navigation chosenpage="Catalogue Export" @setDrawer="drawerSet" />
    </div>

    <v-card
      elevation="2"
      class=" mx-auto"
      style="padding:10px 10px; width:95%; margin-top:20px;"
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="spesificationData"
            class="elevation-1 mx-auto row-pointer"
            style="width:99%; margin-top: 20px; margin-bottom:20px;"
            :items-per-page="itemsPerPage"
          >
            <template v-slot:top>
              <v-toolbar flat style="border-bottom:1px solid #e0e0e0;">
                <v-toolbar-title style="padding-left:10px; "
                  >Spesification Export</v-toolbar-title
                >

                <v-divider class="mx-6" inset vertical></v-divider>
                <v-text-field
                  v-model="param.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  style="position: relative; top:15px;"
                ></v-text-field>
                <v-divider class="mx-6" inset vertical></v-divider>
                <v-btn
                  v-if="json_data.length === 0"
                  small
                  tile
                  color="#e0e0e0"
                  class="ma-2 black--text"
                  style="width:100px; position:relative;"
                  @click="exportAll"
                >
                  Export All
                </v-btn>

                <v-btn
                  v-else
                  small
                  tile
                  color="#e0e0e0"
                  class="ma-2 black--text"
                  style="width:100px; position:relative;"
                  @click="exportFile"
                >
                  Export Now
                </v-btn>
                <download-excel
                  id="downloadSpesificationExcel"
                  :data="json_data"
                  :fields="
                    isJsonEdit === true ? json_fields_edit : json_fields_normal
                  "
                  name="vendor_spesification"
                  style="width:0; height:0; font-size:1px; color:transparent;"
                >
                </download-excel>

                <router-link to="/master-vendor/spesification/import">
                  <v-btn
                    small
                    tile
                    color="#e0e0e0"
                    class="ma-2 black--text"
                    style="width:100px; position:relative;"
                  >
                    Import Data
                  </v-btn>
                </router-link>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:[`item.check`]="{ item }">
              <td>
                <v-checkbox v-model="json_data" :value="item"></v-checkbox>
              </td>
            </template>
            <!-- <template v-slot:[`item.last_ordered`]="{ item }">
              <td>
                {{ new Date(item.last_ordered).toLocaleDateString() }}
              </td>
            </template> -->
            <template v-slot:[`item.merk`]="{ item }">
              <td>
                {{ item.merk !== '' ? item.merk : '-' }}
              </td>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <td>
                {{ new Date(item.created_at).toLocaleDateString('id') }}
              </td>
            </template>
            <template v-slot:[`item.updated_at`]="{ item }">
              <td>
                {{ new Date(item.updated_at).toLocaleDateString('id') }}
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../services/buildType'
import Navigation from '../../components/navigation/Navigation'
import Drawer from '../../components/navigation/Drawer'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: [],
  data() {
    return {
      procAPI: buildType.apiURL('proc'),
      drawer: false,
      dialog: false,
      isImport: false,
      itemsPerPage: 10,
      param: {
        offset: 0,
        limit: 'all',
        keyword: '',
        catalogue_id: ''
      },
      headers: [
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'check',
          width: '1%'
        },
        {
          text: 'Vendor Name',
          value: 'vendor.name',
          align: 'left',
          width: '15%'
        },
        {
          text: 'V.Code',
          sortable: false,
          value: 'vendor.code',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Product Name',
          value: 'product.name',
          align: 'left',
          width: '15%'
        },
        {
          text: 'P.Code',
          sortable: false,
          value: 'product.code',
          align: 'center',
          width: '10%'
        },
        {
          text: 'Spec. Cat',
          value: 'spesification_category.name',
          align: 'left',
          width: '10%'
        },
        // {
        //   text: 'Spec. Cat',
        //   value: 'spesification_category_name',
        //   align: 'left',
        //   width: '10%'
        // },
        {
          text: 'Spec. Att',
          value: 'spesification_attribute.name',
          align: 'left',
          width: '10%'
        },
        {
          text: 'Standard',
          value: 'standard',
          align: 'left',
          width: '15%'
        },
        // {
        //   text: 'Criteria',
        //   value: 'criteria',
        //   align: 'left'
        // },
        // { text: 'Unit', value: 'unit.name', align: 'left' },
        { text: 'Created At', value: 'created_at', align: 'left' },
        { text: 'Updated At', value: 'updated_at', align: 'left' }
      ],
      spesificationData: [],
      json_fields_normal: {
        vendor_name: 'vendor.name',
        vendor_code: 'vendor.code',
        product_name: 'product.name',
        product_code: 'product.code',
        spesification_category_id: 'spesification_category.id',
        spesification_category_name: 'spesification_category.name',
        // spesification_category_name: 'spesification_category.name',
        spesification_attribute_id: 'spesification_attribute.id',
        spesification_attribute_name: 'spesification_attribute.name',
        description: 'description',
        standard: 'standard',
        unit_name: 'unit.name',
        unit_id: 'unit.id',
        criteria: 'criteria',
        analysis_method: 'analysis_method',
        min_frequency_supplier: 'min_frequency_supplier',
        min_frequency_sanqua: 'min_frequency_sanqua'
      },
      json_fields_edit: {
        id: 'id',
        vendor_name: 'vendor.name',
        vendor_code: 'vendor.code',
        product_name: 'product.name',
        product_code: 'product.code',
        spesification_category_id: 'spesification_category.id',
        // spesification_category_name: 'spesification_category.name',
        spesification_category_name: 'spesification_category.name',
        spesification_attribute_id: 'spesification_attribute.id',
        spesification_attribute_name: 'spesification_attribute.name',
        description: 'description',
        standard: 'standard',
        unit_name: 'unit.name',
        unit_id: 'unit.id',
        criteria: 'criteria',
        analysis_method: 'analysis_method',
        min_frequency_supplier: 'min_frequency_supplier',
        min_frequency_sanqua: 'min_frequency_sanqua'
      },
      json_data: [],
      isJsonEdit: false
    }
  },
  components: { Navigation, Drawer },
  watch: {
    'param.keyword'() {
      this.exportTable()
    }
  },
  computed: {
    ...mapGetters([])
  },
  created() {
    this.exportTable()
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    drawerSet(val) {
      this.drawer = val
    },
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            icon: pModalType,
            html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    exportTable() {
      axios
        .get(
          `${this.procAPI}vendor/catalogue_spesification/list?offset=${this.param.offset}&limit=${this.param.limit}&keyword=${this.param.keyword}&vendor_catalogue_id=${this.param.catalogue_id}&spesification_type`
        )
        .then(res => {
          this.spesificationData = []
          const response = res.data.data
          this.spesificationData = response
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
    exportFile() {
      console.log(this.json_data)
      this.showMsgDialog('question', 'Sertakan ID').then(res => {
        if (res.isConfirmed) {
          this.isJsonEdit = true
          console.log(true)
          setTimeout(() => {
            document.getElementById('downloadSpesificationExcel').click()
          }, 500)
        } else if (res.dismiss === 'cancel') {
          this.isJsonEdit = false
          console.log(false)
          setTimeout(() => {
            document.getElementById('downloadSpesificationExcel').click()
          }, 500)
        } else if (res.dismiss === 'backdrop') {
          this.json_data = []
        }

        setTimeout(() => {
          this.json_data = []
        }, 1000)
      })
    },
    exportAll() {
      this.json_data = this.spesificationData
    }
  }
}
</script>
<style></style>
