<template>
  <div style="padding:0;">
    <Navigation chosenpage="eProcurement" @setDrawer="drawerSet" />
    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>
    <v-card
      flat
      class=" mx-auto"
      style="padding:10px 15px 20px 15px; width:95%; margin-top:50px;"
    >
      <v-row>
        <v-col cols="12" style="padding:0;">
          <v-toolbar flat style="border-radius:10px 10px 0 0;">
            <v-btn
              elevation="1"
              class="mx-2 elevation-4"
              dark
              color="cyan"
              style="position:relative; top:-10px; left:-10px; height:80px; width:80px;"
            >
              <v-icon large dark>
                mdi-truck-fast-outline
              </v-icon>
            </v-btn>
            <v-toolbar-title class="overline"
              ><p style="font-size:20px;">
                List Pengadaan Barang & Jasa
              </p></v-toolbar-title
            >
          </v-toolbar>
        </v-col>
        <v-col
          cols="12"
          style="padding:0; text-align:right; position:absolute; top:0; left:0;"
        >
          <!-- <router-link
            style="color:white;"
            to="/e-procurement/procurement-form"
          > -->
          <v-btn
            @click="createNew('/e-procurement/procurement-form')"
            color="green"
            rounded
            class="ma-2"
            style="color:white;"
          >
            Create
          </v-btn>
          <!-- </router-link> -->
        </v-col>
      </v-row>
    </v-card>

    <v-card
      flat
      class=" mx-auto"
      style="padding:20px 10px; width:95%; margin-top:1px;"
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model="selectedData"
            show-select
            item-key="id"
            :single-select="true"
            :headers="headers"
            :items="dataTable"
            class="elevation-1 mx-auto row-pointer"
            style="width:99%; margin-bottom:20px;"
            :items-per-page="itemsPerPage"
            :options.sync="options"
            :loading="loading"
            @click:row="rowClick"
          >
            <template v-slot:top>
              <div
                style="border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;padding:0 10px;"
              >
                <v-row>
                  <v-col
                    cols="12"
                    style="display:flex; flex-direction:row; padding-top:0;padding-bottom:0;"
                  >
                    <div
                      style="padding:10px; display:flex; justify-content: center; align-items:center; margin-right:20px;"
                    >
                      <p style="margin:0;">
                        Filter Search :
                      </p>
                    </div>
                    <div style="min-width:200px; margin-right:20px;">
                      <v-autocomplete
                        item-text="code"
                        item-value="id"
                        cache-items
                        class="mx-1"
                        hide-no-data
                        hide-details
                        label="Nama Perusahaan"
                        return-id
                        clearable
                      />
                    </div>
                    <div style="width:200px; margin-right:20px;">
                      <v-menu
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="date"
                            label="Periode Pengadaan"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                          />
                        </template>
                        <v-date-picker v-model="date" @input="menu1 = false" />
                      </v-menu>
                    </div>
                    <div style="width:100px; margin-right:20px;">
                      <v-autocomplete
                        item-text="code"
                        item-value="id"
                        cache-items
                        class="mx-1"
                        hide-no-data
                        hide-details
                        label="Status"
                        return-id
                      />
                    </div>
                    <div style="">
                      <v-btn
                        color="#e0e0e0"
                        tile
                        small
                        class="ma-5"
                        style="color:rgba(0,0,0,0.5); position:relative; left:-25px;"
                      >
                        Search
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    cols="8"
                    style="padding-top:5px;padding-bottom:0; padding-left:25px; display:flex;"
                  >
                    <div v-if="selectedData.length !== 0">
                      <v-btn
                        color="#e0e0e0"
                        tile
                        small
                        style="color:red;"
                        @click="deletE"
                      >
                        Delete
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    style="padding-top:0;padding-bottom:0; display:flex;"
                  >
                    <v-text-field
                      v-model="paramAPI.keyword"
                      @keyup.enter="searchEnter"
                      label="Search here"
                      type="search"
                      outlined
                      append-icon="mdi-magnify"
                      dense
                      style="width:300px; margin-left:auto;"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </template>
            <template v-slot:[`item.company`]="{ item }">
              <div style="padding: 0; display: flex; flex-direction: row">
                {{ item.company.name }}
              </div>
            </template>
            <template v-slot:[`item.status_approval`]="{ item }">
              <div style="padding: 0; display: flex; flex-direction: row">
                {{ item.status_approval.name }}
              </div>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div style="padding: 0; display: flex; flex-direction: row">
                {{ item.status.name }}
              </div>
            </template>
            <template v-slot:[`item.period_start`]="{ item }">
              <div style="padding: 0; display: flex; flex-direction: row">
                {{
                  convertDate(item.period_start) +
                    ' s/d ' +
                    convertDate(item.period_end)
                }}
              </div>
            </template>
            <template v-slot:[`item.total_hps`]="{ item }">
              <div style="padding: 0; display: flex; flex-direction: row">
                {{ price(item.total_hps) }}
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import Navigation from '../../../components/navigation/Navigation'
import Drawer from '../../../components/navigation/Drawer'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data: () => ({
    proc: buildType.apiURL('proc'),
    selectedData: [],
    paramAPI: {
      keyword: '',
      offset: 0,
      limit: 100,
      status: ''
    },
    // menu_key: 11,
    // permission: {
    // },
    drawer: false,
    dataTable: [],
    itemsPerPage: 10,
    headers: [
      { text: 'No. Procurement', value: 'procurement_no', align: 'left' },
      { text: 'Perusahaan', value: 'company', align: 'left' },
      { text: 'Th. Anggaran', value: 'year', align: 'left' },
      { text: 'Nama Pengadaan', value: 'name', align: 'left' },
      { text: 'Periode', value: 'period_start', align: 'left' },
      { text: 'Total HPS', value: 'total_hps', align: 'left' },
      { text: 'Created at', value: 'created_at', align: 'left' },
      { text: 'Created by', value: 'created_by_name', align: 'left' },
      { text: 'Approval', value: 'status_approval', align: 'left' },
      { text: 'Status', value: 'status', align: 'left' }
    ],
    // loadingAutocomplete: true,
    date: null,
    totalBookingData: 1,
    options: {},
    loading: false
  }),
  components: { Navigation, Drawer },
  //   watch: {
  //     options: {
  //       handler() {
  //         this.submitSearch('', 'table')
  //       },
  //       deep: true
  //     }
  //   },
  created() {
    this.initDataTable()
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(['setProcListId']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    searchEnter() {
      this.initDataTable()
    },
    drawerSet(val) {
      this.drawer = val
    },
    initDataTable() {
      axios
        .get(
          `${this.proc}list?keyword=${this.paramAPI.keyword}&offset=${this.paramAPI.offset}&limit=${this.paramAPI.limit}&is_archived=`
        )
        .then(res => {
          console.log(res)
          this.dataTable = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    createNew(link) {
      this.setProcListId(null)
      this.$router.push(link)
    },
    rowClick(pItem) {
      this.setProcListId(pItem.id)
      this.$router.push('/e-procurement/procurement-form')
    },
    deletE() {
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(res => {
        if (res.isConfirmed) {
          axios
            .delete(`${this.proc}delete/${this.selectedData[0].id}`)
            .then(res => {
              this.showMsgDialog('success', res.data.status_msg, 'false')
              this.initDataTable()
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          this.actionValue = 0
        }
      })
    },

    price(val) {
      if (val !== null || val !== undefined) {
        const reverse = val.toString()

        if (reverse.lastIndexOf('.') === -1) {
          const beforeComa1 = reverse
            .split('')
            .reverse()
            .join('')
          var ribuan1 = beforeComa1.match(/\d{1,3}/g)
          const result1 = ribuan1
            .join(',')
            .split('')
            .reverse()
            .join('')
          return result1
        } else {
          const beforeComa2 = reverse
            .slice(0, reverse.indexOf('.'))
            .split('')
            .reverse()
            .join('')
          var ribuan2 = beforeComa2.match(/\d{1,3}/g)
          const result2 = ribuan2
            .join(',')
            .split('')
            .reverse()
            .join('')
          const afterComa = reverse.slice(reverse.lastIndexOf('.') + 1)
          return result2 + ',' + afterComa
        }
      }
    },

    convertDate(raw) {
      const year = raw.slice(0, raw.indexOf('-'))
      let month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
      const day = raw.slice(raw.lastIndexOf('-') + 1)

      switch (month) {
        case '01':
          month = 'JAN'
          break
        case '02':
          month = 'FEB'
          break
        case '03':
          month = 'MAR'
          break
        case '04':
          month = 'APR'
          break
        case '05':
          month = 'MAY'
          break
        case '06':
          month = 'JUN'
          break
        case '07':
          month = 'JUL'
          break
        case '08':
          month = 'AGS'
          break
        case '09':
          month = 'SEP'
          break
        case '10':
          month = 'OKT'
          break
        case '11':
          month = 'NOV'
          break
        case '12':
          month = 'DES'
          break
      }

      return day + ' ' + month + ' ' + year
    }
  }
}
</script>

<style lang="scss" src=""></style>
<style>
.table {
  border-radius: 3px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255);
}
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
</style>
