<template>
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        style="margin-left:5px;font-size:8px; padding:0 2px;"
        color="white"
        dark
        elevation="1"
        class="black--text"
        small
        @click.stop="support(value)"
        >Pendukung
      </v-btn>
    </template>

    <v-form
      ref="entryForm"
      @submit.prevent="submit"
      style="position: relative;"
    >
      <v-card style="padding:20px;" class="text-center">
        <v-row>
          <v-col cols="12" style="font-size:22px; font-weight:bold;">
            DATA PENDUKUNG
            <v-icon
              style="position:absolute; top:0; right:0; cursor:pointer;"
              @click="close"
              >mdi-close</v-icon
            >
          </v-col>
          <v-col
            cols="12"
            style="border:1px solid #e0e0e0; text-align:left; margin-bottom:10px; display:flex;"
          >
            <div style="width:50%;">
              <v-col cols="12" style="padding:0;">
                <p class="ma-2" style="margin:0; font-weight:bold;">
                  Surat Penawaran
                </p>
              </v-col>
              <v-col cols="11" style="padding:0;">
                <v-file-input
                  @change="uploadLetter"
                  class="ma-2"
                  style="margin:0;height:42px;"
                  dense
                  outlined
                  :label="
                    value.quotation_letter_file !== null
                      ? value.quotation_letter_file
                      : 'click here'
                  "
                  prepend-icon=""
                  append-icon="mdi-file-outline"
                  truncate-length="10"
                ></v-file-input>
              </v-col>
              <v-col cols="12" style="padding:0;">
                <v-btn
                  :disabled="value.quotation_letter_file === null"
                  small
                  color="blue-grey"
                  class="ma-2 white--text"
                  style="margin:0;"
                  @click="downloadLetter"
                >
                  Download
                  <v-icon right dark>
                    mdi-download
                  </v-icon>
                </v-btn>
              </v-col>
            </div>
            <div style="width:50%;">
              <v-col cols="12" style="padding:0;">
                <p class="ma-2" style="margin:0; font-weight:bold;">
                  File Pendukung
                </p>
              </v-col>
              <v-col cols="11" style="padding:0;">
                <v-file-input
                  @change="uploadFile"
                  class="ma-2"
                  style="margin:0;height:42px;"
                  dense
                  outlined
                  :label="value.file !== null ? value.file : 'click here'"
                  prepend-icon=""
                  append-icon="mdi-file-outline"
                  truncate-length="10"
                ></v-file-input>
              </v-col>
              <v-col cols="12" style="padding:0;">
                <v-btn
                  :disabled="value.file === null"
                  small
                  color="blue-grey"
                  class="ma-2 white--text"
                  style="margin:0;"
                  @click="downloadFile"
                >
                  Download
                  <v-icon right dark>
                    mdi-download
                  </v-icon>
                </v-btn>
              </v-col>
            </div>
          </v-col>
          <v-col
            cols="12"
            style="font-size:22px; font-weight:bold; text-align:right;"
          >
            <v-btn
              type="submit"
              style="letter-spacing:5px;"
              large
              color="blue-grey"
              class="ma-2 white--text"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
// import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import buildType from '../../../services/buildType'
export default {
  components: {},
  props: ['value'],
  data() {
    return {
      proc: buildType.apiURL('proc'),
      upload: buildType.apiURL('upload2'),
      download: buildType.apiURL('download'),
      dialog: false
      // letter: []
    }
  },
  computed: {},
  created() {},
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    support(pValue) {
      console.log(pValue)
    },
    uploadLetter(event) {
      console.log(event)
      if (event !== null) {
        const data = new FormData()
        data.append('file', event)
        axios
          .post(`${this.upload}procurement/member/quotation_letter`, data)
          .then(res => {
            if (res.data.status === true) {
              this.showMsgDialog('success', res.data.message, false)
              this.value.quotation_letter_file = res.data.data.name
            } else if (res.data.status === true) {
              this.showMsgDialog('warning', res.data.message, false)
            } else {
              this.showMsgDialog('warning', res.data.message, false)
            }
          })
          .catch(err => {
            this.showMsgDialog(
              'error',
              err
                ? 'Somthing went wrong, please contact an admin'
                : 'Somthing went wrong, please contact an admin',
              false
            )
          })
      } else {
        this.value.quotation_letter_file = null
      }
    },
    uploadFile(event) {
      console.log(event)
      if (event !== null) {
        const data = new FormData()
        data.append('file', event)
        axios
          .post(`${this.upload}procurement/member/other_file`, data)
          .then(res => {
            if (res.data.status === true) {
              this.showMsgDialog('success', res.data.message, false)
              this.value.file = res.data.data.name
            } else if (res.data.status === true) {
              this.showMsgDialog('warning', res.data.message, false)
            } else {
              this.showMsgDialog('warning', res.data.message, false)
            }
          })
          .catch(err => {
            this.showMsgDialog(
              'error',
              err
                ? 'Somthing went wrong, please contact an admin'
                : 'Somthing went wrong, please contact an admin',
              false
            )
          })
      } else {
        this.value.file = null
      }
    },
    submit() {
      // this.letter = [v => !!v || 'Quotation letter file is required']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      console.log(this.value)
      const form = {
        act: 'update_by_id',
        id: this.value.id,
        quotation_letter_file:
          this.value.quotation_letter_file !== null
            ? this.value.quotation_letter_file
            : '',
        file: this.value.file !== null ? this.value.file : ''
      }
      this.save(form)
    },
    save(form) {
      console.log(form)
      axios
        .post(`${this.proc}member/save`, form)
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.$emit('reload')
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    close() {
      // this.letter = []
      this.dialog = false
    },
    downloadFile() {
      axios({
        url: `${this.download}procurement/member/other_file/${this.value.file}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.value.file)
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    downloadLetter() {
      axios({
        url: `${this.download}procurement/member/quotation_letter/${this.value.quotation_letter_file}`,
        method: 'GET',
        responseType: 'blob'
      })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', this.value.quotation_letter_file)
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    }
  }
}
</script>
<style lang="scss" scoped></style>
