<template>
  <v-dialog v-model="dialog" width="90%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        style="margin-left:5px;font-size:8px; padding:0 2px;"
        color="white"
        dark
        elevation="1"
        class="black--text"
        small
        >BA negosiasi
      </v-btn>
    </template>
    <v-card style="padding:20px;" class="text-center">
      <v-row>
        <v-col cols="12" style="font-size:22px; font-weight:bold;">
          BERITA ACARA NEGOSIASI
          <v-icon
            style="position:absolute; top:0; right:0; cursor:pointer;"
            @click="dialog = false"
            >mdi-close</v-icon
          >
        </v-col>
        <v-col cols="12" style="text-align:left; margin-bottom:5px;">
          <DocNumber style="border:1px solid #e0e0e0;" />
        </v-col>
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headers"
            :items="dataTable"
            class="elevation-1 mx-auto row-pointer"
            style="width:100%; border:1px solid #e0e0e0;"
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
          </v-data-table>
        </v-col>
        <v-col
          cols="12"
          style="display:flex; flex-direction:row; justify-content:flex-end;"
        >
          <div class="items-total-box" style="margin-right: 10px;">
            <p style="margin:0; font-weight:bold;">TOTAL PENAWARAN</p>
            <div>
              <p>Sub Total <span>:</span></p>
              <p style="text-align:right;width:100%;">Rp. 10,000,000</p>
            </div>
            <div>
              <p>PPN 10% <span>:</span></p>
              <p style="text-align:right;width:100%;">Rp. 1,000,000</p>
            </div>
            <div>
              <p style="text-align:left; padding-left:13px;">
                Total <span>:</span>
              </p>
              <p style="text-align:right;width:100%;">Rp. 11,000,000</p>
            </div>
          </div>
          <div class="items-total-box">
            <p style="margin:0; font-weight:bold;">TOTAL SETELAH NEGOSIASI</p>
            <div>
              <p>Sub Total <span>:</span></p>
              <p style="text-align:right;width:100%;">Rp. 10,000,000</p>
            </div>
            <div>
              <p>PPN 10% <span>:</span></p>
              <p style="text-align:right;width:100%;">Rp. 1,000,000</p>
            </div>
            <div>
              <p style="text-align:left; padding-left:13px;">
                Total <span>:</span>
              </p>
              <p style="text-align:right;width:100%;">Rp. 11,000,000</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
// import { mapActions, mapGetters, mapMutations } from 'vuex'
import DocNumber from './docNumber'
export default {
  components: { DocNumber },
  props: ['value'],
  data() {
    return {
      dialog: false,
      dataTable: [],
      itemsPerPage: 10,
      headers: [
        {
          text: 'Kategori',
          value: 'category',
          align: 'left'
        },
        {
          text: 'Nama Barang',
          value: 'name',
          align: 'left'
        },
        {
          text: 'Spesifikasi',
          value: 'spesification',
          align: 'left'
        },
        {
          text: 'Harga Satuan',
          value: 'price_per_unit',
          align: 'left'
        },
        {
          text: 'Harga Satuan Nego',
          value: 'offering_price',
          align: 'left'
        },
        {
          text: 'Jumlah',
          value: 'amount',
          align: 'left'
        },
        {
          text: 'Satuan',
          value: 'unit',
          align: 'left'
        },
        {
          text: 'Total',
          value: 'total',
          align: 'left'
        },
        {
          text: 'Total Harga Nego',
          value: 'offering_total',
          align: 'left'
        },
        {
          text: '',
          value: 'action',
          align: 'left'
        }
      ],
      totalData: 1,
      options: {},
      loading: false
    }
  },
  computed: {},
  created() {
    this.initDataTable()
  },
  methods: {
    negotiate(val) {
      console.log(val)
    },

    initDataTable() {
      this.dataTable = [
        {
          category: 'Bahan Baku',
          name: 'BBK Polypropilene',
          spesification: 'Spek A',
          price_per_unit: '12',
          offering_price: '10',
          amount: '1,000,000',
          unit: 'Kg',
          total: '12,000,000',
          offering_total: '10,000,000'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.items-total-box {
  border: 1px solid #e0e0e0;
  padding: 20px 10px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  div {
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    padding-top: 20px;
    display: flex;
    p {
      margin: 0;
      margin-right: 10px;
      min-width: 80px;
      position: relative;
      span {
        position: absolute;
        right: 0;
      }
    }
  }
}
</style>
