<template>
  <v-card
    class="row justify-content-center"
    id="Login"
    style="position: relative;"
  >
    <v-btn
      cols="12"
      color="pink"
      fab
      dark
      small
      style="position:absolute; top:10px; left:10px;"
      @click="back"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-card
      elevation="6"
      class="col-md-4 log-form"
      height="100%"
      style="border-radius:5px; position:relative; backgroundImage: url(https://cdn.vuetifyjs.com/images/parallax/material.jpg);"
    >
      <div
        style="margin-right:auto; left:-10px; top:-10px; position: relative; z-index:1; display: flex; flex-direction: row;"
      >
        <v-btn
          style="height:50px;width:50px; margin-right: 10px;"
          elevation="5"
          fab
          x-large
          color="white"
          class=" rounded-circle"
          ><v-img
            alt="sanqua multi international"
            class=" rounded-circle"
            contain
            src="../../../public/sanquamulti.png"
            transition="scale-transition"
            style="height:50px;width:50px; "
        /></v-btn>
        <h5 style="line-height:20px; margin-top:12px; color: white;">
          Sanqua
          <h4 style="color:#37474F">Change password</h4>
        </h5>
      </div>
      <!-- <p
        style="border-bottom:1px solid black; fontSize: 12px; padding: 10px 10px; border-radius: 5px;"
      >
        Please change your password here
      </p> -->
      <v-alert
        v-if="isChange !== null"
        dense
        :type="isChange === false ? 'error' : 'success'"
        style="border-bottom:1px solid black; fontSize: 12px; border-radius: 5px;"
      >
        {{ alertMsg }}
      </v-alert>
      <b-form @submit.stop.prevent>
        <label
          for="#email"
          style="width:100%; text-align:left; padding-left: 10px"
          >Email</label
        >
        <b-form-input
          v-model="form.email"
          type="email"
          id="#email"
          style="margin-bottom: 20px;"
        ></b-form-input>
        <label
          for="old-password"
          style="width:100%; text-align:left; padding-left: 10px"
          >Old Password</label
        >
        <b-form-input
          v-model="form.old_password"
          type="password"
          id="old-password"
          style="margin-bottom: 20px;"
        ></b-form-input>
        <label
          for="new-password"
          style="width:100%; text-align:left; padding-left: 10px"
          >New Password</label
        >
        <b-form-input
          v-model="form.new_password"
          type="password"
          id="new-password"
          style="margin-bottom: 20px;"
        ></b-form-input>

        <label
          for="confirm-password"
          style="width:100%; text-align:left; padding-left: 10px"
          >Confirm New Password</label
        >
        <b-form-input
          v-model="form.confirm_new_password"
          type="password"
          id="confirm-password"
          style="margin-bottom: 20px;"
        ></b-form-input>
        <div class="col-12" style="text-align: right; padding: 0;">
          <button @click="submitChange">Submit</button>
        </div>
      </b-form>
    </v-card>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'change',
  data() {
    return {
      isChange: null,
      alertMsg: '',
      token: null,
      email: null,
      form: {
        email: '',
        old_password: '',
        new_password: '',
        confirm_new_password: ''
      }
    }
  },
  mounted() {},
  components: {},
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([
      'tokenValidation',
      'verifyData',
      'changePasswordNow',
      'verifyData'
    ]),
    ...mapMutations([]),
    back() {
      this.$router.push('/')
    },
    resetForm() {
      this.form = {
        email: '',
        old_password: '',
        new_password: '',
        confirm_new_password: ''
      }
    },

    submitChange() {
      if (this.form.email === '') {
        this.isChange = false
        this.alertMsg = 'Email must be filled'
        setTimeout(() => {
          this.isChange = null
        }, 4000)
      } else if (this.form.old_password === '') {
        this.isChange = false
        this.alertMsg = 'Old password must be filled'
        setTimeout(() => {
          this.isChange = null
        }, 4000)
      } else if (this.form.new_password.length < 8) {
        this.isChange = false
        this.alertMsg = 'New password at least must be 8 character'
        setTimeout(() => {
          this.isChange = null
        }, 4000)
      } else if (this.form.confirm_new_password !== this.form.new_password) {
        this.isChange = false
        // this.resetForm()
        this.alertMsg = "Password doesn't match"
        setTimeout(() => {
          this.isChange = null
        }, 4000)
      } else {
        // this.verifyData(localStorage.getItem('token')).then(result => {
        this.changePasswordNow([this.form])
          .then(result => {
            if (result.data.status_code === '00') {
              this.isChange = true
              this.resetForm()
              this.alertMsg = result.data.status_msg
              setTimeout(() => {
                this.isChange = null
              }, 4000)
            } else {
              this.isChange = false
              this.alertMsg = result.data.status_msg
              setTimeout(() => {
                this.isChange = null
              }, 4000)
            }
          })
          .catch(error => {
            this.isChange = false
            this.alertMsg = error
            setTimeout(() => {
              this.isChange = null
            }, 4000)
          })
        // })
      }
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/login.scss"></style>
<style scoped></style>
