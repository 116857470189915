<template>
  <div>
    <v-btn
      cols="12"
      color="grey"
      text
      dark
      small
      style="position:relative; top:10px; left:10px;"
      @click="$router.push('/master-vendor/quotation/export')"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-card
      elevation="2"
      class=" mx-auto"
      style="padding:10px 15px 20px 15px; width:95%; margin-top:30px;"
    >
      <v-row>
        <v-col cols="12">
          <v-toolbar flat style="border-radius:10px 10px 0 0;">
            <v-btn
              class="mx-2 elevation-4"
              dark
              large
              color="cyan"
              style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
            >
              <v-icon large dark>
                mdi-upload
              </v-icon>
            </v-btn>
            <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
            <v-toolbar-title>Upload File</v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col cols="12" style="padding:0;">
          <p style="margin:0; font-size:14px; padding-left:35px;">
            Please download tempalate for upload <a href="#">here</a>
          </p>
        </v-col>
        <v-col cols="12" style="padding-left:35px;">
          <p style="border-top:5px solid cyan;"></p>
        </v-col>
        <v-col cols="12" style="padding: 0 0 30px 30px;">
          <v-row>
            <v-col cols="10">
              <v-file-input show-size label="File input" v-model="uploadFile" />
            </v-col>
            <v-col cols="2">
              <v-btn
                tile
                small
                color="grey"
                class="ma-2 white--text"
                @click="quotationImport"
                :disabled="uploadFile === null"
              >
                Upload
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      elevation="2"
      class=" mx-auto"
      style="padding:20px 10px; width:95%; margin-top:20px;"
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="quotationData"
            class="elevation-1 mx-auto row-pointer"
            style="width:99%; margin-top: 20px; margin-bottom:20px;"
            :items-per-page="itemsPerPage"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                style="height:100px; border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;"
              >
                <v-btn
                  class="mx-2 elevation-4"
                  dark
                  large
                  color="cyan"
                  style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                >
                  <v-icon large dark>
                    mdi-clipboard-list-outline
                  </v-icon>
                </v-btn>
                <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
                <v-toolbar-title>Quotation Import Result</v-toolbar-title>
              </v-toolbar>
            </template>
          </v-data-table>
          <v-col cols="12" style="padding:0 5px;">
            <v-btn
              color="success"
              tile
              style="margin-right:20px;"
              height="30px"
              width="100px"
              :disabled="!isImport"
              @click="saveQuotationImport"
            >
              Save Data
            </v-btn>
            <v-btn
              color="pink"
              tile
              style="margin-right:20px;"
              height="30px"
              width="100px"
              class="white--text"
              :disabled="!isImport"
              @click="remove"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-col>
      </v-row>
    </v-card>
    <v-card
      v-if="getIsLoading === true"
      class="mx-auto"
      width="99%"
      height="260"
      style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Please wait</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: [],
  data() {
    return {
      dialog: false,
      isUpload: false,
      uploadFile: null,
      isImport: false,
      itemsPerPage: 10,

      headers: [
        {
          text: 'No',
          align: 'left',
          sortable: false,
          value: 'no',
          width: '5%'
        },
        { text: 'Vendor Name', value: 'vendor_name', align: 'left' },
        {
          text: 'V.Code',
          sortable: false,
          value: 'vendor_code',
          align: 'center'
        },
        { text: 'Product Name', value: 'product_name', align: 'left' },
        {
          text: 'P.Code',
          sortable: false,
          value: 'product_code',
          align: 'center'
        },
        { text: 'Periode Awal', value: 'period_start', align: 'left' },
        { text: 'Periode Akhir', value: 'period_end', align: 'left' },
        { text: 'Unit', value: 'uom', align: 'left' },
        { text: 'Price/unit', value: 'price_per_unit', align: 'left' }
      ],
      quotationData: []
    }
  },
  components: {},
  computed: {
    ...mapGetters(['getIsLoading'])
  },
  methods: {
    ...mapActions(['quotationFile', 'saveImportQuotation']),
    ...mapMutations(['setIsLoading']),
    remove() {
      this.quotationData = []
      this.isImport = false
    },
    showMsgDialog(pModalType, pStatusMsg) {
      this.$swal
        .fire({
          icon: pModalType,
          html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
          showConfirmButton: true,
          showCancelButton: true
        })
        .then(r => {})
    },

    quotationImport() {
      this.quotationData = []
      if (
        this.uploadFile.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.showMsgDialog('error', 'File type must be .xlsx')
        this.uploadFile = null
      } else {
        this.setIsLoading(true)
        const data = new FormData()
        data.append('file', this.uploadFile)

        this.quotationFile(data)
          .then(result => {
            if (result.data.status_code === '00') {
              for (let i = 0; i < result.data.data.length; i++) {
                if (result.data.data[i].vendor_name !== '') {
                  console.log(result.data.data[i])
                  const param = {
                    no: 1 + i,
                    period_end: result.data.data[i].period_end,
                    period_start: result.data.data[i].period_start,
                    price_per_unit: result.data.data[i].price_per_unit,
                    product_code: result.data.data[i].product_code,
                    product_name: result.data.data[i].product_name,
                    uom: result.data.data[i].uom,
                    uom_id: result.data.data[i].uom_id,
                    vendor_code: result.data.data[i].vendor_code,
                    vendor_name: result.data.data[i].vendor_name
                  }
                  if (result.data.data[i].id !== undefined) {
                    Object.assign(param, {
                      id: result.data.data[i].id
                    })
                  }
                  this.quotationData.push(param)
                }
              }
              this.uploadFile = null
              this.isImport = true
              this.setIsLoading(false)
            } else {
              this.showMsgDialog('error', result.data)
              this.setIsLoading(false)
              this.quotationData = []
              this.isImport = false
            }
          })
          .catch(error => {
            this.setIsLoading(false)
            this.showMsgDialog('error', error)
            this.quotationData = []
            this.isImport = false
          })
      }
    },
    saveQuotationImport() {
      const form = {
        act: 'add',
        data: this.quotationData
      }
      this.setIsLoading(true)
      this.saveImportQuotation(form)
        .then(result => {
          if (result.data.status_code === '00') {
            this.setIsLoading(false)
            if (result.data.err_msg === '') {
              this.showMsgDialog(
                'success',
                'All of data was ' + result.data.status_msg
              )
            } else {
              this.showMsgDialog('warning', result.data.err_msg)
            }
            this.quotationData = []
            this.isImport = false
          }
        })
        .catch(error => {
          this.setIsLoading(false)
          this.showMsgDialog('error', error)
          this.quotationData = []
          this.isImport = false
        })
    }
  }
}
</script>
<style>
.table {
  border-radius: 3px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255);
}
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
</style>
