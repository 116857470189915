<template>
  <div class="row justify-content-center" id="Login">
    <div class="col-md-12 log-form ">
      <v-img
        style="width: 100%; min-height: 100vh; position: absolute;"
        src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
      ></v-img>
      <div
        style="position: relative; right:150px; z-index:2; display: flex; flex-direction: row;"
      >
        <v-btn
          @click.prevent="home"
          style="height:50px;width:50px; margin-right: 10px;"
          elevation="5"
          fab
          x-large
          color="white"
          class=" rounded-circle"
          ><v-img
            alt="sanqua multi international"
            class=" rounded-circle"
            contain
            src="../../../public/sanquamulti.png"
            transition="scale-transition"
            style="height:50px;width:50px; "
        /></v-btn>
        <h5 style="line-height:20px; margin-top:12px; color: white;">
          Sanqua
          <h4 style="color:#37474F">Create Account</h4>
        </h5>
      </div>
      <v-card elevation="10" class="mx-auto" width="544" style="padding:20px;">
        <h3
          style="display: flex; justify-content: flex-start; align-items:center; "
        >
          Create account
        </h3>
        <p style="width: 400px; marginBottom: 20px;">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita,
          sunt.
        </p>
        <b-form @submit.prevent="register">
          <div
            class="col-md-12"
            style=" display: flex; flex-direction: row; flex-wrap:wrap; padding:0;"
          >
            <div style="padding:0; 5px;" class="col-md-6">
              <label
                for="text-name"
                style="width:100%; text-align:left; padding-left: 10px"
                >Name</label
              >
              <b-form-input
                type="text"
                id="text-name"
                v-model="form.name"
                style="margin-bottom: 20px;"
              ></b-form-input>
            </div>
            <div style="padding:0 5px;" class="col-md-6">
              <label
                for="text-email"
                style="width:100%; text-align:left; padding-left: 10px"
                >Email</label
              >
              <b-form-input
                type="email"
                id="text-email"
                v-model="form.email"
                style="margin-bottom: 20px;"
              ></b-form-input>
            </div>
            <div style="padding:0 5px;" class="col-md-6">
              <label
                for="text-password"
                style="width:100%; text-align:left; padding-left: 10px"
                >Password</label
              >
              <b-form-input
                type="password"
                id="text-password"
                style="margin-bottom: 20px;"
                v-model="form.password"
              ></b-form-input>
            </div>
            <div style="padding:0 5px;" class="col-md-6">
              <label
                for="text-method"
                style="width:100%; text-align:left; padding-left: 10px"
                >Method</label
              >
              <b-form-input
                type="text"
                id="text-method"
                v-model="form.method"
                style="margin-bottom: 20px;"
              ></b-form-input>
            </div>
            <div style="padding:0 5px;" class="col-md-6">
              <label
                for="text-type"
                style="width:100%; text-align:left; padding-left: 10px"
                >Type</label
              >
              <b-form-select
                id="text-type"
                v-model="selected"
                style="margin-bottom: 20px;"
              >
                <b-form-select-option :value="null"
                  >Please type</b-form-select-option
                >
                <b-form-select-option value="1">type 1</b-form-select-option>
                <b-form-select-option value="2">type 2</b-form-select-option>
              </b-form-select>
            </div>
            <div style="padding:0 5px;" class="col-md-6">
              <label
                for="text-status"
                style="width:100%; text-align:left; padding-left: 10px"
                >Status</label
              >
              <b-form-input
                type="number"
                min="0"
                id="text-status"
                v-model="form.status"
                style="margin-bottom: 20px;"
              ></b-form-input>
            </div>
          </div>
          <div>
            <p
              style="font-size: 12px; text-align:center; color: rgba(0, 0, 0, 0.5)"
            >
              By clicking Agree & Join, you agree to the Sanqua User Agreement,
              Privacy Policy, and Cookie Policy.
            </p>

            <div class="col-12" style="text-align: right; padding: 0;;">
              <button type="submit">Create account</button>
              <router-link to="/login">Sign in now ?</router-link>
            </div>
          </div>
        </b-form>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'login',
  data() {
    return {
      form: {
        name: '',
        email: '',
        password: '',
        method: '',
        status: '',
        type: ''
      },

      selected: null
    }
  },
  components: {},
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    register() {
      if (!this.form.email && !this.form.password) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Input must be filled',
          timer: 1500
        })
        this.form.email = ''
      }
    },
    home() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/login.scss"></style>
