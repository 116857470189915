<template>
  <v-dialog v-model="dialog" width="768px" @click:outside="clickOutside">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="classificationEditBtn"
        v-bind="attrs"
        v-on="on"
        color="pink"
        fab
        dark
        absolute
        small
        style="z-index:1; right:-15px; top:-10px;"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>

    <v-card style=" padding:20px;" class="text-center">
      <v-form v-if="!getIsLoading" ref="entryForm" @submit.prevent="submit">
        <v-row>
          <v-col cols="12">
            TAMBAH / EDIT CLASSIFICATION
          </v-col>
          <v-col cols="12" style="padding:0 10px; margin-top:60px; ">
            <v-row>
              <v-col cols="3" style="text-align:left; padding-left:50px;">
                <p>
                  Nama Klasifikasi
                </p>
              </v-col>
              <v-col cols="6" style="padding:0 15px; height:41px; ">
                <v-text-field
                  v-model="form.name"
                  dense
                  outlined
                  clearable
                  :rules="nameRules"
                  style="margin:0;"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" style="margin-top:60px">
            <v-btn
              small
              color="grey"
              tile
              class="ma-2"
              style="color:white;"
              type="submit"
            >
              Simpan
            </v-btn>
            <v-btn
              small
              type="button"
              color="grey"
              tile
              class="ma-2"
              style="color:white;"
              @click="close"
            >
              Tutup
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <Loader v-else />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import axios from 'axios'
import buildType from '../../../services/buildType'
import Loader from '../../common/loader/CircularProgress'
export default {
  props: ['form'],
  data() {
    return {
      procAPI: buildType.apiURL('proc'),
      dialog: false,
      nameRules: []
    }
  },
  components: { Loader },
  computed: {
    ...mapGetters(['getIsLoading'])
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            // title: pModalType,
            icon: pModalType,
            html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
            showConfirmButton: true,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    clearAlert() {
      this.nameRules = []
    },
    close() {
      this.clearAlert()
      this.$emit('formClose')
      this.dialog = false
    },
    clickOutside() {
      this.clearAlert()
      this.$emit('formClose')
    },
    submit() {
      this.nameRules = [v => !!v || 'Name is required']
      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.save()
        }
      })
    },
    save() {
      axios
        .post(`${this.procAPI}master/classification/save`, this.form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg)
            this.$emit('refreshTable')
            this.close()
          } else {
            this.showMsgDialog('warning', res.data.status_msg)
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong please contact an Admin'
              : 'Something went wrong please contact an Admin'
          )
        })
    }
  }
}
</script>
<style></style>
