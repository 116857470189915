<template>
  <div id="formProcurement">
    <v-btn
      small
      tile
      elevation="0"
      color="transparent"
      class="ma-2 red--text"
      style="position:relative;border:1px solid black;"
      @click="$router.push('/e-procurement/procurement-form')"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-card flat class="form-pr-v-toolbar mx-auto">
      <v-row>
        <v-col cols="12" style="padding:0;">
          <v-toolbar flat style="border-radius:10px 10px 0 0;">
            <v-btn
              elevation="1"
              class="mx-2 elevation-4"
              dark
              color="cyan"
              style="position:relative; top:-10px; left:-10px; height:60px; width:60px;"
            >
              <v-icon large dark>
                mdi-account-supervisor-circle-outline
              </v-icon>
            </v-btn>
            <v-toolbar-title class="overline"
              ><p style="font-size:20px;">
                Formulir Undang Penyedia
              </p>
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col
          cols="12"
          style="padding:0; text-align:right; position:absolute; top:0; left:0;"
        >
          <v-btn color="grey" small tile class="ma-2" style="color:white;">
            Preview
          </v-btn>
          <!-- Pakta integritas -->
          <!-- <IntegrityPact /> -->
        </v-col>
      </v-row>
    </v-card>
    <div class="form-pr-input mx-auto">
      <Form :detail="detail" />
    </div>
    <div class="form-pr-items-table mx-auto">
      <v-card elevation="0" style="padding:20px;">
        <ProviderCard
          :vendorList="arrVendor"
          :param="paramApi"
          @search="searchEnter"
          @reload="arrJoin"
        />
      </v-card>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import Form from '../../../components/procurement/invite_provider/Form_invite'
import ProviderCard from '../../../components/procurement/invite_provider/Provider_card'
// import IntegrityPact from '../../../components/procurement/invite_provider/integrityPactDialog'
export default {
  data: () => ({
    proc: buildType.apiURL('proc'),
    tabs: ['Item Pengadaan', 'Jadwal', 'Persyaratan', 'Deskripsi Tambahan'],
    detail: null,
    product_id: '',
    arrVendor: [],
    paramApi: {
      page: 1,
      keyword: '',
      limit: 2,
      offset: 0,
      arrLength: 0
    }
  }),
  components: {
    Form,
    ProviderCard
    // IntegrityPact
  },
  watch: {
    'paramApi.page'() {
      this.paramApi.offset = this.paramApi.page - 1
      this.initTable(this.paramApi)
    }
  },
  created() {
    this.detail = this.$store.state.procurement.procDetail
    this.arrJoin()
  },
  methods: {
    // ...mapActions([]),
    // ...mapMutations([])
    arrJoin() {
      console.log('join')
      const arr = this.detail.item
      const code = []
      for (let i = 0; i < arr.length; i++) {
        code.push(Number(arr[i].product.id))
      }
      this.product_id = JSON.stringify(code)

      this.initTable(this.paramApi)
    },
    initTable(pValue) {
      axios
        .get(
          `${this.proc}vendor/catalogue/get_by_product?keyword=${
            pValue.keyword
          }&offset=${pValue.offset * pValue.limit}&limit=${
            pValue.limit
          }&product_id=${this.product_id}`
        )
        .then(response => {
          this.paramApi.arrLength = response.data.total_record
          this.arrVendor = response.data.data
          console.log(response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    searchEnter() {
      this.initTable(this.paramApi)
    }
  }
}
</script>
<style lang="scss" scoped>
#formProcurement {
  z-index: 2;
  padding: 20px 10px;

  .form-pr-v-toolbar {
    padding: 10px 15px 20px 15px;
    width: 95%;
    margin-top: 30px;
  }
  .form-pr-input {
    margin-top: 0px;
    border-radius: 3px;
    padding: 20px 30px 10px;
    width: 95%;
    min-height: 35vh;
    border-bottom: 2px solid #e0e0e0;
    background: white;
  }
  .form-pr-items-table {
    border-radius: 3px;
    padding: 20px 30px;
    width: 95%;
    margin-top: 0px;
    min-height: 50vh;
    background: white;
  }
}
</style>
