<template>
  <v-dialog v-model="dialog" width="70%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        style="margin-left:5px;font-size:8px; padding:0 2px;"
        color="white"
        dark
        elevation="1"
        class="black--text"
        small
        @click.stop="provider(value)"
        >Penyedia
      </v-btn>
    </template>
    <v-card style="padding:20px;" class="text-center">
      <v-row>
        <v-col cols="12" style="font-size:22px; font-weight:bold;">
          DATA PENYEDIA
          <v-icon
            style="position:absolute; top:0; right:0; cursor:pointer;"
            @click="dialog = false"
            >mdi-close</v-icon
          >
        </v-col>
        <v-col cols="12" style="text-align:left; margin-bottom:10px;">
          <div style="border:1px solid rgba(0,0,0,0.1);margin-bottom:10px;">
            <v-list-item>
              <div>
                <v-list-item-content>
                  <v-list-item-title class="headline ">
                    No. Vendor :
                  </v-list-item-title>
                </v-list-item-content>
              </div>
            </v-list-item>

            <v-list-item style="padding:0 15px;">
              <div style="width:20%; height:70px;">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <p style="font-size:16px; margin:0; color:black;">
                      Email :
                    </p>
                    <span style="color:black; font-weight:bold;">
                      asds@gmail.com</span
                    >
                  </v-list-item-subtitle>
                </v-list-item-content>
              </div>
              <div style="width:20%;height:70px;">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <p style="font-size:16px; margin:0; color:black;">
                      No. Handphone :
                    </p>
                    <span style="color:black; font-weight:bold;"> asdasda</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </div>
              <div style="width:20%;height:70px;">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <p style="font-size:16px; margin:0; color:black;">
                      Alamat :
                    </p>
                    <span style="color:black; font-weight:bold;"> dasdada</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </div>
            </v-list-item>
          </div>
          <v-item-group
            mandatory
            style="display:flex; flex-direction:row; padding: 0 20px;"
          >
            <v-item
              v-slot="{ active, toggle }"
              v-for="(item, index) in tabs"
              :key="index"
              @change="tabChange(item, index)"
            >
              <v-btn
                :color="active ? 'cyan' : 'grey'"
                @click="toggle"
                style="color:white; border-radius: 0 20px 0 0;"
              >
                {{ item }}
              </v-btn>
            </v-item>
          </v-item-group>
          <v-card elevation="1" style="padding:20px;margin-bottom:20px;">
            <div class="container" v-if="selectedTab === 0">
              <v-row>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      No. NPWP
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-text-field
                      readonly
                      outlined
                      class="ma-2"
                      style="margin:0;height:42px;"
                      dense
                      truncate-length="10"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Penerbit
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-text-field
                      readonly
                      outlined
                      class="ma-2"
                      style="margin:0;height:42px;"
                      dense
                      truncate-length="10"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Tgl. Terbit
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          readonly
                          outlined
                          class="ma-2"
                          style="margin:0;height:42px;"
                          dense
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker style="width:100%;" />
                    </v-menu>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Tgl. Expired
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          readonly
                          outlined
                          class="ma-2"
                          style="margin:0;height:42px;"
                          dense
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker style="width:100%;" />
                    </v-menu>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Alamat
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-textarea
                      readonly
                      class="ma-2"
                      outlined
                      auto-grow
                      dense
                    ></v-textarea>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col
                    cols="11"
                    style="padding:0;height:100%;display:flex; justify-content:flex-start;align-items:flex-end;"
                  >
                    <v-btn
                      large
                      color="blue-grey"
                      class="ma-2 white--text"
                      style="margin:0;position:relative; top:-30px;"
                    >
                      Download File
                      <v-icon right dark>
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </div>
            <div v-if="selectedTab === 1" class="container">
              <v-row>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      No. SIUP
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-text-field
                      readonly
                      outlined
                      class="ma-2"
                      style="margin:0;height:42px;"
                      dense
                      truncate-length="10"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Penerbit
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-text-field
                      readonly
                      outlined
                      class="ma-2"
                      style="margin:0;height:42px;"
                      dense
                      truncate-length="10"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Tgl. Terbit
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          readonly
                          outlined
                          class="ma-2"
                          style="margin:0;height:42px;"
                          dense
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker style="width:100%;" />
                    </v-menu>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Tgl. Expired
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          readonly
                          outlined
                          class="ma-2"
                          style="margin:0;height:42px;"
                          dense
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker style="width:100%;" />
                    </v-menu>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Alamat
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-textarea
                      readonly
                      class="ma-2"
                      outlined
                      auto-grow
                      dense
                    ></v-textarea>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col
                    cols="11"
                    style="padding:0;height:100%;display:flex; justify-content:flex-start;align-items:flex-end;"
                  >
                    <v-btn
                      large
                      color="blue-grey"
                      class="ma-2 white--text"
                      style="margin:0;position:relative; top:-30px;"
                    >
                      Download File
                      <v-icon right dark>
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </div>
            <div v-if="selectedTab === 2" class="container">
              <v-row>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      No. DOMISILI
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-text-field
                      readonly
                      outlined
                      class="ma-2"
                      style="margin:0;height:42px;"
                      dense
                      truncate-length="10"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Penerbit
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-text-field
                      readonly
                      outlined
                      class="ma-2"
                      style="margin:0;height:42px;"
                      dense
                      truncate-length="10"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Tgl. Terbit
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          readonly
                          outlined
                          class="ma-2"
                          style="margin:0;height:42px;"
                          dense
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker style="width:100%;" />
                    </v-menu>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Tgl. Expired
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          readonly
                          outlined
                          class="ma-2"
                          style="margin:0;height:42px;"
                          dense
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker style="width:100%;" />
                    </v-menu>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Alamat
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-textarea
                      readonly
                      class="ma-2"
                      outlined
                      auto-grow
                      dense
                    ></v-textarea>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col
                    cols="11"
                    style="padding:0;height:100%;display:flex; justify-content:flex-start;align-items:flex-end;"
                  >
                    <v-btn
                      large
                      color="blue-grey"
                      class="ma-2 white--text"
                      style="margin:0;position:relative; top:-30px;"
                    >
                      Download File
                      <v-icon right dark>
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </div>
            <div v-if="selectedTab === 3" class="container">
              <v-row>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      No. AKTA
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-text-field
                      readonly
                      outlined
                      class="ma-2"
                      style="margin:0;height:42px;"
                      dense
                      truncate-length="10"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Penerbit
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-text-field
                      readonly
                      outlined
                      class="ma-2"
                      style="margin:0;height:42px;"
                      dense
                      truncate-length="10"
                    ></v-text-field>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Tgl. Terbit
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          readonly
                          outlined
                          class="ma-2"
                          style="margin:0;height:42px;"
                          dense
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker style="width:100%;" />
                    </v-menu>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Tgl. Expired
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-menu
                      :close-on-content-click="true"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          readonly
                          outlined
                          class="ma-2"
                          style="margin:0;height:42px;"
                          dense
                          append-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker style="width:100%;" />
                    </v-menu>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col cols="12" style="padding:0;">
                    <p class="ma-2" style="margin:0; font-weight:bold;">
                      Alamat
                    </p>
                  </v-col>
                  <v-col cols="11" style="padding:0;">
                    <v-textarea
                      readonly
                      class="ma-2"
                      outlined
                      auto-grow
                      dense
                    ></v-textarea>
                  </v-col>
                </v-col>
                <v-col cols="6">
                  <v-col
                    cols="11"
                    style="padding:0;height:100%;display:flex; justify-content:flex-start;align-items:flex-end;"
                  >
                    <v-btn
                      large
                      color="blue-grey"
                      class="ma-2 white--text"
                      style="margin:0;position:relative; top:-30px;"
                    >
                      Download File
                      <v-icon right dark>
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
// import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  components: {},
  props: ['value'],
  data() {
    return {
      dialog: false,
      selectedTab: 0,
      tabs: ['NPWP', 'SIUP', 'DOMISILI', 'AKTA']
    }
  },
  computed: {},
  created() {},
  methods: {
    provider(pValue) {
      console.log(pValue)
    },
    tabChange(item, index) {
      this.selectedTab = index
    }
  }
}
</script>
<style lang="scss" scoped></style>
