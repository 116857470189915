<template>
  <div style="padding:0;">
    <div>
      <Navigation chosenpage="Master Vendor" @setDrawer="drawerSet" />
    </div>
    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>
    <div
      v-if="permission.read_perm === 1"
      style="display:flex; flex-direction:row; border-bottom:1px solid rgba(0,0,0,0.1);"
    >
      <!-- <div>
        <v-navigation-drawer
          v-model="vendorDrawer"
          :mini-variant.sync="mini"
          style="background:#eeeeee; position:relative; z-index:2;"
        >
          <VendorDrawer :btn="button" :perm="permission" />
        </v-navigation-drawer>
      </div> -->
      <div style="margin-bottom:20px; width:100%;">
        <!-- <OtherButton :router="routerLink" @miniDrawer="miniBtn" :btn="button" /> -->

        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:20px 10px; width:95%; margin-top:30px;"
        >
          <v-data-table
            :headers="headers"
            :items="getVendorDataList"
            class="elevation-1 mx-auto "
            style="width:99%; margin-top: 20px; margin-bottom:20px;"
            dense
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar flat style="height:100px;">
                <v-btn
                  class="mx-2 elevation-4"
                  dark
                  large
                  color="cyan"
                  style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                >
                  <v-icon large dark>
                    mdi-handshake
                  </v-icon>
                </v-btn>
                <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
                <v-toolbar-title>Vendor List</v-toolbar-title>
                <v-divider class="mx-6" inset vertical></v-divider>
                <v-text-field
                  v-model="invokeSrc"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  style="position: relative; top:15px; "
                ></v-text-field>

                <div
                  style="position:relative;left:40px;"
                  v-if="json_data.length === 0"
                >
                  <v-btn
                    small
                    tile
                    color="#e0e0e0"
                    class="ma-2 black--text"
                    style="position:relative;"
                    @click="exportAll"
                  >
                    Export All
                  </v-btn>
                </div>
                <div style="position:relative;left:40px;" v-else>
                  <v-btn
                    small
                    tile
                    color="#e0e0e0"
                    class="ma-2 black--text"
                    style="position:relative;"
                    @click="exportFile"
                  >
                    Export Now
                    <v-icon small right dark>
                      mdi-file-export
                    </v-icon>
                  </v-btn>

                  <download-excel
                    id="downloadVendorExcel"
                    :data="json_data"
                    :fields="isJsonEdit ? json_fields_edit : json_fields_normal"
                    name="master_vendor.xls"
                    style="width:0; height:0; font-size:1px; color:transparent;"
                  >
                    Export Now
                  </download-excel>
                </div>
                <div style="position:relative;left:60px;">
                  <VendorImport :perm="permission" />
                </div>
                <v-spacer></v-spacer>
                <v-card style="width:110px; position: absolute; right:-10px;">
                  <v-btn
                    color="pink"
                    class="white--text"
                    fab
                    dark
                    absolute
                    :disabled="permission.create_perm !== 1"
                    small
                    @click="vendorManageAdd(false, 'add')"
                    style="z-index:1; right:-15px; top:-10px;"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    style="font-size:12px; color: black; text-align:left; padding:3px 10px 0 5px ;"
                  >
                    New Vendor
                  </v-toolbar-title></v-card
                >
                <div
                  style="display:flex;justify-content:space-evenly; align-items:center; width:300px;height:35px; position:absolute; bottom:-30px; left:240px;"
                >
                  <p style="margin:0; font-weight:bold;">Sort by</p>
                  <p class="vendorSortIcon" @click="vCode">
                    code:
                    <v-icon small style=" box-shadow: 1px 1px 5px black;">{{
                      vCodeIcon
                    }}</v-icon>
                  </p>
                  <p class="vendorSortIcon" @click="vName">
                    name:
                    <v-icon small style=" box-shadow: 1px 1px 5px black;">{{
                      vNameIcon
                    }}</v-icon>
                  </p>
                </div>
              </v-toolbar>
            </template>

            <template v-slot:[`item.export`]="{ item }">
              <td>
                <v-checkbox v-model="json_data" :value="item"></v-checkbox>
              </td>
            </template>

            <template v-slot:[`item.no`]="{ item }">
              <td>
                {{ item.no + offset * itemsPerPage }}
              </td>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <v-list-item-subtitle>
                {{ item.name.toUpperCase() }}
              </v-list-item-subtitle>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-chip :color="getColor(item.status)" small>
                {{ status(item.status) }}
              </v-chip>
            </template>
            <template v-slot:[`item.code`]="{ item }">
              <div
                v-if="isCodeEdit"
                style="position:relative; height:45px; display:flex; justify-content:center; align-items:center;"
              >
                <v-text-field
                  label="Enter Code"
                  v-if="item.code === null"
                  outlined
                  dense
                  v-model="vendorCode"
                  @keyup.enter="inputCode(item, vendorCode)"
                  style="position:absolute; top:3px; text-align:center; font-size:12px;"
                ></v-text-field>

                <v-list-item-subtitle>
                  {{ item.code }}
                </v-list-item-subtitle>
              </div>
              <div
                v-else
                style="position:relative; height:45px; display:flex; justify-content:center; align-items:center;"
              >
                <v-list-item-subtitle>
                  {{ item.code !== null ? item.code : '-' }}
                </v-list-item-subtitle>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div>
                <!-- <v-btn
                  elevation="0"
                  color="#e0e0e0"
                  class="ma-1 black--text"
                  small
                  :disabled="permission.read_perm !== 1"
                  @click="vendorDetail(item)"
                > -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="margin-right:5px;"
                      color="success"
                      :disabled="permission.read_perm !== 1"
                      @click="vendorDetail(item)"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      mdi-file-find
                    </v-icon>
                  </template>
                  <span>View Details</span>
                </v-tooltip>
                <!-- </v-btn> -->

                <!-- <v-btn
                  elevation="0"
                  color="#e0e0e0"
                  class="ma-1 black--text"
                  small
                  :disabled="permission.read_perm !== 1"
                  @click="vendorManageAdd(false, 'update', item)"
                > -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="margin-right:5px;"
                      color="cyan"
                      :disabled="permission.read_perm !== 1"
                      @click="vendorManageAdd(false, 'update', item)"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <!-- </v-btn> -->

                <!-- <v-btn
                  elevation="0"
                  color="#e0e0e0"
                  class="ma-1 black--text"
                  small
                  :disabled="permission.delete_perm !== 1"
                  @click="vendorDelete(item)"
                > -->
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      style="margin-right:5px;"
                      color="error"
                      :disabled="permission.delete_perm !== 1"
                      @click="vendorDelete(item)"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
                <!-- </v-btn> -->

                <!-- <v-btn
                  elevation="0"
                  v-if="item.status !== -1"
                  color="#e0e0e0"
                  class="ma-1 black--text"
                  small
                  :disabled="permission.update_perm !== 1"
                  @click="blockVendor(item)"
                > -->
                <button
                  v-if="item.status !== -1"
                  :disabled="permission.update_perm !== 1"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :disabled="permission.update_perm !== 1"
                        color="black"
                        @click="blockVendor(item)"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        mdi-account-cancel
                      </v-icon>
                    </template>
                    <span>Block</span>
                  </v-tooltip>
                </button>
                <!-- </v-btn> -->
                <!-- <v-btn
                  v-else
                  color="brown"
                  class="ma-1 white--text"
                  small
                  :disabled="permission.update_perm !== 1"
                  @click="unBlockVendor(item)"
                > -->
                <button v-else :disabled="permission.update_perm !== 1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :disabled="permission.update_perm !== 1"
                        color="black"
                        @click="unBlockVendor(item)"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        mdi-lock-open-check
                      </v-icon>
                    </template>
                    <span>Un-block</span>
                  </v-tooltip>
                </button>
                <!-- </v-btn> -->
              </div>
            </template>
            <template v-slot:footer>
              <v-toolbar flat style="border-top:1px solid rgba(0,0,0,.15);">
                <v-btn
                  small
                  :disabled="permission.update_perm !== 1"
                  @click="!isCodeEdit ? editCode('edit') : editCode('close')"
                >
                  <v-icon left small v-if="!isCodeEdit">
                    mdi-pencil
                  </v-icon>
                  {{ !isCodeEdit ? 'Edit Code' : 'Close' }}
                  <v-icon
                    left
                    small
                    v-if="isCodeEdit"
                    style="padding-left:10px;"
                  >
                    mdi-close
                  </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-pagination
                  style="margin-left:auto;"
                  v-model="page"
                  :length="Math.ceil(getTotalVendor / itemsPerPage) || 1"
                  :total-visible="6"
                ></v-pagination>
                <v-spacer></v-spacer>
                <div
                  style="padding:0; margin:0;display: flex; flex-direction:rows; justify-content:center; align-items:center;"
                >
                  <v-toolbar-title class="caption"
                    >Rows per page:</v-toolbar-title
                  >
                  <v-select
                    style="position: relative; margin-left:10px;height:28px; width:50px; rightt:20px; font-size:12px;"
                    v-model="itemsPerPage"
                    :items="[1, 2, 5, 10, 15, 20, 100]"
                    dense
                  ></v-select>
                </div>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
    <v-card
      flat
      height="90vh"
      v-if="permission.read_perm === 0"
      color="transparent"
      style="display:flex; justify-content:center; align-items:center;"
    >
      <v-toolbar-title class="overline">
        You Can't Access This Page</v-toolbar-title
      >
    </v-card>
    <v-card
      v-if="getIsLoading === true"
      class="mx-auto"
      width="99%"
      height="260"
      style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Please wait</v-progress-circular
      >
    </v-card>
    <!-- ---------------------- -->
    <div>
      <VendorAdd
        v-if="!getVdrAddStat"
        :perm="permission"
        :loadPage="initialize"
      />
      <VendorBlock
        v-if="!getVdrBlockStat"
        :vendorId="vendorBlockId"
        :status="blockStatus"
        :perm="permission"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import Navigation from '../../components/navigation/Navigation'
import Drawer from '../../components/navigation/Drawer'
// import VendorDrawer from '../../components/vendor/Drawer'
// import OtherButton from '../../components/navigation/otherButton'
import VendorAdd from '../../components/vendor/VendorAdd'
import VendorBlock from '../../components/vendor/VendorBlock'
import VendorImport from '../../components/vendor/VendorImport'
import axios from 'axios'
import buildType from '../../services/buildType'
export default {
  name: 'vendorManage',
  data: () => ({
    procAPI: buildType.apiURL('proc'),
    menu_key: 11,
    permission: {},
    application: {
      id: 2,
      name: 'eProcurement'
    },
    button: false,
    drawer: false,
    // vendorDrawer: true,
    mini: true,
    page: 1,
    offset: 0,
    itemsPerPage: 10,
    searchVendor: '',
    invokeSrc: '',
    // drawer: false,
    vendorCode: '',
    orderBy: 'name',
    orderType: 'asc',
    vCodeIcon: 'mdi-arrow-up',
    vNameIcon: 'mdi-arrow-up',
    isCodeEdit: false,
    exportSelection: [],
    items: [
      {
        title: 'User Manage',
        path: '/user-manage',
        icon: 'mdi-account-edit-outline'
      },
      {
        title: 'User Access',
        path: '/user-access',
        icon: 'mdi-badge-account-outline'
      },
      {
        title: 'Vendor',
        path: '/master-vendor',
        icon: 'mdi-handshake-outline'
      },
      { title: 'Product', path: '/master-product', icon: 'mdi-shape-outline' },
      {
        title: 'Product Category',
        path: '/product-category',
        icon: 'mdi-file-tree-outline'
      }
    ],
    // ----------------
    headers: [
      {
        text: '',
        align: 'left',
        sortable: false,
        value: 'export'
        // width: '5%'
      },
      {
        text: 'No',
        align: 'left',
        sortable: false,
        value: 'no'
        // width: '5%'
      },

      {
        text: 'Code',
        sortable: false,
        value: 'code',
        align: 'center'
        // width: '15%'
      },
      {
        text: 'Nama',
        sortable: false,
        value: 'name',
        align: 'left'
        // width: '25%'
      },
      {
        text: 'Email',
        sortable: false,
        value: 'email',
        align: 'left'
        // width: '25%'
      },
      {
        text: 'Phone 1',
        sortable: false,
        value: 'phone1',
        align: 'left'
        // width: '25%'
      },
      {
        text: 'Phone 2',
        sortable: false,
        value: 'phone2',
        align: 'left'
        // width: '25%'
      },
      {
        text: 'Website',
        sortable: false,
        value: 'website',
        align: 'left'
        // width: '25%'
      },
      {
        text: 'Status',
        value: 'status',
        align: 'center',
        // width: '5%',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'center'
        // width: '45%'
      }
    ],
    // -------------------,
    vendorBlockId: '',
    blockStatus: null,
    routerLink: {
      text: 'Vendor blacklist menu',
      link: '/master-vendor/blacklist'
    },

    json_fields_normal: {
      name: 'name',
      code: 'code',
      npwp: 'npwp',
      business_entity_id: 'business_entity_id',
      classification_id: 'classification_id',
      sub_classification_id: 'sub_classification_id',
      province_id: 'province_id',
      city_id: 'city_id',
      address: 'address',
      zip_code: 'zip_code',
      phone1: 'phone1',
      phone2: 'phone2',
      email: 'email',
      website: 'website'
    },
    json_fields_edit: {
      id: 'id',
      name: 'name',
      code: 'code',
      npwp: 'npwp',
      business_entity_id: 'business_entity_id',
      classification_id: 'classification_id',
      sub_classification_id: 'sub_classification_id',
      province_id: 'province_id',
      city_id: 'city_id',
      address: 'address',
      zip_code: 'zip_code',
      phone1: 'phone1',
      phone2: 'phone2',
      email: 'email',
      website: 'website'
    },
    json_data: [],
    json_meta: [
      [
        {
          key: 'charset',
          value: 'utf-8'
        }
      ]
    ],
    isJsonEdit: false
  }),
  components: {
    VendorAdd,
    VendorBlock,
    VendorImport,
    // VendorDrawer,
    Navigation,
    Drawer
    // OtherButton
  },
  created() {
    this.setIsLoading(true)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            setTimeout(() => {
              this.initialize()
              this.setIsLoading(false)
            }, 1500)
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              setTimeout(() => {
                this.initialize()
                this.setIsLoading(false)
              }, 1500)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        setTimeout(() => {
          this.setIsLoading(false)
        }, 1500)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getVdrBlockStat',
      'getVdrAddStat',
      'getVendorDataList',
      'getTotalVendor',
      'getIsLoading',
      'getVendorRouterDetail'
    ])
  },
  watch: {
    invokeSrc() {
      this.searchVendor = this.invokeSrc
      this.offset = 0
      this.initialize()
    },
    page() {
      this.offset = this.page - 1
      this.initialize()
    },
    itemsPerPage() {
      this.page = 1
      this.offset = 0
      this.initialize()
    },
    orderBy() {
      this.initialize()
    },
    orderType() {
      this.initialize()
    }
  },
  methods: {
    ...mapActions([
      'menu_access',
      'logout',
      'vendorDataList',
      'deleteVendorData',
      'editVendorData',
      'addCode',
      'businessEntity',
      'currency',
      'province',
      'classification',
      'detailVendor',
      'vendorSiup',
      'vendorNpwp',
      'vendorTdp',
      'vendorDomisili',
      'vendorAkta'
    ]),
    ...mapMutations([
      'vendorAdd',
      'vendorBlock',
      'setVendorCode',
      'setIsLoading',
      'vendorList',
      'setTotalVendor'
    ]),
    vCode() {
      this.orderBy = 'code'
      if (this.vCodeIcon === 'mdi-arrow-up') {
        this.vCodeIcon = 'mdi-arrow-down'
        this.orderType = 'desc'
      } else {
        this.vCodeIcon = 'mdi-arrow-up'
        this.orderType = 'asc'
      }
    },
    vName() {
      this.orderBy = 'name'
      if (this.vNameIcon === 'mdi-arrow-up') {
        this.vNameIcon = 'mdi-arrow-down'
        this.orderType = 'desc'
      } else {
        this.vNameIcon = 'mdi-arrow-up'
        this.orderType = 'asc'
      }
    },
    drawerSet(val) {
      this.drawer = val
    },
    showMsgDialog(pModalType, pStatusMsg) {
      this.$swal
        .fire({
          icon: pModalType,
          html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
          showConfirmButton: true,
          showCancelButton: true
        })
        .then(r => {})
    },
    showMsgDialog2(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            icon: pModalType,
            html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    inputCode(item, code) {
      this.addCode([item.id, code])
        .then(result => {
          if (result.data.status_code === '00') {
            this.editCode('close')
            this.showMsgDialog('success', result.data.status_msg)
            this.vendorDataList([
              '',
              0,
              this.itemsPerPage,
              this.orderBy,
              this.orderType
            ])
            this.vendorCode = ''
          } else {
            this.showMsgDialog(
              'error',
              'Update failed, please contact an admin'
            )
          }
        })
        .catch(error => {
          this.showMsgDialog(
            error ? 'error' : 'error',
            'Something went wrong, please contact an admin'
          )
        })
    },
    vendorDetail(item) {
      this.setVendorCode(item.code)
      this.detailVendor(item.id)
        .then(result => {
          if (result.status_code === '00') {
            // const vendorName = result.data.name
            //   .toLowerCase()
            //   .split(' ')
            //   .join('_')
            // this.setUrlParam([result.data.id, vendorName])
            // this.setVendorDetailRouter({ id: result.data.id, name: vendorName })
            // this.$router.push(
            //   `/vendor-profile/?${result.data.id}=${vendorName}`
            // )
            this.$router.push('/vendor-profile')
          } else {
            this.showMsgDialog(
              'error',
              'Something went wrong, please contact an admin'
            )
          }
        })
        .catch(error => {
          this.showMsgDialog(
            error ? 'error' : 'error',
            'Something went wrong, please contact an admin'
          )
        })
    },

    vendorManageAdd(param1, param2, param3) {
      this.dropDownInit()
      if (param3 !== undefined) {
        this.setVendorCode(param3.code)
        this.vendorAdd([param1, param2, param3.id])
        this.initDocument()
        this.detailVendor(param3.id)
      } else {
        this.vendorAdd([param1, param2])
      }
    },

    blockVendor(item) {
      this.blockStatus = 1
      this.vendorBlockId = item.id
      this.vendorBlock(false)
    },
    unBlockVendor(item) {
      this.blockStatus = -1
      this.vendorBlockId = item.id
      this.vendorBlock(false)
    },

    vendorDelete(item) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true
        })
        .then(result => {
          if (result.value) {
            this.setIsLoading(true)
            this.deleteVendorData(item.id)
              .then(result => {
                this.setIsLoading(false)
                if (result.data.status_code !== '-99') {
                  this.showMsgDialog('success', result.data.status_msg)
                  this.vendorDataList([
                    '',
                    this.offset * this.itemsPerPage,
                    this.itemsPerPage,
                    this.orderBy,
                    this.orderType
                  ])
                } else {
                  this.showMsgDialog('error', result.data.status_msg)
                }
              })
              .catch(error => {
                this.setIsLoading(false)
                this.showMsgDialog(
                  error ? 'error' : 'error',
                  'Something wen wrong, please contact an admin'
                )
              })
          }
        })
    },

    home() {
      this.$router.push('/')
    },
    signOut() {
      this.$swal
        .fire({ title: 'Are you sure?', showCancelButton: true })
        .then(result => {
          if (result.value) {
            this.logout()
              .then(response => {})
              .catch(error => {
                this.showMsgDialog(
                  error ? 'error' : 'error',
                  'Something wen wrong, please contact an admin'
                )
              })
          }
        })
    },
    initialize() {
      this.vendorDataList([
        this.searchVendor,
        this.offset * this.itemsPerPage,
        this.itemsPerPage,
        this.orderBy,
        this.orderType
      ])
    },
    dropDownInit() {
      this.businessEntity()
      this.currency()
      this.province()
      this.classification()
    },
    initDocument() {
      this.vendorSiup()
      this.vendorNpwp()
      this.vendorTdp()
      this.vendorDomisili()
      this.vendorAkta()
    },
    sort() {
      this.initialize()
    },
    editCode(val) {
      if (val === 'edit') {
        this.isCodeEdit = true
      } else {
        this.isCodeEdit = false
      }
    },
    status(value) {
      if (value === 1) {
        return 'Active'
      } else if (value === -1) {
        return 'Blocked'
      } else if (value === 0) {
        return 'Inactive'
      }
    },
    getColor(status) {
      if (status === 1) {
        return '#e0e0e0'
      } else if (status === -1) {
        return '#e0e0e0'
      } else if (status === 0) {
        return '#e0e0e0'
      }
    },
    miniBtn() {
      this.mini = !this.mini
      if (this.button === false) {
        this.button = true
      } else {
        this.button = false
      }
    },
    exportFile() {
      this.showMsgDialog2('question', 'Sertakan ID').then(res => {
        if (res.isConfirmed) {
          this.isJsonEdit = true
          console.log(true)
          setTimeout(() => {
            document.getElementById('downloadVendorExcel').click()
          }, 500)
        } else if (res.dismiss === 'cancel') {
          this.isJsonEdit = false
          console.log(false)
          setTimeout(() => {
            document.getElementById('downloadVendorExcel').click()
          }, 500)
        } else if (res.dismiss === 'backdrop') {
          this.json_data = []
        }

        setTimeout(() => {
          this.json_data = []
        }, 1000)
      })
    },
    // exportFile() {
    //   const options = confirm('Sertakan ID ?')
    //   if (options) {
    //     this.isJsonEdit = true
    //   } else {
    //     this.isJsonEdit = false
    //   }
    //   setTimeout(() => {
    //     this.json_data = []
    //   }, 500)
    // },
    exportAll() {
      axios
        .get(`${this.procAPI}vendor/list?keyword=&offset=0&limit=all&order_by=`)
        .then(res => {
          this.json_data = []
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              this.json_data.push({
                no: 1 + i,
                code: response[i].code,
                name: response[i].name,
                status: response[i].status,
                id: response[i].id,
                npwp: response[i].npwp,
                business_entity_id:
                  response[i].business_entity !== null
                    ? response[i].business_entity.id
                    : 0,
                classification_id:
                  response[i].classification !== null
                    ? response[i].classification.id
                    : 0,
                sub_classification_id:
                  response[i].sub_classification !== undefined
                    ? response[i].sub_classification !== null
                      ? response[i].sub_classification.id
                      : 0
                    : 0,
                province_id:
                  response[i].province !== null ? response[i].province.id : 0,
                city_id: response[i].city !== null ? response[i].city.id : 0,
                address: response[i].address,
                zip_code: response[i].zip_code,
                phone1: response[i].phone1,
                phone2: response[i].phone2,
                email: response[i].email,
                website: response[i].website
              })
            }
          } else {
            this.json_data = []
            this.showMsgDialog('info', 'data not found')
          }
        })
        .catch(error => {
          this.showMsgDialog(
            error ? 'error' : 'error',
            'Something wen wrong, please contact an admin'
          )
        })
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/vendorManage.scss"></style>
<style lang="scss" scoped>
.vendorSortIcon {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.486);
  padding: 2px 5px;
  border-radius: 3px;
  cursor: pointer;
}
</style>
