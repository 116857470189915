<template>
  <v-dialog v-model="dialog" width="460">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        small
        color="grey"
        tile
        class="ma-2"
        style="color:white;"
      >
        Undang Penyedia
      </v-btn>
    </template>

    <v-card style=" padding:20px; max-height:30vh;" class="text-center">
      <v-row>
        <v-col cols="12">
          KONFIRMASI PENYEDIA
        </v-col>
        <v-col cols="12">
          Sebelum anda menggunakan penyedia, pastikan terlebih dahulu bahwa DATA
          PENGADAAN sudah dilengkapi dan diisi dengan sebenar-benarnya
        </v-col>
        <v-col cols="12">
          <v-btn
            small
            color="grey"
            tile
            class="ma-2"
            style="color:white;"
            @click="$router.push('/e-procurement/invitation-form')"
          >
            Udang Sekarang
          </v-btn>
          <v-btn
            small
            color="grey"
            tile
            class="ma-2"
            style="color:white;"
            @click="dialog = false"
          >
            Tutup
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
// import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: [],
  data() {
    return {
      dialog: false
    }
  },
  computed: {},
  methods: {}
}
</script>
<style></style>
