<template>
  <div>
    <Navigation chosenpage="eProcurement" @setDrawer="drawerSet" />
    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>
    <v-container
      fluid
      style=" background:rgba(255,255,255,0.9);min-width:100%; min-height:95vh; padding:10px 20px;position:relative;"
    >
      <h6
        @click="back"
        style="border:1px solid rgba(0,0,0,0.3); border-radius:5px; position:absolute; width:70px;font-weight:bold; cursor:pointer;"
      >
        <v-icon style="position:relative; top:-1px;">mdi-chevron-left</v-icon
        >back
      </h6>
      <div v-if="detail !== null">
        <div
          style="font-size:14px;font-weight:bold; position:absolute; left:125px;"
        >
          <!-- <v-btn
          elevation="0"
          small
          style="color:white;margin-right:10px;cursor:auto;"
          :loading="loading"
          :disabled="loading"
          color="grey"
        >
          Pendding
        </v-btn> -->
          <v-btn
            v-if="detail.status === 2"
            elevation="0"
            small
            style="color:white;margin-right:10px;cursor:auto;"
            :loading="loading"
            :disabled="loading"
            color="green"
          >
            Confirmed
          </v-btn>
          <v-btn
            v-if="detail.status === -1"
            elevation="0"
            small
            style="color:white; cursor:auto;"
            :loading="loading"
            :disabled="loading"
            color="red"
          >
            Canceled
          </v-btn>
          <v-btn
            v-if="detail.status === 0"
            elevation="0"
            small
            style="color:white;cursor:auto;"
            :loading="loading"
            :disabled="loading"
            color="orange"
          >
            Draft
          </v-btn>
          <v-btn
            v-if="detail.status === 1"
            elevation="0"
            small
            style="color:white;cursor:auto;"
            :loading="loading"
            :disabled="loading"
            color="cyan"
          >
            Submited
          </v-btn>
        </div>

        <h4
          style="margin:auto; width:45%; font-weight:bold; text-align:center;"
        >
          FORMULIR PERMINTAAN BARANG DAN JASA
        </h4>

        <div
          style="font-size:14px;font-weight:bold;position:absolute; top:10px;right:25px;"
        >
          <v-btn
            v-if="detail.status === 1"
            small
            style="color:white;margin-right:10px;"
            :loading="loading"
            :disabled="loading"
            color="green"
            @click="confirm"
          >
            Confirm
          </v-btn>
          <v-btn
            v-if="detail.status === -1"
            small
            style="color:white;margin-right:10px;"
            :loading="loading"
            :disabled="loading"
            color="orange"
            @click="setToDraft"
          >
            Set to Draft
          </v-btn>

          <!-- Public -->
          <v-btn
            v-if="
              detail.status === 0 || detail.status === 1 || detail.status === 2
            "
            small
            style="font-weigth:bold;color:white;"
            :loading="loading"
            :disabled="loading"
            color="pink"
            @click="cancel"
          >
            Cancel
          </v-btn>
        </div>

        <p style="margin:auto 0;text-align:center;">
          Doc.No.: {{ detail.request_no }}
          <v-tooltip bottom color="grey">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                x-small
                text
                icon
                color="grey darken-2"
                @click="loadPage"
              >
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>refresh page</span>
          </v-tooltip>
        </p>
        <div style="width:100%; margin-top:40px;">
          <div style="display:flex;align-items:center;">
            <p
              style="width:190px;margin:auto 0;display:flex; justify-content:space-between;font-weight:bold;"
            >
              <span> Permintaan Department</span> <span>:</span>
            </p>
            <p
              style="border-bottom:2px dotted black;width:170px;padding:0 10px;margin:auto 0;display:flex; justify-content:space-between;"
            >
              <span>{{ detail.department.name }}</span>
            </p>
            <p
              style="width:95px;font-weight:bold;margin:auto 0;display:flex; justify-content:space-between;"
            >
              <span> Tgl. Dibuat</span>
              <span style="font-weight:bolder:margin-left:5px;">:</span>
            </p>
            <p
              style="border-bottom:2px dotted black;width:170px;padding:0 10px;margin:auto 0;display:flex; justify-content:space-between;"
            >
              <span>{{ detail.created_at }}</span>
            </p>

            <v-spacer></v-spacer>
            <v-btn
              tile
              small
              style="color:white;margin-right:10px;"
              :loading="loading"
              color="black"
              @click="printFPB"
            >
              <v-icon small style="margin-right:5px;">mdi-printer</v-icon>
              <span>Print</span>
            </v-btn>
          </div>
        </div>
        <div style="margin-top:10px;">
          <v-data-table
            class="elevation-1"
            style="cursor: pointer;border:1px solid rgba(0,0,0,0.2);"
            :headers="headers"
            :items="detail.purchase_request_detail"
            :items-per-page="10"
            :loading="loading"
            @click:row="rowClick"
            item-key="id"
          >
            <template v-slot:[`item.no`]="{ index }">
              <td>
                {{ index + 1 }}
              </td>
            </template>
            <template v-slot:[`item.product`]="{ item }">
              <td>
                {{ item.product !== null ? item.product.name : '' }}
              </td>
            </template>
            <template v-slot:[`item.vendor`]="{ item }">
              <td>
                {{ item.vendor !== null ? item.vendor.name : '' }}
              </td>
            </template>
            <template v-slot:[`item.budget_price_per_unit`]="{ item }">
              <td>
                {{ price(item.budget_price_per_unit) }}
              </td>
            </template>
            <template v-slot:[`item.quotation_price_per_unit`]="{ item }">
              <td>
                {{ price(item.quotation_price_per_unit) }}
              </td>
            </template>
            <template v-slot:[`item.estimate_date_use`]="{ item }">
              <td>
                {{ convertDate(item.estimate_date_use) }}
              </td>
            </template>
            <template v-slot:[`item.has_budget`]="{ item }">
              <td :style="`color:${item.has_budget === 1 ? 'green' : 'red'};`">
                {{ hasBudget(item.has_budget) }}
              </td>
            </template>
          </v-data-table>
        </div>

        <div style="display:flex;margin-top:10px;min-height:150px;width:100%;">
          <div style="padding: 0 10px; width:60%;font-size:14px;">
            <p>Harap mengisi keterangan dengan lengkap dan jelas</p>
            <p>Data pendukung:</p>

            <div style="display:flex;">
              <p
                :style="
                  `color:${
                    detail.reference_from_ecommerce === 1 ? 'green' : 'red'
                  };`
                "
              >
                <span style="margin-right:10px;">(1)</span> Referensi penawaran
                vendor atau referensi harga barang dari e-commerce
              </p>
              <v-checkbox
                v-model="detail.reference_from_ecommerce"
                :true-value="1"
                :false-value="0"
                :color="detail.reference_from_ecommerce === 1 ? 'green' : 'red'"
                readonly
                style=" margin:0 0 0 10px; height:25px; padding:0;"
              ></v-checkbox>
            </div>

            <div style="display:flex;">
              <p
                :style="
                  `color:${detail.budget_is_approved === 1 ? 'green' : 'red'};`
                "
              >
                <span style="margin-right:10px;">(2)</span> Budget yang sudah di
                approve
              </p>
              <v-checkbox
                v-model="detail.budget_is_approved"
                :true-value="1"
                :false-value="0"
                :color="detail.budget_is_approved === 1 ? 'green' : 'red'"
                readonly
                style=" margin:0 0 0 10px; height:25px; padding:0;"
              ></v-checkbox>
            </div>
            <div style="display:flex;">
              <p
                :style="
                  `color:${
                    detail.memo_special_request === 1 ? 'green' : 'red'
                  };`
                "
              >
                <span style="margin-right:10px;">(3)</span> Memo atau Berita
                Acara dengan permintaan kondisi khusus
              </p>
              <v-checkbox
                v-model="detail.memo_special_request"
                :true-value="1"
                :false-value="0"
                :color="detail.memo_special_request === 1 ? 'green' : 'red'"
                readonly
                style=" margin:0 0 0 10px; height:25px; padding:0;"
              ></v-checkbox>
            </div>
            <p>
              <span style="margin-right:10px;">*</span>
              Pembelian kategori barang
              <span style="font-weight:bold;">"Investment / Asset"</span> wajib
              diketahui oleh
              <span style="font-weight:bold;">Finance & Accounting</span> dan
              Approval
              <span style="font-weight:bold;">Direktur</span>
            </p>
            <p></p>
          </div>

          <div style="padding: 10px 10px;width:40%;">
            <v-card
              elevation="1"
              class="items-total-box"
              style="margin-left: auto;"
            >
              <p style="margin:0; font-weight:bold;">
                SUMMARY ITEM & HARGA
              </p>
              <div>
                <p>Total Qty<span>:</span></p>
                <p style="text-align:right;width:100%;">
                  {{ detail.total_qty }}
                </p>
              </div>
              <div>
                <p>Total Harga <span>:</span></p>
                <p style="text-align:right;width:100%;">
                  {{ price(detail.total_price) }}
                </p>
              </div>
            </v-card>
          </div>
        </div>

        <div style="margin-top:10px;  padding:0; min-height:100px; width:100%;">
          <v-data-table
            :headers="headersMatrix"
            :items="matrix"
            class="elevation-1 mx-auto"
            style="width:100%; margin-bottom:10px; border:1px solid #e0e0e0"
            :items-per-page="10"
            hide-default-footer
          >
            <!-- <template v-slot:[`item.approver_user`]="{ item }">
              <div>
                <v-chip
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                >
                  {{ item.approver_user }}
                </v-chip>
              </div>
            </template> -->

            <template v-slot:[`item.approver_user`]="{ item }">
              <div @click="rowClick(item)" style="cursor:pointer;">
                <v-chip
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                  v-for="(item, index) in item.approver_user"
                  :key="index"
                >
                  {{ item.user.name }}
                </v-chip>
              </div>
            </template>
            <!-- <template v-slot:[`item.approved`]="{ item }">
              <div>
                <v-chip
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                >
                  {{ item.approved }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.rejected`]="{ item }">
              <div>
                <v-chip
                  v-if="item.rejected !== ''"
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                >
                  {{ item.rejected }}
                </v-chip>
              </div>
            </template> -->
            <template v-slot:[`item.approved`]="{ item }">
              <div v-for="(items, index) in item.approver_user" :key="index">
                <v-chip
                  v-if="items.status === 1"
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                >
                  {{ items.user.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.rejected`]="{ item }">
              <div v-for="(items, index) in item.approver_user" :key="index">
                <v-chip
                  v-if="items.status === -1"
                  label
                  outlined
                  small
                  style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                >
                  {{ items.user.name }}
                </v-chip>
              </div>
            </template>
          </v-data-table>
          <div style="text-align:right; padding-right:5px;">
            <v-btn
              v-if="
                getUserProfile.level.find(
                  ({ application }) => application.id === 1
                ) !== undefined
              "
              color="grey"
              tile
              dark
              small
              dense
              style="font-size:10px; width:100px;"
              @click="fetch"
            >
              <span>Fetch Matrix</span>
            </v-btn>
          </div>
        </div>
        <div>
          <FpbFormEdit
            :param="{
              act: 'update',
              items: itemEdit
            }"
            :detail="detail"
            @loadPage="loadPage"
          />
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../services/buildType'
import { mapGetters } from 'vuex'
export default {
  components: {
    FpbFormEdit: () => import('../../components/fpb/Fpb'),
    Navigation: () => import('../../components/navigation/Navigation'),
    Drawer: () => import('../../components/navigation/Drawer')
  },
  data() {
    return {
      drawer: false,
      oAuth: buildType.apiURL('oauth'),
      proc: buildType.apiURL('proc'),
      pagePath: this.$route.params.path,
      isFpbPageEdit: false,

      checkbox: false,
      detail: null,
      headers: [
        {
          text: 'No',
          value: 'no',
          align: 'left',
          sortable: false,
          width: '2%'
        },
        {
          text: 'Deskripsi',
          value: 'product',
          align: 'left',
          sortable: false,
          width: '25%'
        },
        // {
        //   text: 'Stock Akhir',
        //   value: 'last_stock',
        //   align: 'left',
        //   sortable: false,
        //   width: '5%'
        // },
        {
          text: 'Supplier',
          value: 'vendor',
          align: 'left',
          sortable: false,
          width: '18%'
        },
        {
          text: 'Qty',
          value: 'qty',
          align: 'left',
          sortable: false,
          width: '5%'
        },
        {
          text: 'Budget',
          value: 'budget_price_per_unit',
          align: 'left',
          sortable: false,
          width: '10%'
        },
        {
          text: 'Penawaran',
          value: 'quotation_price_per_unit',
          align: 'left',
          sortable: false,
          width: '10%'
        },
        {
          text: 'Tgl. Diperlukan',
          value: 'estimate_date_use',
          align: 'left',
          sortable: false,
          width: '10%'
        },
        {
          text: 'Alasan Permintaan',
          value: 'description',
          align: 'left',
          sortable: false,
          width: '20%'
        },
        {
          text: 'Ada budget',
          value: 'has_budget',
          align: 'left',
          sortable: false,
          width: '5%'
        }
      ],
      status: null,
      // actionValue: 0,
      // selectedData: [],
      loading: false,
      loader: null,

      headersMatrix: [
        { text: 'Sequence', value: 'sequence', align: 'left' },
        { text: 'Doc. No', value: 'document_no', align: 'left' },
        { text: 'Model', value: 'table_name', align: 'center' },
        { text: 'Approver', value: 'approver_user', align: 'center' },
        { text: 'Min. Approved', value: 'min_approver', align: 'center' },
        { text: 'Approved by', value: 'approved', align: 'center' },
        { text: 'Rejected by', value: 'rejected', align: 'center' }
      ],
      matrix: [],

      itemEdit: null
    }
  },
  computed: {
    ...mapGetters(['getFpbId', 'getUserProfile'])
  },
  created() {
    if (this.pagePath === 'fpb-list') {
      this.isFpbPageEdit = true
    }
    this.loadPage()
    console.log(this.getFpbId)
  },
  methods: {
    drawerSet(val) {
      this.drawer = val
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    loadPage() {
      if (this.getFpbId !== null) {
        this.itemEdit = null
        this.actionValue = 0
        this.selectedData = []
        axios
          .get(`${this.proc}fpb/detail/${this.getFpbId}`)
          .then(res => {
            console.log(res.data.data)
            this.detail = res.data.data
            this.matrixCheck(res.data.data)
          })
          .catch(err => {
            console.log(err)
          })
      } else {
        this.$router.push('/fpb-list')
      }
    },
    back() {
      this.detail = null
      this.$store.commit('setFpbId', null)
      this.$router.push('/fpb-list')
    },
    rowClick(pValue) {
      console.log(pValue)
      this.itemEdit = pValue
      setTimeout(() => {
        document.getElementById('fpbFormEdit').click()
      }, 500)
    },

    // submitFPB() {
    //   this.loader = 'loading'

    //   const l = this.loader
    //   this[l] = !this[l]

    //   axios
    //     .post(`${this.proc}fpb/submit`, { id: this.getFpbId })
    //     .then(res => {
    //       console.log(res)
    //       this[l] = false
    //       this.loadPage()
    //     })
    //     .catch(err => {
    //       console.log(err)
    //       this[l] = false
    //     })

    //   this.loader = null
    // },
    confirm() {
      this.showMsgDialog(
        'warning',
        'Make sure all necessary data are filled before you confirm this request, confirm now ?',
        true
      ).then(res => {
        if (res.isConfirmed) {
          if (this.detail.approval_matrix !== null) {
            this.loader = 'loading'

            const l = this.loader
            this[l] = !this[l]

            axios
              .post(`${this.proc}fpb/confirm`, {
                document_id: this.detail.approval_matrix[0].document_id,
                status: 1
              })
              .then(res => {
                console.log(res)
                if (res.data.status_code !== '-99') {
                  this.showMsgDialog('success', res.data.status_msg, false)
                  this.loadPage()
                } else {
                  this.showMsgDialog('warning', res.data.status_msg, false)
                }
                this[l] = false
              })
              .catch(err => {
                console.log(err)
                this[l] = false
              })

            this.loader = null
          } else {
            this.showMsgDialog('warning', 'Approval matrix is null ', false)
          }
        }
      })
    },
    cancel() {
      this.showMsgDialog('question', 'Cancel this request', true).then(res => {
        if (res.isConfirmed) {
          this.loader = 'loading'

          const l = this.loader
          this[l] = !this[l]

          axios
            .post(`${this.proc}fpb/cancel`, { id: this.getFpbId })
            .then(res => {
              console.log(res)
              this[l] = false
              this.loadPage()
            })
            .catch(err => {
              console.log(err)
              this[l] = false
            })

          this.loader = null
        }
      })
    },
    setToDraft() {
      this.showMsgDialog('question', 'Set This request to draft', true).then(
        res => {
          if (res.isConfirmed) {
            this.loader = 'loading'

            const l = this.loader
            this[l] = !this[l]

            axios
              .post(`${this.proc}fpb/set_to_draft`, { id: this.getFpbId })
              .then(res => {
                console.log(res)
                this[l] = false
                this.loadPage()
              })
              .catch(err => {
                console.log(err)
                this[l] = false
              })

            this.loader = null
          }
        }
      )
    },

    printFPB() {
      this.loader = 'loading'

      const l = this.loader
      this[l] = !this[l]
      setTimeout(() => {
        this[l] = false
        axios({
          url: `${this.proc}fpb/export_to_pdf/${this.getFpbId}`,
          method: 'GET',
          responseType: 'blob'
        })
          .then(res => {
            console.log(res)
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${this.detail.request_no}.pdf`)
            document.body.appendChild(link)
            link.click()
          })
          .catch(error => {
            console.log(error)
          })
      }, 2000)

      this.loader = null
    },
    // action(event) {
    //   switch (event) {
    //     case 1:
    //       this.itemEdit = this.selectedData[0]
    //       setTimeout(() => {
    //         document.getElementById('fpbFormEdit').click()
    //       }, 500)
    //       break
    //     case 2:
    //       this.delete()
    //       break
    //   }
    // },

    // addItem() {
    //   console.log(this.selectedData)
    //   // this.initDropdown()
    //   setTimeout(() => {
    //     document.getElementById('fpbFormAdd').click()
    //   }, 500)
    // },
    // delete() {
    //   this.showMsgDialog(
    //     'question',
    //     'Are you sure, This data will be deleted permanently',
    //     true
    //   ).then(res => {
    //     if (res.isConfirmed) {
    //       axios
    //         .delete(`${this.proc}fpb/delete_detail/${this.selectedData[0].id}`)
    //         .then(res => {
    //           this.showMsgDialog('success', res.data.status_msg, 'false')
    //           this.loadPage()
    //         })
    //         .catch(err => {
    //           console.log(err)
    //         })
    //     } else {
    //       this.actionValue = 0
    //     }
    //   })
    // },
    // searchEnter() {
    //   console.log('enter')
    //   // this.loadPage()
    // },
    price(pItem) {
      if (pItem !== undefined) {
        if (pItem !== null) {
          return pItem.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
        }
      }
      return ''
    },
    hasBudget(val) {
      switch (val) {
        case 0:
          return 'Tidak'
        case 1:
          return 'Ada'
      }
    },
    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        let month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        switch (month) {
          case '01':
            month = 'Jan'
            break
          case '02':
            month = 'Feb'
            break
          case '03':
            month = 'Mar'
            break
          case '04':
            month = 'Apr'
            break
          case '05':
            month = 'May'
            break
          case '06':
            month = 'Jun'
            break
          case '07':
            month = 'Jul'
            break
          case '08':
            month = 'Aug'
            break
          case '09':
            month = 'Sep'
            break
          case '10':
            month = 'Oct'
            break
          case '11':
            month = 'Nov'
            break
          case '12':
            month = 'Des'
            break
        }

        return day + ' ' + month + ' ' + year
        // return (day + '-' + month + '-' + year).replace(/-/g, '/')
      }
    },

    matrixCheck(item) {
      if (item.approval_matrix !== null) {
        axios
          .get(
            `${this.oAuth}approval_matrix/document/list?limit=100&offset=0&keyword=&application_id=2&table_name=${item.approval_matrix[0].table_name}&document_id=${item.approval_matrix[0].document_id}`
          )
          .then(res => {
            console.log(res)
            if (res.data.status_code === '00') {
              this.matrix = res.data.data
            } else {
              this.showMsgDialog('error', res.data.status_msg)
            }
          })
          .catch(err => {
            console.log(err)
            this.showMsgDialog(
              'error',
              err
                ? 'Authority check error, please contact an Admin'
                : 'Authority check error, please contact an Admin'
            )
          })
      } else {
        this.showMsgDialog('warning', 'Approval matrix is null ', false)
      }
    },
    fetch() {
      console.log(this.detail)
      if (this.detail.approval_matrix !== null) {
        this.matrix = []
        const form = {
          act: 'fetch_matrix',
          document_id: this.detail.approval_matrix[0].document_id,
          document_no: this.detail.request_no,
          // app id nantinya dari backend aja
          application_id: 2,
          table_name: this.detail.approval_matrix[0].table_name
        }
        console.log(form)
        axios
          .post(`${this.oAuth}approval_matrix/document/save`, form)
          .then(res => {
            console.log(res)
            if (res.data.status_code !== '-99') {
              this.showMsgDialog('success', res.data.status_msg)
              this.loadPage()
            } else {
              if (res.data.status_msg === 'Parameter value has problem') {
                this.showMsgDialog('error', res.error_msg[0].msg, false)
              } else {
                this.showMsgDialog('error', res.status_msg, false)
              }
            }
          })
          .catch(err => {
            console.log(err)
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, please contact an Admin'
                : 'Something went wrong, please contact an Admin',
              false
            )
          })
      } else {
        this.showMsgDialog('warning', 'Approval matrix is null ', false)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.items-total-box {
  border: 1px solid #e0e0e0;
  padding: 20px 10px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  div {
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    padding-top: 20px;
    display: flex;
    p {
      margin: 0;
      margin-right: 10px;
      min-width: 80px;
      position: relative;
      span {
        position: absolute;
        right: 0;
      }
    }
  }
}
</style>
