<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div class="pr-items-add-new-line" style="padding:5px;">
          <p
            id="termTabForm"
            v-bind="attrs"
            v-on="on"
            style="padding:3px;"
            @click="init"
          >
            Tambah Baris Baru
          </p>
        </div>
      </template>
      <v-sheet height="400px" style="position:relative;">
        <v-form
          ref="entryForm"
          @submit.prevent="submit"
          style="position: relative;"
        >
          <div
            style="border:1px solid rgba(0,0,0,0.3); position:absolute; top:10px; left:10px; z-index:1; border-radius:5px; padding: 3px 7px;"
          >
            <v-icon @click="close" color="red">
              mdi-close
            </v-icon>
            <v-btn
              type="submit"
              color="grey"
              small
              tile
              style="margin-left:20px; color:white;"
            >
              Simpan
            </v-btn>
          </div>
          <div class="text-center" style="height:100%;">
            <v-row style="padding:15px;">
              <v-col cols="12" style="padding:0;">
                <v-toolbar-title class="overline">
                  <p style="font-size:18px;">
                    Persyaratan
                  </p>
                </v-toolbar-title>
              </v-col>

              <v-col cols="12" style="padding:10px 0;">
                <div style="display: flex;">
                  <v-col cols="2" style="padding:0; display:flex;">
                    <p style=" margin:auto;">
                      Syarat
                    </p>
                  </v-col>
                  <v-col cols="9" style="padding:0;">
                    <v-text-field
                      v-model="form.term"
                      :rules="term"
                      dense
                      clearable
                      outlined
                      style="margin:0; height:41.5px; width:100%; "
                      label="Type Here"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding:10px 0;"> </v-col>
              <v-col cols="12" style="padding:10px 0;">
                <div style="display: flex;">
                  <v-col cols="2" style="padding:0; display:flex; ">
                    <p style="width:100%;">
                      Deskripsi
                    </p>
                  </v-col>
                  <v-col cols="9" style="padding:0;">
                    <v-textarea
                      auto-grow
                      row-height="30"
                      clearable
                      outlined
                      style="margin:0;"
                      label="Type Here"
                      v-model="form.description"
                      :rules="desc"
                    ></v-textarea>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding:10px 0;"> </v-col>
            </v-row>
          </div>
        </v-form>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../../services/buildType'
export default {
  props: ['param', 'id', 'itemId'],
  data: () => ({
    sheet: false,
    proc: buildType.apiURL('proc'),
    form: {},
    select: null,
    term: [],
    desc: []
  }),
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    init() {
      this.initForm()
    },
    initForm() {
      if (this.itemId === null) {
        this.form = {
          procurement_id: this.id,
          term: null,
          description: null
        }
      } else {
        this.form = {
          procurement_id: this.id,
          term: this.itemId.term,
          description: this.itemId.description
        }
      }
    },
    submit() {
      this.term = [v => !!v || 'Term is required']
      this.desc = [v => !!v || 'Description is required']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = this.form

      if (this.itemId !== null) {
        Object.assign(form, {
          act: 'update',
          id: this.itemId.id
        })
      } else {
        Object.assign(form, {
          act: 'add'
        })
      }
      this.save(form)
    },
    save(form) {
      console.log(form)
      axios
        .post(`${this.proc}term/save`, form)
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.$emit('reload')
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    close() {
      this.term = []
      this.desc = []
      this.sheet = false
      this.$emit('close', null)
    }
  }
}
</script>
