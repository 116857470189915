<template>
  <div>
    <v-app>
      <v-main class="app-main">
        <nav
          v-if="this.$route.path === '/e-procurement/invitation-form'"
          style="position: sticky; top:0; z-index:1;"
        >
          <Navigation
            chosenpage="eProcurement"
            @setDrawer="drawerSet"
            page="home"
          />
        </nav>
        <router-view />
        <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
          <Drawer />
        </v-navigation-drawer>
        <v-footer padless style="width:100%;background:transparent;relative: ">
          <Footer />
        </v-footer>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Footer from './components/navigation/footer'
import Navigation from './components/navigation/Navigation'
import Drawer from './components/navigation/Drawer'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'App',
  data: () => ({
    token: localStorage.getItem('token'),
    drawer: false
  }),
  components: {
    Footer,
    Navigation,
    Drawer
  },
  computed: {
    ...mapGetters(['getToken'])
  },
  created() {
    console.log(`Application running as: "${process.env.VUE_APP_BUILD_TYPE}"`)
    this.interceptorRequest()
    this.interceptorResponse()
      .then(res => {
        if (res.data.status_code === '-99') {
          this.logout()
        }
      })
      .catch(err => {
        console.log(err)
      })
    // if (this.token === null) {
    //   this.setLogout()
    // }
  },
  methods: {
    ...mapActions(['interceptorRequest', 'interceptorResponse', 'logout']),
    ...mapMutations(['setLogout']),
    signOut() {
      this.$swal
        .fire({
          title: 'Sign out now',
          icon: 'question',
          showCancelButton: true
        })
        .then(result => {
          if (result.value) {
            this.logout()
              .then(response => {})
              .catch(error => {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: error,
                  showConfirmButton: false,
                  showCancelButton: false,
                  timer: 1000
                })
              })
          }
        })
    },

    drawerSet(val) {
      this.drawer = val
    }
  }
}
</script>
<style scoped>
.app-main {
  background-image: url('./assets/image/e-sanqua-logo-1.jpg'),
    url('./assets/image/background1.png');
  background-size: 250px 350px, cover;
  background-repeat: no-repeat, no-repeat;
  background-position: center 120px, center;
}
</style>
