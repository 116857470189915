<template>
  <div>
    <Navigation chosenpage="eProcurement" @setDrawer="drawerSet" />
    <div id="formProcurement">
      <v-btn
        small
        tile
        elevation="0"
        color="transparent"
        class="ma-2 red--text"
        style="position:relative;border:1px solid black;"
        @click="$router.push('/e-procurement/procurement-form')"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-card flat class="form-pr-v-toolbar mx-auto">
        <v-row>
          <v-col cols="12" style="padding:0;">
            <v-toolbar flat style="border-radius:10px 10px 0 0;">
              <v-btn
                elevation="1"
                class="mx-2 elevation-4"
                dark
                color="cyan"
                style="position:relative; top:-10px; left:-10px; height:60px; width:60px;"
              >
                <v-icon large dark>
                  mdi-flag-variant
                </v-icon>
              </v-btn>
              <v-toolbar-title class="overline"
                ><p style="font-size:20px;">
                  Peserta Pengadaan
                </p>
              </v-toolbar-title>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-card
          class="mx-auto"
          outlined
          style="margin-top:10px;margin-bottom:10px;"
        >
          <DocNumber />
        </v-card>
        <v-card class="mx-auto" outlined style="margin-top:15px;">
          <ParticipantsTable :dTable="result" @reload="initDataTable" />
        </v-card>
      </v-card>
    </div>

    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapGetters } from 'vuex'
import Navigation from '../../../components/navigation/Navigation'
import Drawer from '../../../components/navigation/Drawer'
import ParticipantsTable from '../../../components/procurement/participants/participantsTable'
import DocNumber from '../../../components/procurement/participants/docNumber'
export default {
  data: () => ({
    proc: buildType.apiURL('proc'),
    drawer: false,
    paramAPI: {
      keyword: '',
      limit: 10,
      offset: 0,
      arrLength: 0
    },
    result: []
  }),
  components: { ParticipantsTable, DocNumber, Navigation, Drawer },
  computed: { ...mapGetters(['getProcListId']) },
  created() {
    this.initDataTable()
  },
  methods: {
    // ...mapActions([]),
    // ...mapMutations([])
    drawerSet(val) {
      this.drawer = val
    },
    initDataTable() {
      axios
        .get(
          `${this.proc}member/list?keyword=${this.paramAPI.keyword}&offset=${this.paramAPI.offset}&limit=${this.paramAPI.limit}&is_archived=&procurement_id=${this.getProcListId}`
        )
        .then(res => {
          console.log(res)
          this.paramAPI.arrLength = res.data.total_record
          this.result = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
#formProcurement {
  z-index: 2;
  padding: 20px 10px;

  .form-pr-v-toolbar {
    padding: 10px 15px 20px 15px;
    width: 95%;
    margin-top: 30px;
  }
  .form-pr-input {
    margin-top: 0px;
    border-radius: 3px;
    padding: 20px 30px 10px;
    width: 95%;
    min-height: 35vh;
    border-bottom: 2px solid #e0e0e0;
    background: white;
  }
  .form-pr-items-table {
    border-radius: 3px;
    padding: 20px 30px;
    width: 95%;
    margin-top: 0px;
    min-height: 50vh;
    background: white;
  }
}
</style>
