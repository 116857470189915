<template>
  <div
    style="position: fixed; top:10px; left:10px; bottom:10px; right:10px; background:rgba(200,200,200,0.5); z-index:2; display: flex; justify-content:center; align-items:center;"
  >
    <v-card :width="40 + '%'">
      <v-card-title>
        <v-toolbar dark height="50px" color="primary">
          <span class="title">Form Edit Pengalaman</span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row class="justify-content-center">
            <v-col cols="11" class="justify-content-center flex-column">
              <v-col cols="12" style="height:65px; padding:0 5px;">
                <v-text-field
                  v-model="form.name"
                  label="Nama pekerjaan"
                  required
                  type="text"
                  outlined
                  dense
                  style="margin-top:5px;"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 5px;">
                <v-text-field
                  v-model="form.type"
                  label="Bidang"
                  required
                  type="text"
                  outlined
                  dense
                  style="margin-top:5px;"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 5px;">
                <v-text-field
                  prepend-icon="mdi-map-marker"
                  v-model="form.location"
                  label="Lokasi"
                  required
                  type="text"
                  outlined
                  dense
                  style="margin-top:5px;"
                ></v-text-field>
              </v-col>
              <v-col cols="6" style="height:65px; padding:0 5px;">
                <v-autocomplete
                  prepend-icon="mdi-calendar-month"
                  label="Month"
                  v-model="form.month"
                  required
                  outlined
                  :items="getMonth"
                  item-text="name"
                  item-value="id"
                  return-id
                  dense
                  style="margin-top:5px;"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" style="height:65px; padding:0 5px;">
                <v-text-field
                  prepend-icon="mdi-calendar-month"
                  v-model="form.year"
                  label="Year"
                  required
                  min="1"
                  type="number"
                  outlined
                  dense
                  style="margin-top:5px;"
                ></v-text-field> </v-col
            ></v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" @click="closeForm" text>
          Close
        </v-btn>
        <v-btn color="blue darken-1" @click="saveForm" text>
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      v-if="getIsLoading === true"
      class="mx-auto"
      width="99%"
      height="260"
      style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Please wait</v-progress-circular
      >
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      form: {
        name: '',
        type: '',
        location: '',
        month: null,
        year: null
      },
      date: new Date().toISOString().substr(0, 10),
      menu: {
        menu4: false
      }
    }
  },
  created() {
    this.form = {
      name: this.getExpId.name,
      type: this.getExpId.type,
      location: this.getExpId.location,
      month: this.getExpId.month,
      year: this.getExpId.year
    }
  },
  computed: {
    ...mapGetters(['getExpId', 'getVendorId', 'getMonth', 'getIsLoading'])
  },
  methods: {
    ...mapActions(['editExperience', 'vendorExperienceList']),
    ...mapMutations(['setModalEditExp', 'setIsLoading']),
    closeForm() {
      this.setModalEditExp([false, null])
    },
    saveForm() {
      this.$swal
        .fire({
          title: 'Save now',
          icon: 'question',
          showCancelButton: true,
          showConfirmButton: true
        })
        .then(result => {
          if (result.value) {
            if (
              this.form.name === '' ||
              this.form.type === '' ||
              this.form.location === '' ||
              this.form.month === null ||
              this.form.year === null
            ) {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Invalid input, form can not be empty',
                showConfirmButton: true,
                timer: 5000
              })
            } else {
              this.setIsLoading(true)
              this.editExperience(this.form)
                .then(result => {
                  this.setIsLoading(false)
                  if (result.data.status_code !== '-99') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 5000
                    })
                    this.form = {
                      name: '',
                      type: '',
                      location: '',
                      month: null,
                      year: null
                    }
                    this.vendorExperienceList([this.getVendorId, '', 0, 10])
                    this.setModalEditExp([false, null])
                  } else {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 5000
                    })
                  }
                })
                .catch(error => {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error
                      ? 'Error: Please Contact an Admin'
                      : 'Error: Please Contact an Admin',
                    showConfirmButton: true,
                    timer: 5000
                  })
                })
            }
          }
        })
    }
  }
}
</script>
