<template>
  <div>
    <div
      style="display:flex; justify-content:space-between; align-items:center; margin-bottom:15px;"
    >
      <p style="margin:0; font-weight:bold; dont-size:12px;">
        Berikut list penyedia berdasarkan kategori barang dalam pengadaan
      </p>
      <div style="">
        <v-text-field
          @keyup.enter="searchEnter"
          v-model="param.keyword"
          style="display:flex; padding:0; height:42px; width:350px;"
          outlined
          append-icon="mdi-magnify"
          dense
          label="Search"
        >
        </v-text-field>
      </div>
    </div>
    <v-card
      v-for="(item, index) in vendorList"
      :key="index"
      class="mx-auto"
      outlined
      style="margin-bottom:15px;"
    >
      <v-list-item three-line>
        <!-- <div style="border:1px solid black; width:15%; height:150px;"> -->
        <v-list-item-avatar size="140" tile color="grey"></v-list-item-avatar>
        <!-- </div> -->
        <div style="width:20%; height:150px;">
          <v-list-item-content>
            <v-list-item-title class="headline mb-1 font-weight:bold;">
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              >{{ item.address !== '' ? item.address : '-' }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>{{
              item.city !== null ? item.city.name : '-'
            }}</v-list-item-subtitle>
            <v-list-item-subtitle>{{
              item.province !== null ? item.province.name : '-'
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </div>
        <div style="width:20%; height:150px; ">
          <v-list-item-content>
            <v-list-item-title class="headline mb-1" style="color:white"
              >..
            </v-list-item-title>
            <v-list-item-subtitle>
              <span style="font-size:16px;color:black; font-weight:bold;"
                >Email :</span
              >
              <span> {{ item.email !== '' ? item.email : '-' }}</span>
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              <span style="font-size:16px; color:black;font-weight:bold;"
                >Telp :</span
              >
              <span>
                {{
                  item.phone1 !== ''
                    ? item.phone1
                    : item.phone2 !== ''
                    ? item.phone2
                    : '-'
                }}</span
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </div>

        <div style="width:40%; height:150px;">
          <v-list-item-content>
            <v-list-item-title class="headline mb-1" style="color:white"
              >..
            </v-list-item-title>
            <v-list-item-subtitle>
              <p
                style="font-size:16px;color:black; font-weight:bold; margin:0;"
              >
                Proyek Terakhir :
              </p>
              <span>(Belum ada di api resultnya)</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </div>
        <div
          style="width:10%; height:150px; display:flex;justify-content:center; align-items:center;"
        >
          <!-- form pengiriman undangan -->
          <!-- <DeliveryForm /> -->
          <IntegrityPact :detail="item" @reload="loadData" />
        </div>
      </v-list-item>
    </v-card>
    <div class="text-center">
      <v-pagination
        v-model="param.page"
        total-visible="7"
        :length="Math.ceil(param.arrLength / param.limit) || 1"
        circle
      ></v-pagination>
    </div>
  </div>
</template>
<script>
// import DeliveryForm from './invitationDeliveryDialog'
import IntegrityPact from './integrityPactDialog'
// import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['vendorList', 'param'],
  data: () => ({}),
  components: {
    // DeliveryForm
    IntegrityPact
  },
  methods: {
    // ...mapActions([]),
    // ...mapMutations([]),
    searchEnter() {
      this.$emit('search')
    },
    loadData() {
      this.$emit('reload')
    }
  }
}
</script>
<style lang="scss" src=""></style>
