<template>
  <div style="padding:0;">
    <div>
      <Navigation chosenpage="Product Category" @setDrawer="drawerSet" />
    </div>

    <v-data-table
      v-if="permission.read_perm === 1"
      :headers="headers"
      :items="getCategoryProductList"
      :loading="loading"
      loading-text="Please wait"
      class="elevation-6 mx-auto"
      style="width:90%; margin-top: 20px; margin-bottom:20px;"
      dense
      :items-per-page="itemsPerPage"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar
          ><v-icon style="margin-right: 10px;">mdi-account-details</v-icon>
          <v-toolbar-title>Category List</v-toolbar-title>
          <v-divider class="mx-6" inset vertical></v-divider>
          <v-text-field
            v-model="invokeSrc"
            label="Search here"
            type="search"
            outlined
            dense
            style="position: relative; top:15px; width:10px;"
          ></v-text-field>
          <div
            v-if="permission.create_perm === 1"
            style="position:absolute; right:133px; "
          >
            <CategoryImport :perm="permission" />
          </div>
          <div
            class="d-flex justify-center align-center"
            style="position:absolute; right:270px; "
          >
            <v-checkbox
              v-model="checkbox"
              style="height:25px; width:25px;"
            ></v-checkbox>
            <div v-if="!checkbox" class="d-flex justify-center align-center">
              <div style="" v-if="categorySelection.length === 0">
                <v-btn
                  small
                  tile
                  color="#e0e0e0"
                  class="ma-2 black--text"
                  style="width:100px; position:relative;"
                  @click="exportAll"
                >
                  Export All
                </v-btn>
              </div>
              <div v-else>
                <v-btn
                  small
                  tile
                  color="#e0e0e0"
                  class="ma-2 black--text"
                  style="position:relative;"
                  @click="exportFile"
                >
                  Export Now

                  <v-icon small right dark>
                    mdi-file-export
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div v-else>
              <v-btn
                small
                tile
                color="#e0e0e0"
                class="ma-2 black--text"
                style="width:145px; position:relative;"
                @click="exportDropdown"
              >
                Export Dropdown
              </v-btn>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-card
            v-if="permission.create_perm === 1"
            style="width:110px; position: absolute; right:-10px;"
          >
            <v-btn
              color="pink"
              fab
              dark
              absolute
              small
              @click="categoryManageEdit(null, { act: 'add' })"
              style="z-index:1; right:-15px; top:-10px;"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-toolbar-title
              style="font-size:12px; color: black; text-align:left; padding:3px 10px 0 5px;"
            >
              New Category
            </v-toolbar-title></v-card
          >
        </v-toolbar>
      </template>
      <template v-slot:[`item.compare`]="{ item }">
        <td>
          <v-checkbox v-model="categorySelection" :value="item"></v-checkbox>
        </td>
      </template>
      <template v-slot:[`item.no`]="{ item }">
        <div>
          {{ item.no + offset * itemsPerPage }}
        </div>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div>
          {{ item.name }}
        </div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <td>
          {{ convertDate(item.created_at) }}
        </td>
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <td>
          {{ convertDate(item.updated_at) }}
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <!-- <v-btn
            v-if="permission.read_perm === 1"
            color="cyan"
            class="ma-1 white--text"
            small
            @click="categoryManageEdit(item, { act: 'edit' })"
          > -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                style="margin-right:5px;"
                color="cyan"
                v-if="permission.read_perm === 1"
                @click="categoryManageEdit(item, { act: 'edit' })"
                v-bind="attrs"
                v-on="on"
                small
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <!-- </v-btn> -->
          <!-- <v-btn
            v-if="permission.delete_perm === 1"
            color="error"
            class="ma-1 white--text"
            small
            @click="categoryManageDelete(item)"
          > -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="permission.delete_perm === 1"
                @click="categoryManageDelete(item)"
                style="margin-right:5px;"
                color="error"
                v-bind="attrs"
                v-on="on"
                small
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <!-- </v-btn> -->
        </div>
      </template>

      <template v-slot:footer>
        <v-toolbar>
          <v-spacer></v-spacer>
          <v-pagination
            style="margin-left:auto;"
            v-model="page"
            :length="Math.ceil(getTotalCategory / itemsPerPage) || 1"
            :total-visible="7"
          ></v-pagination>
          <v-spacer></v-spacer>
          <div
            style="padding:0; margin:0;display: flex; flex-direction:rows; justify-content:center; align-items:center;"
          >
            <v-toolbar-title class="caption">Rows per page:</v-toolbar-title>
            <v-select
              style="position: relative; margin-left:10px;height:28px; width:50px; rightt:20px; font-size:12px;"
              v-model="itemsPerPage"
              :items="[5, 10, 15, 20, 100]"
              dense
            ></v-select>
          </div>
        </v-toolbar>
      </template>
    </v-data-table>

    <v-card
      flat
      height="70vh"
      v-else
      color="transparent"
      style="display:flex; justify-content:center; align-items:center;"
    >
      <v-toolbar-title class="overline">
        You Can't Access This Page</v-toolbar-title
      >
    </v-card>

    <div>
      <CategoryProductEdit v-if="!getCategoryEditStat" :perm="permission" />
    </div>
    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>

    <v-card
      v-if="getIsLoading === true"
      class="mx-auto"
      width="99%"
      height="260"
      style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Please wait</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../services/buildType'
import XLSX from 'xlsx'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Navigation from '../../components/navigation/Navigation'
import Drawer from '../../components/navigation/Drawer'
import CategoryProductEdit from '../../components/product_category/CategoryProductEdit'
import CategoryImport from '../../components/product_category/CategoryImport'
export default {
  name: 'CategoryProduct',
  data: () => ({
    procAPI: buildType.apiURL('proc'),
    permission: {},
    menu_key: 19,
    application: {
      id: 2,
      name: 'eProcurement'
    },
    drawer: false,
    page: 1,
    offset: 0,
    loading: false,
    itemsPerPage: 10,
    searchProduct: '',
    invokeSrc: '',
    items: [
      {
        title: 'User Manage',
        path: '/user-manage',
        icon: 'mdi-account-edit-outline'
      },
      {
        title: 'User Access',
        path: '/user-access',
        icon: 'mdi-badge-account-outline'
      },
      {
        title: 'Vendor',
        path: '/master-vendor',
        icon: 'mdi-handshake-outline'
      },
      { title: 'Product', path: '/master-product', icon: 'mdi-shape-outline' },
      {
        title: 'Product Category',
        path: '/product-category',
        icon: 'mdi-file-tree-outline'
      }
    ],
    headers: [
      {
        text: '',
        align: 'left',
        sortable: false,
        value: 'compare',
        width: '1%'
      },
      {
        text: 'No',
        align: 'center',
        sortable: false,
        value: 'no',
        width: '5%'
      },
      { text: 'Nama', value: 'name', width: '25%', align: 'center' },
      {
        text: 'Created at',
        sortable: false,
        value: 'created_at',
        width: '10%',
        align: 'center'
      },
      {
        text: 'Created by',
        sortable: false,
        value: 'created_by',
        width: '10%',
        align: 'center'
      },
      {
        text: 'Updated at',
        sortable: false,
        value: 'updated_at',
        width: '10%',
        align: 'center'
      },
      {
        text: 'Updated by',
        sortable: false,
        value: 'updated_by',
        width: '10%',
        align: 'center'
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '10%'
      }
    ],
    categorySelection: [],
    checkbox: false
  }),
  watch: {
    invokeSrc() {
      this.searchProduct = this.invokeSrc
      this.loadDataCategory()
    },
    page() {
      this.offset = this.page - 1
      this.loadDataCategory()
    },
    itemsPerPage() {
      this.page = 1
      this.offset = 0
      this.loadDataCategory()
    },
    categorySelection() {
      console.log(this.categorySelection)
    }
  },
  components: {
    CategoryProductEdit,
    CategoryImport,
    Navigation,
    Drawer
  },
  created() {
    this.setIsLoading(true)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            setTimeout(() => {
              this.loadDataCategory()
              this.setIsLoading(false)
            }, 1500)
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              setTimeout(() => {
                this.loadDataCategory()
                this.setIsLoading(false)
              }, 1500)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        setTimeout(() => {
          this.setIsLoading(false)
        }, 1500)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getCategoryEditStat',
      'getCategoryProductList',
      'getCategoryEditAct',
      'getTotalCategory',
      'getIsLoading'
    ])
  },
  methods: {
    ...mapActions([
      'logout',
      'menu_access',
      'adminCategoryList',
      'initCategory',
      'adminCategoryDelete'
    ]),
    ...mapMutations([
      'categoryEdit',
      'setCategoryData',
      'setLoadDataCategory',
      'setIsLoading'
    ]),
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            // title:
            //   pModalType === 'success'
            //     ? '<strong>Success</strong>'
            //     : `<strong>${pModalType}</strong>`,
            // icon: 'question',
            // html: pStatusMsg,
            // showConfirmButton: true,
            // showCancelButton: true,
            icon: pModalType,
            html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    drawerSet(val) {
      this.drawer = val
    },
    loadDataCategory() {
      this.adminCategoryList([
        this.searchProduct,
        this.offset * this.itemsPerPage,
        this.itemsPerPage
      ])
        .then(results => {
          this.initCategory(results)
          this.loading = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    categoryManageEdit(item, param) {
      this.categoryEdit([false, item, param])

      if (this.getCategoryEditAct === 'edit') {
        console.log(item)
        this.setCategoryData(item)
      }
    },

    categoryManageDelete(item) {
      this.$swal
        .fire({
          title: 'This data will be deleted permanently. Are you sure?',
          showCancelButton: true,
          icon: 'warning'
        })
        .then(result => {
          if (result.value) {
            this.adminCategoryDelete(item)
              .then(response => {
                this.invokeSrc = ''
                this.adminCategoryList(['', 0, this.itemsPerPage])
                  .then(results => {
                    this.initCategory(results)
                  })
                  .catch(error => {
                    console.log(error)
                  })
                this.showMsgDialog('success', 'Product has been deleted')
              })
              .catch(error => {
                this.showMsgDialog(
                  error ? 'error' : 'error',
                  'Something went wrong please contact an admin'
                )
              })
          }
        })
    },

    categoryManageDetail(item) {
      this.showMsgDialog('info', 'Cooming Soon')
    },

    home() {
      this.$router.push('/')
    },
    exportAll() {
      axios
        .get(
          `${this.procAPI}master/product_category/list?offset=0&limit=1000&keyword=&order_by=name&order_type=desc`
        )
        .then(res => {
          console.log(res)
          this.categorySelection = []
          const response = res.data.data
          if (response !== undefined) {
            for (let i = 0; i < response.length; i++) {
              this.categorySelection.push({
                id: response[i].id,
                name: response[i].name,
                created_at: response[i].created_at,
                updated_at: response[i].updated_at
              })
            }
          } else {
            this.categorySelection = []
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    exportFile() {
      this.showMsgDialog('question', 'Sertakan ID').then(res => {
        if (res.isConfirmed) {
          this.exportNow(1)
        } else if (res.dismiss === 'cancel') {
          this.exportNow(0)
        } else if (res.dismiss === 'backdrop') {
          this.categorySelection = []
        }
      })
    },
    exportNow(num) {
      const arrData = []
      for (let i = 0; i < this.categorySelection.length; i++) {
        const param = {
          name: this.categorySelection[i].name,
          created_at: this.convertDate(this.categorySelection[i].created_at),
          updated_at: this.convertDate(this.categorySelection[i].updated_at)
        }
        if (num === 1) {
          Object.assign(param, {
            id: this.categorySelection[i].id
          })
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },
    downloadExcell(arrData) {
      const data = XLSX.utils.json_to_sheet(arrData)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'product_category.xlsx')

      setTimeout(() => {
        this.categorySelection = []
      }, 1000)
    },
    exportDropdown() {
      axios
        .get(`${this.procAPI}master/product_category/drop_down?keyword=`)
        .then(res => {
          const response = res.data.data
          this.showMsgDialog('question', 'Sertakan ID').then(res => {
            if (res.isConfirmed) {
              this.exportNow2(1, response)
            } else if (res.dismiss === 'cancel') {
              this.exportNow2(0, response)
            } else if (res.dismiss === 'backdrop') {
              this.categorySelection = []
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    exportNow2(num, data) {
      const arrData = []
      for (let i = 0; i < data.length; i++) {
        const param = {
          name: data[i].name
        }
        if (num === 1) {
          Object.assign(param, {
            id: data[i].id
          })
        }
        arrData.push(param)
      }
      this.downloadExcell(arrData)
    },

    convertDate(val) {
      const date = new Date(val)
      const str = date.toLocaleString()

      const day = str.slice(0, str.indexOf('/'))
      const month = str.slice(str.indexOf('/') + 1, str.lastIndexOf('/'))
      const year = str.slice(str.lastIndexOf('/') + 1, str.lastIndexOf(','))
      return day + '-' + month + '-' + year
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/productManage.scss"></style>
