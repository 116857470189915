<template>
  <div>
    <Navigation chosenpage="eProcurement" @setDrawer="drawerSet" />
    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>
    <!-- <div class="container"> -->

    <v-card class="container" style="margin-top:40px;">
      <v-row>
        <v-col cols="12">
          <v-toolbar flat style="height: 60px; border-radius: 10px 10px 0 0;">
            <v-toolbar-title>List FPB</v-toolbar-title>
            <v-divider class="mx-6" inset vertical></v-divider>
            <div
              style="display:flex; justify-content:center; align-items:center;"
            >
              <v-text-field
                v-model="paramAPI.keyword"
                @keyup.enter="searchEnter"
                label="Search here"
                type="search"
                outlined
                dense
                append-icon="mdi-magnify"
                style="position: relative; top: 15px; width: 410px"
              ></v-text-field>

              <div
                style="display:flex;justify-content:space-evenly; align-items:center; width:300px;height:35px;"
              >
                <p style="margin:0; font-weight:bold;">Sort by :</p>
                <p class="vendorSortIcon" @click="vRequest">
                  no. request:
                  <v-icon small style=" box-shadow: 1px 1px 5px black;">{{
                    vRequestIcon
                  }}</v-icon>
                </p>
                <p class="vendorSortIcon" @click="vName">
                  name:
                  <v-icon small style=" box-shadow: 1px 1px 5px black;">{{
                    vNameIcon
                  }}</v-icon>
                </p>
              </div>
            </div>
            <div style="width: 120px;margin-left:auto;">
              <v-select
                label="Status"
                style="
                        position: relative;
                        top: 15px;
                        margin-left: 10px;
                        font-size: 12px;
                      "
                v-model="paramAPI.status"
                :items="[
                  { id: '', name: 'All' },
                  { id: 0, name: 'Draft' },
                  { id: 1, name: 'Submited' },
                  { id: -1, name: 'Canceled' }
                ]"
                item-text="name"
                item-value="id"
                outlined
                return-id
                dense
              ></v-select>
            </div>
          </v-toolbar>
        </v-col>

        <v-col cols="12">
          <v-data-table
            class="elevation-1"
            style="cursor:pointer;"
            :headers="headers"
            :items="result"
            :items-per-page="paramAPI.limit"
            @click:row="rowClick"
            hide-default-footer
          >
            <template v-slot:[`item.employee`]="{ item }">
              <td>
                {{ item.employee.name }}
              </td>
            </template>
            <template v-slot:[`item.department`]="{ item }">
              <td>
                {{ item.department.name }}
              </td>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <td :style="`color: ${colorStatus(item.status)};`">
                {{ status(item.status) }}
              </td>
            </template>
          </v-data-table>
          <div style="display:flex;">
            <v-pagination
              style="margin-right:auto; "
              v-model="paramAPI.page"
              total-visible="7"
              :length="Math.ceil(paramAPI.length / paramAPI.limit) || 1"
              circle
            ></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <!-- <div>
      <DetailVendor :result="detail" :rowData="selected" />
    </div> -->

    <!-- </div> -->
  </div>
</template>
<script>
import Navigation from '../../components/navigation/Navigation'
import Drawer from '../../components/navigation/Drawer'
import axios from 'axios'
import buildType from '../../services/buildType'
export default {
  components: { Navigation, Drawer },
  data: () => ({
    drawer: false,
    proc: buildType.apiURL('proc'),
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      status: '',
      length: 0,
      sortBy: 'request_no',
      sortType: 'desc'
    },
    headers: [
      {
        text: 'No. Request',
        value: 'request_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'PIC',
        value: 'employee',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department',
        value: 'department',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Request',
        value: 'requested_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    detail: null,
    selected: null,
    vRequestIcon: 'mdi-arrow-up',
    vNameIcon: 'mdi-arrow-up'
  }),
  watch: {
    'paramAPI.page'() {
      this.paramAPI.offset = this.paramAPI.page - 1
      this.initDataTable()
    },
    'paramAPI.sortBy'() {
      this.initDataTable()
    },
    'paramAPI.sortType'() {
      this.initDataTable()
    }
  },
  created() {
    this.initDataTable()
  },
  methods: {
    drawerSet(val) {
      this.drawer = val
    },
    rowClick(pValue) {
      // axios
      //   .get(`${this.proc}fpb/detail/${pValue.id}`)
      //   .then(res => {
      //     console.log(res.data.data)
      //     this.detail = res.data.data
      this.$store.commit('setFpbId', pValue.id)
      this.$router.push('/fpb-list/fpb')
      // })
      // .catch(err => {
      //   console.log(err)
      // })
    },
    initDataTable() {
      axios
        .get(
          `${this.proc}fpb/list?keyword=${this.paramAPI.keyword}&offset=${this
            .paramAPI.offset * this.paramAPI.limit}&limit=${
            this.paramAPI.limit
          }&order_by=${this.paramAPI.sortBy}&order_type=${
            this.paramAPI.sortType
          }`
        )
        .then(res => {
          console.log(res)
          this.paramAPI.length = res.data.total_record
          this.result = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    searchEnter() {
      this.initDataTable()
    },
    vRequest() {
      this.paramAPI.sortBy = 'request_no'
      if (this.vRequestIcon === 'mdi-arrow-up') {
        this.vRequestIcon = 'mdi-arrow-down'
        this.paramAPI.sortType = 'desc'
      } else {
        this.vRequestIcon = 'mdi-arrow-up'
        this.paramAPI.sortType = 'asc'
      }
    },
    vName() {
      this.paramAPI.sortBy = 'employee_name'
      if (this.vNameIcon === 'mdi-arrow-up') {
        this.vNameIcon = 'mdi-arrow-down'
        this.paramAPI.sortType = 'desc'
      } else {
        this.vNameIcon = 'mdi-arrow-up'
        this.paramAPI.sortType = 'asc'
      }
    },
    status(val) {
      switch (val) {
        case -1:
          return 'Canceled'
        case 0:
          return 'Draft'
        case 1:
          return 'Submited'
        case 2:
          return 'Confirmed'
      }
    },
    colorStatus(val) {
      switch (val) {
        case -1:
          return 'red'
        case 0:
          return 'orange'
        case 1:
          return 'green'
        case 2:
          return 'green'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vendorSortIcon {
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.486);
  padding: 2px 5px;
  border-radius: 3px;
  cursor: pointer;
}
</style>
