<template>
  <div
    style="position: fixed; top:0; left:0; bottom:0; right:0; background:rgba(255,255,255,0.8); z-index:2; display: flex; justify-content:center; align-items:center;"
  >
    <v-card
      elevation="6"
      style="osition:relative; top:0; max-height:98vh; overflow:auto;"
      :width="getVdrCatalogueModalStat[1] === 'supply' ? 45 + '%' : 50 + '%'"
    >
      <div v-if="perm.create_perm === 1">
        <v-card-title>
          <v-toolbar dark color="primary">
            <span
              v-if="getVdrCatalogueModalStat[1] === 'supply'"
              class="headline"
              >Form Add & Edit Penawaran</span
            >
            <span
              v-if="getVdrCatalogueModalStat[1] === 'rawspec'"
              class="headline"
              >Form Add & Edit Spesifikasi Bahan Baku</span
            >
            <span
              v-if="getVdrCatalogueModalStat[1] === 'genspec'"
              class="headline"
              >Form Add & Edit Spesifikasi Umum</span
            >
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row
              v-if="getVdrCatalogueModalStat[1] === 'supply'"
              class="justify-content-center"
            >
              <v-col cols="10" style="height:65px; padding:0 5px;">
                <v-autocomplete
                  @change="supplyUnitName"
                  v-model="form_supply.uom_name"
                  prepend-icon="mdi-file-tree-outline"
                  :items="getUnitDropdown"
                  label="Unit"
                  item-text="name"
                  item-value="id"
                  outlined
                  return-id
                  required
                  :clearable="
                    (getQuotationAct === 'add' && perm.create_perm !== 0) ||
                      (getQuotationAct === 'update' && perm.update_perm === 1)
                  "
                  :readonly="
                    (getQuotationAct === 'add' && perm.create_perm === 0) ||
                      (getQuotationAct === 'update' && perm.update_perm !== 1)
                  "
                  :rules="rules.unitRules"
                  dense
                  style="margin-top:5px;"
                ></v-autocomplete>
              </v-col>

              <v-col cols="10" style="height:65px; padding:0 5px;">
                <v-text-field
                  v-model="form_supply.price_per_unit"
                  prepend-icon="mdi-cash-usd-outline"
                  label="Harga Per Unit"
                  required
                  :rules="rules.priceRules"
                  :readonly="
                    (getQuotationAct === 'add' && perm.create_perm === 0) ||
                      (getQuotationAct === 'update' && perm.update_perm !== 1)
                  "
                  type="text"
                  outlined
                  dense
                  style="margin-top:5px;"
                ></v-text-field>
              </v-col>
              <v-col cols="10" style="height:65px; padding:0 10px;">
                <v-row>
                  <v-col cols="12">
                    <v-menu
                      v-model="menu.menu4"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :disabled="
                        (getQuotationAct === 'add' && perm.create_perm === 0) ||
                          (getQuotationAct === 'update' &&
                            perm.update_perm !== 1)
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form_supply.period_start"
                          label="Periode Start"
                          prepend-icon="mdi-calendar"
                          readonly
                          :rules="rules.periodStartRules"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form_supply.period_start"
                        @input="menu.menu4 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="10" style="height:65px; padding:0 10px;">
                <v-row>
                  <v-col cols="12">
                    <v-menu
                      v-model="menu.menu5"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      :disabled="
                        (getQuotationAct === 'add' && perm.create_perm === 0) ||
                          (getQuotationAct === 'update' &&
                            perm.update_perm !== 1)
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form_supply.period_end"
                          label="Periode End"
                          prepend-icon="mdi-calendar"
                          readonly
                          :rules="rules.periodEndRules"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="form_supply.period_end"
                        @input="menu.menu5 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="10" style="height:65px; padding:0 10px;">
                <div
                  style="display:flex; justify-content:flex-start; align-items:flex-start; padding:0; margin:0; height:40px; position:relative;"
                >
                  <v-file-input
                    @change="supplyUpload"
                    :clearable="perm.update_perm === 1"
                    style="margin:0"
                    :label="
                      form_supply.file_quotation || 'Upload File Pendukung'
                    "
                    outlined
                    :disabled="
                      (getQuotationAct === 'add' && perm.create_perm === 0) ||
                        (getQuotationAct === 'update' && perm.update_perm !== 1)
                    "
                    dense
                    required
                    :rules="rules.fileRules"
                  ></v-file-input>
                  <!-- <button
                    :disabled="
                      (getQuotationAct === 'add' && perm.create_perm === 0) ||
                        (getQuotationAct === 'update' && perm.update_perm !== 1)
                    "
                    @click="submitSupplyUpload"
                    class="formAddSupply"
                    style="font-size:8px; border:1px solid rgba(0,0,0,0.5); width:30px; height:100%; border-radius:5px; display:flex; justify-content:center; align-items:center;"
                  >
                    Upload
                  </button> -->
                </div>
              </v-col>
            </v-row>

            <v-row
              v-if="getVdrCatalogueModalStat[1] === 'rawspec'"
              class="justify-content-center"
            >
              <v-col cols="6" style="height:65px; padding:0 5px;">
                <v-autocomplete
                  v-model="form_raw_spesification.spesification_category_id"
                  prepend-icon="mdi-shape-outline"
                  @change="initSpecAttributeDropdown"
                  :items="getSpecCategoryDropdown"
                  outlined
                  dense
                  label="Kategori"
                  placeholder="Pilih Kategori Spesifikasi"
                  style="margin-top:10px;"
                  item-text="name"
                  item-value="id"
                  :clearable="
                    (getSpecificationAct === 'add' && perm.create_perm !== 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm === 1)
                  "
                  :readonly="
                    (getSpecificationAct === 'add' && perm.create_perm === 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm !== 1)
                  "
                  return-id
                ></v-autocomplete>
              </v-col>

              <v-col cols="6" style="height:65px; padding:0 5px;">
                <v-autocomplete
                  v-model="form_raw_spesification.spesification_attribute_id"
                  prepend-icon="mdi-transit-connection-horizontal"
                  :items="getSpecAttributeDropdown"
                  outlined
                  dense
                  label="Attribute"
                  placeholder="Pilih Attribute"
                  style="margin-top:10px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  :clearable="
                    (getSpecificationAct === 'add' && perm.create_perm !== 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm === 1)
                  "
                  :readonly="
                    (getSpecificationAct === 'add' && perm.create_perm === 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm !== 1)
                  "
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="6"
                style="height:65px; padding:0 5px; margin-right: auto;"
              >
                <v-autocomplete
                  v-model="form_raw_spesification.unit_id"
                  prepend-icon="mdi-file-tree-outline"
                  :items="getUnitDropdown"
                  outlined
                  dense
                  label="Unit"
                  placeholder="Pilih Unit"
                  style="margin-top:10px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  :clearable="
                    (getSpecificationAct === 'add' && perm.create_perm !== 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm === 1)
                  "
                  :readonly="
                    (getSpecificationAct === 'add' && perm.create_perm === 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm !== 1)
                  "
                ></v-autocomplete>
              </v-col>

              <v-col
                cols="6"
                style="height:65px; padding:0 5px; margin-right: auto;"
              >
                <v-autocomplete
                  v-model="form_raw_spesification.criteria"
                  prepend-icon="mdi-view-list-outline"
                  :items="[
                    { id: 1, name: 'Major' },
                    { id: 2, name: 'Kritikal' }
                  ]"
                  outlined
                  dense
                  label="Kriteria"
                  placeholder="Pilih Kriteria"
                  style="margin-top:10px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  :clearable="
                    (getSpecificationAct === 'add' && perm.create_perm !== 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm === 1)
                  "
                  :readonly="
                    (getSpecificationAct === 'add' && perm.create_perm === 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm !== 1)
                  "
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" style="height:65px; padding:0 5px;">
                <v-text-field
                  v-model="form_raw_spesification.analysis_method"
                  prepend-icon="mdi-toy-brick-search-outline"
                  label="Metode Analisa"
                  required
                  type="text"
                  outlined
                  dense
                  style="margin-top:5px;"
                  :clearable="
                    (getSpecificationAct === 'add' && perm.create_perm !== 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm === 1)
                  "
                  :readonly="
                    (getSpecificationAct === 'add' && perm.create_perm === 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm !== 1)
                  "
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                style="min-height:75px; padding:0 5px; position:relative; "
              >
                <v-textarea
                  v-model="form_raw_spesification.standard"
                  prepend-icon="mdi-standard-definition"
                  label="Standard"
                  required
                  type="text"
                  outlined
                  :clearable="
                    (getSpecificationAct === 'add' && perm.create_perm !== 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm === 1)
                  "
                  :readonly="
                    (getSpecificationAct === 'add' && perm.create_perm === 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm !== 1)
                  "
                  height="80"
                  style="margin-top:5px; "
                ></v-textarea>
              </v-col>

              <v-col
                cols="12"
                style="padding:0; border-radius:5px; background: rgba(200,200,10,0.3)"
              >
                <v-card-title cols="12">
                  <v-icon>mdi-waveform</v-icon>
                  <span style="font-size:20px;">
                    Minimum Frequency
                  </span>
                </v-card-title>
                <v-col cols="11" style="height:65px; padding:0; margin:0 auto;">
                  <v-text-field
                    v-model="form_raw_spesification.min_frequency_supplier"
                    label="Suplier"
                    required
                    type="text"
                    outlined
                    dense
                    style="margin:0px;"
                    :clearable="
                      (getSpecificationAct === 'add' &&
                        perm.create_perm !== 0) ||
                        (getSpecificationAct === 'update' &&
                          perm.update_perm === 1)
                    "
                    :readonly="
                      (getSpecificationAct === 'add' &&
                        perm.create_perm === 0) ||
                        (getSpecificationAct === 'update' &&
                          perm.update_perm !== 1)
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="11" style="height:65px; padding:0; margin:0 auto;">
                  <v-text-field
                    v-model="form_raw_spesification.min_frequency_sanqua"
                    label="Sanqua"
                    required
                    type="text"
                    outlined
                    dense
                    style="margin:0px;"
                    :clearable="
                      (getSpecificationAct === 'add' &&
                        perm.create_perm !== 0) ||
                        (getSpecificationAct === 'update' &&
                          perm.update_perm === 1)
                    "
                    :readonly="
                      (getSpecificationAct === 'add' &&
                        perm.create_perm === 0) ||
                        (getSpecificationAct === 'update' &&
                          perm.update_perm !== 1)
                    "
                  ></v-text-field>
                </v-col>
              </v-col>
            </v-row>

            <v-row
              v-if="getVdrCatalogueModalStat[1] === 'genspec'"
              class="justify-content-center"
            >
              <v-col cols="6" style="height:65px; padding:0 5px;">
                <v-autocomplete
                  v-model="form_gen_spesification.spesification_category_id"
                  prepend-icon="mdi-shape-outline"
                  :items="getSpecCategoryDropdown"
                  @change="initSpecAttributeDropdown"
                  outlined
                  dense
                  label="Kategori"
                  placeholder="Pilih Kategori Spesifikasi"
                  style="margin-top:10px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  :clearable="
                    (getSpecificationAct === 'add' && perm.create_perm !== 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm === 1)
                  "
                  :readonly="
                    (getSpecificationAct === 'add' && perm.create_perm === 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm !== 1)
                  "
                ></v-autocomplete>
              </v-col>

              <v-col cols="6" style="height:65px; padding:0 5px;">
                <v-autocomplete
                  v-model="form_gen_spesification.spesification_attribute_id"
                  prepend-icon="mdi-transit-connection-horizontal"
                  :items="getSpecAttributeDropdown"
                  outlined
                  dense
                  label="Attribute"
                  placeholder="Pilih Attribute"
                  style="margin-top:10px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  :clearable="
                    (getSpecificationAct === 'add' && perm.create_perm !== 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm === 1)
                  "
                  :readonly="
                    (getSpecificationAct === 'add' && perm.create_perm === 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm !== 1)
                  "
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" style="min-height:165px; padding:0 5px;">
                <v-textarea
                  v-model="form_gen_spesification.description"
                  label="Deskripsi"
                  outlined
                  clear-icon="mdi-close-circle"
                  style="margin:0px;"
                  :clearable="
                    (getSpecificationAct === 'add' && perm.create_perm !== 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm === 1)
                  "
                  :readonly="
                    (getSpecificationAct === 'add' && perm.create_perm === 0) ||
                      (getSpecificationAct === 'update' &&
                        perm.update_perm !== 1)
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </div>
      <v-card
        flat
        height="45vh"
        v-else
        style="display:flex; justify-content:center; align-items:center;"
      >
        <v-toolbar-title class="overline">
          You Can't Access This Page</v-toolbar-title
        >
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" @click="closeForm" text>
          Close
        </v-btn>
        <v-btn
          v-if="getVdrCatalogueModalStat[1] === 'supply'"
          color="blue darken-1"
          :disabled="
            (getQuotationAct === 'add' && perm.create_perm === 0) ||
              (getQuotationAct === 'update' && perm.update_perm !== 1)
          "
          text
          @click="submitSupply"
        >
          Save
        </v-btn>
        <v-btn
          v-if="getVdrCatalogueModalStat[1] === 'rawspec'"
          color="blue darken-1"
          text
          :disabled="
            (getSpecificationAct === 'add' && perm.create_perm === 0) ||
              (getSpecificationAct === 'update' && perm.update_perm !== 1)
          "
          @click="submitRawSpec"
        >
          Save
        </v-btn>
        <v-btn
          v-if="getVdrCatalogueModalStat[1] === 'genspec'"
          color="blue darken-1"
          text
          @click="submitGenSpec"
          :disabled="
            (getSpecificationAct === 'add' && perm.create_perm === 0) ||
              (getSpecificationAct === 'update' && perm.update_perm !== 1)
          "
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card
      v-if="getIsLoading === true"
      class="mx-auto"
      width="99%"
      height="260"
      style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Please wait</v-progress-circular
      >
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import buildType from '../../services/buildType'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['perm'],
  data() {
    return {
      procAPI: buildType.apiURL('proc'),
      specCategoryId: null,
      form_supply: {
        uom_id: null,
        uom_name: '',
        price_per_unit: null,
        file_quotation: '-',
        period_start: '',
        period_end: ''
      },
      form_raw_spesification: {
        spesification_category_id: null,
        spesification_attribute_id: null,
        description: '',
        spesification_type: null,
        standard: '',
        unit_id: null,
        criteria: null,
        analysis_method: '',
        min_frequency_supplier: '',
        min_frequency_sanqua: ''
      },
      form_gen_spesification: {
        spesification_category_id: null,
        spesification_attribute_id: null,
        description: '',
        spesification_type: null,
        standard: '',
        unit_id: null,
        criteria: null,
        analysis_method: '',
        min_frequency_supplier: '',
        min_frequency_sanqua: ''
      },
      file: {
        supplyUpload: null
      },
      date: new Date().toISOString().substr(0, 10),
      menu: {
        menu4: false,
        menu5: false
      },

      rules: {
        priceRules: [
          v => !!v || 'Price Per Unit is required',
          v => (v && v !== null) || 'Price is required'
        ],
        unitRules: [v => !!v || 'Unit Name is required'],
        periodStartRules: [v => !!v || 'Period start is required'],
        periodEndRules: [v => !!v || 'Period end is required'],
        fileRules: [v => !!v || 'File support is required']
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUnitDropdown',
      'getVdrCatalogueModalStat',
      'getUnitDropdown',
      'getQuotationDetail',
      'getQuotationAct',
      'getSpecificationAct',
      'getRawSpecDetail',
      'getSpecCategoryDropdown',
      'getSpecAttributeDropdown',
      'getGenSpecDetail',
      'getSpecType',
      'getIsLoading'
    ])
  },
  created() {
    this.dropDown()
    this.loadPage()
  },
  methods: {
    ...mapActions([
      'manageQuotation',
      'uploadQuotationFile',
      'quotationList',
      'specificationList',
      'manageSpesification',
      'manageGenSpesification',
      'spesificationCategoryDropdown',
      'spesificationAttributeDropdown'
    ]),
    ...mapMutations([
      'setVendorCatalogueModal',
      'setQuotationForm',
      'setSpesificationForm',
      'setGenSpesificationForm',
      'setIsLoading'
    ]),
    loadPage() {
      if (
        this.getQuotationAct === 'update' &&
        this.getVdrCatalogueModalStat[1] === 'supply'
      ) {
        this.form_supply = {
          uom_id: this.getQuotationDetail.unit_id,
          uom_name: this.getQuotationDetail.unit_id,
          price_per_unit: this.getQuotationDetail.unitPrice,
          file_quotation: this.getQuotationDetail.fileSupport,
          period_start: this.getQuotationDetail.period_start,
          period_end: this.getQuotationDetail.period_end
        }
      }
      if (this.getSpecificationAct === 'update') {
        if (
          this.getSpecType === 1 &&
          this.getVdrCatalogueModalStat[1] === 'rawspec'
        ) {
          this.setIsLoading(true)
          axios
            .get(
              `${this.procAPI}vendor/catalogue_spesification/detail/${this.getRawSpecDetail.id}`
            )
            .then(response => {
              this.form_raw_spesification = {
                spesification_category_id: this.getRawSpecDetail
                  .spesification_category_id,
                spesification_attribute_id: this.getRawSpecDetail
                  .spesification_attribute_id,
                description: this.getRawSpecDetail.description,
                spesification_type: null,
                standard: this.getRawSpecDetail.standard,
                unit_id: this.getRawSpecDetail.unit_id,
                criteria: this.getRawSpecDetail.criteria,
                analysis_method: response.data.data.analysis_method,
                min_frequency_supplier:
                  response.data.data.min_frequency_supplier,
                min_frequency_sanqua: response.data.data.min_frequency_sanqua
              }
              this.setIsLoading(false)
            })
            .catch(error => {
              this.setIsLoading(false)
              console.log(error)
            })
        } else if (
          this.getSpecType === 2 &&
          this.getVdrCatalogueModalStat[1] === 'genspec'
        ) {
          console.log(this.getGenSpecDetail)
          this.form_gen_spesification = {
            spesification_category_id:
              this.getGenSpecDetail.specCategoryDetail !== null
                ? this.getGenSpecDetail.specCategoryDetail.id
                : null,
            spesification_attribute_id:
              this.getGenSpecDetail.specAttributeDetail !== null
                ? this.getGenSpecDetail.specAttributeDetail.id
                : null,
            description: this.getGenSpecDetail.description,
            spesification_type: null,
            standard: this.getGenSpecDetail.standard,
            unit_id:
              this.getGenSpecDetail.unit_detail !== null
                ? this.getGenSpecDetail.unit_detail.id
                : null,
            criteria: this.getGenSpecDetail.criteria,
            analysis_method: '',
            min_frequency_supplier: '',
            min_frequency_sanqua: ''
          }
        }
      }
    },
    dropDown() {
      this.spesificationCategoryDropdown()
    },
    initSpecAttributeDropdown(event) {
      this.spesificationAttributeDropdown([event])
    },
    closeForm() {
      this.setVendorCatalogueModal([false, this.getVdrCatalogueModalStat[1]])
      this.form_supply = {
        uom_id: null,
        uom_name: '',
        price_per_unit: null,
        file_quotation: '-',
        period_start: '',
        period_end: ''
      }
      this.form_raw_spesification = {
        spesification_category_id: null,
        spesification_attribute_id: null,
        description: '',
        spesification_type: null,
        standard: '',
        unit_id: null,
        criteria: null,
        analysis_method: '',
        min_frequency_supplier: '',
        min_frequency_sanqua: ''
      }
    },

    supplyUpload(event) {
      if (event !== null) {
        this.setIsLoading(true)
        this.uploadQuotationFile(event)
          .then(result => {
            this.setIsLoading(false)
            if (result.data.status === false) {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false
              })
            } else if (result.data.status === true) {
              this.$swal.fire({
                position: 'center',
                icon: 'success',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false
              })
              this.form_supply.file_quotation = result.data.data.name
            } else {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false
              })
            }
          })
          .catch(error => {
            this.setIsLoading(error)
            this.form_supply.file_quotation = '-'
            this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: error
                ? 'Somthing went wrong, please contact an admin'
                : 'Somthing went wrong, please contact an admin',
              showConfirmButton: true,
              showCancelButton: false
            })
          })
      } else {
        this.form_supply.file_quotation = '-'
      }
    },
    supplyUnitName(event) {
      this.form_supply.uom_id = event
    },
    submitSupply() {
      console.log(this.form_supply)
      this.$swal
        .fire({
          title: 'Save now',
          icon: 'question',
          showCancelButton: true,
          showConfirmButton: true
        })
        .then(result => {
          if (result.value) {
            if (this.form_supply.uom_name === '') {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Unit is required',
                showConfirmButton: true,
                timer: 5000
              })
            } else if (this.form_supply.price_per_unit === null) {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Price is required',
                showConfirmButton: true,
                timer: 5000
              })
            } else if (this.form_supply.period_start === '') {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Period start is required',
                showConfirmButton: true,
                timer: 5000
              })
            } else if (this.form_supply.period_end === '') {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Priod end is required',
                showConfirmButton: true,
                timer: 5000
              })
            } else if (this.form_supply.file_quotation === '') {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Support file is required',
                showConfirmButton: true,
                timer: 5000
              })
            } else {
              this.setIsLoading(true)
              this.setQuotationForm(this.form_supply)
              this.manageQuotation()
                .then(result => {
                  console.log(result)
                  this.setIsLoading(false)
                  if (result.data.status_code !== '-99') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 5000
                    })
                    this.quotationList(['', '', 0, 10])
                    this.closeForm()
                  } else {
                    if (
                      result.data.status_msg === 'Parameter value has problem'
                    ) {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Invalid input, please complete the form',
                        showConfirmButton: true,
                        timer: 5000
                      })
                    } else {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: result.data.status_msg,
                        showConfirmButton: true,
                        timer: 5000
                      })
                    }
                  }
                })
                .catch(error => {
                  this.setIsLoading(error)
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error,
                    showConfirmButton: true,
                    timer: 5000
                  })
                })
            }
          }
        })
    },
    submitRawSpec() {
      this.$swal
        .fire({
          title: 'Save now',
          icon: 'question',
          showCancelButton: true,
          showConfirmButton: true
        })
        .then(result => {
          if (result.value) {
            if (
              this.form_raw_spesification.spesification_category_id === null
            ) {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Category cannot be empty',
                showConfirmButton: true,
                timer: 5000
              })
            } else if (
              this.form_raw_spesification.spesification_attribute_id === null
            ) {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Attribute cannot be empty',
                showConfirmButton: true,
                timer: 5000
              })
            } else {
              this.setIsLoading(true)
              this.setSpesificationForm(this.form_raw_spesification)
              this.manageSpesification()
                .then(result => {
                  this.setIsLoading(false)
                  if (result.data.status_code !== '-99') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 5000
                    })
                    this.specificationList(['', 0, 5])
                    this.closeForm()
                  } else {
                    if (
                      result.data.status_msg === 'Parameter value has problem'
                    ) {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Invalid input, please complete the form',
                        showConfirmButton: true,
                        timer: 5000
                      })
                    } else {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: result.data.status_msg,
                        showConfirmButton: true,
                        timer: 5000
                      })
                    }
                  }
                })
                .catch(error => {
                  this.setIsLoading(error)
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error
                      ? 'Somthing went wrong, please contact an admin'
                      : 'Somthing went wrong, please contact an admin',
                    showConfirmButton: true,
                    timer: 5000
                  })
                })
            }
          }
        })
    },
    submitGenSpec() {
      this.$swal
        .fire({
          title: 'Save now',
          icon: 'question',
          showCancelButton: true,
          showConfirmButton: true
        })
        .then(result => {
          if (result.value) {
            if (
              this.form_gen_spesification.spesification_category_id === null
            ) {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Category cannot be empty',
                showConfirmButton: true,
                timer: 5000
              })
            } else if (
              this.form_gen_spesification.spesification_attribute_id === null
            ) {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: 'Attribute cannot be empty',
                showConfirmButton: true,
                timer: 5000
              })
            } else {
              this.setIsLoading(true)
              this.setGenSpesificationForm(this.form_gen_spesification)
              this.manageGenSpesification()
                .then(result => {
                  this.setIsLoading(false)
                  if (result.data.status_code !== '-99') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 5000
                    })
                    this.specificationList(['', 0, 5])
                    this.closeForm()
                  } else {
                    if (
                      result.data.status_msg === 'Parameter value has problem'
                    ) {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Invalid input, please complete the form',
                        showConfirmButton: true,
                        timer: 5000
                      })
                    } else {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: result.data.status_msg,
                        showConfirmButton: true,
                        timer: 5000
                      })
                    }
                  }
                })
                .catch(error => {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error
                      ? 'Somthing went wrong, please contact an admin'
                      : 'Somthing went wrong, please contact an admin',
                    showConfirmButton: true,
                    timer: 5000
                  })
                })
            }
          }
        })
    }
  }
}
</script>
