class ApiService {
  development(access) {
    switch (access) {
      case 'oauth':
        return 'https://api.sanquawater.co.id/dev-oauth/'
      case 'proc':
        return 'https://api.sanquawater.co.id/dev-eprocurement/'
      case 'upload':
        return 'https://api.sanquawater.co.id/'
      case 'upload2':
        return 'https://api.sanquawater.co.id/dev-upload/'
      case 'download':
        return 'https://api.sanquawater.co.id/download/'
      case 'hr':
        return 'https://api.sanquawater.co.id/dev-hr/'
      case 'esanqua':
        return 'https://api.sanquawater.co.id/dev-esanqua/'
      default:
        return 'http://localhost:1189/api/oauth/v1'
    }
  }

  production(access) {
    switch (access) {
      case 'oauth':
        return 'http://10.10.20.6:1189/api/oauth/v1/'
      case 'proc':
        return 'http://10.10.20.6:1191/api/procurement/v1/'
      // case 'upload':
      //   return 'http://10.10.20.6:1192/api/procurement/v1/'
      case 'upload':
        return 'http://10.10.20.6:1192/api/upload/v1/'
      case 'upload2':
        return 'http://10.10.20.6:1192/api/upload/v1/'
      case 'download':
        return 'http://10.10.20.6:1192/api/download/v1/'
      case 'hr':
        return 'http://10.10.20.6:2189/api/hr/v1/'
      case 'esanqua':
        return 'http://10.10.20.6:1193/api/esanqua/v1/'
      default:
        return 'http://localhost:1189/api/oauth/v1'
    }
  }

  domain(access) {
    switch (access) {
      case 'oauth':
        return 'https://api.sanquawater.co.id/prod-oauth/'
      case 'proc':
        return 'https://api.sanquawater.co.id/eprocurement/'
      case 'upload':
        return 'https://api.sanquawater.co.id/'
      case 'upload2':
        return 'https://api.sanquawater.co.id/upload/'
      case 'download':
        return 'https://api.sanquawater.co.id/download/'
      case 'hr':
        return 'https://api.sanquawater.co.id/hr/'
      case 'esanqua':
        return 'https://api.sanquawater.co.id/esanqua/'
      default:
        return 'https://api.sanquawater.co.id/prod-oauth'
    }
  }
}

export default new ApiService()
