<template>
  <div class="text-center">
    <v-bottom-sheet v-model="sheet" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div class="pr-items-add-new-line" style="padding:5px;">
          <p
            id="procItemTabForm"
            v-bind="attrs"
            v-on="on"
            style="padding:3px;"
            @click="init"
          >
            Tambah Baris Baru
          </p>
        </div>
      </template>
      <v-sheet height="400px" style="position:relative;">
        <v-form
          ref="entryForm"
          @submit.prevent="submit"
          style="position: relative;"
        >
          <div
            style="border:1px solid rgba(0,0,0,0.3); position:absolute; top:10px; left:10px; z-index:1; border-radius:5px; padding: 3px 7px;"
          >
            <v-icon @click="close" color="red">
              mdi-close
            </v-icon>
            <v-btn
              type="submit"
              color="grey"
              small
              tile
              style="margin-left:20px; color:white;"
            >
              Simpan
            </v-btn>
          </div>
          <div class="text-center" style="height:100%;">
            <v-row style="padding:15px;">
              <v-col cols="12" style="padding:0;">
                <v-toolbar-title class="overline">
                  <p style="font-size:18px;">
                    Item Pengadaan
                  </p>
                </v-toolbar-title>
              </v-col>

              <v-col cols="6" style="padding:10px 0;">
                <div style="display: flex;">
                  <v-col cols="3" style="padding:0; display:flex;">
                    <p style=" margin:auto;">
                      Item/Produk
                    </p>
                  </v-col>
                  <v-col cols="9" style="padding:0;">
                    <v-autocomplete
                      style="margin:0; height:41.5px; width:100%; "
                      v-model="form.product_id"
                      :items="dropdown.product"
                      :label="
                        itemId !== null ? itemId.product.name : 'Type here'
                      "
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      :loading="par.loading"
                      cache-items
                      :search-input.sync="par.keyword.product"
                      clearable
                      :rules="spesification"
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding:10px 0;">
                <div style="display: flex;">
                  <v-col cols="3" style="padding:0; display:flex; ">
                    <p style=" margin:auto; ">
                      Qty
                    </p>
                  </v-col>
                  <v-col cols="6" style="padding:0;">
                    <v-text-field
                      v-model="form.qty"
                      type="number"
                      dense
                      outlined
                      style="margin:0; height:41.5px; width:150px;"
                      :rules="qty"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding:10px 0;">
                <div style="display: flex;">
                  <v-col cols="3" style="padding:0; display:flex;">
                    <p style=" margin:auto;">
                      Unit
                    </p>
                  </v-col>
                  <v-col cols="6" style="padding:0;">
                    <v-autocomplete
                      style="margin:0; height:41.5px; width:150px;"
                      v-model="form.unit_id"
                      :items="dropdown.unit"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                      :rules="unit"
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding:10px 0;">
                <div style="display: flex;">
                  <v-col cols="3" style="padding:0; display:flex; ">
                    <p style=" margin:auto; ">
                      Currency
                    </p>
                  </v-col>
                  <v-col cols="6" style="padding:0;">
                    <v-autocomplete
                      style="margin:0; height:41.5px; width:150px;"
                      v-model="form.currency_id"
                      :items="dropdown.currency"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                      :rules="currency"
                    ></v-autocomplete>
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding:10px 0;">
                <div style="display: flex;">
                  <v-col cols="3" style="padding:0; display:flex;">
                    <p style=" margin:auto;">
                      Harga unit
                    </p>
                  </v-col>
                  <v-col cols="6" style="padding:0;">
                    <v-text-field
                      v-model="form.unit_price"
                      dense
                      outlined
                      style="margin:0; height:41.5px;"
                      :rules="unitPrice"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="6" style="padding:10px 0;">
                <div style="display: flex;">
                  <v-col cols="3" style="padding:0; display:flex;">
                    <p style=" margin:auto;">
                      Spesifikasi
                    </p>
                  </v-col>
                  <v-col cols="8" style="padding:0;">
                    <v-textarea
                      v-model="form.description"
                      dense
                      auto-grow
                      outlined
                      clearable
                      :rules="spesification"
                      style="margin:0;"
                    />
                  </v-col>
                </div>
              </v-col>

              <!-- <v-col
                cols="12"
                style="padding:0 0 0 50px; position:absolute; top:0; text-align:left;"
              >
                <v-btn
                  type="submit"
                  color="grey"
                  small
                  tile
                  class="ma-2"
                  style="color:white;"
                >
                  Simpan
                </v-btn>
              </v-col> -->
            </v-row>
          </div>
        </v-form>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../../services/buildType'
import { mapActions } from 'vuex'
export default {
  props: ['param', 'id', 'itemId'],
  data: () => ({
    sheet: false,
    proc: buildType.apiURL('proc'),
    form: {},
    par: {
      keyword: {
        product: null,
        unit: '',
        currency: ''
      },
      loading: false
    },
    dropdown: {
      product: [],
      unit: [],
      currency: []
    },
    select: null,

    productName: [],
    qty: [],
    unit: [],
    unitPrice: [],
    currency: [],
    spesification: []
  }),
  watch: {
    'par.keyword.product'(val) {
      val && val !== this.select && this.dropDownProduct(val)
    }
  },
  methods: {
    ...mapActions(['globalDropdown']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    init() {
      this.initForm()
      this.initDropdown()
    },
    initDropdown() {
      this.dropDownProduct(this.par.keyword.product)
      this.dropDownUnit(this.par.keyword.unit)
      this.dropDownCurrency(this.par.keyword.currency)
    },
    dropDownProduct(v) {
      this.globalDropdown(['product', v]).then(res => {
        this.dropdown.product = res.data.data
      })
    },
    dropDownUnit(v) {
      this.globalDropdown(['unit', v]).then(res => {
        this.dropdown.unit = res.data.data
      })
    },
    dropDownCurrency(v) {
      this.globalDropdown(['currency', v]).then(res => {
        this.dropdown.currency = res.data.data
      })
    },
    initForm() {
      if (this.itemId === null) {
        this.form = {
          procurement_id: this.id,
          product_id: null,
          qty: null,
          currency_id: null,
          unit_id: null,
          unit_price: null,
          description: ''
        }
      } else {
        this.form = {
          procurement_id: this.id,
          product_id: this.itemId.product.id,
          qty: this.itemId.qty,
          currency_id: this.itemId.currency.id,
          unit_id: this.itemId.unit.id,
          unit_price: this.itemId.total / this.itemId.qty,
          description: this.itemId.description
        }
      }
    },
    submit() {
      this.productName = [v => !!v || 'Product is required']
      this.qty = [v => !!v || 'Qty is required']
      this.unit = [v => !!v || 'Unit is required']
      this.unitPrice = [v => !!v || 'Unit Price is required']
      this.currency = [v => !!v || 'Currency is required']
      this.spesification = [v => !!v || 'Spesification is required']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = this.form

      if (this.itemId !== null) {
        Object.assign(form, {
          act: 'update',
          id: this.itemId.id
        })
      } else {
        Object.assign(form, {
          act: 'add'
        })
      }
      this.save(form)
    },
    save(form) {
      console.log(form)
      axios
        .post(`${this.proc}item/save`, form)
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.$emit('reload')
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    close() {
      this.productName = []
      this.qty = []
      this.unit = []
      this.unitPrice = []
      this.currency = []
      this.spesification = []

      this.sheet = false
      this.$emit('close', null)
    }
  }
}
</script>
