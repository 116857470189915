// import axios from 'axios'
import buildType from '../../services/buildType'
// import router from '../../router/index'
export default {
  state: {
    oAuth: buildType.apiURL('oauth'),
    procAPI: buildType.apiURL('proc'),
    id: null,
    procDetail: null
  },
  mutations: {
    setProcListId(state, payload) {
      state.id = payload
    },
    setProcDetail(state, payload) {
      state.procDetail = payload
    }
  },
  actions: {},
  getters: {
    getProcListId(state) {
      return state.id
    }
  }
}
