<template>
  <v-toolbar style="position: sticky; top:0; z-index:2;" color="cyan" dark>
    <div class="d-flex align-center">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <router-link style="color:white;" to="/">
            <v-btn
              @click="home"
              v-bind="attrs"
              v-on="on"
              style="height:45px;width:45px; margin-right: 10px;"
              elevation="5"
              fab
              color="white"
              ><v-img
                alt="sanqua multi international"
                class=" rounded-circle"
                contain
                src="../../../public/sanquamulti.png"
                transition="scale-transition"
                style="height:40px;width:40px; "
            /></v-btn>
          </router-link>
        </template>
        <span>Home</span>
      </v-tooltip>

      <h5 style="line-height:20px; margin-top: 20px;">
        <h5 style="font-size: 20px; width:180px;">
          Sanqua <br />
          <h4 style="width:200px;">{{ chosenpage }}</h4>
        </h5>
      </h5>
    </div>

    <v-tabs hide-slider align-with-title>
      <v-tab>
        <router-link
          style="color:white;position:absolute; top:0; left:0; bottom:0; right:0; display:flex; justify-content:center; align-items:center;"
          to="/"
          >Home</router-link
        >
      </v-tab>
      <v-menu bottom offset-y :rounded="'0'">
        <template v-slot:activator="{ on, attrs }">
          <v-tab v-bind="attrs" v-on="on" style="color:white;">
            Master
          </v-tab>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, i) in ddmaster"
            :key="i"
            style="height:40px;"
          >
            <v-menu bottom offset-x :rounded="'0'">
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  v-bind="attrs"
                  v-on="on"
                  style="width:220px; height:100%; display:flex; justify-content:flex-start;"
                >
                  {{ item.title }}
                </v-tab>
              </template>
              <v-list>
                <v-list-item
                  v-for="(tabs, i) in item.sub_tabs"
                  :key="i"
                  style="height:40px; min-width:220px;"
                >
                  <v-tab
                    style="font-size:12px; display:flex; justify-content:flex-start; width:100%;height:100%;"
                  >
                    <router-link
                      :to="tabs.path"
                      style="width:100%;height:100%; display:flex; justify-content:flex-start; align-items:center; text-align:left;"
                    >
                      {{ tabs.sub_title }}
                    </router-link>
                  </v-tab>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-tab style=" width:150px;"
        ><router-link
          style="color:white;position:absolute; top:0; left:0; bottom:0; right:0; display:flex; justify-content:center; align-items:center;"
          to="/e-procurement/procurement-list"
          >Procurement</router-link
        ></v-tab
      >
      <v-tab style=" width:80px;"
        ><router-link
          style="color:white;position:absolute; top:0; left:0; bottom:0; right:0; display:flex; justify-content:center; align-items:center;"
          to="/fpb-list"
          >Fpb</router-link
        ></v-tab
      >
    </v-tabs>

    <v-toolbar-items class="hidden-sm-and-down">
      <v-tab>
        <!-- <v-badge color="pink" content="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">
                mdi-email
              </v-icon>
            </template>
            <span>You have new email</span>
          </v-tooltip>
        </v-badge> -->
      </v-tab>
      <v-divider style="margin-top:0px;" vertical></v-divider>
    </v-toolbar-items>

    <v-toolbar-items class="hidden-sm-and-down" @click="drawer">
      <v-tab>
        <v-toolbar-title style="font-size: 12px; ">
          {{ getUserProfile !== null ? getUserProfile.username : 'Admin' }}
          <br />
          {{
            getUserProfile !== null
              ? '(' + getUserProfile.sanqua_company_name + ')'
              : 'Dept MIS'
          }}
        </v-toolbar-title>
      </v-tab>
      <v-divider style="margin-top:0px;" vertical></v-divider>
    </v-toolbar-items>
  </v-toolbar>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'Navigation',
  props: ['chosenpage'],
  data: () => ({
    ddmaster: [
      {
        title: 'Vendor',
        sub_tabs: [
          {
            sub_title: 'Vendor List',
            path: '/master-vendor'
          },
          {
            sub_title: 'Catalogue',
            path: '/master-vendor/catalogue/export'
          },
          {
            sub_title: 'Quotation',
            path: '/master-vendor/quotation/export'
          },
          {
            sub_title: 'Spesification',
            path: '/master-vendor/spesification/export'
          }
        ]
      },
      {
        title: 'Product',
        sub_tabs: [
          {
            sub_title: 'Products List',
            path: '/master-product'
          }
        ]
      },
      {
        title: 'Product Category',
        sub_tabs: [
          {
            sub_title: 'Product Category List',
            path: '/product-category'
          }
        ]
      }
      // { title: 'Product Category', path: '/product-category' }
    ],
    items: [
      { title: 'Email', icon: 'mdi-email' },
      { title: 'Dashboard', icon: 'mdi-view-dashboard' },
      { title: 'About', icon: 'mdi-forum' },
      { title: 'Account', icon: 'mdi-account-box' },
      { title: 'Admin', icon: 'mdi-gavel' }
    ]
  }),
  components: {},
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    drawer() {
      this.$emit('setDrawer', true)
    },

    home() {
      // this.setTabs([])
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/navigation.scss"></style>
