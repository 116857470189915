<template>
  <div>
    <div style="margin:0 0 20px 0;">
      <Navigation chosenpage="Catalogue Export" @setDrawer="drawerSet" />
    </div>

    <v-card
      elevation="2"
      class=" mx-auto"
      style="padding:10px 10px; width:95%; margin-top:20px;"
    >
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="catalogueData"
            class="elevation-1 mx-auto row-pointer"
            style="width:99%; margin-top: 20px; margin-bottom:20px;"
            :items-per-page="itemsPerPage"
          >
            <template v-slot:top>
              <v-toolbar flat style="border-bottom:1px solid #e0e0e0;">
                <v-toolbar-title style="padding-left:10px; "
                  >Catalogue Export</v-toolbar-title
                >

                <v-divider class="mx-6" inset vertical></v-divider>
                <v-text-field
                  v-model="param.keyword"
                  label="Search here"
                  type="search"
                  outlined
                  dense
                  style="position: relative; top:15px;"
                ></v-text-field>

                <div
                  cols="12"
                  style="padding: 0; justify-content:flex-start; align-items:center; display:flex; margin:5px;"
                >
                  <p style="margin:0 5px 0 0; color: rgba(0, 0, 0, 0.5);">
                    Investasi
                  </p>
                  <v-checkbox
                    v-model="param.isAsset"
                    style=" margin:0; height:25px; padding:0;"
                    @change="getAsset"
                    :false-value="0"
                    :true-value="1"
                  ></v-checkbox>
                </div>
                <v-divider class="mx-6" inset vertical></v-divider>
                <v-btn
                  v-if="json_data.length === 0"
                  small
                  tile
                  color="#e0e0e0"
                  class="ma-2 black--text"
                  style="width:100px; position:relative;"
                  @click="exportAll"
                >
                  Export All
                </v-btn>

                <v-btn
                  v-else
                  small
                  tile
                  color="#e0e0e0"
                  class="ma-2 black--text"
                  style="width:100px; position:relative;"
                  @click="exportFile"
                >
                  Export Now
                </v-btn>
                <download-excel
                  id="downloadCatalogueExcel"
                  :data="json_data"
                  :fields="
                    isJsonEdit === true ? json_fields_edit : json_fields_normal
                  "
                  name="vendor_catalogue"
                  style="width:0; height:0; font-size:1px; color:transparent;"
                >
                </download-excel>

                <router-link to="/master-vendor/catalogue/import">
                  <v-btn
                    small
                    tile
                    color="#e0e0e0"
                    class="ma-2 black--text"
                    style="width:100px; position:relative;"
                  >
                    Import Data
                  </v-btn>
                </router-link>
                <v-spacer></v-spacer>
              </v-toolbar>
            </template>

            <template v-slot:[`item.check`]="{ item }">
              <td>
                <v-checkbox v-model="json_data" :value="item"></v-checkbox>
              </td>
            </template>
            <template v-slot:[`item.last_ordered`]="{ item }">
              <td>
                {{ new Date(item.last_ordered).toLocaleDateString() }}
              </td>
            </template>
            <template v-slot:[`item.last_price`]="{ item }">
              <td>
                {{ 'Rp. ' + price(item.last_price) }}
              </td>
            </template>
            <template v-slot:[`item.currency`]="{ item }">
              <td>
                {{ item.currency !== null ? item.currency.symbol : '-' }}
              </td>
            </template>
            <template v-slot:[`item.merk`]="{ item }">
              <td>
                {{ item.merk !== '' ? item.merk : '-' }}
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-navigation-drawer v-model="drawer" fixed height="100vh" temporary>
      <Drawer />
    </v-navigation-drawer>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../services/buildType'
import Navigation from '../../components/navigation/Navigation'
import Drawer from '../../components/navigation/Drawer'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: [],
  data() {
    return {
      procAPI: buildType.apiURL('proc'),
      drawer: false,
      dialog: false,
      isImport: false,
      itemsPerPage: 10,
      param: {
        offset: 0,
        limit: 'all',
        keyword: '',
        isAsset: 0
      },
      headers: [
        {
          text: '',
          align: 'left',
          sortable: false,
          value: 'check',
          width: '5%'
        },
        { text: 'Vendor Name', value: 'vendor.name', align: 'left' },
        // {
        //   text: 'V.Code',
        //   sortable: false,
        //   value: 'vendor.code',
        //   align: 'center'
        // },
        { text: 'Product Name', value: 'product.name', align: 'left' },
        {
          text: 'P.Code',
          sortable: false,
          value: 'product.code',
          align: 'center'
        },
        {
          text: 'Merk',
          sortable: false,
          value: 'merk',
          align: 'center'
        },
        { text: 'Currency', value: 'currency', align: 'left' },
        { text: 'Last Price', value: 'last_price', align: 'left' },
        { text: 'Last Order', value: 'last_ordered', align: 'left' },
        { text: 'Purchased Plant', value: 'last_purchase_plant', align: 'left' }
      ],
      catalogueData: [],

      json_fields_normal: {
        vendor_name: 'vendor.name',
        vendor_code: 'vendor.code',
        product_name: 'product.name',
        product_code: 'product.code',
        product_category: 'product.category',
        merk: 'merk',
        uom: 'uom_name',
        uom_id: 'uom_id',
        purchase_uom: 'purchase_uom_name',
        purchase_uom_id: 'purchase_uom_id',
        catalogue_type_name: 'catalogue_type_name',
        catalogue_type: 'catalogue_type',
        currency_id: 'currency.id',
        last_price: 'last_price',
        last_ordered: 'last_ordered',
        last_purchase_plant: 'last_purchase_plant'
      },
      json_fields_edit: {
        id: 'id',
        vendor_name: 'vendor.name',
        vendor_code: 'vendor.code',
        product_name: 'product.name',
        product_code: 'product.code',
        product_category: 'product.category',
        merk: 'merk',
        uom: 'uom_name',
        uom_id: 'uom_id',
        purchase_uom: 'purchase_uom_name',
        purchase_uom_id: 'purchase_uom_id',
        catalogue_type_name: 'catalogue_type_name',
        catalogue_type: 'catalogue_type',
        currency_id: 'currency.id',
        last_price: 'last_price',
        last_ordered: 'last_ordered',
        last_purchase_plant: 'last_purchase_plant'
      },
      json_data: [],
      isJsonEdit: false
    }
  },
  components: { Navigation, Drawer },
  watch: {
    'param.keyword'() {
      this.exportTable()
    }
  },
  computed: {
    ...mapGetters([])
  },
  created() {
    this.exportTable()
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    drawerSet(val) {
      this.drawer = val
    },
    showMsgDialog(pModalType, pStatusMsg) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            icon: pModalType,
            html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    getAsset(val) {
      this.exportTable()
    },
    exportTable() {
      axios
        .get(
          `${this.procAPI}vendor/catalogue/list?offset=${this.param.offset}&vendor_id=&category_id=&keyword=${this.param.keyword}&limit=${this.param.limit}&is_asset=${this.param.isAsset}`
        )
        .then(res => {
          this.catalogueData = []
          const response = res.data.data
          this.catalogueData = response
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    },
    price(val) {
      if (val !== null) {
        const reverse = val
          .toString()
          .split('')
          .reverse()
          .join('')
        var ribuan = reverse.match(/\d{1,3}/g)
        return ribuan
          .join('.')
          .split('')
          .reverse()
          .join('')
      } else {
        return ''
      }
    },
    exportFile() {
      console.log(this.json_data)
      this.showMsgDialog('question', 'Sertakan ID').then(res => {
        if (res.isConfirmed) {
          this.isJsonEdit = true
          console.log(true)
          setTimeout(() => {
            document.getElementById('downloadCatalogueExcel').click()
          }, 500)
        } else if (res.dismiss === 'cancel') {
          this.isJsonEdit = false
          console.log(false)
          setTimeout(() => {
            document.getElementById('downloadCatalogueExcel').click()
          }, 500)
        } else if (res.dismiss === 'backdrop') {
          this.json_data = []
        }

        setTimeout(() => {
          this.json_data = []
        }, 1000)
      })
    },
    exportAll() {
      this.json_data = this.catalogueData
    }
  }
}
</script>
<style></style>
