import axios from 'axios'
import buildType from '../../services/buildType'
export default {
  state: {
    procAPI: buildType.apiURL('proc'),
    uploadAPI: buildType.apiURL('upload'),
    env: process.env.VUE_APP_BUILD_TYPE,

    act: '',
    newForm: {},
    vendorId: '',
    vendorAddStatus: true,
    vendorBlockStatus: true,
    vendorListData: [],
    isProfileEdit: false,
    totalItems: 0,
    businessEntity: [],
    currency: [],
    province: [],
    classification: [],
    vendorDetail: {},
    urlParam: {},
    experience: [],
    isExpEditModal: false,
    experienceId: null,
    expTotalItems: 0,
    document: {
      siup: null,
      npwp: null,
      domisili: null,
      tdp: null,
      akta: null
    },
    vendorCode: ''
  },
  mutations: {
    vendorForm(state, payload) {
      let newTags = ''
      if (payload.tags.length > 0) {
        newTags = payload.tags.join()
      }
      console.log(payload)
      const formData = {
        act: state.act,
        code: payload.code,
        name: payload.name,
        logo: payload.logo,
        npwp: payload.npwp,
        business_entity_id: payload.business_entity_id,
        // currency_id: payload.currency_id,
        classification_id: payload.classification_id,
        sub_classification_id: payload.sub_classification_id,
        province_id: payload.province_id,
        city_id: payload.city_id,
        address: payload.address,
        zip_code: payload.zip_code,
        phone1: payload.phone1,
        phone2: payload.phone2,
        email: payload.email,
        website: payload.website,
        about: payload.about !== '' ? payload.about : '-',
        location_lat: payload.location_lat,
        location_long: payload.location_long,
        tags: newTags,
        company_scale: payload.company_scale,
        register_via: payload.register_via
      }

      if (state.act === 'update') {
        Object.assign(formData, { id: state.vendorId })
      }

      state.newForm = formData
    },
    vendorList(state, payload) {
      state.vendorListData = []
      const response = payload
      if (response !== undefined) {
        for (let i = 0; i < response.length; i++) {
          state.vendorListData.push({
            no: 1 + i,
            code: response[i].code,
            // currency_id:
            //   response[i].currency !== null ? response[i].currency.id : 0,
            name: response[i].name,
            status: response[i].status,
            id: response[i].id,
            npwp: response[i].npwp,
            business_entity_id:
              response[i].business_entity !== null
                ? response[i].business_entity.id
                : 0,
            classification_id:
              response[i].classification !== null
                ? response[i].classification.id
                : 0,
            sub_classification_id:
              response[i].sub_classification !== undefined
                ? response[i].sub_classification !== null
                  ? response[i].sub_classification.id
                  : 0
                : 0,
            province_id:
              response[i].province !== null ? response[i].province.id : 0,
            city_id: response[i].city !== null ? response[i].city.id : 0,
            address: response[i].address,
            zip_code: response[i].zip_code,
            phone1: response[i].phone1,
            phone2: response[i].phone2,
            email: response[i].email,
            website: response[i].website
          })
        }
      } else {
        state.vendorListData = []
      }
      console.log(state.vendorListData)
    },
    vendorAdd(state, payload) {
      state.vendorAddStatus = payload[0]
      state.act = payload[1]

      if (payload[2] !== undefined) {
        if (state.isProfileEdit === false) {
          state.vendorId = payload[2]
        }
      }
    },
    vendorBlock(state, payload) {
      state.vendorBlockStatus = payload
    },
    setBusinessEntity(state, payload) {
      state.businessEntity = payload
    },
    setCurrency(state, payload) {
      state.currency = payload
    },
    setProvince(state, payload) {
      state.province = payload
    },
    setClassification(state, payload) {
      state.classification = payload
    },
    setVendorDetail(state, payload) {
      state.vendorDetail = payload
      state.vendorId = payload.id
    },
    setVendroId(state, payload) {
      state.vendorId = payload
    },
    setUrlParam(state, payload) {
      const urlData = {
        id: payload[0],
        name: payload[1]
      }
      state.urlParam = urlData
    },
    setExperienceList(state, payload) {
      state.experience = []
      const response = payload.data
      if (response !== undefined) {
        state.expTotalItems = payload.total_record
        for (let i = 0; i < response.length; i++) {
          state.experience.push({
            no: 1 + i,
            type: response[i].type,
            name: response[i].name,
            id: response[i].id,
            location: response[i].location,
            month: response[i].month,
            year: response[i].year
          })
        }
      } else {
        state.expTotalItems = 1
        state.experience = []
      }
    },
    setNewExpLine(state, payload) {
      if (payload === 1) {
        state.experience.push({
          no: 1 + state.experience.length,
          id: null,
          name: '',
          type: '',
          location: '',
          month: '',
          year: ''
        })
      } else {
        state.experience.pop()
      }
    },
    setModalEditExp(state, payload) {
      state.isExpEditModal = payload[0]
      state.experienceId = payload[1]
    },
    setSiup(state, payload) {
      state.document.siup = payload
    },
    setNpwp(state, payload) {
      state.document.npwp = payload
    },
    setDomisili(state, payload) {
      state.document.domisili = payload
    },
    setTdp(state, payload) {
      state.document.tdp = payload
    },
    setAkta(state, payload) {
      state.document.akta = payload
    },
    setTotalVendor(state, payload) {
      state.totalItems = payload.total_record
    },
    setVendorProfileeditCon(state, payload) {
      state.isProfileEdit = payload
    },
    setVendorCode(state, payload) {
      state.vendorCode = payload
    }
  },
  actions: {
    detailVendor(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.procAPI}vendor/detail/${payload}`)
          .then(response => {
            context.commit('setVendorDetail', response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    vendorDataList(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.procAPI}vendor/list?keyword=${
              payload[0]
            }&offset=${[payload[1]]}&limit=${payload[2]}&order_by=${
              payload[3]
            }&order_type=${payload[4]}`
          )
          .then(response => {
            context.commit('vendorList', response.data.data)
            context.commit('setTotalVendor', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    vendorExperienceList(context, payload) {
      axios
        .get(
          `${context.state.procAPI}vendor/experience/list?keyword=${payload[1]}&offset=${payload[2]}&limit=${payload[3]}&vendor_id=${payload[0]}`
        )
        .then(response => {
          context.commit('setExperienceList', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    addNewVendor(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/save`, context.state.newForm)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addCode(context, payload) {
      const form = {
        act: 'update',
        id: payload[0],
        code: payload[1]
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/save`, form)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    addNewExperience(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/experience/save`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editVendorData(context) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/save`, context.state.newForm)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    editExperience(context, payload) {
      const newData = {
        act: 'update',
        id: context.state.experienceId.id,
        vendor_id: context.state.vendorId,
        name: payload.name,
        type: payload.type,
        location: payload.location,
        month: Number(payload.month),
        year: Number(payload.year)
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/experience/save`, newData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteVendorData(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${context.state.procAPI}vendor/delete/${payload}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteExperience(context, payload) {
      const newForm = {
        id: payload.id,
        vendor_id: context.state.vendorId,
        name: payload.name,
        type: payload.type,
        location: payload.location,
        month: payload.month,
        year: payload.year
      }
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.procAPI}vendor/experience/delete/${payload.id}`,
            newForm
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    businessEntity(context) {
      axios
        .get(
          `${context.state.procAPI}master/business_entity/drop_down?keyword=&order_by=name&order_type=asc`
        )
        .then(response => {
          context.commit('setBusinessEntity', response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    currency(context) {
      axios
        .get(`${context.state.procAPI}master/currency/drop_down?keyword=`)
        .then(response => {
          console.log(response.data.data)
          context.commit('setCurrency', response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    province(context) {
      axios
        .get(
          `${context.state.procAPI}master/province/drop_down?order_by=name&order_type=asc`
        )
        .then(response => {
          console.log(response.data.data)
          context.commit('setProvince', response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    classification(context) {
      axios
        .get(
          `${context.state.procAPI}master/classification/drop_down?order_by=name&order_type=asc&keyword`
        )
        .then(response => {
          console.log(response.data.data)
          context.commit('setClassification', response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    vendorLogo(context, payload) {
      const data = new FormData()
      data.append('file', payload)
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.uploadAPI}upload/vendor/logo`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    vendorDocumentUpload(context, payload) {
      const data = new FormData()
      data.append('file', payload[1])

      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.uploadAPI}upload/vendor/${payload[0]}`, data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    vendorSiup(context) {
      context.state.document.siup = null
      axios
        .get(
          `${context.state.procAPI}vendor/document/list?offset=0&limit=10&vendor_id=${context.state.vendorId}&document_type_id=1`
        )
        .then(response => {
          context.commit('setSiup', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    vendorNpwp(context) {
      context.state.document.npwp = null
      axios
        .get(
          `${context.state.procAPI}vendor/document/list?offset=0&limit=10&vendor_id=${context.state.vendorId}&document_type_id=2`
        )
        .then(response => {
          context.commit('setNpwp', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    vendorDomisili(context) {
      context.state.document.domisili = null
      axios
        .get(
          `${context.state.procAPI}vendor/document/list?offset=0&limit=10&vendor_id=${context.state.vendorId}&document_type_id=3`
        )
        .then(response => {
          context.commit('setDomisili', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    vendorTdp(context) {
      context.state.document.tdp = null
      axios
        .get(
          `${context.state.procAPI}vendor/document/list?offset=0&limit=10&vendor_id=${context.state.vendorId}&document_type_id=4`
        )
        .then(response => {
          context.commit('setTdp', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    vendorAkta(context) {
      context.state.document.akta = null
      axios
        .get(
          `${context.state.procAPI}vendor/document/list?offset=0&limit=10&vendor_id=${context.state.vendorId}&document_type_id=9`
        )
        .then(response => {
          context.commit('setAkta', response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    blockVendor(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/block`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    unBlockVendor(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/unblock`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {
    getVendorAct(state) {
      return state.act
    },
    getVendorCode(state) {
      return state.vendorCode
    },
    getVdrAddStat(state) {
      return state.vendorAddStatus
    },
    getVdrBlockStat(state) {
      return state.vendorBlockStatus
    },
    getVendorDataList(state) {
      return state.vendorListData
    },
    getVendorDropDown(state) {
      const dropDown = {
        businessEntity: state.businessEntity,
        province: state.province,
        classification: state.classification,
        currency: state.currency
      }

      return dropDown
    },
    getVendorDetail(state) {
      return state.vendorDetail
    },
    getVendorUrlParam(state) {
      return state.urlParam
    },
    getVendorId(state) {
      return state.vendorId
    },
    getExperienceList(state) {
      return state.experience
    },
    getModalEditExp(state) {
      return state.isExpEditModal
    },
    getExpId(state) {
      return state.experienceId
    },
    getDocument(state) {
      return state.document
    },
    getTotalVendor(state) {
      return state.totalItems
    },
    getExpTotalItems(state) {
      return state.expTotalItems
    },
    getIsProfileEdit(state) {
      return state.isProfileEdit
    }
  }
}
