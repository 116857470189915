<template>
  <div id="vendorEdit" class="col-12" style="z-index: 5;">
    <div class="ue-1">
      <v-card
        v-if="perm.read_perm === 1"
        style="min-height:99vh; padding-bottom: 30px;"
      >
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            getVendorAct === 'add'
              ? 'Formulir Add Vendor'
              : perm.update !== 1
              ? 'Vendor Detail'
              : 'Formulir Edit Vendor'
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items style="padding:10px; ">
            <v-btn
              v-if="getVendorAct === 'add'"
              :disabled="perm.create_perm !== 1"
              color="success"
              class="white--text"
              @click="saveAdd"
            >
              Save
            </v-btn>
            <v-btn
              v-else
              :disabled="perm.update_perm !== 1"
              class="white--text"
              color="success"
              @click="saveEdit"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list style="position: relative;">
          <v-card class="mx-auto" width="95%">
            <v-card-title
              class="title font-weight-regular justify-space-between"
            >
              <span>{{ currentTitle }}</span>
              <v-avatar
                color="primary lighten-2"
                class="subheading white--text"
                size="24"
                v-text="step"
              ></v-avatar>
            </v-card-title>

            <v-window v-model="step" style=" height:370px;">
              <v-window-item :value="1">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="4" style="height:65px; padding:0 10px;">
                        <v-text-field
                          v-model="formAddVendor.name"
                          label="Nama Vendor *"
                          type="text"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                          :rules="rules.nameRules"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" style="height:65px; padding:0 10px;">
                        <v-text-field
                          type="email"
                          v-model="formAddVendor.email"
                          label="Email"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="4" style="height:65px; padding:0 10px;">
                        <v-text-field
                          v-model="formAddVendor.phone1"
                          label="No. Handphone 1 *"
                          type="text"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                          :rules="rules.phone1Rules"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="3" style="height:65px; padding:0 10px;">
                        <v-text-field
                          v-model="formAddVendor.phone2"
                          label="No. Handphone 2"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                          type="text"
                          required
                        ></v-text-field>
                      </v-col>

                      <!-- <v-col cols="3" style="height:65px; padding:0 10px;">
                        <v-text-field
                          v-model="formAddVendor.npwp"
                          label="NPWP"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                          type="text"
                          required
                          disabled
                        ></v-text-field>
                      </v-col> -->

                      <v-col cols="3" style="height:65px; padding:0 10px;">
                        <v-autocomplete
                          required
                          v-model="formAddVendor.business_entity_id"
                          :items="getVendorDropDown.businessEntity"
                          item-text="name"
                          item-value="id"
                          return-id
                          label="Badan Usaha *"
                          :rules="rules.entityRules"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="3" style="height:65px; padding:0 10px;">
                        <v-autocomplete
                          v-model="formAddVendor.company_scale"
                          required
                          :items="[
                            { id: 1, name: 'Kecil' },
                            { id: 2, name: 'Menengah' },
                            { id: 3, name: 'Besar' }
                          ]"
                          item-text="name"
                          item-value="id"
                          return-id
                          label="Skala Perusahaan"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="3" style="height:65px; padding:0 10px;">
                        <!-- <v-autocomplete
                          required
                          v-model="formAddVendor.currency_id"
                          :items="getVendorDropDown.currency"
                          item-text="code"
                          item-value="id"
                          return-id
                          label="Currency"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-autocomplete> -->
                      </v-col>

                      <v-col cols="12" style="height:65px; padding:0 10px;">
                        <v-textarea
                          v-model="formAddVendor.about"
                          height="180px"
                          outlined
                          no-resize
                          clear-icon="mdi-close-circle"
                          name="input-7-4"
                          label="Tentang Perusahaan"
                          :clearable="
                            getVendorAct === 'update' && perm.update_perm === 1
                          "
                          required
                          style="margin-top:20px;"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-window-item>

              <v-window-item :value="2">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="6"
                        style="height:65px; padding:0 10px; margin-bottom:30px;"
                      >
                        <v-textarea
                          v-model="formAddVendor.address"
                          no-resize
                          height="65px"
                          outlined
                          clear-icon="mdi-close-circle"
                          name="input-7-4"
                          label="Alamat"
                          :clearable="
                            getVendorAct === 'update' && perm.update_perm === 1
                          "
                          required
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-textarea>
                      </v-col>

                      <v-col
                        cols="6"
                        style="height:65px; padding:0 10px; margin-bottom:30px;"
                      >
                        <v-autocomplete
                          required
                          v-model="formAddVendor.province_id"
                          @change="generateProvince"
                          :items="getVendorDropDown.province"
                          item-text="name"
                          item-value="id"
                          return-object
                          label="Provinsi"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-autocomplete>
                      </v-col>

                      <v-col
                        cols="6"
                        style="height:65px; padding:0 10px; margin-bottom:30px;"
                      >
                        <v-autocomplete
                          v-model="formAddVendor.city_id"
                          @change="generateCity"
                          item-text="name"
                          item-value="id"
                          return-object
                          required
                          :items="city"
                          :label="showCity(getVendorDetail)"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-autocomplete>
                      </v-col>

                      <v-col
                        cols="6"
                        style="height:65px; padding:0 10px; margin-bottom:30px;"
                      >
                        <v-text-field
                          v-model="formAddVendor.zip_code"
                          label="Kode Pos"
                          type="text"
                          required
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="6"
                        style="height:65px; padding:0 10px; margin-bottom:30px;"
                      >
                        <v-text-field
                          v-model="formAddVendor.location_lat"
                          label="Lat"
                          type="text"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="6"
                        style="height:65px; padding:0 10px; margin-bottom:30px;"
                      >
                        <v-text-field
                          v-model="formAddVendor.location_long"
                          label="Lng"
                          type="text"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-window-item>

              <v-window-item :value="3">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="6"
                        style="height:65px; padding:0 10px; margin-bottom:30px;"
                      >
                        <v-autocomplete
                          required
                          v-model="formAddVendor.classification_id"
                          :items="getVendorDropDown.classification"
                          @change="generateClassification"
                          label="Klasifikasi"
                          item-text="name"
                          item-value="id"
                          return-object
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-autocomplete>
                      </v-col>

                      <v-col
                        cols="4"
                        style="height:65px; padding:0 10px;"
                        v-if="subClassification.length > 0"
                      >
                        <v-autocomplete
                          required
                          @change="generateClassification2"
                          v-model="formAddVendor.sub_classification_id"
                          :items="subClassification"
                          label="Sub Klasifikasi"
                          item-text="name"
                          item-value="id"
                          return-object
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-autocomplete>
                      </v-col>

                      <v-col
                        cols="6"
                        style="height:65px; padding:0 10px; margin-bottom:30px;"
                      >
                        <v-text-field
                          v-model="formAddVendor.website"
                          label="Website"
                          type="text"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="6"
                        style="height:65px; padding:0 10px; margin-bottom:30px;"
                      >
                        <v-combobox
                          v-model="formAddVendor.tags"
                          label="Tags"
                          multiple
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-combobox>
                      </v-col>

                      <v-col
                        cols="6"
                        style="height:65px; position:relative; padding:0 10px; margin-bottom:30px;"
                      >
                        <p style="margin:0; position:absolute; top:-10px;">
                          Logo
                        </p>
                        <v-file-input
                          @change="uploadLogo"
                          style="margin-top:10px;"
                          :label="
                            getVendorAct !== 'add'
                              ? logoName(getVendorDetail.logo)
                              : 'Upload logo'
                          "
                          prepend-icon=""
                          append-icon="mdi-attachment"
                          outlined
                          dense
                          :rules="rules.uploadRules"
                          :disabled="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-file-input>
                      </v-col>
                      <v-col
                        cols="4"
                        style="height:65px; padding:0 10px; margin-bottom:30px; position:relative; z-index:2;"
                      >
                        <v-text-field
                          v-model="formAddVendor.code"
                          label="Vendor Code"
                          type="text"
                          :readonly="
                            getVendorAct === 'update' && perm.update_perm !== 1
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col
                        v-if="getVendorAct !== 'add'"
                        cols="12"
                        style="position:relative; top:-133px; text-align:right; padding-right: 10px;"
                      >
                        <v-img
                          style="margin-left:auto;"
                          :lazy-src="
                            `${getProductImageLink}vendors/logo/${formAddVendor.logo}`
                          "
                          max-height="150"
                          max-width="250"
                          :src="
                            `${getProductImageLink}vendors/logo/${formAddVendor.logo}`
                          "
                        ></v-img>
                      </v-col>
                      <v-col
                        v-else
                        cols="12"
                        style="padding:0;position:relative; top:-133px; text-align:right; padding-right: 15px;"
                      >
                        <v-img
                          v-if="formAddVendor.logo.length > 0"
                          style="margin:auto;"
                          :lazy-src="
                            `${getProductImageLink}vendors/logo/${formAddVendor.logo}`
                          "
                          max-height="150"
                          max-width="250"
                          alt="no image uploaded"
                          :src="
                            `${getProductImageLink}vendors/logo/${formAddVendor.logo}`
                          "
                        ></v-img>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-window-item>
            </v-window>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn :disabled="step === 1" text @click="step--">
                Back
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="step === 3"
                color="primary"
                depressed
                @click="step++"
              >
                Next
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card
            v-if="getVendorAct !== 'add' && !isEditForm"
            class="mx-auto"
            width="99%"
            height="260"
            style="display:flex; justify-content: center; align-items:center; position:absolute; top:0; left:0; right:0; background: rgba(255, 255, 255, 0.8); height:100%;"
          >
            <v-progress-circular
              :size="170"
              :width="10"
              color="blue"
              indeterminate
              >Waiting Data ready</v-progress-circular
            >
          </v-card>
        </v-list>

        <v-divider></v-divider>
        <v-list v-if="getVendorAct === 'update' && getIsProfileEdit === false">
          <v-card v-if="isEditForm" class="mx-auto" width="99%">
            <v-toolbar flat color="warning" dark height="30px">
              <v-card-title
                class="title font-weight-regular justify-space-between"
              >
                <span>{{ headtitle }}</span>
              </v-card-title>
            </v-toolbar>
            <v-tabs vertical>
              <v-tab @click="subFormTitle('SIUP')">
                SIUP
              </v-tab>
              <v-tab @click="subFormTitle('NPWP')">
                NPWP
              </v-tab>
              <v-tab @click="subFormTitle('DOMISILI')">
                DOMISILI
              </v-tab>
              <v-tab @click="subFormTitle('TDP')">
                TDP
              </v-tab>
              <v-tab @click="subFormTitle('AKTA')">
                AKTA
              </v-tab>
              <v-tab @click="subFormTitle('PENGALAMAN', getVendorId)">
                PENGALAMAN
              </v-tab>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:250px;">
                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. SIUP"
                        :readonly="perm.update_perm !== 1"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        type="text"
                        v-model="formDocument.instance"
                        :readonly="perm.update_perm !== 1"
                        label="Instansi Pemberi"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            :disabled="perm.update_perm !== 1"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. SIUP"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu1 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            :disabled="perm.update_perm !== 1"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-autocomplete
                        v-model="formDocument.siup_qualification"
                        :items="[
                          { id: 1, name: 'Kecil' },
                          { id: 2, name: 'Menengah' },
                          { id: 3, name: 'Besar' },
                          { id: 4, name: 'Mikro' }
                        ]"
                        item-text="name"
                        item-value="id"
                        :readonly="perm.update_perm !== 1"
                        return-id
                        label="Kualifikasi"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        style="margin-top:10px;"
                        label="Upload Document"
                        :disabled="perm.update_perm !== 1"
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    :disabled="perm.update_perm !== 1"
                    depressed
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(1)"
                  >
                    Simpan SIUP
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:250px;">
                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. NPWP"
                        :readonly="perm.update_perm !== 1"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-textarea
                        v-model="formDocument.address"
                        no-resize
                        height="65px"
                        outlined
                        clear-icon="mdi-close-circle"
                        name="input-7-4"
                        label="Alamat"
                        :clearable="perm.update_perm === 1"
                        :readonly="perm.update_perm !== 1"
                        type="text"
                        style="margin-top:10px;"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :disabled="perm.update_perm !== 1"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu3 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu7"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            :disabled="perm.update_perm !== 1"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu7 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        type="text"
                        v-model="formDocument.instance"
                        :readonly="perm.update_perm !== 1"
                        label="KPP"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        style="margin-top:10px;"
                        label="Upload Document"
                        :disabled="perm.update_perm !== 1"
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    :disabled="perm.update_perm !== 1"
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(2)"
                  >
                    Simpan NPWP
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:150px; margin-top:20px;">
                    <v-col cols="6" style="height:65px; padding:0 10px; ">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. Surat"
                        :readonly="perm.update_perm !== 1"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu4"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :disabled="perm.update_perm !== 1"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu4 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu8"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :disabled="perm.update_perm !== 1"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu8 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        :disabled="perm.update_perm !== 1"
                        style="margin-top:10px;"
                        label="Upload Document"
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    :disabled="perm.update_perm !== 1"
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(3)"
                  >
                    Simpan Domisili
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:190px; margin-top:20px;">
                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. TDP"
                        :readonly="perm.update_perm !== 1"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu5"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :disabled="perm.update_perm !== 1"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.document_date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu5 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu9"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :disabled="perm.update_perm !== 1"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu9 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        :disabled="perm.update_perm !== 1"
                        style="margin-top:10px;"
                        label="Upload Document"
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    :disabled="perm.update_perm !== 1"
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(4)"
                  >
                    Simpan TDP
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:150px; margin-top:20px;">
                    <v-col cols="6" style="height:65px; padding:0 10px; ">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. Akta"
                        :readonly="perm.update_perm !== 1"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu6"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :disabled="perm.update_perm !== 1"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu6 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu10"
                            :disabled="perm.update_perm !== 1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu10 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        :disabled="perm.update_perm !== 1"
                        style="margin-top:10px;"
                        label="Upload Document"
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    :disabled="perm.update_perm !== 1"
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(9)"
                  >
                    Simpan Akta
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-data-table
                  :headers="expHeaders"
                  :items="getExperienceList"
                  :items-per-page="itemsPerPage"
                  class="elevation-6 mx-auto"
                  style="width:95%; margin-top: 20px; margin-bottom:20px;"
                  dense
                  hide-default-footer
                >
                  <template v-slot:top>
                    <v-toolbar
                      ><v-icon style="margin-right: 10px;"
                        >mdi-account-details</v-icon
                      >
                      <v-toolbar-title>Pengalaman</v-toolbar-title>
                      <v-divider class="mx-6" inset vertical></v-divider>
                      <v-text-field
                        v-model="invokeSrc"
                        label="Search here"
                        type="search"
                        outlined
                        dense
                        style="position: relative; top:15px; width:10px;"
                      ></v-text-field>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.no`]="{ item }">
                    <td>
                      {{ item.no + offset * itemsPerPage }}
                    </td>
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    <v-list-item v-if="item.id !== null">
                      {{ item.name }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      v-model="formAddExp.name"
                      type="text"
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.type`]="{ item }">
                    <v-list-item v-if="item.id !== null" style="padding:0;">
                      {{ item.type }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      v-model="formAddExp.type"
                      type="text"
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.location`]="{ item }">
                    <v-list-item v-if="item.id !== null" style="padding:0;">
                      {{ item.location }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      type="text"
                      v-model="formAddExp.location"
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.month`]="{ item }">
                    <v-list-item v-if="item.id !== null" style="padding:0;">
                      {{ month(item.month) }}
                    </v-list-item>
                    <v-autocomplete
                      v-else
                      v-model="formAddExp.month"
                      :items="getMonth"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                    >
                    </v-autocomplete>
                  </template>

                  <template v-slot:[`item.year`]="{ item }">
                    <v-list-item v-if="item.id !== null" style="padding:0;">
                      {{ item.year }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      v-model="formAddExp.year"
                      type="number"
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      v-if="item.id === null"
                      color="success"
                      class="ma-1 white--text"
                      small
                      :disabled="
                        perm.update_perm !== 1 || perm.create_perm !== 1
                      "
                      @click="addNewExp(2)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small>
                            mdi-content-save
                          </v-icon>
                        </template>
                        <span>Save</span>
                      </v-tooltip>
                    </v-btn>
                    <v-btn
                      v-else
                      color="cyan"
                      class="ma-1 white--text"
                      small
                      :disabled="perm.update_perm !== 1"
                      @click="editExp(item)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small>
                            mdi-pencil
                          </v-icon>
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                    </v-btn>
                    <v-btn
                      color="error"
                      class="ma-1 white--text"
                      small
                      :disabled="perm.delete_perm !== 1"
                      @click="deleteExp(item)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" small>
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </v-btn>
                  </template>
                  <template v-slot:footer>
                    <v-toolbar>
                      <v-btn
                        :disabled="
                          perm.update_perm !== 1 || perm.create_perm !== 1
                        "
                        small
                        @click="!isNewLine ? addNewExp(1) : addNewExp(0)"
                      >
                        <v-icon v-if="!isNewLine" left small>
                          mdi-plus
                        </v-icon>
                        {{ !isNewLine ? 'Add New Line' : 'cancel ' }}

                        <v-icon
                          v-if="isNewLine"
                          left
                          small
                          style="margin:0 0 0 10px;"
                        >
                          mdi-window-close
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-pagination
                        style="margin-left:auto;"
                        v-model="page"
                        :length="
                          Math.ceil(getExpTotalItems / itemsPerPage) || 1
                        "
                        :total-visible="6"
                      ></v-pagination>
                      <v-spacer></v-spacer>
                      <div
                        style="padding:0; margin:0;display: flex; flex-direction:rows; justify-content:center; align-items:center;"
                      >
                        <v-toolbar-title class="caption"
                          >Rows per page:</v-toolbar-title
                        >
                        <v-select
                          style="position: relative; margin-left:10px;height:28px; width:50px; rightt:20px; font-size:12px;"
                          v-model="itemsPerPage"
                          :items="[1, 5, 10, 15, 20, 100]"
                          dense
                        ></v-select>
                      </div>
                    </v-toolbar>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-card>
          <v-card
            v-else
            class="mx-auto"
            width="99%"
            height="260"
            style="display:flex; justify-content: center; align-items:center;"
          >
            <v-progress-circular
              :size="170"
              :width="10"
              color="blue"
              indeterminate
              >Waiting Data Ready</v-progress-circular
            >
          </v-card>
        </v-list>

        <v-list v-if="getVendorAct === 'add'">
          <v-card
            v-if="created_id !== null"
            class="mx-auto"
            width="99%"
            style="border:1px solid black;"
          >
            <v-toolbar flat color="warning" dark height="30px">
              <v-card-title
                class="title font-weight-regular justify-space-between"
              >
                <span>{{ headtitle }}</span>
              </v-card-title>
            </v-toolbar>
            <v-tabs vertical>
              <v-tab @click="subFormTitle('SIUP')">
                SIUP
              </v-tab>
              <v-tab @click="subFormTitle('NPWP')">
                NPWP
              </v-tab>
              <v-tab @click="subFormTitle('DOMISILI')">
                DOMISILI
              </v-tab>
              <v-tab @click="subFormTitle('TDP')">
                TDP
              </v-tab>
              <v-tab @click="subFormTitle('AKTA')">
                AKTA
              </v-tab>
              <v-tab @click="subFormTitle('PENGALAMAN', created_id)">
                PENGALAMAN
              </v-tab>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:250px;">
                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. SIUP"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        type="text"
                        v-model="formDocument.instance"
                        label="Instansi Pemberi"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu1"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. SIUP"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu1 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-autocomplete
                        v-model="formDocument.siup_qualification"
                        :items="[
                          { id: 1, name: 'Kecil' },
                          { id: 2, name: 'Menengah' },
                          { id: 3, name: 'Besar' },
                          { id: 4, name: 'Mikro' }
                        ]"
                        item-text="name"
                        item-value="id"
                        return-id
                        label="Kualifikasi"
                      ></v-autocomplete>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        style="margin-top:10px;"
                        label="Upload Document"
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(1)"
                  >
                    Simpan SIUP
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:250px;">
                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. NPWP"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-textarea
                        v-model="formDocument.address"
                        no-resize
                        height="65px"
                        outlined
                        clear-icon="mdi-close-circle"
                        name="input-7-4"
                        label="Alamat"
                        clearable
                        type="text"
                        style="margin-top:10px;"
                      ></v-textarea>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu3"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu3 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu7"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu7 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        type="text"
                        v-model="formDocument.instance"
                        label="KPP"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        style="margin-top:10px;"
                        label="Upload Document"
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(2)"
                  >
                    Simpan NPWP
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:150px; margin-top:20px;">
                    <v-col cols="6" style="height:65px; padding:0 10px; ">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. Surat"
                        type="text"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu4"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu4 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu8"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu8 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        style="margin-top:10px;"
                        label="Upload Document"
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(3)"
                  >
                    Simpan Domisili
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:190px; margin-top:20px;">
                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. TDP"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu5"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.document_date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu5 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu9"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu9 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        style="margin-top:10px;"
                        label="Upload Document"
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(4)"
                  >
                    Simpan TDP
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-container style="height:300px;">
                  <v-row style="height:150px; margin-top:20px;">
                    <v-col cols="6" style="height:65px; padding:0 10px; ">
                      <v-text-field
                        v-model="formDocument.document_no"
                        label="No. Akta"
                        type="text"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu6"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.date"
                                label="Tgl. Terdaftar"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.date"
                              @input="menu.menu6 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            v-model="menu.menu10"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="formDocument.expire_date"
                                label="Kadaluarsa"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="formDocument.expire_date"
                              @input="menu.menu10 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="6" style="height:65px; padding:0 10px;">
                      <v-file-input
                        @change="uploadDoc"
                        style="margin-top:10px;"
                        label="Upload Document"
                        outlined
                        dense
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <v-btn
                    outlined
                    depressed
                    color="cyan"
                    style="color:white; position: absolute; right:15px; bottom:10px;"
                    @click="saveDocument(9)"
                  >
                    Simpan Akta
                  </v-btn>
                </v-container>
              </v-tab-item>

              <v-tab-item style="padding: 15px;">
                <v-data-table
                  :headers="expHeaders"
                  :items="getExperienceList"
                  :items-per-page="itemsPerPage"
                  class="elevation-6 mx-auto"
                  style="width:90%; margin-top: 20px; margin-bottom:20px;"
                  dense
                  hide-default-footer
                  ><template v-slot:top>
                    <v-toolbar
                      ><v-icon style="margin-right: 10px;"
                        >mdi-account-details</v-icon
                      >
                      <v-toolbar-title>Pengalaman</v-toolbar-title>
                      <v-divider class="mx-6" inset vertical></v-divider>
                      <v-text-field
                        v-model="invokeSrc"
                        label="Search here"
                        type="search"
                        outlined
                        dense
                        style="position: relative; top:15px; width:10px;"
                      ></v-text-field>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.no`]="{ item }">
                    <td>
                      {{ item.no + offset * itemsPerPage }}
                    </td>
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    <v-list-item v-if="item.id !== null">
                      {{ item.name }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      v-model="formAddExp.name"
                      type="text"
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.type`]="{ item }">
                    <v-list-item v-if="item.id !== null">
                      {{ item.type }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      v-model="formAddExp.type"
                      type="text"
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.location`]="{ item }">
                    <v-list-item v-if="item.id !== null">
                      {{ item.location }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      type="text"
                      v-model="formAddExp.location"
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.month`]="{ item }">
                    <v-list-item v-if="item.id !== null" style="padding:0;">
                      {{ month(item.month) }}
                    </v-list-item>
                    <v-autocomplete
                      v-else
                      v-model="formAddExp.month"
                      :items="getMonth"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                    >
                    </v-autocomplete>
                  </template>

                  <template v-slot:[`item.year`]="{ item }">
                    <v-list-item v-if="item.id !== null">
                      {{ item.year }}
                    </v-list-item>
                    <v-text-field
                      v-else
                      v-model="formAddExp.year"
                      type="number"
                      dense
                    ></v-text-field>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom v-if="item.id === null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="addNewExp(2)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-content-save
                        </v-icon>
                      </template>
                      <span>Save</span>
                    </v-tooltip>
                    <v-tooltip bottom v-else>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="editExpFromAdd(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          small
                          class="mr-2"
                          @click="deleteExp(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Save</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:footer>
                    <v-toolbar>
                      <v-btn
                        small
                        @click="!isNewLine ? addNewExp(1) : addNewExp(0)"
                      >
                        <v-icon v-if="!isNewLine" left small>
                          mdi-plus
                        </v-icon>
                        {{ !isNewLine ? 'Add New Line' : 'cancel ' }}

                        <v-icon
                          v-if="isNewLine"
                          left
                          small
                          style="margin:0 0 0 10px;"
                        >
                          mdi-window-close
                        </v-icon>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-pagination
                        style="margin-left:auto;"
                        v-model="page"
                        :length="
                          Math.ceil(getExpTotalItems / itemsPerPage) || 1
                        "
                        :total-visible="6"
                      ></v-pagination>
                      <v-spacer></v-spacer>
                      <div
                        style="padding:0; margin:0;display: flex; flex-direction:rows; justify-content:center; align-items:center;"
                      >
                        <v-toolbar-title class="caption"
                          >Rows per page:</v-toolbar-title
                        >
                        <v-select
                          style="position: relative; margin-left:10px;height:28px; width:50px; rightt:20px; font-size:12px;"
                          v-model="itemsPerPage"
                          :items="[1, 5, 10, 15, 20, 100]"
                          dense
                        ></v-select>
                      </div>
                    </v-toolbar>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-list>
      </v-card>
      <v-card
        flat
        v-if="perm.read_perm !== 1"
        height="100vh"
        style="width:100%; left:0; top:0; display:flex; justify-content:center; align-items:center; position:fixed;"
      >
        <v-btn
          cols="12"
          color="pink"
          fab
          dark
          small
          absolute
          top
          left
          style="left:10px; top:10px;"
          @click="cancel"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="overline">
          You Can't Access This Page</v-toolbar-title
        >
      </v-card>
    </div>

    <div>
      <EditExperience v-if="getModalEditExp" />
    </div>
    <v-card
      v-if="getIsLoading === true"
      class="mx-auto"
      width="99%"
      height="260"
      style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Please wait</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EditExperience from './ExperienceEdit'
export default {
  props: ['perm'],
  data() {
    return {
      page: 1,
      offset: 0,
      itemsPerPage: 10,
      invokeSrc: '',
      searchExp: '',
      headtitle: 'SIUP',
      formAddVendor: {
        name: '',
        code: '',
        logo: '',
        npwp: '',
        business_entity_id: 0,
        // currency_id: 0,
        classification_id: 0,
        sub_classification_id: 0,
        province_id: 0,
        city_id: 0,
        address: '',
        zip_code: '',
        phone1: '',
        phone2: '',
        email: '',
        website: '',
        about: '',
        location_lat: '',
        location_long: '',
        tags: '',
        company_scale: 0,
        register_via: 1
      },
      formDocument: {
        vendor_id: '',
        document_type_id: null,
        document_no: '',
        date: null,
        expire_date: '',
        file: '',
        description: '',
        instance: null,
        siup_qualification: null,
        address: ''
      },
      formAddExp: {
        name: '',
        type: '',
        location: '',
        month: null,
        year: null
      },
      step: 1,
      date: new Date().toISOString().substr(0, 10),
      menu: {
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        menu5: false,
        menu6: false,
        menu7: false,
        menu8: false,
        menu9: false,
        menu10: false
      },
      // ------------
      expHeaders: [
        {
          text: 'No',
          align: 'start',
          sortable: false,
          value: 'no',
          width: '3%'
        },
        {
          text: 'Nama Pekerjaan',
          sortable: false,
          value: 'name',
          width: '20%'
        },
        {
          text: 'Bidang',
          sortable: false,
          value: 'type',
          width: '20%'
        },
        {
          text: 'Lokasi',
          sortable: false,
          value: 'location',
          width: '15%'
        },
        {
          text: 'Bulan',
          sortable: false,
          value: 'month',
          width: '12%'
        },
        {
          text: 'Tahun',
          sortable: false,
          value: 'year',
          width: '15%'
        },
        { text: 'Actions', sortable: false, value: 'actions', width: '15%' }
      ],
      experience: [],
      // --------------
      city: [],
      subClassification: [],
      subClassification2: [],
      isNewLine: false,
      rules: {
        uploadRules: [
          value =>
            !value ||
            value.size < 2000000 ||
            'Image size should be less than 2 MB!'
        ],
        nameRules: [v => !!v || 'Name is required'],
        // provinceRules: [v => !!v || 'Province is required'],
        // zipCodeRules: [
        //   v => !!v || 'Zip Code is required',
        //   v => v.length < 6 || 'Zip Code must be less than 6 characters',
        //   v => v.length > 4 || 'Zip Code must be more than 4 characters'
        // ],
        entityRules: [v => !!v || 'Business entity is required'],
        phone1Rules: [v => !!v || 'Phone number 1 is required']
        // addressRules: [v => !!v || 'Address is required']
      },
      created_id: null,
      isEditForm: false
    }
  },
  components: { EditExperience },
  watch: {
    invokeSrc() {
      this.searchExp = this.invokeSrc
      this.offset = 0
      this.initialize(
        this.getVendorAct === 'add' ? this.created_id : this.getVendorId
      )
    },
    page() {
      this.offset = this.page - 1
      this.initialize(
        this.getVendorAct === 'add' ? this.created_id : this.getVendorId
      )
    },
    itemsPerPage() {
      this.page = 1
      this.offset = 0
      this.initialize(
        this.getVendorAct === 'add' ? this.created_id : this.getVendorId
      )
    }
  },
  created() {
    console.log('boom')
    if (this.getVendorAct !== 'add') {
      setTimeout(() => {
        console.log(this.getVendorDetail)
        this.formAddVendor = {
          code: this.getVendorCode,
          name: this.getVendorDetail.name,
          logo: this.logoName(this.getVendorDetail.logo),
          npwp: this.getVendorDetail.npwp,
          business_entity_id:
            this.getVendorDetail.business_entity === null
              ? null
              : this.getVendorDetail.business_entity.id,
          // currency_id:
          //   this.getVendorDetail.currency === null
          //     ? null
          //     : this.getVendorDetail.currency.id,
          classification_id:
            this.getVendorDetail.classification === null
              ? null
              : this.getVendorDetail.classification.id,
          sub_classification_id:
            this.getVendorDetail.sub_classification === null
              ? null
              : this.getVendorDetail.sub_classification.id,
          province_id:
            this.getVendorDetail.province === null
              ? null
              : this.getVendorDetail.province.id,
          city_id:
            this.getVendorDetail.city === null
              ? null
              : this.getVendorDetail.city.id,
          address: this.getVendorDetail.address,
          zip_code: this.getVendorDetail.zip_code,
          phone1: this.getVendorDetail.phone1,
          phone2: this.getVendorDetail.phone2,
          email: this.getVendorDetail.email,
          website: this.getVendorDetail.website,
          about: this.getVendorDetail.about,
          location_lat: this.getVendorDetail.location_lat,
          location_long: this.getVendorDetail.location_long,
          tags: this.tagsJoin(this.getVendorDetail.tags),
          company_scale: this.getVendorDetail.company_scale,
          register_via: this.getVendorDetail.register_via
        }
        this.isEditForm = true
        this.documentLoad()
      }, 2000)
    } else {
      this.formDocument = {
        vendor_id: '',
        document_type_id: null,
        document_no: '',
        date: null,
        expire_date: '',
        file: '',
        description: '',
        instance: null,
        siup_qualification: null,
        address: ''
      }
    }
  },
  computed: {
    ...mapGetters([
      'getMonth',
      'getVendorCode',
      'getVdrAddStat',
      'getVendorAct',
      'getVendorDropDown',
      'getVendorId',
      'getExperienceList',
      'getModalEditExp',
      'getDocument',
      'getVendorDetail',
      'getExpTotalItems',
      'getIsProfileEdit',
      'getIsLoading',
      'getProductImageLink'
    ]),
    currentTitle() {
      switch (this.step) {
        case 1:
          return 'Formulir wajib 1 - 3'
        case 2:
          return 'Formulir wajib 2 - 3'
        default:
          return 'Formulir wajib 3 - 3'
      }
    }
  },
  methods: {
    ...mapActions([
      'addNewVendor',
      'editVendorData',
      'vendorDataList',
      'vendorLogo',
      'vendorExperienceList',
      'deleteExperience',
      'addNewExperience',
      'vendorDocumentUpload',
      'manageDocument',
      'detailVendor'
    ]),
    ...mapMutations([
      'vendorAdd',
      'vendorForm',
      'setNewExpLine',
      'setModalEditExp',
      'setVendroId',
      'setVendorProfileeditCon',
      'setIsLoading',
      'setUrlParam'
    ]),
    logoName(value) {
      console.log(value)
      const val =
        value !== undefined
          ? value.replace('localhost/uploads', '10.10.20.8:8020')
          : undefined
      if (val === undefined) {
        return null
      } else {
        const lastIndxOf = val.lastIndexOf('/')
        const str = val.substring(lastIndxOf + 1)
        return str
      }
    },
    cancelFromAdd() {
      if (this.getVendorAct !== 'update') {
        this.vendorAdd([true, ''])
      } else {
        this.vendorAdd([true, '', ''])
      }
      this.isEditForm = false
      this.formAddVendor = {
        name: '',
        code: '',
        logo: '',
        npwp: '',
        business_entity_id: 0,
        // currency_id: 0,
        classification_id: 0,
        sub_classification_id: 0,
        province_id: 0,
        city_id: 0,
        address: '',
        zip_code: '',
        phone1: '',
        phone2: '',
        email: '',
        website: '',
        about: '',
        location_lat: '',
        location_long: '',
        tags: '',
        company_scale: 0,
        register_via: 1
      }
    },
    cancel() {
      if (this.getVendorAct !== 'update') {
        this.vendorAdd([true, ''])
      } else {
        this.vendorAdd([true, '', ''])
      }
      this.setVendorProfileeditCon(false)
      this.created_id = null
      this.isEditForm = false

      this.formAddVendor = {
        name: '',
        code: '',
        logo: '',
        npwp: '',
        business_entity_id: 0,
        // currency_id: 0,
        classification_id: 0,
        sub_classification_id: 0,
        province_id: 0,
        city_id: 0,
        address: '',
        zip_code: '',
        phone1: '',
        phone2: '',
        email: '',
        website: '',
        about: '',
        location_lat: '',
        location_long: '',
        tags: '',
        company_scale: 0,
        register_via: 1
      }
      this.$emit('loadPage')
    },
    saveAdd() {
      const reg1 = new RegExp(' ')
      const reg2 = new RegExp('-')
      this.vendorForm(this.formAddVendor)

      if (!this.formAddVendor.name) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Vendor name must be filled',
          showConfirmButton: true,
          showCancelButton: false,
          timer: 4500
        })
      } else if (!this.formAddVendor.phone1) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'No. Handphone 1 must be filled',
          showConfirmButton: true,
          showCancelButton: false,
          timer: 4500
        })
      } else if (
        reg1.test(this.formAddVendor.phone1) ||
        reg2.test(this.formAddVendor.phone1)
      ) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: "No. Handphone can't contain space or special character",
          showConfirmButton: true,
          showCancelButton: false,
          timer: 4500
        })
      } else if (this.formAddVendor.business_entity_id === 0) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Business entity must be filled',
          showConfirmButton: true,
          showCancelButton: false,
          timer: 4500
        })
      } else if (this.formAddVendor.classification_id === 0) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Clasification must be filled',
          showConfirmButton: true,
          showCancelButton: false,
          timer: 4500
        })
      } else {
        this.$swal
          .fire({
            title: 'Save now',
            icon: 'question',
            showCancelButton: true
          })
          .then(result => {
            if (result.value) {
              this.setIsLoading(true)
              this.addNewVendor()
                .then(result => {
                  console.log(result)
                  if (result.data.status_code === '-99') {
                    this.setIsLoading(false)
                    this.$swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      showCancelButton: false,
                      timer: 4500
                    })
                  } else if (result.data.status_code === '01') {
                    this.setIsLoading(false)
                    this.$swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      showCancelButton: false,
                      timer: 4500
                    })
                  } else {
                    this.$bvToast.toast('Data berhasil ditambahkan', {
                      title: 'Success',
                      variant: 'success',
                      solid: true
                    })
                    this.vendorDataList(['', 0, 10, 'name', 'asc'])

                    setTimeout(() => {
                      this.setIsLoading(false)
                    }, 900)
                    setTimeout(() => {
                      this.vendorDetailFromAdd(result.data.created_id)
                      this.cancelFromAdd()
                    }, 1000)
                  }
                })
                .catch(error => {
                  console.log(error)
                  this.setIsLoading(false)
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error
                      ? 'Somthing went wrong, please contact an admin'
                      : 'Somthing went wrong, please contact an admin',
                    showConfirmButton: true,
                    showCancelButton: false,
                    timer: 4500
                  })
                })
            }
          })
      }
    },
    saveEdit() {
      const reg1 = new RegExp(' ')
      const reg2 = new RegExp('-')
      this.vendorForm(this.formAddVendor)
      if (!this.formAddVendor.name) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Vendor name must be filled',
          showConfirmButton: true,
          showCancelButton: false,
          timer: 4500
        })
      } else if (!this.formAddVendor.phone1) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'No. Handphone 1 must be filled',
          showConfirmButton: true,
          showCancelButton: false,
          timer: 4500
        })
      } else if (
        reg1.test(this.formAddVendor.phone1) ||
        reg2.test(this.formAddVendor.phone1)
      ) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: "No. Handphone can't contain space or special character",
          showConfirmButton: true,
          showCancelButton: false,
          timer: 4500
        })
      } else if (this.formAddVendor.business_entity_id === 0) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Business entity must be filled',
          showConfirmButton: true,
          showCancelButton: false,
          timer: 4500
        })
      } else {
        this.$swal
          .fire({
            title: 'Save now ?',
            icon: 'warning',
            showCancelButton: true
          })
          .then(result => {
            if (result.value) {
              this.setIsLoading(true)
              this.editVendorData()
                .then(result => {
                  console.log(result)
                  this.setIsLoading(false)
                  if (result.data.status_code === '-99') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      showCancelButton: false,
                      timer: 4500
                    })
                  } else if (result.data.status_code === '01') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      showCancelButton: false,
                      timer: 4500
                    })
                  } else {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'Successfully edit data',
                      showConfirmButton: true,
                      showCancelButton: false,
                      timer: 4500
                    })
                    if (this.getIsProfileEdit === true) {
                      this.vendorDetail(this.getVendorId)
                      this.cancel()
                    }
                    this.vendorDataList(['', 0, 10, 'name', 'asc'])
                  }
                })
                .catch(error => {
                  this.setIsLoading(false)
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error
                      ? 'Somthing went wrong, please contact an admin'
                      : 'Somthing went wrong, please contact an admin',
                    showConfirmButton: true,
                    showCancelButton: false,
                    timer: 4500
                  })
                })
            }
          })
      }
    },

    subFormTitle(value, paramid) {
      this.headtitle = value
      this.resetDocForm()
      this.documentLoad()
      if (value === 'PENGALAMAN') {
        this.initialize(paramid)
      }
    },

    uploadLogo(event) {
      if (event !== null) {
        this.setIsLoading(true)
        this.vendorLogo(event)
          .then(result => {
            this.setIsLoading(false)
            if (result.data.status === false) {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false,
                timer: 4500
              })
            } else if (result.data.status === true) {
              this.$swal.fire({
                position: 'center',
                icon: 'success',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false,
                timer: 4500
              })
              this.formAddVendor.logo = result.data.data.name
            } else {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false,
                timer: 4500
              })
            }
          })
          .catch(error => {
            this.setIsLoading(false)
            this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: error
                ? 'Somthing went wrong, please contact an admin'
                : 'Somthing went wrong, please contact an admin',
              showConfirmButton: true,
              showCancelButton: false,
              timer: 4500
            })
          })
      } else {
        this.formAddVendor.logo = ''
      }
    },

    saveDocument(val) {
      if (val === 1) {
        this.formDocument.document_type_id = 1
      } else if (val === 2) {
        this.formDocument.document_type_id = 2
      } else if (val === 3) {
        this.formDocument.document_type_id = 3
      } else if (val === 4) {
        this.formDocument.document_type_id = 4
      } else if (val === 9) {
        this.formDocument.document_type_id = 9
      }

      if (this.getVendorAct === 'add') {
        this.formDocument.vendor_id = this.created_id
      } else {
        this.formDocument.vendor_id = this.getVendorId
      }

      if (this.formDocument.date === null || this.formDocument.date === '') {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Tanggal terdaftar tidak boleh kosong',
          showConfirmButton: true,
          timer: 4500
        })
      } else if (
        this.formDocument.expire_date === null ||
        this.formDocument.expire_date === ''
      ) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Tanggal Kadaluarsa tidak boleh kosong',
          showConfirmButton: true,
          timer: 4500
        })
      } else {
        this.$swal
          .fire({
            title: 'Simpan perubahan',
            icon: 'question',
            showCancelButton: true
          })
          .then(result => {
            if (result.value) {
              this.setIsLoading(true)
              this.manageDocument(this.formDocument)
                .then(result => {
                  console.log(result)
                  this.setIsLoading(false)
                  if (result.data.status_code === '00') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 4500
                    })
                    if (this.getVendorAct === 'add') {
                      this.resetDocForm()
                    }
                  } else {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'error',
                      title:
                        'Error data has occurred with status code ' +
                        result.data.status_code +
                        ', please contact an Admin',
                      showConfirmButton: true,
                      timer: 4500
                    })
                  }
                })
                .catch(error => {
                  console.log(error)
                })
            }
          })
      }
    },
    uploadDoc(event) {
      this.setIsLoading(true)
      this.vendorDocumentUpload([this.headtitle.toLowerCase(), event])
        .then(result => {
          this.setIsLoading(false)
          if (result.data.status === false) {
            this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: result.data.message,
              showConfirmButton: true,
              showCancelButton: false,
              timer: 4500
            })
          } else if (result.data.status === true) {
            this.$swal.fire({
              position: 'center',
              icon: 'success',
              title: result.data.message,
              showConfirmButton: true,
              showCancelButton: false,
              timer: 4500
            })
            this.formDocument.file = result.data.data.name
          } else {
            this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: result.data.message,
              showConfirmButton: true,
              showCancelButton: false,
              timer: 4500
            })
          }
        })
        .catch(error => {
          this.setIsLoading(false)
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error
              ? 'Somthing went wrong, please contact an admin'
              : 'Somthing went wrong, please contact an admin',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 4500
          })
        })
    },

    initialize(id) {
      this.vendorExperienceList([
        id,
        this.searchExp,
        this.offset * this.itemsPerPage,
        this.itemsPerPage
      ])
    },
    addNewExp(val) {
      if (val === 1) {
        this.setNewExpLine(1)
        this.isNewLine = true
      } else if (val === 0) {
        this.setNewExpLine(0)
        this.isNewLine = false
      } else {
        const addExpForm = {
          act: 'add',
          vendor_id:
            this.getVendorAct === 'add' ? this.created_id : this.getVendorId,
          name: this.formAddExp.name,
          type: this.formAddExp.type,
          location: this.formAddExp.location,
          month: Number(this.formAddExp.month),
          year: Number(this.formAddExp.year)
        }
        this.$swal
          .fire({
            title: 'Save now',
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true
          })
          .then(result => {
            if (result.value) {
              this.setIsLoading(true)
              this.addNewExperience(addExpForm)
                .then(result => {
                  this.setIsLoading(false)
                  if (result.data.status_code !== '-99') {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: result.data.status_msg,
                      showConfirmButton: true,
                      timer: 5000
                    })
                    this.isNewLine = false
                    this.initialize(
                      this.getVendorAct === 'add'
                        ? this.created_id
                        : this.getVendorId
                    )
                    this.formAddExp = {
                      name: '',
                      type: '',
                      location: '',
                      month: null,
                      year: null
                    }
                  } else {
                    if (
                      result.data.status_msg === 'Parameter value has problem'
                    ) {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Name/ type/ location can not be empty',
                        showConfirmButton: true,
                        timer: 5000
                      })
                    } else {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: result.data.status_msg,
                        showConfirmButton: true,
                        timer: 5000
                      })
                    }
                  }
                })
                .catch(error => {
                  this.setIsLoading(false)
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: error,
                    showConfirmButton: true,
                    timer: 5000
                  })
                })
            }
          })
      }
    },
    editExp(item) {
      this.setModalEditExp([true, item])
    },
    editExpFromAdd(item) {
      this.setVendroId(this.created_id)
      this.setModalEditExp([true, item])
    },
    deleteExp(item) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true
        })
        .then(result => {
          if (result.value) {
            this.setIsLoading(true)
            this.deleteExperience(item)
              .then(result => {
                this.setIsLoading(false)
                if (result.data.status_code !== '-99') {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })
                  this.initialize(
                    this.getVendorAct === 'add'
                      ? this.created_id
                      : this.getVendorId
                  )
                } else {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })
                }
              })
              .catch(error => {
                this.setIsLoading(false)
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: error,
                  showConfirmButton: true,
                  timer: 5000
                })
              })
          }
        })
    },

    generateBsEntity(data) {
      this.formAddVendor.business_entity_id = data.id
    },
    generateProvince(data) {
      this.formAddVendor.province_id = data.id
      this.city = data.city
    },
    generateCity(data) {
      this.formAddVendor.city_id = data.id
    },
    generateClassification(data) {
      this.formAddVendor.classification_id = data.id
      this.subClassification = data.sub_classification
    },
    generateClassification2(data) {
      this.formAddVendor.sub_classification_id = data.id
      this.subClassification2 = data.sub_classification_2
    },
    tagsJoin(tagsString) {
      if (tagsString !== null) {
        const stringJoin = tagsString.split(',')
        return stringJoin
      } else {
        return ''
      }
    },
    month(val) {
      switch (val) {
        case 1:
          return 'JAN'
        case 2:
          return 'FEB'
        case 3:
          return 'MAR'
        case 4:
          return 'APR'
        case 5:
          return 'MAY'
        case 6:
          return 'JUN'
        case 7:
          return 'JUL'
        case 8:
          return 'AGS'
        case 9:
          return 'SEP'
        case 10:
          return 'OKT'
        case 11:
          return 'NOV'
        case 12:
          return 'DES'
      }
    },
    documentLoad() {
      if (this.getVendorAct !== 'add') {
        if (this.headtitle === 'SIUP') {
          setTimeout(() => {
            if (this.getDocument.siup.status_code === '00') {
              this.formDocument = {
                vendor_id: '',
                document_type_id: 1,
                document_no: this.getDocument.siup.data.document_no,
                date: this.getDocument.siup.data.date,
                expire_date: this.getDocument.siup.data.expire_date,
                file: this.getDocument.siup.data.file,
                description: '',
                instance: this.getDocument.siup.data.instance,
                siup_qualification: this.getDocument.siup.data
                  .siup_qualification,
                address: ''
              }
            } else {
              this.formDocument = {
                vendor_id: '',
                document_type_id: 1,
                document_no: '',
                date: '',
                expire_date: '',
                file: '',
                description: '',
                instance: '',
                siup_qualification: null,
                address: ''
              }
            }
          }, 1000)
        } else if (this.headtitle === 'NPWP') {
          if (this.getDocument.npwp.status_code === '00') {
            this.formDocument = {
              vendor_id: '',
              document_type_id: 1,
              document_no: this.getDocument.npwp.data.document_no,
              date: this.getDocument.npwp.data.date,
              expire_date: this.getDocument.npwp.data.expire_date,
              file: this.getDocument.npwp.data.file,
              description: '',
              instance: this.getDocument.npwp.data.instance,
              siup_qualification: null,
              address: this.getDocument.npwp.data.address
            }
          } else {
            this.formDocument = {
              vendor_id: '',
              document_type_id: 1,
              document_no: '',
              date: '',
              expire_date: '',
              file: '',
              description: '',
              instance: '',
              siup_qualification: null,
              address: ''
            }
          }
        } else if (this.headtitle === 'DOMISILI') {
          if (this.getDocument.domisili.status_code === '00') {
            this.formDocument = {
              vendor_id: '',
              document_type_id: 1,
              document_no: this.getDocument.domisili.data.document_no,
              date: this.getDocument.domisili.data.date,
              expire_date: this.getDocument.domisili.data.expire_date,
              file: this.getDocument.domisili.data.file,
              description: '',
              instance: '',
              siup_qualification: null,
              address: ''
            }
          } else {
            this.formDocument = {
              vendor_id: '',
              document_type_id: 1,
              document_no: '',
              date: '',
              expire_date: '',
              file: '',
              description: '',
              instance: '',
              siup_qualification: null,
              address: ''
            }
          }
        } else if (this.headtitle === 'TDP') {
          if (this.getDocument.tdp.status_code === '00') {
            this.formDocument = {
              vendor_id: '',
              document_type_id: 1,
              document_no: this.getDocument.tdp.data.document_no,
              date: this.getDocument.tdp.data.date,
              expire_date: this.getDocument.tdp.data.expire_date,
              file: this.getDocument.tdp.data.file,
              description: '',
              instance: '',
              siup_qualification: null,
              address: ''
            }
          } else {
            this.formDocument = {
              vendor_id: '',
              document_type_id: 1,
              document_no: '',
              date: '',
              expire_date: '',
              file: '',
              description: '',
              instance: '',
              siup_qualification: null,
              address: ''
            }
          }
        } else if (this.headtitle === 'AKTA') {
          if (this.getDocument.akta.status_code === '00') {
            this.formDocument = {
              vendor_id: '',
              document_type_id: 1,
              document_no: this.getDocument.akta.data.document_no,
              date: this.getDocument.akta.data.date,
              expire_date: this.getDocument.akta.data.expire_date,
              file: this.getDocument.akta.data.file,
              description: '',
              instance: '',
              siup_qualification: null,
              address: ''
            }
          } else {
            this.formDocument = {
              vendor_id: '',
              document_type_id: 1,
              document_no: '',
              date: '',
              expire_date: '',
              file: '',
              description: '',
              instance: '',
              siup_qualification: null,
              address: ''
            }
          }
        }
      }
    },
    showCity(data) {
      if (this.getVendorAct === 'add') {
        return 'Kota'
      } else {
        if (this.getVendorDetail.city !== null) {
          if (this.getVendorDetail.city !== undefined) {
            return this.getVendorDetail.city.name
          }
        } else {
          return ''
        }
      }
    },
    vendorDetail(item) {
      this.detailVendor(item)
        .then(result => {
          if (result.status_code === '00') {
            console.log('welcome')
          } else {
            this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Somthing went wrong, please contact an admin',
              showConfirmButton: true,
              showCancelButton: false,
              timer: 4500
            })
          }
        })
        .catch(error => {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error
              ? 'Somthing went wrong, please contact an admin'
              : 'Somthing went wrong, please contact an admin',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 4500
          })
        })
    },
    resetDocForm() {
      this.formDocument = {
        vendor_id: '',
        document_type_id: null,
        document_no: '',
        date: null,
        expire_date: '',
        file: '',
        description: '',
        instance: null,
        siup_qualification: null,
        address: ''
      }
    },
    vendorDetailFromAdd(id) {
      this.detailVendor(id)
        .then(result => {
          if (result.status_code === '00') {
            const vendorName = result.data.name
              .toLowerCase()
              .split(' ')
              .join('_')
            this.setUrlParam([result.data.id, vendorName])
            this.$router.push(
              `/vendor-profile/?${result.data.id}=${vendorName}`
            )
          } else {
            this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: 'Somthing went wrong, please contact an admin',
              showConfirmButton: true,
              showCancelButton: false,
              timer: 4500
            })
          }
        })
        .catch(error => {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error
              ? 'Somthing went wrong, please contact an admin'
              : 'Somthing went wrong, please contact an admin',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 4500
          })
        })
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/vendorManage.scss"></style>
