<template>
  <div id="productEdit" class="col-12">
    <div class="ue-1" v-if="perm.read_perm === 1">
      <v-card style="min-height:99vh; padding-bottom: 30px;">
        <v-card-title>
          <span class="headline">Add New Product</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                style="padding: 0 15px; height:45px; justify-content:flex-start; align-items:center; display:flex;"
              >
                <p
                  style="
                      margin:0;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                >
                  Investasi
                </p>
                <v-checkbox
                  v-model="form.is_asset"
                  style=" margin:0 0 0 10px; height:25px; padding:0;"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-text-field
                  prepend-icon="mdi-account-plus-outline"
                  v-model="form.name"
                  label="Name*"
                  required
                  outlined
                  dense
                  :rules="rules.nameRules"
                  style="margin-top:10px;"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-text-field
                  prepend-icon="mdi-ab-testing"
                  v-model="form.code"
                  label="Code"
                  required
                  outlined
                  dense
                  style="margin-top:10px;"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-autocomplete
                  prepend-icon="mdi-shape-outline"
                  v-model="form.category_id"
                  :items="getCategoryDropdown"
                  outlined
                  dense
                  label="Kategori*"
                  style="margin-top:10px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  :rules="rules.categoryRules"
                  required
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-autocomplete
                  v-model="form.unit_id"
                  prepend-icon="mdi-unity"
                  :items="getUnitDropdown"
                  outlined
                  dense
                  label="Unit Satuan*"
                  style="margin-top:10px;"
                  item-text="name"
                  item-value="id"
                  return-id
                  :rules="rules.unitRules"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-text-field
                  prepend-icon="mdi-watermark"
                  v-model="form.merk"
                  label="Merk"
                  required
                  outlined
                  dense
                  style="margin-top:10px;"
                ></v-text-field>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-textarea
                  prepend-icon="mdi-clipboard-list-outline"
                  v-model="form.spesification"
                  no-resize
                  height="65px"
                  outlined
                  dense
                  clear-icon="mdi-close-circle"
                  name="input-7-4"
                  label="Spesifikasi"
                  required
                  style="margin-top:10px;"
                  clearable
                ></v-textarea>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-file-input
                  :rules="rules.rules1"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  style="margin-top:30px;"
                  label="Foto 1"
                  outlined
                  clearable
                  dense
                  @change="img1_upload"
                ></v-file-input>
              </v-col>
              <v-col cols="12" style="height:65px; padding:0 10px;">
                <v-file-input
                  :rules="rules.rules1"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  style="margin-top:30px;"
                  label="Foto 2"
                  outlined
                  dense
                  @change="img2_upload"
                ></v-file-input> </v-col
              ><v-col cols="12" style="height:65px; padding:0 10px;">
                <v-file-input
                  :rules="rules.rules1"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  style="margin-top:30px;"
                  label="Foto 3"
                  outlined
                  dense
                  @change="img3_upload"
                ></v-file-input> </v-col
              ><v-col cols="12" style="height:65px; padding:0 10px;">
                <v-file-input
                  :rules="rules.rules1"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  style="margin-top:30px;"
                  label="Foto 4"
                  outlined
                  dense
                  @change="img4_upload"
                ></v-file-input> </v-col
              ><v-col cols="12" style="height:65px; padding:0 10px;">
                <v-file-input
                  :rules="rules.rules1"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  style="margin-top:30px;"
                  label="Foto 5"
                  outlined
                  dense
                  @change="img5_upload"
                ></v-file-input>
              </v-col>

              <v-card
                v-if="getIsLoading === true"
                class="mx-auto"
                width="99%"
                height="260"
                style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
              >
                <v-progress-circular
                  :size="170"
                  :width="10"
                  color="blue"
                  indeterminate
                  >Please wait</v-progress-circular
                >
              </v-card>
            </v-row>
          </v-container>
          <div
            style="display: flex; flex-direction:column; padding:10px 0 0 40px;"
          >
            <small>- * indicates required field</small>
            <small>- Max image size = 2MB</small>
            <small>- Image resolution must be 1920x1080 px</small>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            :loading="getIsLoading"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>

    <v-card
      flat
      height="97vh"
      v-else
      color="white"
      style="position:fixed; width: 50%; display:flex; justify-content:center; align-items:center; padding:20px; background:#e0e0e0;"
    >
      <v-toolbar-title class="overline">
        You Can't Access This Page</v-toolbar-title
      >
      <v-btn
        color="grey"
        style="position:absolute; top:10px; left:10px;color:white;"
        @click="cancel"
      >
        <v-icon left>
          mdi-arrow-left-circle
        </v-icon>
        Close
      </v-btn>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['perm', 'keyword', 'orderType', 'orderBy'],
  data() {
    return {
      loading: false,
      categorySearch: '',
      unitSearch: '',
      form: {
        act: 'add',
        category_id: null,
        name: '',
        code: '',
        unit_id: null,
        merk: '',
        spesification: '',
        photo_1: '',
        photo_2: '',
        photo_3: '',
        photo_4: '',
        photo_5: '',
        is_asset: false
      },
      rules: {
        rules1: [
          value =>
            !value ||
            value.size < 2000000 ||
            'Avatar size should be less than 2 MB!'
        ],
        nameRules: [v => !!v || 'Name is required'],
        categoryRules: [v => !!v || 'Category is required'],
        unitRules: [v => !!v || 'Unit is required']
      }
    }
  },
  watch: {},
  components: {},
  created() {
    this.dropDownProduct()
    this.dropDownUnit()
  },
  computed: {
    ...mapGetters([
      'getProductAddStat',
      'getCategoryProductList',
      'getCategoryDropdown',
      'getUnitDropdown',
      'getIsLoading'
    ])
  },
  methods: {
    ...mapActions([
      'adminProductAdd',
      'adminProductList',
      'initProduct',
      'categoryProduct',
      'unitProduct',
      'uploadImgMasterProduct'
    ]),
    ...mapMutations(['productAdd', 'setIsLoading']),
    // dummy v-autocomplete
    dropDownProduct() {
      this.categoryProduct(this.categorySearch)
    },
    dropDownUnit() {
      this.unitProduct(this.unitSearch)
    },
    // ---------
    categorySelect(value) {
      this.form.category_id = value.id
    },
    unitSelect(value) {
      this.form.unit_id = value.id
    },
    img1_upload(event) {
      if (event !== null) {
        if (event.size <= 1024 * 1024) {
          const data = new FormData()
          data.append('photo', event)
          data.append('index', 1)

          this.uploadImgMasterProduct(data)
            .then(results => {
              if (results.data.message === 'No file uploaded') {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              } else {
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              }

              this.form.photo_1 = results.data.data.name
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.$swal.fire({
            position: 'center',
            title: 'Max size = 1MB',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          this.form.photo_1 = null
        }
      } else {
        this.form.photo_1 = null
      }
    },
    img2_upload(event) {
      if (event !== null) {
        if (event.size <= 1024 * 1024) {
          const data = new FormData()
          data.append('photo', event)
          data.append('index', 2)

          this.uploadImgMasterProduct(data)
            .then(results => {
              if (results.data.message === 'No file uploaded') {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              } else {
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              }
              this.form.photo_2 = results.data.data.name
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.$swal.fire({
            position: 'center',
            title: 'Max size = 1MB',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          this.form.photo_2 = null
        }
      } else {
        this.form.photo_2 = null
      }
    },
    img3_upload(event) {
      if (event !== null) {
        if (event.size <= 1024 * 1024) {
          const data = new FormData()
          data.append('photo', event)
          data.append('index', 3)

          this.uploadImgMasterProduct(data)
            .then(results => {
              if (results.data.message === 'No file uploaded') {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              } else {
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              }
              this.form.photo_3 = results.data.data.name
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.$swal.fire({
            position: 'center',
            title: 'Max size = 1MB',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          this.form.photo_3 = null
        }
      } else {
        this.form.photo_3 = null
      }
    },
    img4_upload(event) {
      if (event !== null) {
        if (event.size <= 1024 * 1024) {
          const data = new FormData()
          data.append('photo', event)
          data.append('index', 4)

          this.uploadImgMasterProduct(data)
            .then(results => {
              if (results.data.message === 'No file uploaded') {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              } else {
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              }
              this.form.photo_4 = results.data.data.name
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.$swal.fire({
            position: 'center',
            title: 'Max size = 1MB',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          this.form.photo_4 = null
        }
      } else {
        this.form.photo_4 = null
      }
    },
    img5_upload(event) {
      if (event !== null) {
        if (event.size <= 1024 * 1024) {
          const data = new FormData()
          data.append('photo', event)
          data.append('index', 5)

          this.uploadImgMasterProduct(data)
            .then(results => {
              if (results.data.message === 'No file uploaded') {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              } else {
                this.$swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: results.data.message,
                  showConfirmButton: true,
                  showCancelButton: false,
                  timer: 5000
                })
              }
              this.form.photo_5 = results.data.data.name
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.$swal.fire({
            position: 'center',
            title: 'Max size = 1MB',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          this.form.photo_5 = null
        }
      } else {
        this.form.photo_5 = null
      }
    },
    cancel() {
      if (!this.getProductAddStat) {
        this.productAdd(true)
      } else {
        this.productAdd(false)
      }
      this.form = {
        category_id: null,
        name: '',
        unit_id: null,
        merk: '',
        spesification: '',
        photo_1: '',
        photo_2: '',
        photo_3: '',
        photo_4: '',
        photo_5: ''
      }
    },
    save() {
      this.setIsLoading(true)
      this.adminProductAdd(this.form)
        .then(results => {
          if (results.data.status_code === '-99') {
            console.log(results.data)
            this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: results.data.status_msg
                ? 'Invalid input, Required field must be filled'
                : 'Something went wrong, please try again',
              showConfirmButton: true,
              showCancelButton: false,
              timer: 5000
            })
          } else {
            this.form = {
              category_id: null,
              name: '',
              code: '',
              unit_id: null,
              merk: '',
              spesification: '',
              photo_1: '',
              photo_2: '',
              photo_3: '',
              photo_4: '',
              photo_5: ''
            }

            this.adminProductList([
              this.keyword,
              0,
              10,
              this.orderBy,
              this.orderType
            ])
              .then(results => {
                this.initProduct(results)
              })
              .catch(error => {
                console.log(error)
              })

            this.$swal
              .fire({
                position: 'center',
                icon: 'success',
                title: results.data.status_msg,
                showConfirmButton: true,
                timer: 5000
              })
              .then(result => {
                this.productAdd(true)
                // this.$swal
                //   .fire({
                //     title: 'Add again',
                //     icon: 'question',
                //     showCancelButton: true
                //   })
                //   .then(result => {
                //     console.log(result)
                //     if (!result.value) {
                //       if (!this.getAddStat) {
                //       } else {
                //         this.productAdd(false)
                //       }
                //     }
                //   })
              })
          }

          setTimeout(() => {
            this.setIsLoading(false)
          }, 200)
        })
        .catch(error => {
          console.log(error)
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error,
            showConfirmButton: true,
            showCancelButton: false,
            timer: 5000
          })
          setTimeout(() => {
            this.setIsLoading(false)
          }, 200)
        })
    },
    imageValidation() {}
  }
}
</script>

<style lang="scss" src="../../assets/css/productManage.scss"></style>
