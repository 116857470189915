import axios from 'axios'
import buildType from '../../services/buildType'
export default {
  state: {
    procAPI: buildType.apiURL('proc'),
    uploadAPI: buildType.apiURL('upload')
  },
  mutations: {},
  actions: {
    manageDocument(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/document/save`, payload)
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    documentDownload(context, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${context.state.uploadAPI}download/vendor/${payload[0]}/${payload[1]}`,
          method: 'GET',
          responseType: 'blob'
        })
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', payload)
            document.body.appendChild(link)
            link.click()
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {}
}
