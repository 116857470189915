<template>
  <v-dialog v-model="dialog" width="60%">
    <template v-slot:activator="{ on, attrs }">
      <p
        style="margin:0;"
        v-bind="attrs"
        v-on="on"
        id="procQuotationDetailForm"
      ></p>
    </template>

    <v-form
      ref="entryForm"
      @submit.prevent="submit"
      style="position: relative;"
    >
      <v-card style="padding:20px;" class="text-center">
        <v-row>
          <v-col cols="12" style="font-size:22px; font-weight:bold;">
            FORM EDIT
            <v-icon
              style="position:absolute; top:0; right:0; cursor:pointer;"
              @click="close"
              >mdi-close</v-icon
            >
          </v-col>
          <v-col
            cols="12"
            style="border:1px solid #e0e0e0; text-align:left; margin-bottom:10px; display:flex; flex-wrap:wrap;"
          >
            <div style="width:50%;">
              <v-col cols="12" style="padding:0;">
                <p class="ma-2" style="margin:0; font-weight:bold;">
                  Nama
                </p>
              </v-col>
              <v-col cols="12" style="padding:0;">
                <v-text-field
                  class="ma-2"
                  style="margin:0;height:42px;"
                  dense
                  outlined
                  truncate-length="10"
                ></v-text-field>
              </v-col>
            </div>
            <div style="width:50%;">
              <v-col cols="12" style="padding:0;">
                <p class="ma-2" style="margin:0; font-weight:bold;">
                  Kategori
                </p>
              </v-col>
              <v-col cols="12" style="padding:0;">
                <v-text-field
                  class="ma-2"
                  style="margin:0;height:42px;"
                  dense
                  outlined
                  truncate-length="10"
                ></v-text-field>
              </v-col>
            </div>
            <div style="width:50%;">
              <v-col cols="12" style="padding:0;">
                <p class="ma-2" style="margin:0; font-weight:bold;">
                  Qty
                </p>
              </v-col>
              <v-col cols="12" style="padding:0;">
                <v-text-field
                  class="ma-2"
                  style="margin:0;height:42px;"
                  dense
                  outlined
                  truncate-length="10"
                ></v-text-field>
              </v-col>
            </div>
            <div style="width:50%;">
              <v-col cols="12" style="padding:0;">
                <p class="ma-2" style="margin:0; font-weight:bold;">
                  Harga
                </p>
              </v-col>
              <v-col cols="12" style="padding:0;">
                <v-text-field
                  class="ma-2"
                  style="margin:0;height:42px;"
                  dense
                  outlined
                  truncate-length="10"
                ></v-text-field>
              </v-col>
            </div>
            <div style="width:100%;">
              <v-col cols="12" style="padding:0;">
                <p class="ma-2" style="margin:0; font-weight:bold;">
                  Deskripsi
                </p>
              </v-col>
              <v-col cols="12" style="padding:0;">
                <v-textarea
                  auto-grow
                  class="ma-2"
                  style="margin:0;"
                  dense
                  outlined
                  truncate-length="10"
                ></v-textarea>
              </v-col>
            </div>
          </v-col>
          <v-col
            cols="12"
            style="font-size:22px; font-weight:bold; text-align:right;"
          >
            <v-btn
              type="submit"
              style="letter-spacing:5px;"
              large
              color="blue-grey"
              class="ma-2 white--text"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
// import { mapActions, mapGetters, mapMutations } from 'vuex'
// import axios from 'axios'
import buildType from '../../../../services/buildType'
export default {
  components: {},
  data() {
    return {
      proc: buildType.apiURL('proc'),
      upload: buildType.apiURL('upload2'),
      download: buildType.apiURL('download'),
      dialog: false
      // letter: []
    }
  },
  computed: {},
  created() {},
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    submit() {
      // this.letter = [v => !!v || 'Quotation letter file is required']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const form = {}
      this.save(form)
    },
    save(form) {
      console.log(form)
      //   axios
      //     .post(`${this.proc}member/save`, form)
      //     .then(res => {
      //       console.log(res)
      //       if (res.data.status_code !== '-99') {
      //         this.showMsgDialog('success', res.data.status_msg, false)
      //         this.$emit('reload')
      //         this.close()
      //       } else {
      //         if (res.data.status_msg === 'Parameter value has problem') {
      //           this.showMsgDialog(
      //             'error',
      //             'Invalid input, Parameter value has problem',
      //             false
      //           )
      //         } else {
      //           this.showMsgDialog('error', res.data.status_msg, false)
      //         }
      //       }
      //     })
      //     .catch(err => {
      //       this.showMsgDialog(
      //         'error',
      //         err
      //           ? 'Something went wrong, Please contact an admin!'
      //           : 'Something went wrong, Please contact an admin!',
      //         false
      //       )
      //     })
    },
    close() {
      // this.letter = []
      this.dialog = false
    }
  }
}
</script>
<style lang="scss" scoped></style>
