import axios from 'axios'
import router from '../../router/index'
import Swal from 'sweetalert2'
import buildType from '../../services/buildType'
export default {
  state: {
    oAuth: buildType.apiURL('oauth'),
    login: false,
    token: localStorage.getItem('token') || null,
    userProfile: null
  },
  mutations: {
    setLogin(state) {
      state.login = true
    },
    setLogout(state) {
      state.login = false
    },
    setToken(state, payload) {
      state.token = payload.token
    },
    setLoginUserData(state, payload) {
      if (payload === null) {
        state.userProfile = payload
      } else {
        state.userProfile = {
          employee: payload.employee,
          employee_id: payload.employee_id,
          level: payload.level,
          sanqua_company_id: payload.sanqua_company_id,
          sanqua_company_name: payload.sanqua_company_name,
          user_id: payload.user_id,
          user_type: payload.user_type,
          username: payload.username,
          vendor_id: payload.vendor_id
        }
      }
    }
  },
  actions: {
    tokenValidation(context, payload) {
      const code = {
        code: payload
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.oAuth}user/verify_forgot_password`, code)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    verifyData(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.oAuth}user/verify_token?token=${payload}&method=conventional`
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    changePasswordNow(context, payload) {
      const newForm = {
        email: payload[0].email,
        old_password: payload[0].old_password,
        new_password: payload[0].confirm_new_password
      }
      console.log(newForm)
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.oAuth}user/logged_change_password`, newForm)
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    adminLogin(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.oAuth}user/login`, payload)
          .then(response => {
            if (response.data.status_code === '-99') {
            } else {
              context.commit('setLogin')
              context.commit('setToken', response.data)
              localStorage.setItem('token', response.data.token)
              context.commit('setLoginUserData', response.data)
              router.push('/')
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    emailForgot(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.oAuth}user/forgot_password`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    logout(context) {
      if (context.state.login === true) {
        localStorage.removeItem('token')
        context.commit('setLogout')
        context.commit('setLoginUserData', null)
        router.push('/login')
      }
    },
    interceptorRequest(context) {
      axios.interceptors.request.use(
        function(config) {
          config.headers['x-method'] = 'conventional'
          config.headers['x-token'] = `${context.state.token}`
          config.headers['x-application-id'] = 2
          return config
        },
        function(error) {
          return Promise.reject(error)
        }
      )
    },
    interceptorResponse(context) {
      return new Promise((resolve, reject) => {
        axios.interceptors.response.use(
          function(response) {
            resolve(response)
            return response
            // if (response.data.status_msg === 'Login successfully') {
            //   return response
            // }
            // if (response.data.token_data !== undefined) {
            //   if (response.data.token_data.status_code === '-99') {
            //     if (
            //       response.data.token_data.err_msg.name === 'JsonWebTokenError'
            //     ) {
            //       Swal.fire('Notice!', 'Invalid Token', 'error')
            //       localStorage.removeItem('token')
            //       context.commit('setLogout')
            //       context.commit('setLoginUserData', null)
            //       router.push('/login')
            //       // return response
            //     } else if (
            //       response.data.token_data.err_msg.name === 'TokenExpiredError'
            //     ) {
            //       Swal.fire(
            //         'Notice!',
            //         'Token expired, please login again',
            //         'error'
            //       )
            //       localStorage.removeItem('token')
            //       context.commit('setLogout')
            //       context.commit('setLoginUserData', null)
            //       router.push('/login')
            //       // return response
            //     }
            //   } else {
            //     return response
            //   }
            // } else {
            //   if (response.data.status_code === '-99') {
            //     if (response.data.status_msg === 'Data not found') {
            //       return response
            //     } else if (
            //       response.data.status_msg ===
            //       'Incorrect old password. Please try again using correct old password'
            //     ) {
            //       return response
            //     } else {
            //       if (response.data.err_msg.name === 'JsonWebTokenError') {
            //         Swal.fire('Notice!', 'Invalid Token', 'error')
            //         localStorage.removeItem('token')
            //         context.commit('setLogout')
            //         context.commit('setLoginUserData', null)
            //         router.push('/login')
            //         // return response
            //       } else if (response.data.err_msg.name === 'TokenExpiredError') {
            //         Swal.fire(
            //           'Notice!',
            //           'Token expired, please login again',
            //           'error'
            //         )
            //         localStorage.removeItem('token')
            //         context.commit('setLogout')
            //         context.commit('setLoginUserData', null)
            //         router.push('/login')
            //         // return response
            //       }
            //     }
            //   } else {
            //     return response
            //   }
            // }
          },

          function(error) {
            console.log(error.message)
            if (error.response.status === 400) {
              Swal.fire(
                'Notice!',
                'Something went worng, Please contact an admin',
                'error'
              )
              localStorage.removeItem('token')
              context.commit('setLogout')
              context.commit('setLoginUserData', null)
              router.push('/login')
            }
            reject(error)
            return Promise.reject(error)
          }
        )
      })
    }
  },
  getters: {
    getLogin(state) {
      return state.login
    },
    getToken(state) {
      return state.token
    },
    getUserProfile(state) {
      return state.userProfile
    }
  }
}
