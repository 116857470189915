import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import JsonExcel from 'vue-json-excel'

import {
  ImagePlugin,
  BootstrapVue,
  IconsPlugin,
  ToastPlugin,
  CarouselPlugin
} from 'bootstrap-vue'

import VueSweetalert2 from 'vue-sweetalert2'

import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
// import 'sweetalert2/dist/sweetalert2.min.css'

require('dotenv').config()

Vue.component('downloadExcel', JsonExcel)
Vue.use(VueSweetalert2)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ToastPlugin)
Vue.use(CarouselPlugin)
Vue.use(ImagePlugin)
Vue.use(require('vue-moment'))

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
