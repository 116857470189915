import axios from 'axios'
import buildType from '../../services/buildType'
export default {
  state: {
    production: process.env.VUE_APP_BUILD_TYPE === 'domain',
    procAPI: buildType.apiURL('proc'),
    uploadAPI: buildType.apiURL('upload'),

    productEditStatus: true,
    productAddStatus: true,
    productDetailStatus: false,
    productCompareStatus: true,
    defaultData: [],
    productData: {},
    categoryDropdown: [],
    unitDropdown: [],
    idEdit: '',
    totalItems: 0,
    compareItems: [],
    detailProduct: [],

    isCompareLoading: false
  },
  mutations: {
    setCompareLoading(state, payload) {
      state.isCompareLoading = payload
    },
    productEdit(state, payload) {
      state.productEditStatus = payload
    },
    productAdd(state, payload) {
      state.productAddStatus = payload
    },
    productCompare(state, payload) {
      state.productCompareStatus = payload[0]
      // state.compareItems = payload[1]
    },
    setProductCompare(state, payload) {
      state.compareItems = payload
    },
    setProductDetail(state, payload) {
      state.productDetailStatus = payload
    },
    setLoadDataProduct(state, payload) {
      state.defaultData = []
      state.totalItems = payload.data.total_record
      const response = payload.data.data
      console.log(response)
      if (response !== undefined) {
        for (let i = 0; i < response.length; i++) {
          state.defaultData.push({
            no: 1 + i,
            id: response[i].id,
            name: response[i].name,
            merk: response[i].merk,
            code: response[i].code,
            spesification: response[i].spesification,
            unit: response[i].unit !== null ? response[i].unit : 0,
            category: response[i].category !== null ? response[i].category : 0,
            created: response[i].created_at,
            last_update: response[i].updated_at,
            created_by: response[i].created_by_name,
            update_by: response[i].updated_by_name,
            image: response[i].photo,
            category_detail: {
              erp_id:
                response[i].category !== null
                  ? response[i].category.erp_id
                  : 'null',
              id:
                response[i].category !== null
                  ? response[i].category.id
                  : 'null',
              category_name:
                response[i].category !== null
                  ? response[i].category.name
                  : 'null',
              category_img:
                response[i].category !== null
                  ? response[i].category.photo
                  : 'null',
              category_created:
                response[i].category !== null
                  ? response[i].category.createdAt
                  : 'null',
              category_updated:
                response[i].category !== null
                  ? response[i].category.updatedAt
                  : 'null'
            },
            is_asset: response[i].is_asset
          })
        }
      } else {
        state.defaultData = []
      }
    },
    setProductData(state, payload) {
      state.productData = payload
    },
    setCategoryProductList(state, payload) {
      state.categoryDropdown = payload
    },
    setUnitDropdown(state, payload) {
      state.unitDropdown = payload
    },
    setDetailProduct(state, payload) {
      state.productData = payload
      state.detailProduct = []
      state.detailProduct.push(payload)
    }
  },
  actions: {
    initProduct(context, payload) {
      context.commit('setLoadDataProduct', payload)
    },
    adminProductList(context, payload) {
      context.state.defaultData = []
      return new Promise((resolve, reject) => {
        axios
          .get(
            `${context.state.procAPI}master/product/list?offset=${payload[1]}&limit=${payload[2]}&keyword=${payload[0]}&order_by=${payload[3]}&order_type=${payload[4]}`
          )
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    adminProductAdd(context, payload) {
      console.log(payload)
      const newForm = {
        act: payload.act,
        category_id: payload.category_id,
        name: payload.name,
        code: payload.code,
        unit_id: payload.unit_id,
        merk: payload.merk,
        spesification: payload.spesification,
        photo_1: payload.photo_1,
        photo_2: payload.photo_2,
        photo_3: payload.photo_3,
        photo_4: payload.photo_4,
        photo_5: payload.photo_5,
        is_asset: payload.is_asset ? 1 : 0
      }

      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}master/product/save`, newForm)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    adminProductEdit(context, payload) {
      const newForm = {
        act: 'update',
        name: payload.name,
        code: payload.code,
        id: context.state.productData.id,
        category_id: payload.category_id,
        unit_id: payload.unit_id,
        merk: payload.merk,
        spesification: payload.spesification,
        photo_1: payload.photo_1,
        photo_2: payload.photo_2,
        photo_3: payload.photo_3,
        photo_4: payload.photo_4,
        photo_5: payload.photo_5,
        is_asset: payload.is_asset ? 1 : 0
      }
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}master/product/save`, newForm)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    adminProductDelete(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${context.state.procAPI}master/product/delete/${payload.id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    categoryProduct(context, payload) {
      axios
        .get(
          `${context.state.procAPI}master/product_category/drop_down?keyword=${payload}`
        )
        .then(response => {
          context.commit('setCategoryProductList', response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    unitProduct(context, payload) {
      axios
        .get(`${context.state.procAPI}master/unit/drop_down?keyword=${payload}`)
        .then(response => {
          context.commit('setUnitDropdown', response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    uploadImgMasterProduct(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.uploadAPI}${
              context.state.production ? 'upload/' : ''
            }product/photo`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    productViewDetail(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${context.state.procAPI}master/product/detail/${payload.id}`)
          .then(response => {
            context.commit('setDetailProduct', response.data.data)
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    compareProduct(context, payload) {
      context.state.compareItems = []
      let arrProduct = []
      arrProduct = []
      for (let i = 0; i < payload.length; i++) {
        axios
          .get(`${context.state.procAPI}master/product/detail/${payload[i].id}`)
          .then(response => {
            arrProduct.push(response.data.data)
          })
          .catch(error => {
            console.log(error)
          })
      }
      setTimeout(() => {
        context.commit('setCompareLoading', true)
        context.commit('setProductCompare', arrProduct)
      }, 3000)
    }
  },
  getters: {
    getProductData(state) {
      return state.defaultData
    },
    getProductEditStat(state) {
      return state.productEditStatus
    },
    getProductAddStat(state) {
      return state.productAddStatus
    },
    getProductCompareStat(state) {
      return state.productCompareStatus
    },
    getCategoryDropdown(state) {
      return state.categoryDropdown
    },
    getUnitDropdown(state) {
      return state.unitDropdown
    },
    getTotalItems(state) {
      return state.totalItems
    },
    getCompareItems(state) {
      return state.compareItems
    },
    getProductDetail(state) {
      return state.productData
    },
    getProductDetailStat(state) {
      return state.productDetailStatus
    },
    getDetailProduct(state) {
      return state.detailProduct
    },
    getLoadCompare(state) {
      return state.isCompareLoading
    }
  }
}
