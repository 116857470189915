<template>
  <v-card
    flat
    tile
    style="background:transparent; width:100%;"
    class="black--text text-center"
  >
    <v-card-text class="black--text">
      {{ new Date().getFullYear() }} —
      <strong>Sanqua Multi International</strong>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data: () => ({}),
  components: {},
  created() {},
  computed: {},
  methods: {}
}
</script>

<style>
.fsa {
  color: #eeeeee;
}
</style>
