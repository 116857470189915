<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="100%">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="perm.create_perm !== 1"
          small
          tile
          color="#e0e0e0"
          class="ma-2 black--text"
          style="width:100px; position:relative;"
        >
          Import
          <v-icon right dark>
            mdi-file-import-outline
          </v-icon>
        </v-btn>
      </template>

      <v-card style="min-height:100vh; padding:20px; background:#e0e0e0;">
        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:10px 15px 20px 15px; width:95%; margin-top:30px;"
        >
          <v-row>
            <v-col cols="12">
              <v-toolbar flat style="border-radius:10px 10px 0 0;">
                <v-btn
                  class="mx-2 elevation-4"
                  dark
                  large
                  color="cyan"
                  style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                >
                  <v-icon large dark>
                    mdi-upload
                  </v-icon>
                </v-btn>
                <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
                <v-toolbar-title>Upload File</v-toolbar-title>
              </v-toolbar>
            </v-col>
            <v-col cols="12" style="padding:0;">
              <p style="margin:0; font-size:14px; padding-left:35px;">
                Please download tempalate for upload <a href="#">here</a>
              </p>
            </v-col>
            <v-col cols="12" style="padding-left:35px;">
              <p style="border-top:5px solid cyan;"></p>
            </v-col>
            <v-col cols="12" style="padding: 0 0 30px 30px;">
              <v-row>
                <v-col cols="10">
                  <v-file-input
                    show-size
                    label="File input"
                    v-model="uploadFile"
                  />
                </v-col>
                <v-col cols="2">
                  <v-btn
                    tile
                    small
                    color="grey"
                    class="ma-2 white--text"
                    @click="vendorImport"
                    :disabled="uploadFile === null"
                  >
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-card
          elevation="2"
          class=" mx-auto"
          style="padding:20px 10px; width:95%; margin-top:20px;"
        >
          <v-row>
            <v-col cols="12">
              <v-data-table
                dense
                :headers="headers"
                :items="vendorData"
                class="elevation-1 mx-auto row-pointer"
                style="width:99%; margin-top: 20px; margin-bottom:20px;"
                :items-per-page="itemsPerPage"
              >
                <template v-slot:top>
                  <v-toolbar
                    flat
                    style="height:100px; border-radius:10px 10px 0 0; border-bottom:1px solid #e0e0e0;"
                  >
                    <v-btn
                      class="mx-2 elevation-4"
                      dark
                      large
                      color="cyan"
                      style="position:relative; top:-20px; left:-10px; height:80px; width:80px;"
                    >
                      <v-icon large dark>
                        mdi-clipboard-list-outline
                      </v-icon>
                    </v-btn>
                    <!-- <v-icon style="margin-right: 10px;">mdi-handshake</v-icon> -->
                    <v-toolbar-title>Vendor Import Result</v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.address`]="{ item }">
                  <v-list-item-content style="width:200px;">
                    <v-list-item-title style="font-size:12px;">
                      {{ item.address }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-data-table>
              <v-col cols="12" style="padding:0 5px;">
                <v-btn
                  small
                  tile
                  style="margin-right:20px;"
                  color="success"
                  height="30px"
                  width="100px"
                  :disabled="!isImport"
                  @click="saveVendorImport"
                >
                  Save Data
                </v-btn>
                <v-btn
                  small
                  tile
                  color="pink"
                  style="color:white;"
                  height="30px"
                  width="100px"
                  :disabled="!isImport"
                  @click="remove"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-col>
          </v-row>
        </v-card>
      </v-card>
      <v-card
        v-if="getIsLoading === true"
        class="mx-auto"
        width="99%"
        height="260"
        style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
      >
        <v-progress-circular :size="170" :width="10" color="blue" indeterminate
          >Please wait</v-progress-circular
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['perm'],
  data() {
    return {
      dialog: false,
      isUpload: false,
      uploadFile: null,
      isImport: false,
      itemsPerPage: 10,

      headers: [
        {
          text: 'Code',
          sortable: false,
          value: 'code',
          align: 'left',
          width: '10%',
          fontSize: '12px'
        },
        { text: 'Name', value: 'name', align: 'left', width: '13%' },
        { text: 'Email', value: 'email', align: 'left', width: '13%' },
        { text: 'Phone', value: 'phone1', align: 'left', width: '10%' },
        { text: 'NPWP', value: 'npwp', align: 'left', width: '12%' },
        { text: 'Website', value: 'website', align: 'left', width: '10%' },
        {
          text: 'Classification',
          value: 'classification_id',
          align: 'left',
          width: '12%'
        },
        {
          text: 'Address',
          value: 'address',
          align: 'left'
        }
      ],
      vendorData: []
    }
  },
  computed: {
    ...mapGetters(['getIsLoading'])
  },
  methods: {
    ...mapActions(['vendorFile', 'saveImportVendor']),
    ...mapMutations(['setIsLoading']),
    showMsgDialog(pModalType, pStatusMsg) {
      this.$swal
        .fire({
          // title:
          //   pModalType !== 'success'
          //     ? '<strong>Failed</strong>'
          //     : '<strong>Success</strong>',
          // icon: pModalType,
          // html: pStatusMsg,
          // timer: 5000
          icon: pModalType,
          html: `<strong style="font-size:18px;">${pStatusMsg}</strong>`,
          showConfirmButton: true,
          showCancelButton: true
        })
        .then(r => {
          if (pModalType !== 'error') {
            location.reload()
          }
        })
    },
    remove() {
      this.vendorData = []
      this.isImport = false
    },
    clear() {
      this.uploadFile = null
    },
    vendorImport() {
      this.vendorData = []
      if (
        this.uploadFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        this.setIsLoading(true)
        const data = new FormData()
        data.append('file', this.uploadFile)

        this.vendorFile(data)
          .then(result => {
            if (result.data.status_code === '00') {
              for (let i = 0; i < result.data.data.length; i++) {
                if (result.data.data[i].name !== '') {
                  const param = {
                    no: 1 + i,
                    code: result.data.data[i].code,
                    name: result.data.data[i].name,
                    address: result.data.data[i].address,
                    business_entity_id: result.data.data[i].business_entity_id,
                    city_id: result.data.data[i].city_id,
                    classification_id: result.data.data[i].classification_id,
                    email: result.data.data[i].email,
                    npwp: result.data.data[i].npwp,
                    phone1: result.data.data[i].phone1,
                    phone2: result.data.data[i].phone2,
                    province_id: result.data.data[i].province_id,
                    sub_classification_id:
                      result.data.data[i].sub_classification_id,
                    website: result.data.data[i].website,
                    zip_code: result.data.data[i].zip_code
                  }
                  // console.log(result.data.data[i].id)
                  if (result.data.data[i].id !== undefined) {
                    Object.assign(param, {
                      id: result.data.data[i].id
                    })
                  }
                  this.vendorData.push(param)
                }
              }
              this.uploadFile = null
              this.isImport = true
              this.setIsLoading(false)
            } else {
              this.showMsgDialog('error', result.data)
              this.setIsLoading(false)
              this.vendorData = []
              this.isImport = false
            }
          })
          .catch(error => {
            this.setIsLoading(false)
            this.showMsgDialog('error', error)
            this.vendorData = []
            this.isImport = false
          })
      } else {
        this.showMsgDialog('error', 'File type must be .xlsx')
        this.clear()
      }
    },
    saveVendorImport() {
      const form = {
        act: 'add',
        data: this.vendorData
      }
      this.setIsLoading(true)
      this.saveImportVendor(form)
        .then(result => {
          if (result.data.status_code === '00') {
            this.setIsLoading(false)
            if (result.data.err_msg === '') {
              this.showMsgDialog(
                'success',
                'All of data was ' + result.data.status_msg
              )
            } else {
              this.showMsgDialog('warning', result.data.err_msg)
            }
            this.vendorData = []
            this.isImport = false
          }
        })
        .catch(error => {
          console.log(error)
          this.setIsLoading(false)
          this.showMsgDialog('error', error)
          this.vendorData = []
          this.isImport = false
        })
    }
  }
}
</script>
<style>
.table {
  border-radius: 3px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.21);
  background-color: transparent;
  border: 1px solid rgb(255, 255, 255);
}
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
</style>
