<template>
  <div>
    <div style="margin:0 0 20px 0;">
      <Navigation chosenpage="Vendor Catalogue" />
    </div>

    <v-row
      v-if="pageStatus === true"
      justify="center"
      style="position: relative; padding: 0; margin:0 0 20px 0;"
    >
      <v-card style="width:99%; padding:0; margin:0;">
        <v-toolbar dark color="primary">
          <v-btn
            style="color: White; padding: 0 10px; margin-right: 20px;"
            tile
            color="pink"
            @click.prevent="backToProfile"
          >
            <v-icon left>unit mdi-arrow-left </v-icon>
            Back
          </v-btn>
          <v-toolbar-title>Detail Vendor Catalogue</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            style="color: White; padding: 0 10px; margin-right: 20px;"
            tile
            color="warning"
            :disabled="
              getCatalogueAct === 'update' && permission.update_perm !== 1
            "
            @click.prevent="saveCatalogue"
          >
            <v-icon left>
              mdi-content-save-outline
            </v-icon>
            Save
          </v-btn>
        </v-toolbar>

        <div v-if="permission.read_perm === 1">
          <v-list three-line subheader min-height="40vh">
            <v-subheader class="headline mb-2"
              >#{{ product_code }} {{ getVendorDetail.name }}</v-subheader
            >

            <v-row justify="center">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="formCatalogue.product_id"
                          :loading="loading"
                          :items="itemss"
                          :search-input.sync="search"
                          @keydown.enter.prevent="querySelections(search)"
                          hide-no-data
                          hide-details
                          item-text="name"
                          item-value="id"
                          return-id
                          clearable
                          :label="
                            detailProduct !== null
                              ? detailProduct.product_name
                              : 'Product'
                          "
                          solo-inverted
                          :disabled="
                            getCatalogueAct === 'update' &&
                              permission.update_perm !== 1
                          "
                        >
                          <template v-slot:item="{ item }">
                            <div style="background:rgba(255,255,255,0.1);">
                              {{ '(' + item.code + ')' + ' ' + item.name }}
                            </div>
                          </template>
                        </v-autocomplete>
                      </v-col>

                      <v-col cols="4">
                        <v-text-field
                          v-model="formCatalogue.merk"
                          label="Merk"
                          :readonly="
                            getCatalogueAct === 'update' &&
                              permission.update_perm !== 1
                          "
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          :items="getUnitDropdown"
                          v-model="formCatalogue.uom"
                          label="UOM"
                          item-text="name"
                          item-value="id"
                          return-object
                          required
                          :clearable="
                            getCatalogueAct === 'update' &&
                              permission.update_perm === 1
                          "
                          :readonly="
                            getCatalogueAct === 'update' &&
                              permission.update_perm !== 1
                          "
                          :rules="rules.uomRules"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="formCatalogue.purchase_uom"
                          :items="getUnitDropdown"
                          label="Purchase UOM"
                          item-text="name"
                          item-value="id"
                          return-object
                          required
                          :clearable="
                            getCatalogueAct === 'update' &&
                              permission.update_perm === 1
                          "
                          :readonly="
                            getCatalogueAct === 'update' &&
                              permission.update_perm !== 1
                          "
                          :rules="rules.puomRules"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="8"
                        style="display:flex; flex-direction:column; padding:0; height:55px;"
                      >
                        <div
                          style="display:flex; flex-direction:row; padding:0 15px; height:55px;"
                        >
                          <v-file-input
                            @change="brochureUpload"
                            :disabled="
                              getCatalogueAct === 'update' &&
                                permission.update_perm !== 1
                            "
                            chips
                            prepend-icon=""
                            append-icon="mdi-attachment"
                            truncate-length="20"
                            :label="brochureLabel || 'Upload File Brosur'"
                            outlined
                          ></v-file-input>
                          <!-- <v-btn
                            @click="brochureUploadSubmit"
                            color="grey"
                            class="caption"
                            :disabled="
                              getCatalogueAct === 'update' &&
                                permission.update_perm !== 1
                            "
                            style="color:white; height:100%; width:58px; border-radius:5px;"
                          >
                            upload
                          </v-btn> -->
                        </div>
                      </v-col>
                      <v-col
                        v-if="permission.read_perm === 1"
                        cols="12"
                        style="padding:0 45px; text-align:right; position:relative; bottom:30px;"
                      >
                        <a
                          @click="downloadBrochure(brochureLabel)"
                          style="paddingLeft:35px;"
                          href="#"
                          v-if="brochureLabel !== ''"
                        >
                          Download brosur
                        </a>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="formCatalogue.catalogue_type"
                          @change="catalogueType"
                          :items="[
                            { id: 1, name: 'Bahan Baku' },
                            { id: 2, name: 'Umum' }
                          ]"
                          label="Spesification Type"
                          item-text="name"
                          item-value="id"
                          return-id
                          required
                          :clearable="
                            getCatalogueAct === 'update' &&
                              permission.update_perm === 1
                          "
                          :readonly="
                            getCatalogueAct === 'update' &&
                              permission.update_perm !== 1
                          "
                        >
                          <!-- :disabled="
                            formCatalogue.catalogue_type !== null ? true : false
                          " -->
                        </v-autocomplete>
                      </v-col>
                      <!-- <v-col cols="8">
                        <v-text-field
                          label="Note"
                          disabled
                          :readonly="
                            getCatalogueAct === 'update' &&
                              permission.update_perm !== 1
                          "
                        ></v-text-field>
                      </v-col> -->
                      <v-col cols="8">
                        <!-- <v-text-field
                          v-model="formCatalogue.last_purchase_plant"
                          label="Purchased by"
                          :readonly="
                            getCatalogueAct === 'update' &&
                              permission.update_perm !== 1
                          "
                        ></v-text-field> -->
                        <v-autocomplete
                          v-model="formCatalogue.last_purchase_plant"
                          label="Purchased by"
                          :items="[
                            'IMP',
                            'TMP',
                            'GIT',
                            'KAJ',
                            'WIM',
                            'SMI',
                            'TMP BAWEN'
                          ]"
                          required
                          :clearable="
                            getCatalogueAct === 'update' &&
                              permission.update_perm === 1
                          "
                          :readonly="
                            getCatalogueAct === 'update' &&
                              permission.update_perm !== 1
                          "
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="formCatalogue.last_price"
                          label="Last Price"
                          :readonly="
                            getCatalogueAct === 'update' &&
                              permission.update_perm !== 1
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          v-model="formCatalogue.currency_id"
                          :items="getCurrencyDropdown"
                          label="Currency"
                          item-text="code"
                          item-value="id"
                          return-id
                          required
                          :clearable="
                            getCatalogueAct === 'update' &&
                              permission.update_perm === 1
                          "
                          :readonly="
                            getCatalogueAct === 'update' &&
                              permission.update_perm !== 1
                          "
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          v-model="formCatalogue.last_ordered"
                          label="Last Ordered (yyyy-mm-dd)"
                          :readonly="
                            getCatalogueAct === 'update' &&
                              permission.update_perm !== 1
                          "
                          type="date"
                          name="date"
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="4">
                        <v-text-field
                          v-model="formCatalogue.fpb_no"
                          label="Nomor FPB"
                          required
                        ></v-text-field>
                      </v-col> -->
                      <v-col cols="12" v-if="getCatalogueAct === 'update'">
                        <v-container fluid>
                          <v-textarea
                            v-model="formCatalogue.description"
                            :clearable="
                              getCatalogueAct === 'update' &&
                                permission.update_perm === 1
                            "
                            :readonly="
                              getCatalogueAct === 'update' &&
                                permission.update_perm !== 1
                            "
                            outlined
                            clear-icon="mdi-close-circle"
                            label="Keterangan"
                            :rules="rules.descriptionRules"
                          ></v-textarea>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-row>
          </v-list>

          <v-divider></v-divider>

          <v-list
            three-line
            subheader
            style="padding:0;"
            v-if="getIsResultId === true"
          >
            <v-card>
              <v-toolbar color="cyan" dark flat>
                <v-tabs v-model="tab" align-with-title>
                  <v-tabs-slider color="yellow"></v-tabs-slider>

                  <v-tab v-for="item in items" :key="item">
                    {{ item }}
                  </v-tab>
                </v-tabs>
              </v-toolbar>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in items" :key="item">
                  <v-card flat style="min-height: 32vh;">
                    <v-data-table
                      :headers="
                        item === 'penawaran'
                          ? supplyHead
                          : specType === 'Bahan Baku'
                          ? rawSpecHead
                          : genSpecHead
                      "
                      :items="
                        item === 'penawaran'
                          ? getQuotationList
                          : specType === 'Bahan Baku'
                          ? getRawSpecificationList
                          : getGenSpecificationList
                      "
                      :items-per-page="
                        item === 'penawaran'
                          ? supplyPerPage
                          : specType === 'Bahan Baku'
                          ? rawPerPage
                          : genPerPage
                      "
                      class="elevation-2 mx-auto"
                      style="width:99%; margin-top: 20px; margin-bottom:20px; "
                      dense
                      hide-default-footer
                    >
                      <template v-slot:top>
                        <v-toolbar-title style="padding:0 0 0 10px;">{{
                          item === 'penawaran'
                            ? 'History Penawaran'
                            : 'Spesifikasi'
                        }}</v-toolbar-title>
                        <!-- di hide dulu karena blm perlu -->
                        <!-- <v-toolbar
                        elevation="1"
                        v-if="item === 'penawaran'"
                        style="margin-top:5px;"
                      >
                        <v-col class="d-flex" cols="6" sm="4">
                          <v-text-field
                            v-model="invokeSrc1"
                            label="Search here"
                            type="search"
                            outlined
                            dense
                            style="position: relative; top:15px; width:10px;"
                          ></v-text-field>
                        </v-col>
                      </v-toolbar> -->
                        <v-toolbar
                          elevation="1"
                          v-if="item === 'spesifikasi'"
                          style="margin-top:20px;"
                        >
                          <v-col class="d-flex" cols="4">
                            <v-select
                              @change="spesificationTypeChange"
                              :items="['Bahan Baku', 'Umum']"
                              v-model="specType"
                              clearable
                              label="Tipe Spesifikasi"
                              disabled
                              dense
                            ></v-select>
                          </v-col>
                          <v-divider class="mx-6" inset vertical></v-divider>
                          <v-text-field
                            v-if="specType === 'Bahan Baku'"
                            v-model="invokeSrc2"
                            label="Search here"
                            type="search"
                            outlined
                            dense
                            style="position: relative; top:15px; width:10px;"
                          ></v-text-field>
                          <v-text-field
                            v-if="specType === 'Umum'"
                            v-model="invokeSrc3"
                            label="Search here"
                            type="search"
                            outlined
                            dense
                            style="position: relative; top:15px; width:10px;"
                          ></v-text-field>
                          <!-- <v-divider class="mx-6" inset vertical></v-divider> -->
                        </v-toolbar>
                      </template>
                      <template v-slot:[`item.period`]="{ item }">
                        {{ item.period[0] | moment('DD MMM YYYY') }} -
                        {{ item.period[1] | moment('DD MMM YYYY') }}
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                          v-if="specType === 'Umum' && item.id !== null"
                          color="cyan"
                          class="ma-1 white--text"
                          small
                          :disabled="permission.read_perm !== 1"
                          @click="
                            tab === 0
                              ? formEdit([true, 'supply', 'update', item])
                              : formEdit([true, 'genspec', 'update', item])
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small>
                                mdi-pencil
                              </v-icon>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                        </v-btn>
                        <v-btn
                          v-if="specType === 'Umum' && item.id === null"
                          color="success"
                          class="ma-1 white--text"
                          small
                          :disabled="permission.create_perm !== 1"
                          @click="addNewLine(2)"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small>
                                mdi-content-save
                              </v-icon>
                            </template>
                            <span>Save spec umum</span>
                          </v-tooltip>
                        </v-btn>
                        <v-btn
                          v-if="specType !== 'Umum'"
                          color="cyan"
                          class="ma-1 white--text"
                          small
                          :disabled="permission.read_perm !== 1"
                          @click="
                            tab === 0
                              ? formEdit([true, 'supply', 'update', item])
                              : specType === 'Bahan Baku'
                              ? formEdit([true, 'rawspec', 'update', item])
                              : null
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small>
                                mdi-pencil
                              </v-icon>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                        </v-btn>
                        <v-btn
                          color="error"
                          class="ma-1 white--text"
                          small
                          :disabled="permission.delete_perm !== 1"
                          @click="
                            tab === 0
                              ? deleteSupply(item)
                              : specType === 'Bahan Baku'
                              ? deleteRawSpec(item)
                              : deleteGenSpec(item)
                          "
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" small>
                                mdi-delete
                              </v-icon>
                            </template>
                            <span>Delete</span>
                          </v-tooltip>
                        </v-btn>
                      </template>
                      <template v-slot:[`item.fileSupport`]="{ item }">
                        <v-btn
                          :disabled="
                            item.fileSupport === null || item.fileSupport === ''
                          "
                          style="cursor:pointer; width:100px; font-size:10px;"
                          @click="quotationDownload(item.fileSupport)"
                        >
                          <v-icon>mdi-download</v-icon>
                          download
                        </v-btn>
                      </template>
                      <template v-slot:[`item.unit_name`]="{ item }">
                        <p v-if="item.id !== null">
                          {{ item.unit_name !== '' ? item.unit_name : '-' }}
                        </p>
                      </template>
                      <template v-slot:[`item.unitPrice`]="{ item }">
                        {{
                          item.unitPrice !== '' ? price(item.unitPrice) : '-'
                        }}
                      </template>
                      <template
                        v-if="specType === 'Bahan Baku'"
                        v-slot:[`item.criteria`]="{ item }"
                      >
                        <p v-if="item.id !== null">
                          {{
                            item.criteria === 1
                              ? 'Major'
                              : item.criteria === 2
                              ? 'Kritikal'
                              : '-'
                          }}
                        </p>
                      </template>
                      <template
                        v-if="specType === 'Umum'"
                        v-slot:[`item.specCategoryDetail`]="{ item }"
                      >
                        <p v-if="item.id !== null">
                          {{
                            item.specCategoryDetail !== null
                              ? item.specCategoryDetail.name
                              : '-'
                          }}
                        </p>
                        <v-autocomplete
                          @change="initSpecAttributeDropdown"
                          v-else
                          v-model="genSpecForm.spesification_category_id"
                          :items="getSpecCategoryDropdown"
                          outlined
                          dense
                          item-text="name"
                          item-value="id"
                          return-id
                        ></v-autocomplete>
                      </template>
                      <template
                        v-if="specType === 'Umum'"
                        v-slot:[`item.specAttributeDetail`]="{ item }"
                      >
                        <p v-if="item.id !== null">
                          {{
                            item.specAttributeDetail !== null
                              ? item.specAttributeDetail.name
                              : '-'
                          }}
                        </p>
                        <v-autocomplete
                          v-else
                          v-model="genSpecForm.spesification_attribute_id"
                          :items="getSpecAttributeDropdown"
                          outlined
                          dense
                          item-text="name"
                          item-value="id"
                          return-id
                          :disabled="permission.create_perm !== 1"
                        ></v-autocomplete>
                      </template>
                      <template
                        v-if="specType === 'Umum'"
                        v-slot:[`item.description`]="{ item }"
                      >
                        <p v-if="item.id !== null">
                          {{ item.description }}
                        </p>
                        <v-textarea
                          v-else
                          v-model="genSpecForm.description"
                          :clearable="permission.create_perm === 1"
                          :disabled="permission.create_perm !== 1"
                          outlined
                          dense
                          clear-icon="mdi-close-circle"
                          :label="item ? 'Deskripsi' : 'Deskripsi'"
                        ></v-textarea>
                      </template>

                      <template v-slot:footer>
                        <v-toolbar>
                          <v-btn
                            v-if="item === 'penawaran'"
                            @click="formAdd([true, 'supply', 'add'])"
                            color="#e0e0e0"
                            class="ma-1 black--text"
                            small
                            :disabled="permission.create_perm !== 1"
                          >
                            <v-icon left small>
                              mdi-plus
                            </v-icon>
                            <span>Add New</span>
                          </v-btn>
                          <v-btn
                            v-if="
                              item === 'spesifikasi' &&
                                specType === 'Bahan Baku'
                            "
                            @click="formAdd([true, 'rawspec', 'add'])"
                            color="#e0e0e0"
                            class="ma-1 black--text"
                            small
                            :disabled="permission.create_perm !== 1"
                          >
                            <v-icon left small>
                              mdi-plus
                            </v-icon>
                            <span>Add New</span>
                          </v-btn>
                          <v-btn
                            v-if="item === 'spesifikasi' && specType === 'Umum'"
                            @click="!isNewLine ? addNewLine(1) : addNewLine(0)"
                            color="#e0e0e0"
                            class="ma-1 black--text"
                            small
                            :disabled="permission.create_perm !== 1"
                          >
                            <v-icon left small v-if="!isNewLine">
                              mdi-plus
                            </v-icon>
                            {{ !isNewLine ? 'Add New Line' : 'cancel ' }}
                            <v-icon
                              v-if="isNewLine"
                              left
                              small
                              style="margin:0 0 0 10px;"
                            >
                              mdi-window-close
                            </v-icon>
                          </v-btn>

                          <v-spacer></v-spacer>

                          <v-pagination
                            v-if="item === 'penawaran'"
                            style="margin-left:auto;"
                            v-model="page"
                            :length="
                              Math.ceil(getTotalQuotItem / supplyPerPage) || 1
                            "
                            :total-visible="6"
                          ></v-pagination>
                          <v-pagination
                            v-if="
                              item === 'spesifikasi' &&
                                specType === 'Bahan Baku'
                            "
                            style="margin-left:auto;"
                            v-model="rawPage"
                            :length="
                              Math.ceil(getTotalRawSpecItem / rawPerPage) || 1
                            "
                            :total-visible="6"
                          ></v-pagination>
                          <v-pagination
                            v-if="item === 'spesifikasi' && specType === 'Umum'"
                            style="margin-left:auto;"
                            v-model="genPage"
                            :length="
                              Math.ceil(getTotalGenSpecItem / genPerPage) || 1
                            "
                            :total-visible="6"
                          ></v-pagination>
                          <v-spacer></v-spacer>
                          <div
                            style="padding:0; margin:0;display: flex; flex-direction:rows; justify-content:center; align-items:center;"
                          >
                            <v-toolbar-title class="caption"
                              >Rows per page:</v-toolbar-title
                            >
                            <v-select
                              v-if="item === 'penawaran'"
                              style="position: relative; margin-left:10px;height:28px; width:50px; rightt:20px; font-size:12px;"
                              v-model="supplyPerPage"
                              :items="[1, 2, 5, 10, 15, 20, 100]"
                              dense
                            ></v-select>
                            <v-select
                              v-if="
                                item === 'spesifikasi' &&
                                  specType === 'Bahan Baku'
                              "
                              style="position: relative; margin-left:10px;height:28px; width:50px; rightt:20px; font-size:12px;"
                              v-model="rawPerPage"
                              :items="[1, 2, 5, 10, 15, 20, 100]"
                              dense
                            ></v-select>
                            <v-select
                              v-if="
                                item === 'spesifikasi' && specType === 'Umum'
                              "
                              style="position: relative; margin-left:10px;height:28px; width:50px; rightt:20px; font-size:12px;"
                              v-model="genPerPage"
                              :items="[1, 2, 5, 10, 15, 20, 100]"
                              dense
                            ></v-select>
                          </div>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-list>
        </div>

        <v-card
          flat
          height="75vh"
          v-else
          style="display:flex; justify-content:center; align-items:center;"
        >
          <v-toolbar-title class="overline">
            You Can't Access This Page</v-toolbar-title
          >
        </v-card>
      </v-card>
    </v-row>

    <div>
      <VdrSupplySpec
        v-if="
          getVdrCatalogueModalStat[0] &&
            getVdrCatalogueModalStat[1] === 'supply'
        "
        :perm="permission"
      />
      <VdrSupplySpec
        v-if="
          getVdrCatalogueModalStat[0] &&
            getVdrCatalogueModalStat[1] === 'rawspec'
        "
        :perm="permission"
      />
      <VdrSupplySpec
        v-if="
          getVdrCatalogueModalStat[0] &&
            getVdrCatalogueModalStat[1] === 'genspec'
        "
        :perm="permission"
      />
    </div>

    <v-card
      v-if="getIsLoading === true"
      class="mx-auto"
      width="99%"
      height="260"
      style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Please wait</v-progress-circular
      >
    </v-card>
  </div>
</template>

<script>
import Navigation from '../../components/navigation/Navigation'
import VdrSupplySpec from '../../components/vendor/VdrSupply&Spec'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import buildType from '../../services/buildType'
import axios from 'axios'
export default {
  name: 'vendorCatalogue',
  data() {
    return {
      procAPI: buildType.apiURL('proc'),
      loading: false,
      itemss: [],
      search: null,
      select: null,
      productObject: null,

      pageStatus: true,
      path: this.$route.fullPath,
      page: 1,
      rawPage: 1,
      genPage: 1,

      offset: 0,
      rawOffset: 0,
      genOffset: 0,

      itemsPerPage: 10,
      supplyPerPage: 100,
      rawPerPage: 100,
      genPerPage: 100,

      searchSupply: '',
      invokeSrc1: '',

      specRawSrc: '',
      invokeSrc2: '',

      specGenSrc: '',
      invokeSrc3: '',

      tab: 0,
      catalogue_id: null,
      specType: 'Bahan Baku',
      items: ['penawaran', 'spesifikasi'],
      product_code: '',
      isNewLine: false,
      supplyHead: [
        // {
        //   text: 'No',
        //   align: 'start',
        //   sortable: false,
        //   value: 'no'
        // },
        { text: 'Periode', sortable: false, value: 'period' },
        { text: 'Unit', sortable: false, value: 'unit_name' },
        { text: 'Harga per unit', sortable: false, value: 'unitPrice' },
        { text: 'File Pendukung', sortable: false, value: 'fileSupport' },
        { text: 'Action', sortable: false, value: 'actions', align: 'center' }
      ],
      supply: [],
      rawSpecHead: [
        // {
        //   text: 'No',
        //   align: 'start',
        //   sortable: false,
        //   value: 'no'
        // },
        {
          text: 'Kategory',
          sortable: false,
          value: 'spesification_category_name'
        },
        {
          text: 'Attribute',
          sortable: false,
          value: 'spesification_attribute_name'
        },
        { text: 'Standard', sortable: false, value: 'standard' },
        { text: 'Unit', sortable: false, value: 'unit_name' },
        { text: 'Kriteria', sortable: false, value: 'criteria' },
        { text: 'Action', sortable: false, value: 'actions', align: 'center' }
      ],
      rawSpecification: [],
      genSpecHead: [
        // {
        //   text: 'No',
        //   align: 'start',
        //   sortable: false,
        //   value: 'no',
        //   width: '5%'
        // },
        {
          text: 'Kategory',
          sortable: false,
          value: 'specCategoryDetail',
          width: '20%'
        },
        {
          text: 'Attribut',
          sortable: false,
          value: 'specAttributeDetail',
          width: '20%'
        },
        {
          text: 'Deskripsi',
          sortable: false,
          value: 'description',
          width: '45%',
          align: 'center'
        },
        {
          text: 'Action',
          sortable: false,
          value: 'actions',
          width: '10%',
          align: 'center'
        }
      ],
      genSpecification: [],
      genSpecForm: {
        spesification_type: null,
        standard: '',
        unit_id: null,
        criteria: null,
        analysis_method: '',
        min_frequency_supplier: '',
        min_frequency_sanqua: ''
      },
      formCatalogue: {},
      rules: {
        productIdRules: [v => !!v || 'Product id is required'],
        uomRules: [v => !!v || 'UOM is required'],
        puomRules: [v => !!v || 'Purchase UOM is required']
      },
      brochureLabel: '',
      detailProduct: null,
      permission: {},
      menu_key: 18,
      application: {
        id: 2,
        name: 'eProcurement'
      }
    }
  },
  components: { Navigation, VdrSupplySpec },
  watch: {
    search(val) {
      if (this.search === null || this.search === '') {
        this.itemss = []
      }
      // val && val !== this.select && this.querySelections(val)
    },

    invokeSrc1() {
      this.searchSupply = this.invokeSrc1
      this.offset = 0
      this.initListQuotation()
    },
    page() {
      this.offset = this.page - 1
      this.initListQuotation()
    },
    supplyPerPage() {
      this.page = 1
      this.offset = 0
      this.initListQuotation()
    },

    invokeSrc2() {
      this.specRawSrc = this.invokeSrc2
      this.rawOffset = 0
      this.specificationInit()
    },
    rawPage() {
      this.rawOffset = this.rawPage - 1
      this.specificationInit()
    },
    rawPerPage() {
      this.rawPage = 1
      this.rawOffset = 0
      this.specificationInit()
    },

    invokeSrc3() {
      this.specGenSrc = this.invokeSrc3
      this.genOffset = 0
      this.specificationInit()
    },
    genPage() {
      this.genOffset = this.genPage - 1
      this.specificationInit()
    },
    genPerPage() {
      this.genPage = 1
      this.genOffset = 0
      this.specificationInit()
    }
  },
  created() {
    this.setIsLoading(true)
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            setTimeout(() => {
              this.loadPage()
              this.setIsLoading(false)
            }, 1500)
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              setTimeout(() => {
                this.loadPage()
                this.setIsLoading(false)
              }, 1500)
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        setTimeout(() => {
          this.setIsLoading(false)
        }, 1500)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUserProfile',
      'getVdrCatalogueModalStat',
      // 'getVendorUrlParam',
      'getCatalogueAct',
      'getCatalogueDetail',
      'getUnitDropdown',
      'getCurrencyDropdown',
      'getProductDropdown',
      'getQuotationList',
      'getTotalQuotItem',
      'getRawSpecificationList',
      'getTotalRawSpecItem',
      'getTotalGenSpecItem',
      'getIsResultId',
      'getVendorDetail',
      'getVendorId',
      'getGenSpecificationList',
      'getSpecCategoryDropdown',
      'getSpecAttributeDropdown',
      'getIsLoading'
    ])
  },
  methods: {
    ...mapActions([
      'menu_access',
      'catalogueManage',
      'catalogueDetail',
      'unitProduct',
      'productDropdown',
      'currencyDropdown',
      'quotationList',
      'deleteQuotation',
      'specificationList',
      'brochureCatalogue',
      'quotationFileDownload',
      'deleteRawSpesification',
      'deleteGenSpesification',
      'spesificationCategoryDropdown',
      'spesificationAttributeDropdown',
      'manageGenSpesification',
      'brochureDownload'
    ]),
    ...mapMutations([
      'setVendorCatalogueModal',
      'setFormCatalogueManage',
      'setCatalogueSupplyDetail',
      'setCatalogueRawSpecDetail',
      'setCatalogueGenSpecDetail',
      'setCatalogueAct',
      'setIsResultId',
      'setCatalogueAddId',
      'setSpecType',
      'setNewGenLine',
      'setGenSpesificationForm',
      'setGenAddAct',
      'setIsLoading'
    ]),
    catalogueType(event) {
      if (event === 1) {
        this.specType = 'Bahan Baku'
      } else {
        this.specType = 'Umum'
      }
    },
    loadPage() {
      // this.verifyPath()
      this.dropDownUnit()
      this.currencyDropdown()
      if (this.getCatalogueAct === 'update') {
        this.catalogueDetail()
          .then(result => {
            console.log(result)
            this.specType = result.catalogue_type === 1 ? 'Bahan Baku' : 'Umum'
            this.detailProduct = result
            this.initForm(['update', result])
            this.subFormTitle()
            this.setIsResultId(true)
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.initForm(['add', null])
      }
    },
    querySelections(v) {
      axios
        .get(
          `${this.procAPI}master/product/drop_down?keyword=${v}&offset=${this.offset}&limit=${this.itemsPerPage}`
        )
        .then(response => {
          console.log(response.data.data)
          this.itemss = response.data.data
          // this.loading = false
        })
        .catch(error => {
          console.log(error)
          // this.loading = false
        })
    },

    backToProfile() {
      this.$router.push('/vendor-profile')
      // this.$router.push(
      //   `/vendor-profile/?${this.getVendorUrlParam.id}=${this.getVendorUrlParam.name}`
      // )
      this.setCatalogueAct(['', null])
      this.setIsResultId(false)
    },
    saveCatalogue() {
      console.log(this.formCatalogue)
      if (this.formCatalogue.product_id === null) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Product Id Tidak boleh kosong',
          showConfirmButton: true,
          timer: 4500
        })
        this.formCatalogue.product_id = ''
      } else if (this.formCatalogue.uom === null) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'UOM tidak boleh kosong',
          showConfirmButton: true,
          timer: 4500
        })
      } else if (this.formCatalogue.purchase_uom === null) {
        this.$swal.fire({
          position: 'center',
          icon: 'warning',
          title: 'Purchase UOM tidak boleh kosong',
          showConfirmButton: true,
          timer: 4500
        })
      } else {
        if (
          this.formCatalogue.uom.id === null ||
          this.formCatalogue.uom.name === null
        ) {
          this.$swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Uom ID / Name Kosong, ulangi pilih uom !',
            showConfirmButton: true,
            timer: 4500
          })
          return null
        }

        if (
          this.formCatalogue.purchase_uom.id === null ||
          this.formCatalogue.purchase_uom.name === null
        ) {
          this.$swal.fire({
            position: 'center',
            icon: 'warning',
            title: 'Purchase Uom ID / Name Kosong, ulangi pilih uom !',
            showConfirmButton: true,
            timer: 4500
          })
          return null
        }

        this.$swal
          .fire({
            title: 'Submit Now',
            icon: 'question',
            showConfirmButton: true,
            showCancelButton: true
          })
          .then(result => {
            if (result.value) {
              this.setIsLoading(true)
              this.formCatalogue.vendor_id = this.getVendorId
              this.setFormCatalogueManage(this.formCatalogue)
              this.catalogueManage()
                .then(result => {
                  console.log(result)
                  this.setIsLoading(false)
                  if (result.status_code === '00') {
                    this.setCatalogueAddId(result.created_id)
                    this.subFormTitle()
                    this.setIsResultId(true)
                    this.$swal
                      .fire({
                        position: 'center',
                        icon: 'success',
                        title: result.status_msg,
                        showConfirmButton: true,
                        timer: 4500
                      })
                      .then(res => {
                        if (this.getCatalogueAct !== 'update') {
                          this.setCatalogueAct([
                            'update',
                            { id: result.created_id }
                          ])
                        }
                        this.loadPage()
                      })
                  } else {
                    if (result.status_msg === 'Parameter value has problem') {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: result.error_msg[0].msg,
                        showConfirmButton: true,
                        timer: 4500
                      })
                    } else {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: result.status_msg,
                        showConfirmButton: true,
                        timer: 4500
                      })
                    }
                  }
                })
                .catch(error => {
                  this.setIsLoading(false)
                  console.log(error)
                })
            }
          })
      }
    },

    subFormTitle() {
      this.initListQuotation()
      this.specificationInit()
    },
    specificationInit() {
      this.setSpecType(this.specType)
      this.specificationList([
        this.specType === 'Bahan Baku' ? this.specRawSrc : this.specGenSrc,
        this.specType === 'Bahan Baku'
          ? this.rawOffset * this.rawPerPage
          : this.genOffset * this.genPerPage,
        this.specType === 'Bahan Baku' ? this.rawPerPage : this.genPerPage
      ])
    },
    formEdit(payload) {
      this.setVendorCatalogueModal([payload[0], payload[1], payload[2]])
      if (this.tab === 0) {
        this.setCatalogueSupplyDetail(payload[3])
      } else {
        if (this.specType === 'Bahan Baku') {
          this.setCatalogueRawSpecDetail(payload[3])
        } else {
          this.setCatalogueGenSpecDetail(payload[3])
        }
      }
    },
    deleteSupply(item) {
      this.$swal
        .fire({
          title: 'Are you sure, Data will be deleted permanently',
          icon: 'question',
          showCancelButton: true,
          showConfirmButton: true
        })
        .then(result => {
          if (result.value) {
            this.setIsLoading(true)
            this.deleteQuotation(item)
              .then(result => {
                console.log(result)
                this.setIsLoading(false)
                if (result.data.status_code !== '-99') {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })
                  this.quotationList(['', '', 0, 10])
                } else {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })
                }
              })
              .catch(error => {
                console.log(error)
                this.setIsLoading(false)
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: error,
                  showConfirmButton: true,
                  timer: 5000
                })
              })
          }
        })
    },
    deleteRawSpec(item) {
      this.$swal
        .fire({
          title: 'Are you sure, Data will be deleted permanently',
          icon: 'question',
          showCancelButton: true,
          showConfirmButton: true
        })
        .then(result => {
          if (result.value) {
            this.setIsLoading(true)
            this.deleteRawSpesification(item)
              .then(result => {
                this.setIsLoading(false)
                if (result.data.status_code !== '-99') {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })
                  this.specificationList(['', 0, 5])
                } else {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })
                }
              })
              .catch(error => {
                this.setIsLoading(false)
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: error,
                  showConfirmButton: true,
                  timer: 5000
                })
              })
          }
        })
    },
    deleteGenSpec(item) {
      this.$swal
        .fire({
          title: 'Are you sure, Data will be deleted permanently',
          icon: 'question',
          showCancelButton: true,
          showConfirmButton: true
        })
        .then(result => {
          if (result.value) {
            this.setIsLoading(true)
            this.deleteRawSpesification(item)
              .then(result => {
                this.setIsLoading(false)
                if (result.data.status_code !== '-99') {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'success',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })
                  this.specificationList(['', 0, 5])
                } else {
                  this.$swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: result.data.status_msg,
                    showConfirmButton: true,
                    timer: 5000
                  })
                }
              })
              .catch(error => {
                this.setIsLoading(false)
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: error,
                  showConfirmButton: true,
                  timer: 5000
                })
              })
          }
        })
    },
    formAdd(payload) {
      this.setVendorCatalogueModal([payload[0], payload[1], payload[2]])
    },
    addNewLine(val) {
      this.spesificationCategoryDropdown()
      if (val === 1) {
        this.setNewGenLine(1)
        this.isNewLine = true
      } else if (val === 0) {
        this.setNewGenLine(0)
        this.isNewLine = false
      } else {
        this.setGenAddAct('add')
        this.$swal
          .fire({
            title: 'Save now',
            icon: 'question',
            showCancelButton: true,
            showConfirmButton: true
          })
          .then(result => {
            if (result.value) {
              if (this.genSpecForm.spesification_category_id === null) {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Category cannot be empty',
                  showConfirmButton: true,
                  timer: 5000
                })
              } else if (this.genSpecForm.spesification_attribute_id === null) {
                this.$swal.fire({
                  position: 'center',
                  icon: 'error',
                  title: 'Attribute cannot be empty',
                  showConfirmButton: true,
                  timer: 5000
                })
              } else {
                this.setGenSpesificationForm(this.genSpecForm)
                this.manageGenSpesification()
                  .then(result => {
                    if (result.data.status_code !== '-99') {
                      this.$swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: result.data.status_msg,
                        showConfirmButton: true,
                        timer: 5000
                      })
                      this.isNewLine = false
                      this.resetForm()
                      this.specificationList(['', 0, 5])
                    } else {
                      if (
                        result.data.status_msg === 'Parameter value has problem'
                      ) {
                        this.$swal.fire({
                          position: 'center',
                          icon: 'error',
                          title: 'Invalid input, please complete the form',
                          showConfirmButton: true,
                          timer: 5000
                        })
                      } else {
                        this.$swal.fire({
                          position: 'center',
                          icon: 'error',
                          title: result.data.status_msg,
                          showConfirmButton: true,
                          timer: 5000
                        })
                      }
                    }
                  })
                  .catch(error => {
                    this.$swal.fire({
                      position: 'center',
                      icon: 'error',
                      title: error
                        ? 'Somthing went wrong, please contact an admin'
                        : 'Somthing went wrong, please contact an admin',
                      showConfirmButton: true,
                      timer: 5000
                    })
                  })
              }
            }
          })
      }
    },
    initForm(arr) {
      this.productDropdown(['', this.offset, this.itemsPerPage])
      if (arr[0] === 'update') {
        console.log(arr)
        this.brochureLabel = arr[1].file_brochure
        this.product_code = arr[1].product_code
        let uom = null
        let purchaseUom = null
        if (arr[1].uom !== null) {
          if ((arr[1].uom.id !== null) & (arr[1].uom.name !== null)) {
            uom = arr[1].uom
          }
        }
        if (arr[1].purchase_uom !== null) {
          if (
            (arr[1].purchase_uom.id !== null) &
            (arr[1].purchase_uom.name !== null)
          ) {
            purchaseUom = arr[1].purchase_uom
          }
        }
        this.formCatalogue = {
          vendor_id: arr[1].vendor_id,
          product_id: arr[1].product_id,
          merk: arr[1].merk,
          uom: uom,
          purchase_uom: purchaseUom,
          file_brochure: arr[1].file_brochure,
          description: arr[1].description,
          catalogue_type: arr[1].catalogue_type,
          currency_id: arr[1].currency !== null ? arr[1].currency.id : null,
          last_price: arr[1].last_price,
          last_ordered: this.convertDate(arr[1].last_ordered),
          last_purchase_plant: arr[1].last_purchase_plant,
          fpb_no: arr[1].fpb_no
        }
      } else {
        this.brochureLabel = ''
        this.product_code = '-'
        this.formCatalogue = {
          vendor_id: '',
          product_id: null,
          merk: '',
          uom: null,
          purchase_uom: null,
          file_brochure: '',
          description: '',
          catalogue_type: null,
          currency_id: null,
          last_price: null,
          last_ordered: null,
          last_purchase_plant: null,
          fpb_no: null
        }
      }
    },
    dropDownUnit() {
      this.unitProduct('')
    },
    initListQuotation() {
      this.quotationList([
        '',
        this.searchSupply,
        this.offset * this.supplyPerPage,
        this.supplyPerPage
      ])
    },
    quotationDownload(data) {
      this.setIsLoading(true)
      this.quotationFileDownload(data)
        .then(result => {
          this.setIsLoading(false)
          console.log(result)
        })
        .catch(error => {
          this.setIsLoading(false)
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error
              ? 'Somthing went wrong, please contact an admin'
              : 'Somthing went wrong, please contact an admin',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 4500
          })
        })
    },
    brochureUpload(event) {
      if (event !== null) {
        // this.formCatalogue.file_brochure = event
        this.setIsLoading(true)
        this.brochureCatalogue(event)
          .then(result => {
            this.setIsLoading(false)
            if (result.data.status === false) {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false
              })
            } else if (result.data.status === true) {
              this.$swal.fire({
                position: 'center',
                icon: 'success',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false
              })
              this.formCatalogue.file_brochure = result.data.data.name
            } else {
              this.$swal.fire({
                position: 'center',
                icon: 'error',
                title: result.data.message,
                showConfirmButton: true,
                showCancelButton: false
              })
            }
          })
          .catch(error => {
            this.setIsLoading(false)
            this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: error
                ? 'Somthing went wrong, please contact an admin'
                : 'Somthing went wrong, please contact an admin',
              showConfirmButton: true,
              showCancelButton: false,
              timer: 4500
            })
          })
      } else {
        this.formCatalogue.file_brochure = ''
      }
    },
    // brochureUploadSubmit() {
    //   this.setIsLoading(true)
    //   this.brochureCatalogue(this.formCatalogue.file_brochure)
    //     .then(result => {
    //       this.setIsLoading(false)
    //       if (result.data.status === false) {
    //         this.$swal.fire({
    //           position: 'center',
    //           icon: 'error',
    //           title: result.data.message,
    //           showConfirmButton: true,
    //           showCancelButton: false
    //         })
    //       } else if (result.data.status === true) {
    //         this.$swal.fire({
    //           position: 'center',
    //           icon: 'success',
    //           title: result.data.message,
    //           showConfirmButton: true,
    //           showCancelButton: false
    //         })
    //         this.formCatalogue.file_brochure = result.data.data.name
    //       } else {
    //         this.$swal.fire({
    //           position: 'center',
    //           icon: 'error',
    //           title: result.data.message,
    //           showConfirmButton: true,
    //           showCancelButton: false
    //         })
    //       }
    //     })
    //     .catch(error => {
    //       this.setIsLoading(false)
    //       this.$swal.fire({
    //         position: 'center',
    //         icon: 'error',
    //         title: error
    //           ? 'Somthing went wrong, please contact an admin'
    //           : 'Somthing went wrong, please contact an admin',
    //         showConfirmButton: true,
    //         showCancelButton: false,
    //         timer: 4500
    //       })
    //     })
    // },
    downloadBrochure(val) {
      this.setIsLoading(true)
      this.brochureDownload(val)
        .then(result => {
          this.setIsLoading(false)
        })
        .catch(error => {
          this.setIsLoading(false)
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error
              ? 'Somthing went wrong, please contact an admin'
              : 'Somthing went wrong, please contact an admin',
            showConfirmButton: true,
            showCancelButton: false,
            timer: 4500
          })
        })
    },
    spesificationTypeChange(event) {
      this.setSpecType(event)
      this.specificationInit()

      if (this.specType === 'Umum') {
        this.spesificationCategoryDropdown()
        // this.spesificationAttributeDropdown([null])
      }
    },

    initSpecAttributeDropdown(event) {
      this.spesificationAttributeDropdown([event])
    },
    resetForm() {
      this.genSpecForm = {
        spesification_type: null,
        standard: '',
        unit_id: null,
        criteria: null,
        analysis_method: '',
        min_frequency_supplier: '',
        min_frequency_sanqua: ''
      }
    },

    price(val) {
      const reverse = val
        .toString()
        .split('')
        .reverse()
        .join('')
      var ribuan = reverse.match(/\d{1,3}/g)
      return ribuan
        .join(',')
        .split('')
        .reverse()
        .join('')
    },
    convertDate(val) {
      if (val === null || val === '') {
        return null
      } else {
        const date = new Date(val)
        const str = date.toLocaleString('id')

        let day = str.slice(0, str.indexOf('/'))
        if (day.length < 2) day = '0' + day
        let month = str.slice(str.indexOf('/') + 1, str.lastIndexOf('/'))
        if (month.length < 2) month = '0' + month
        // const year = str.slice(str.lastIndexOf('/') + 1, str.lastIndexOf(','))
        const year = str.slice(str.lastIndexOf('/') + 1, str.indexOf(' ') - 1)
        console.log(year + '-' + month + '-' + day)
        return year + '-' + month + '-' + day
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
