import axios from 'axios'
import buildType from '../../services/buildType'
// import router from '../../router/index'
export default {
  state: {
    procAPI: buildType.apiURL('proc')
  },
  mutations: {},
  actions: {
    vendorFile(context, payload) {
      return new Promise((resolve, reject) => {
        // ;`${context.state.procAPI}api/procurement/v1/vendor/upload`,
        axios
          .post(`${context.state.procAPI}vendor/upload`, payload)
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    saveImportVendor(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/batch_save`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    catalogueFile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/catalogue/upload`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveImportCatalogue(context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}vendor/catalogue/batch_save`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    quotationFile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}vendor/catalogue_quotation/upload`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveImportQuotation(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}vendor/catalogue_quotation/batch_save`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    spesificationFile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}vendor/catalogue_spesification/upload`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveImportSpesification(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}vendor/catalogue_spesification/batch_save`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    productFile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}master/product/upload`, payload)
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    saveImportProduct(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}master/product/batch_save`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    categoryFile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}master/product_category/upload`,
            payload
          )
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    saveImportCategory(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}master/product_category/batch_save`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    spesificationCategoryFile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}master/spesification_category/upload`,
            payload
          )
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    saveImportSpesificationCategory(context, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}master/spesification_category/batch_save`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    spesificationAttributeFile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}master/spesification_attribute/upload`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveImportSpesificationAttribute(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}master/spesification_attribute/batch_save`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    currencyFile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}master/currency/upload`, payload)
          .then(response => {
            console.log(response)
            resolve(response)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    saveImportCurrency(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}master/currency/upload`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    unitFile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}master/unit/upload`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveImportUnit(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}master/unit/batch_save`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    businessEntityFile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}master/business_entity/upload`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveImportBusinessEntity(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}master/business_entity/batch_save`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    classificationFile(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.procAPI}master/classification/upload`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    saveImportClassification(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}master/classification/batch_save`,
            payload
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {}
}
