<template>
  <div>
    <v-item-group multiple id="productDetail">
      <v-card elevation="5" class="compare" v-if="getIsLoading === false">
        <div v-if="perm.read_perm === 1">
          <div class="compare-title">
            <v-toolbar class="compare-title-tool">
              <v-icon style="margin-right: 10px;">mdi-compare</v-icon>
              <v-toolbar-title>Detail</v-toolbar-title>
              <v-divider class="mx-6" inset vertical></v-divider>
              <v-toolbar-title class=" orange--text mb-2">
                {{ getDetailProduct[0].name }}
              </v-toolbar-title>
              <v-divider class="mx-6" inset vertical></v-divider>
              <v-toolbar-title class=" orange--text mb-2">
                {{
                  getDetailProduct[0].code !== null
                    ? getDetailProduct[0].code
                    : '-'
                }}
              </v-toolbar-title>
              <v-divider class="mx-6" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn color="grey" style="color:white;" @click="compareBack">
                <v-icon left>
                  mdi-arrow-left-circle
                </v-icon>
                Back
              </v-btn>
            </v-toolbar>
          </div>

          <v-row class="compare-row1 justify-content-center">
            <v-col cols="12" class="compare-col1">
              <v-sheet class="mx-auto compare-sheet">
                <v-carousel
                  hide-delimiters
                  show-arrows
                  class="mx-auto compare-group"
                  style="height:45vh; "
                >
                  <v-carousel-item
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  >
                    <v-img
                      style="height:300px; margin:auto;"
                      aspect-ratio="1.7"
                      contain
                      :src="
                        getDetailProduct[0].photo_1 === null
                          ? `${getProductImageLink}products/photo_1/ProductPhoto-g8extzpjvxqb5hcpc51be.png`
                          : `${getProductImageLink}products/${photoFilter1(
                              getDetailProduct[0].photo_1,
                              1
                            )}`
                      "
                      alt="product_img"
                    >
                    </v-img>
                  </v-carousel-item>
                  <v-carousel-item
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  >
                    <v-img
                      style="height:300px; margin:auto;"
                      aspect-ratio="1.7"
                      contain
                      :src="
                        getDetailProduct[0].photo_2 === null
                          ? `${getProductImageLink}products/photo_2/ProductPhoto-55antzuhkfomhw6th24t1.png`
                          : `${getProductImageLink}products/${photoFilter2(
                              getDetailProduct[0].photo_2,
                              2
                            )}`
                      "
                      alt="product_img"
                    >
                    </v-img>
                  </v-carousel-item>
                  <v-carousel-item
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  >
                    <v-img
                      style="height:300px; margin:auto;"
                      aspect-ratio="1.7"
                      contain
                      :src="
                        getDetailProduct[0].photo_3 === null
                          ? `${getProductImageLink}products/photo_3/ProductPhoto-uh71x4omnfukjgst6ql8.png`
                          : `${getProductImageLink}products/${photoFilter3(
                              getDetailProduct[0].photo_3,
                              3
                            )}`
                      "
                      alt="product_img"
                    >
                    </v-img>
                  </v-carousel-item>
                  <v-carousel-item
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  >
                    <v-img
                      style="height:300px; margin:auto;"
                      aspect-ratio="1.7"
                      contain
                      :src="
                        getDetailProduct[0].photo_4 === null
                          ? `${getProductImageLink}products/photo_4/ProductPhoto-lfny8jfow4y9t29wbj2o7.png`
                          : `${getProductImageLink}products/${photoFilter4(
                              getDetailProduct[0].photo_4,
                              4
                            )}`
                      "
                      alt="product_img"
                    >
                    </v-img>
                  </v-carousel-item>
                  <v-carousel-item
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  >
                    <v-img
                      style="height:300px; margin:auto;"
                      aspect-ratio="1.7"
                      contain
                      :src="
                        getDetailProduct[0].photo_5 === null
                          ? `${getProductImageLink}products/photo_5/ProductPhoto-8f0m2zjvgyx87szph2olkq.png`
                          : `${getProductImageLink}products/${photoFilter5(
                              getDetailProduct[0].photo_5,
                              5
                            )}`
                      "
                      alt="product_img"
                    >
                    </v-img>
                  </v-carousel-item>
                </v-carousel>

                <div
                  class="mx-auto"
                  style="width:100%; border:1px solid rgba(0,0,0,0.2); border-left:none; border-right:none;"
                >
                  <v-card-text
                    class="compare-card-text"
                    style="position: relative; color:#2e3634b6; height:40vh;"
                  >
                    <v-row>
                      <v-col
                        style="padding:10px; height:40px; border-right:1px solid rgba(0,0,0,0.2);"
                        cols="3"
                        class="title mb-2"
                      >
                        <h6
                          style="font-size:12px; font-weight:600; borderRadius:5px; padding: 0 3px; "
                        >
                          Price:
                          <p style="font-size:14px; font-weight:400;">
                            {{ '-' }}
                          </p>
                        </h6>
                      </v-col>
                      <v-col
                        style="padding:10px; height:40px; border-right:1px solid rgba(0,0,0,0.2)"
                        cols="1"
                        class="title mb-2"
                      >
                        <h6
                          style="font-size:12px; font-weight:600; borderRadius:5px; padding: 0 3px; "
                        >
                          Code:
                          <p style="font-size:14px; font-weight:400;">
                            {{
                              getDetailProduct[0].code !== null
                                ? getDetailProduct[0].code
                                : '-'
                            }}
                          </p>
                        </h6>
                      </v-col>
                      <v-col
                        style="padding:10px; height:40px; border-right:1px solid rgba(0,0,0,0.2)"
                        cols="4"
                        class=" title mb-2"
                      >
                        <h6
                          style="font-size:12px; font-weight:600; borderRadius:5px; padding: 0 3px; "
                        >
                          Category: <br />
                          <p style="font-size:14px; font-weight:400;">
                            {{
                              getDetailProduct[0].category !== null
                                ? getDetailProduct[0].category.name
                                : '-'
                            }}
                          </p>
                        </h6>
                      </v-col>
                      <v-col
                        style="padding:10px; height:40px;  border-right:1px solid rgba(0,0,0,0.2)"
                        cols="2"
                        class=" title mb-2"
                      >
                        <h6
                          style="font-size:12px; font-weight:600; borderRadius:5px; padding: 0 3px;"
                        >
                          Merk: <br />
                          <p style="font-size:14px; font-weight:400;">
                            {{
                              getDetailProduct[0].merk !== null
                                ? getDetailProduct[0].merk
                                : '-'
                            }}
                          </p>
                        </h6>
                      </v-col>
                      <v-col
                        style="padding:10px; height:40px;"
                        cols="2"
                        class=" title mb-2"
                      >
                        <h6
                          style="font-size:12px; font-weight:600; borderRadius:5px; padding: 0 3px; "
                        >
                          Unit:
                          <p style="font-size:14px; font-weight:400;">
                            {{
                              getDetailProduct[0].unit !== null
                                ? getDetailProduct[0].unit.name
                                : '-'
                            }}
                          </p>
                        </h6>
                      </v-col>
                      <v-col
                        style="padding:10px; border-top:1px solid rgba(0,0,0,0.2); margin-top:20px;"
                        cols="12"
                        class=" title mb-2"
                      >
                        <h6
                          style="font-size:18px; font-weight:600; borderRadius:5px; padding: 5px 3px; height:165px;"
                        >
                          Spesifikasi/ Deskripsi: <br />
                          <p
                            style="font-size:14px; font-weight:400; padding: 5px 0;"
                          >
                            {{
                              getDetailProduct[0].spesification !== null
                                ? getDetailProduct[0].spesification
                                : '-'
                            }}
                          </p>
                        </h6>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </div>
                <!-- <ProductCategory /> -->
              </v-sheet>
            </v-col>
          </v-row>
        </div>

        <v-card
          flat
          height="90vh"
          v-else
          color="transparent"
          style="display:flex; justify-content:center; align-items:center;"
        >
          <v-btn
            color="grey"
            style="position:absolute; top:10px; left:10px;color:white;"
            @click="compareBack"
          >
            <v-icon left>
              mdi-arrow-left-circle
            </v-icon>
            Back
          </v-btn>
          <v-toolbar-title class="overline">
            You Can't Access This Page</v-toolbar-title
          >
        </v-card>
      </v-card>

      <v-card
        v-else
        class="mx-auto"
        width="99%"
        height="260"
        style="display:flex; justify-content: center; align-items:center; font-size:12px; position:absolute; top:0; height:100%; left:0; right:0;background: rgba(255,255,255,0.5)"
      >
        <v-progress-circular :size="170" :width="10" color="blue" indeterminate
          >Please wait</v-progress-circular
        >
      </v-card>
    </v-item-group>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['perm'],
  data: () => ({
    model: [],
    str_photo: ''
  }),
  watch: {},
  // components: { ProductCategory },
  created() {},
  computed: {
    ...mapGetters([
      'getCompareItems',
      'getDetailProduct',
      'getIsLoading',
      'getProductImageLink'
    ])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(['setProductDetail']),
    compareBack() {
      this.setProductDetail(false)
    },
    photoFilter1(link, index) {
      const strPhoto = 'photo_1/ProductPhoto-g8extzpjvxqb5hcpc51be.png'

      if (link === null || link === '') {
        return strPhoto
      } else {
        const lastIndxOf = link.lastIndexOf('/')
        const str = link.substring(lastIndxOf + 1)
        if (str === 'null') {
          return strPhoto
        } else {
          return `photo_${index}/${str}`
        }
      }
    },
    photoFilter2(link, index) {
      const strPhoto = 'photo_2/ProductPhoto-55antzuhkfomhw6th24t1.png'

      if (link === null || link === '') {
        return strPhoto
      } else {
        const lastIndxOf = link.lastIndexOf('/')
        const str = link.substring(lastIndxOf + 1)
        if (str === 'null') {
          return strPhoto
        } else {
          return `photo_${index}/${str}`
        }
      }
    },
    photoFilter3(link, index) {
      const strPhoto = 'photo_3/ProductPhoto-uh71x4omnfukjgst6ql8.png'

      if (link === null || link === '') {
        return strPhoto
      } else {
        const lastIndxOf = link.lastIndexOf('/')
        const str = link.substring(lastIndxOf + 1)
        if (str === 'null') {
          return strPhoto
        } else {
          return `photo_${index}/${str}`
        }
      }
    },
    photoFilter4(link, index) {
      const strPhoto = 'photo_4/ProductPhoto-lfny8jfow4y9t29wbj2o7.png'

      if (link === null || link === '') {
        return strPhoto
      } else {
        const lastIndxOf = link.lastIndexOf('/')
        const str = link.substring(lastIndxOf + 1)
        if (str === 'null') {
          return strPhoto
        } else {
          return `photo_${index}/${str}`
        }
      }
    },
    photoFilter5(link, index) {
      const strPhoto = 'photo_5/ProductPhoto-8f0m2zjvgyx87szph2olkq.png'

      if (link === null || link === '') {
        return strPhoto
      } else {
        const lastIndxOf = link.lastIndexOf('/')
        const str = link.substring(lastIndxOf + 1)
        if (str === 'null') {
          return strPhoto
        } else {
          return `photo_${index}/${str}`
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
