<template>
  <v-dialog v-model="dialog" width="90%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        style="margin-left:5px;font-size:8px; padding:0 2px;"
        color="white"
        dark
        elevation="1"
        class="black--text"
        small
        >Evaluasi
      </v-btn>
    </template>
    <v-card style="padding:30px;" class="text-center">
      <v-col cols="12" style="font-size:22px; font-weight:bold;">
        <v-icon
          style="position:absolute; top:0; right:0; cursor:pointer;"
          @click="dialog = false"
          >mdi-close</v-icon
        >
      </v-col>
      <v-item-group
        mandatory
        style="display:flex; flex-direction:row; padding: 0 20px;"
      >
        <v-item
          v-slot="{ active, toggle }"
          v-for="(item, index) in tabs"
          :key="index"
          @change="tabChange(item, index)"
        >
          <v-btn
            :color="active ? 'cyan' : 'grey'"
            @click="toggle"
            style="color:white; border-radius: 0 20px 0 0;"
          >
            {{ item }}
          </v-btn>
        </v-item>
      </v-item-group>
      <v-card elevation="1" style="padding:20px;margin-bottom:20px;">
        <div v-if="selectedTab === 0">
          <Verification />
        </div>
        <div v-if="selectedTab === 1"><Verification /></div>
        <div v-if="selectedTab === 2"><Verification /></div>
        <div v-if="selectedTab === 3"><Verification /></div>
      </v-card>
    </v-card>
  </v-dialog>
</template>
<script>
// import { mapActions, mapGetters, mapMutations } from 'vuex'
import Verification from '../modal/participant/Evaluation.vue'
export default {
  components: { Verification },
  props: ['value'],
  data() {
    return {
      dialog: false,
      selectedTab: 0,
      tabs: ['Administrasi', 'Teknis', 'Harga', 'Dokumen'],
      dataTable: [],
      itemsPerPage: 10,
      headers: [
        {
          text: 'Verifikasi',
          value: 'verification',
          align: 'left'
        },
        {
          text: 'Tersedia',
          value: 'available',
          align: 'left'
        },
        {
          text: 'Keterangan',
          value: 'description',
          align: 'left'
        }
      ],
      totalData: 1,
      options: {},
      loading: false
    }
  },
  computed: {},
  created() {
    // this.initDataTable()
  },
  methods: {
    // initDataTable() {
    //   this.dataTable = [
    //     {
    //       category: 'Bahan Baku',
    //       name: 'BBK Polypropilene',
    //       spesification: 'Spek A',
    //     }
    //   ]
    // }
    tabChange(item, index) {
      this.selectedTab = index
    }
  }
}
</script>
<style lang="scss" scoped>
.items-total-box {
  border: 1px solid #e0e0e0;
  padding: 20px 10px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 14px;
  div {
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
    padding-top: 20px;
    display: flex;
    p {
      margin: 0;
      margin-right: 10px;
      min-width: 80px;
      position: relative;
      span {
        position: absolute;
        right: 0;
      }
    }
  }
}
</style>
