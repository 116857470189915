<template>
  <div
    tile
    class="mx-auto main-dashboard"
    style="margin-top:10px; margin-bottom:10px;width:100%; padding: 20px 10px;"
  >
    <div style="width:100%; margin: 20px 0;">
      <v-row>
        <v-col cols="4" style="padding:0;">
          <v-card elevation="6" style="height:150px; width:80%; margin:auto;">
            <div
              style="height:80%;display:flex; flex-direction:row; justify-content: center; align-items:center; "
            >
              <h1 style="margin-right:20px;">25</h1>
              <h4 style="">Pengadaan Aktif</h4>
            </div>
            <div style="height:20%; text-align:center;">
              <a href="#">view more</a>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" style="padding:0;">
          <v-card elevation="6" style="height:150px; width:80%; margin:auto;">
            <div
              style="height:80%;display:flex; flex-direction:row; justify-content: center; align-items:center; "
            >
              <h1 style="margin-right:20px;">189</h1>
              <h4 style="">Vendor Aktif</h4>
            </div>
            <div style="height:20%; text-align:center;">
              <a href="#">view more</a>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4" style="padding:0;">
          <v-card elevation="6" style="height:150px; width:80%; margin:auto;">
            <div
              style="height:80%;display:flex; flex-direction:row; justify-content: center; align-items:center; "
            >
              <h1 style="margin-right:20px;">10</h1>
              <h4 style="">Pengadaan Pagu Tertinggi</h4>
            </div>
            <div style="height:20%; text-align:center;">
              <a href="#">view more</a>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div style="width:100%; padding: 30px 20px;">
      <v-data-table
        class="elevation-1 row-pointer"
        :headers="headers"
        :items="bookingList"
        :options.sync="options"
        :server-items-length="totalBookingData"
        :loading="loading"
        @click:row="rowClick"
      >
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  data: () => ({
    bookingList: [],
    headers: [
      {
        text: 'No',
        value: 'no',
        align: 'left',
        sortable: true
      },
      {
        text: 'Nama PT',
        value: 'pt_name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Department',
        value: 'department',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl. Mulai',
        value: 'start_date',
        align: 'left',
        sortable: true
      },
      {
        text: 'Tgl. Berakhir',
        value: 'end_date',
        align: 'left',
        sortable: true
      },
      {
        text: 'Nama Produk',
        value: 'product_name',
        align: 'left',
        sortable: true
      },
      {
        text: 'Pagu',
        value: 'pagu',
        align: 'left',
        sortable: true
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: true
      }
    ],
    totalBookingData: 1,
    options: {},
    loading: false
  }),
  watch: {
    options: {
      handler() {
        this.submitSearch('', 'table')
      }
    }
  },
  mounted() {
    this.submitSearch('', 'default')
  },
  created() {
    this.initBookingList()
  },
  computed: {
    ...mapGetters([])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations([]),
    initBookingList() {
      this.bookingList = [
        {
          no: 1,
          pt_name: 'SMI',
          department: 'MIS',
          start_date: '01 Mei 2020',
          end_date: '31 Mei 2020',
          product_name: 'Server Dell Intel Xeon',
          pagu: 'Rp. 45.000.000',
          status: 'Negosiasi Harga'
        },
        {
          no: 2,
          pt_name: 'IMP',
          department: 'PRODUKSI',
          start_date: '15 Juni 2020',
          end_date: '15 Agustus 2020',
          product_name: 'Biji Plastik',
          pagu: 'Rp. 70.000.000',
          status: 'Evaluasi Dokumen Pendukung'
        },
        {
          no: 3,
          pt_name: 'SMI',
          department: 'HRD',
          start_date: '20 April 2020',
          end_date: '20 Mei 2020',
          product_name: 'ATK - Kertas A4',
          pagu: 'Rp. 12.500.000',
          status: 'Penunjukan Vendor'
        }
      ]
    },
    submitSearch(pKeyword, pTrigerBy) {
      // this.loading = true
      // if (pTrigerBy === 'search_button') {
      //   this.paramAPI.keyword = pKeyword
      // }
      // this.paramAPI.offset =
      //   this.options.page === 1
      //     ? this.options.page - 1
      //     : this.options.page - 1 + this.options.itemsPerPage - 1
      // this.paramAPI.limit = this.options.itemsPerPage
      // this.$store.dispatch('bookingList/getbookingListList', this.paramAPI).then(
      //   data => {
      //     this.bookingList = data.data
      //     console.log(this.bookingList)
      //     this.totalBookingData = data.total_record
      //   },
      //   error => {
      //     // alert('Failed')
      //     console.log('Error View:' + error)
      //   }
      // )
      // this.loading = false
    },
    rowClick(pItem) {
      console.log(pItem)
      // this.$store.commit('bookingList/actFormbookingList', 'update')
      // this.$store.commit('bookingList/detailbookingList', pItem)
      // this.$router.push('/admin/pages/bookingList/form/id/' + pItem.id)
    }
  }
}
</script>

<style lang="scss" src=""></style>
