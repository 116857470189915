import axios from 'axios'
import buildType from '../../services/buildType'
export default {
  state: {
    procAPI: buildType.apiURL('proc'),
    uploadAPI: buildType.apiURL('upload'),

    categoryEditStatus: true,
    categoryEditValue: null,
    categoryEditAct: '',
    defaultData: [],
    totalItems: 0,
    categoryData: {},
    idEdit: ''
  },
  mutations: {
    categoryEdit(state, payload) {
      state.categoryEditStatus = payload[0]
      if (payload[1] !== undefined && payload[2] !== undefined) {
        state.categoryEditAct = payload[2].act
      }
    },
    setLoadDataCategory(state, payload) {
      state.defaultData = []
      state.totalItems = payload.data.total_record
      const response = payload.data.data
      console.log(response)
      if (response !== undefined) {
        for (let i = 0; i < response.length; i++) {
          state.defaultData.push({
            no: 1 + i,
            id: response[i].id,
            name: response[i].name,
            image: response[i].photo,
            created_at: response[i].created_at,
            created_by: response[i].created_by_name,
            updated_at: response[i].updated_at,
            updated_by: response[i].updated_by_name,
            is_investment: response[i].is_investment
          })
        }
      } else {
        state.defaultData = []
      }
    },
    setCategoryData(state, payload) {
      state.categoryData = payload
    }
  },
  actions: {
    initCategory(context, payload) {
      context.commit('setLoadDataCategory', payload)
    },
    adminCategoryList(context, payload) {
      context.state.defaultData = []
      return new Promise((resolve, reject) => {
        axios

          .get(
            `${context.state.procAPI}master/product_category/list?offset=${payload[1]}&limit=${payload[2]}&keyword=${payload[0]}`
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    adminCategoryEdit(context, payload) {
      let isInvestment = null
      if (payload.is_investment) {
        isInvestment = 1
      } else {
        isInvestment = 0
      }
      const formEdit = {
        act: 'update',
        name: payload.name,
        id: context.state.categoryData.id,
        photo: payload.photo,
        is_investment: isInvestment
      }
      const formAdd = {
        act: 'add',
        name: payload.name,
        photo: payload.photo,
        is_investment: isInvestment
      }
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${context.state.procAPI}master/product_category/save`,
            context.state.categoryEditAct === 'add' ? formAdd : formEdit
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    adminCategoryDelete(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(
            `${context.state.procAPI}master/product_category/delete/${payload.id}`
          )
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    uploadImgEditCategory(context, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${context.state.uploadAPI}upload/product/photo`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {
    getCategoryProductList(state) {
      return state.defaultData
    },
    getCategoryEditStat(state) {
      return state.categoryEditStatus
    },
    getCategoryEditAct(state) {
      return state.categoryEditAct
    },
    getTotalCategory(state) {
      return state.totalItems
    },
    getCategoryData(state) {
      return state.categoryData
    }
  }
}
