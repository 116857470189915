<template>
  <v-row justify="center" style="position:relative;">
    <a
      href="#"
      @click="dialog = true"
      style="position:relative; top:15px; right:15px; width:100px; height:100%;"
    >
      View rate
    </a>
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card style="padding:20px;">
        <v-alert border="top" colored-border type="info" elevation="2"
          >Beri penilaian untuk vendor dengan memilih nilai yang sesuai
          berdasarkan parameter dibawah ini dari 1 hingga 10, lalu klik save
        </v-alert>
        <v-card style="margin-top:20px;" flat color="transparent" width="95%">
          <v-alert dense outlined type="error" v-if="isFailed">
            {{ failMsg }}
          </v-alert>
          <v-card-text class="pt-0">
            <v-text-field
              v-if="getVendorRatting === null"
              v-model="value.pic"
              :readonly="perm.create_perm !== 1"
              label="PIC"
            ></v-text-field>
            <v-text-field
              v-else
              :readonly="perm.update_perm !== 1"
              v-model="getVendorRatting[getVendorRatting.length - 1].pic"
              label="PIC"
            ></v-text-field>
          </v-card-text>
          <v-card-text class="pt-0">
            <v-slider
              v-if="getVendorRatting === null"
              thumb-label
              v-model="value.harga"
              label="Harga :"
              :readonly="perm.create_perm !== 1"
              max="10"
              min="0"
              ticks
            >
              <template v-slot:append>
                <v-text-field
                  v-model="value.harga"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  readonly
                  type="number"
                  style="width: 30px"
                ></v-text-field>
              </template>
            </v-slider>
            <v-slider
              v-else
              thumb-label
              v-model="getVendorRatting[getVendorRatting.length - 1].harga"
              label="Harga :"
              :readonly="perm.update_perm !== 1"
              max="10"
              min="0"
              ticks
            >
              <template v-slot:append>
                <v-text-field
                  v-model="getVendorRatting[getVendorRatting.length - 1].harga"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  readonly
                  style="width: 20px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-card-text>

          <v-card-text class="pt-0">
            <v-slider
              v-if="getVendorRatting === null"
              thumb-label
              v-model="value.kualitas"
              label="Kualitas :"
              :readonly="perm.create_perm !== 1"
              max="10"
              min="0"
              ticks
            >
              <template v-slot:append>
                <v-text-field
                  v-model="value.kualitas"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  readonly
                  type="number"
                  style="width: 30px"
                ></v-text-field>
              </template>
            </v-slider>
            <v-slider
              v-else
              thumb-label
              v-model="getVendorRatting[getVendorRatting.length - 1].kualitas"
              label="Kualitas :"
              :readonly="perm.update_perm !== 1"
              max="10"
              min="0"
              ticks
            >
              <template v-slot:append>
                <v-text-field
                  v-model="
                    getVendorRatting[getVendorRatting.length - 1].kualitas
                  "
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  readonly
                  type="number"
                  style="width: 30px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-card-text>

          <v-card-text class="pt-0">
            <v-slider
              v-if="getVendorRatting === null"
              thumb-label
              v-model="value.pengiriman"
              label="Pengiriman :"
              :readonly="perm.create_perm !== 1"
              max="10"
              min="0"
              ticks
            >
              <template v-slot:append>
                <v-text-field
                  v-model="value.pengiriman"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  readonly
                  type="number"
                  style="width: 30px"
                ></v-text-field>
              </template>
            </v-slider>
            <v-slider
              v-else
              thumb-label
              :readonly="perm.update_perm !== 1"
              v-model="getVendorRatting[getVendorRatting.length - 1].pengiriman"
              label="Pengiriman :"
              max="10"
              min="0"
              ticks
            >
              <template v-slot:append>
                <v-text-field
                  v-model="
                    getVendorRatting[getVendorRatting.length - 1].pengiriman
                  "
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  readonly
                  type="number"
                  style="width: 30px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-card-text>

          <v-card-text class="pt-0">
            <v-slider
              v-if="getVendorRatting === null"
              thumb-label
              v-model="value.kesesuaian_penawaran"
              label="Kesesuaian penawaran :"
              :readonly="perm.create_perm !== 1"
              max="10"
              min="0"
              ticks
            >
              <template v-slot:append>
                <v-text-field
                  v-model="value.kesesuaian_penawaran"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  readonly
                  type="number"
                  style="width: 30px"
                ></v-text-field>
              </template>
            </v-slider>
            <v-slider
              v-else
              thumb-label
              v-model="
                getVendorRatting[getVendorRatting.length - 1]
                  .kesesuaian_penawaran
              "
              label="Kesesuaian penawaran :"
              :readonly="perm.update_perm !== 1"
              max="10"
              min="0"
              ticks
            >
              <template v-slot:append>
                <v-text-field
                  v-model="
                    getVendorRatting[getVendorRatting.length - 1]
                      .kesesuaian_penawaran
                  "
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  readonly
                  type="number"
                  style="width: 30px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-card-text>
          <v-card-text class="pt-0">
            <v-slider
              v-if="getVendorRatting === null"
              thumb-label
              v-model="value.komunikatif"
              label="Komunikatif :"
              :readonly="perm.create_perm !== 1"
              max="10"
              min="0"
              ticks
            >
              <template v-slot:append>
                <v-text-field
                  v-model="value.komunikatif"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  readonly
                  type="number"
                  style="width: 30px"
                ></v-text-field>
              </template>
            </v-slider>
            <v-slider
              v-else
              thumb-label
              v-model="
                getVendorRatting[getVendorRatting.length - 1].komunikatif
              "
              :readonly="perm.update_perm !== 1"
              label="Komunikatif :"
              max="10"
              min="0"
              ticks
            >
              <template v-slot:append>
                <v-text-field
                  v-model="
                    getVendorRatting[getVendorRatting.length - 1].komunikatif
                  "
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  readonly
                  type="number"
                  style="width: 30px"
                ></v-text-field>
              </template>
            </v-slider>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancel">
            Cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="save"
            v-if="perm.update_perm === 1 && perm.create_perm === 1"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['items', 'perm'],
  data() {
    return {
      isFailed: false,
      failMsg: 'PIC must be filled',
      dialog: false,
      value: {
        vendor_id: this.items,
        rate_date: new Date().toISOString().substr(0, 10),
        pic: '',
        harga: 0,
        kualitas: 0,
        pengiriman: 0,
        komunikatif: 0,
        kesesuaian_penawaran: 0
      },
      rules: [v => v > 50 || 'Not good']
    }
  },
  created() {
    console.log(this.perm)
  },
  computed: {
    ...mapGetters([
      'getVendorDetail',
      // 'getVendorUrlParam',
      'getVendorId',
      'getExperienceList',
      'getModalEditExp',
      'getDocument',
      'getTotalCatalogue',
      'getCatalogueList',
      'getMonth',
      'getExpTotalItems',
      'getVdrAddStat',
      'getIsLoading',
      'getVendorRatting'
    ])
  },
  methods: {
    ...mapActions(['saveRatting', 'loadRate', 'vendorDataList']),
    ...mapMutations([]),
    save() {
      let form = {}
      if (this.getVendorRatting !== null) {
        form = this.getVendorRatting[this.getVendorRatting.length - 1]
        this.setRatting(form)
      } else {
        form = this.value
        if (this.value.pic === '') {
          this.isFailed = true
          setTimeout(() => {
            this.isFailed = false
          }, 3000)
        } else {
          this.setRatting(form)
        }
      }
    },
    setRatting(form) {
      this.saveRatting([form, this.items])
        .then(result => {
          if (result.data.status_code !== '-99') {
            this.$bvToast.toast(`${result.data.status_msg}`, {
              title: 'Success',
              variant: 'success',
              solid: true,
              autoHideDelay: 4000
            })
            this.vendorDataList(['', 0, 10, 'name', 'asc'])
            this.loadRate(this.items)
            this.dialog = false
          } else {
            this.$swal.fire({
              position: 'center',
              icon: 'error',
              title: result.data.status_msg,
              showConfirmButton: true,
              timer: 5000
            })
          }
        })
        .catch(error => {
          this.$swal.fire({
            position: 'center',
            icon: 'error',
            title: error,
            showConfirmButton: true,
            timer: 5000
          })
        })
    },
    cancel() {
      this.value = {
        vendor_id: '',
        rate_date: '',
        pic: '',
        price: 0,
        quality: 0,
        delivery: 0,
        communicative: 0,
        corresponding: 0
      }
      this.dialog = false
    }
  }
}
</script>
