<template>
  <div id="formProcurement">
    <!-- <router-link style="color:white;" to="/e-procurement/procurement-list" -->
    <v-btn
      small
      tile
      elevation="0"
      color="transparent"
      class="ma-2 red--text"
      style="position:relative;"
      @click="$router.push('/e-procurement/procurement-list')"
    >
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <!-- </router-link> -->

    <!-- <v-form ref="entryForm" @submit.prevent="submit" style="position: relative"> -->
    <v-card flat class="form-pr-v-toolbar mx-auto">
      <v-row>
        <v-col cols="12" style="padding:0;">
          <v-toolbar flat style="border-radius:10px 10px 0 0;">
            <v-btn
              elevation="1"
              class="mx-2 elevation-4"
              dark
              color="cyan"
              style="position:relative; top:-10px; left:-10px; height:80px; width:80px;"
            >
              <v-icon large dark>
                mdi-format-line-style
              </v-icon>
            </v-btn>
            <v-toolbar-title class="overline"
              ><p style="font-size:20px;">
                Formulir Pembuatan Pengadaan
              </p>
            </v-toolbar-title>
          </v-toolbar>
        </v-col>
        <v-col
          cols="12"
          style="padding:0; text-align:right; position:absolute; top:0; left:0; display:flex; justify-content:flex-end;"
        >
          <v-spacer />
          <div v-if="detail !== null">
            <div v-if="detail.status_approval !== null">
              <h4
                :style="
                  `border-left:1px solid #e0e0e0; border-right:1px solid #e0e0e0; padding:10px 30px; font-weight:bold; color:${statusColor(
                    detail.status_approval.name
                  )};`
                "
              >
                {{ detail.status_approval.name }}
              </h4>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <div
      style="border-bottom:1px solid #e0e0e0; background:white; padding:5px 23px; display:flex; justify-content:flex-end;"
    >
      <div v-if="detail !== null">
        <div v-if="detail.status_approval !== null">
          <v-btn
            v-if="detail.status_approval.id === 1 && isAllow"
            color="green"
            small
            tile
            class="ma-2"
            style="color:white;"
            @click="confirmApprovement"
          >
            Approve
          </v-btn>
        </div>
      </div>
      <div
        v-if="
          permission.level_id === '1' ||
            permission.level_id === '22' ||
            permission.level_id === '23'
        "
      >
        <div v-if="detail !== null">
          <div
            v-if="detail.status_approval !== null"
            style=" padding-left:13px;"
          >
            <v-btn
              v-if="
                (detail.status_approval.id === 1 && isAllow) ||
                  detail.status_approval.id === 2
              "
              color="red"
              small
              tile
              class="ma-2"
              style="color:white;"
              @click="cancelApprovement"
            >
              Cancel
            </v-btn>
          </div>
        </div>
      </div>
      <v-spacer />
      <div v-if="permission.level_id === '1' || permission.level_id === '23'">
        <v-btn
          v-if="getProcListId === null"
          @click="triggerSubmit"
          color="grey"
          small
          tile
          class="ma-2"
          style="color:white;"
        >
          Simpan
        </v-btn>
        <div v-if="detail !== null">
          <div
            style="display:flex;"
            v-if="detail.created_by_name === getUserProfile.username"
          >
            <div v-if="detail.status_approval !== null">
              <div v-if="detail.status_approval.id === 0">
                <v-btn
                  v-if="getProcListId !== null && isEdit"
                  @click="triggerSubmit"
                  color="grey"
                  small
                  tile
                  class="ma-2"
                  style="color:white;"
                >
                  Simpan
                </v-btn>
                <v-btn
                  v-if="getProcListId !== null"
                  @click="allowEdit"
                  color="grey"
                  small
                  tile
                  class="ma-2"
                  style="color:white;"
                >
                  {{ !isEdit ? 'Edit' : 'Batal' }}
                </v-btn>
              </div>
            </div>

            <div v-if="detail.status_approval !== null">
              <v-btn
                v-if="detail.status_approval.id === 0"
                color="green"
                small
                tile
                class="ma-2"
                style="color:white;"
                @click="sendToApproval"
              >
                Simpan ke approval
              </v-btn>
            </div>

            <div v-if="detail.status_approval !== null">
              <v-btn
                v-if="detail.status_approval.id === -2"
                color="grey"
                small
                tile
                class="ma-2"
                style="color:white;"
                @click="sendToDraft"
              >
                Simpan ke draft
              </v-btn>
            </div>

            <div v-if="detail.status_approval !== null">
              <InviteModal v-if="detail.status_approval.id === 2" />
            </div>
            <div v-if="detail.status_approval !== null">
              <v-btn
                v-if="detail.status_approval.id === 2"
                elevation="0"
                large
                tile
                style="color:white; margin-right:8px; border:1px solid black; color:black;"
                @click="$router.push('/e-procurement/participants')"
              >
                Vendor
              </v-btn>
            </div>
            <!-- <v-btn
        elevation="0"
        large
        tile
        style="color:white; margin-right:3px; border:1px solid black; color:black;"
      >
        Jadwal
      </v-btn> -->
          </div>
        </div>
      </div>
    </div>

    <div class="form-pr-input mx-auto">
      <Form
        :detail="detail"
        :param="form"
        :dept="department"
        :comp="company"
        @reload="loadPage"
        :allowEdit="isEdit"
      />
    </div>
    <!-- </v-form> -->
    <div class="form-pr-items-table mx-auto" v-if="getProcListId !== null">
      <v-item-group
        mandatory
        style="display:flex; flex-direction:row; padding: 0 20px;"
      >
        <v-item
          v-slot="{ active, toggle }"
          v-for="(item, index) in tabs"
          :key="index"
          @change="tabChange(item, index)"
        >
          <v-btn
            :color="active ? 'cyan' : 'grey'"
            @click="toggle"
            style="color:white; border-radius: 0 20px 0 0;"
          >
            {{ item }}
          </v-btn>
        </v-item>
      </v-item-group>
      <v-card style="padding:20px;margin-bottom:20px;">
        <ProcurementItems
          v-if="selectedTab === 0"
          :itemData="itemTab"
          :procId="procurement_id"
          :data="detail"
          @reload="initFormEdit"
        />
        <Schedule
          v-if="selectedTab === 1"
          :scheduleData="scheduleTab"
          :dropdown="schedule"
          :procId="procurement_id"
          @reload="initFormEdit"
        />
        <Requirements
          v-if="selectedTab === 2"
          :termData="termTab"
          :procId="procurement_id"
          @reload="initFormEdit"
        />
        <!-- <AdditionalDesc v-if="selectedTab === 3" /> -->
      </v-card>

      <v-expansion-panels class="mb-6" elevation="1">
        <v-expansion-panel v-for="(item, i) in 1" :key="i">
          <v-expansion-panel-header
            expand-icon="mdi-menu-down"
            style="font-weight: bold"
          >
            Approver Table...
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card
              class=" mx-auto"
              style="padding:10px 15px 20px 15px; width:100%; margin-top:5px; margin-bottom:10px;"
            >
              <v-data-table
                :headers="headers"
                :items="matrix"
                class="elevation-1 mx-auto"
                style="width:99%; margin-bottom:10px; border:1px solid #e0e0e0"
                :items-per-page="itemsPerPage"
                hide-default-footer
              >
                <template v-slot:[`item.approver_user`]="{ item }">
                  <div @click="rowClick(item)" style="cursor:pointer;">
                    <v-chip
                      label
                      outlined
                      small
                      style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                      v-for="(item, index) in item.approver_user"
                      :key="index"
                    >
                      {{ item.user.name }}
                    </v-chip>
                  </div>
                </template>
                <template v-slot:[`item.approved`]="{ item }">
                  <div
                    v-for="(items, index) in item.approver_user"
                    :key="index"
                  >
                    <v-chip
                      v-if="items.status === 1"
                      label
                      outlined
                      small
                      style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                    >
                      {{ items.user.name }}
                    </v-chip>
                  </div>
                </template>
                <template v-slot:[`item.rejected`]="{ item }">
                  <div
                    v-for="(items, index) in item.approver_user"
                    :key="index"
                  >
                    <v-chip
                      v-if="items.status === -1"
                      label
                      outlined
                      small
                      style="margin-right:5px; padding:0 3px; cursor:pointer;border:1px solid rgba(0,0,0,0.5);"
                    >
                      {{ items.user.name }}
                    </v-chip>
                  </div>
                </template>
              </v-data-table>
              <div style="text-align:right; padding-right:5px;">
                <v-btn
                  v-if="
                    getUserProfile.level.find(
                      ({ application }) => application.id === 1
                    ) !== undefined
                  "
                  color="grey"
                  tile
                  dark
                  small
                  dense
                  style="font-size:10px; width:100px;"
                  @click="fetch"
                >
                  <span>Fetch Matrix</span>
                </v-btn>
              </div>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import buildType from '../../../services/buildType'
import Form from '../../../components/procurement/procurement_form/Form_create'
import ProcurementItems from '../../../components/procurement/procurement_form/Procurement_items'
import Schedule from '../../../components/procurement/procurement_form/Schedule'
import Requirements from '../../../components/procurement/procurement_form/Requirements'
// import AdditionalDesc from '../../../components/procurement/procurement_form/Additional_desc'
import InviteModal from '../../../components/procurement/invite_provider/confirmationDialog'
import { mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    proc: buildType.apiURL('proc'),
    hr: buildType.apiURL('hr'),

    menu_key: 32,
    permission: {},
    application: {
      id: 2,
      name: 'eProcurement'
    },

    procurement_id: '',
    isEdit: false,
    selectedTab: 0,
    tabs: [
      'Item Pengadaan',
      'Jadwal',
      'Persyaratan'
      // 'Deskripsi Tambahan'
    ],

    detail: null,
    form: {},
    company: [],
    department: [],
    schedule: [],

    itemTab: [],
    scheduleTab: [],
    termTab: [],

    // approval table

    headers: [
      { text: 'Sequence', value: 'sequence', align: 'left' },
      { text: 'Doc. No', value: 'document_no', align: 'left' },
      { text: 'Model', value: 'table_name', align: 'center' },
      { text: 'User', value: 'approver_user', align: 'center' },
      { text: 'Min. Approver', value: 'min_approver', align: 'center' },
      { text: 'Approved by', value: 'approved', align: 'center' },
      { text: 'Rejected by', value: 'rejected', align: 'center' }
    ],
    isAllow: false,
    matrix: [],
    itemsPerPage: 10
  }),
  components: {
    Form,
    ProcurementItems,
    Schedule,
    Requirements,
    // AdditionalDesc,
    InviteModal
  },
  created() {
    var arr = this.getUserProfile.level
    for (let i = 0; i < arr.length; i++) {
      if (
        arr[i].application.id === this.application.id ||
        arr[i].application.id === 1
      ) {
        this.menu_access([this.menu_key, this.getUserProfile.level[i].id])
          .then(result => {
            this.permission = result.data
            // this.setPermission(result.data)

            if (this.getProcListId === null) {
              this.initFormAdd()
            } else {
              this.initFormEdit(this.getProcListId)
            }
            this.initDropdown()
          })
          .catch(error => {
            if (error) {
              this.permission = {
                read_perm: 0
              }
              // this.setPermission({ read_perm: 0 })
            }
          })
      } else {
        this.permission = {
          read_perm: 0
        }
        // this.setPermission({ read_perm: 0 })
      }
    }
  },
  computed: {
    ...mapGetters(['getProcListId', 'getUserProfile'])
  },
  methods: {
    ...mapActions([
      'menu_access',
      'approvalMatrixDocumentList',
      'fetchMatrix',
      'documentAuthority'
    ]),
    // ...mapMutations(['setPermission','setProcDetail]),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    loadPage(id) {
      this.initFormEdit(id)
      this.isEdit = false
    },
    allowEdit() {
      if (!this.isEdit) {
        this.isEdit = true
      } else {
        this.isEdit = false
      }
    },
    tabChange(item, index) {
      this.selectedTab = index
    },
    initFormAdd() {
      this.form = {
        act: 'add',
        name: '',
        year: '',
        total_hps: 0,
        file: '',
        price_on_market: 0,
        period_start: '',
        period_end: '',
        total_working_days: 0,
        validity_period_offer: 0,
        qualification_requirements: '',

        company: this.getUserProfile.employee.company,
        department: this.getUserProfile.employee.department
      }
    },
    initFormEdit(id) {
      this.procurement_id = id
      axios
        .get(`${this.proc}detail/${id}`)
        .then(response => {
          const result = response.data.data
          this.$store.commit('setProcDetail', result)
          // this.setProcDetail(result)
          this.detail = result
          this.authorityCheck(result)
          this.matrixCheck(result)
          this.form = {
            act: 'update',
            id: id,
            name: result.name,
            year: result.year,
            total_hps: result.total_hps,
            file: result.file,
            price_on_market: result.price_on_market,
            period_start: result.period_start,
            period_end: result.period_end,
            total_working_days: result.total_working_days,
            validity_period_offer: result.validity_period_offer,
            qualification_requirements: result.qualification_requirements,

            company: result.company,
            department: result.department
          }

          this.initTabs(id)
        })
        .catch(error => {
          console.log(error)
        })
    },
    triggerSubmit() {
      document.getElementById('procFormSubmitBtn').click()
    },
    initDropdown() {
      this.dropDownCompany()
      this.dropDownDepartment()
      this.dropDownSchedule()
    },
    dropDownCompany() {
      axios
        .get(`${this.hr}master/company/list`)
        .then(response => {
          this.company = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    dropDownDepartment() {
      axios
        .get(`${this.hr}master/department/dropdown`)
        .then(response => {
          this.department = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    dropDownSchedule() {
      axios
        .get(
          `${this.proc}globalmaster/procurementscheduleattribute/dropdown?keyword=`
        )
        .then(response => {
          this.schedule = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    initTabs(id) {
      this.initTabProcItem(id)
      this.initTabProcSchedule(id)
      this.initTabProcTerm(id)
    },
    initTabProcItem(id) {
      axios
        .get(
          `${this.proc}item/list?keyword=&offset=0&limit=100&is_archived=&procurement_id=${id}`
        )
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.itemTab = res.data.data
          } else {
            this.itemTab = []
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    initTabProcSchedule(id) {
      this.scheduleTab = []
      axios
        .get(
          `${this.proc}schedule/list?keyword=&offset=0&limit=100&is_archived=&procurement_id=${id}`
        )
        .then(res => {
          // this.scheduleTab = res.data.data

          if (res.data.status_code !== '-99') {
            for (let i = 0; i < res.data.data.length; i++) {
              this.scheduleTab.push({
                created_at: res.data.data[i].created_at,
                created_by_name: res.data.data[i].created_by_name,
                end_date: this.convertDate(res.data.data[i].end_date),
                start_date: this.convertDate(res.data.data[i].start_date),
                schedule_attribute: res.data.data[i].schedule_attribute,
                id: res.data.data[i].id,
                isEdit: false
              })
            }
          } else {
            this.scheduleTab = []
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    initTabProcTerm(id) {
      this.termTab = []
      axios
        .get(
          `${this.proc}term/list?keyword=&offset=0&limit=10&is_archived=&procurement_id=${id}`
        )
        .then(res => {
          if (res.data.status_code !== '-99') {
            for (let i = 0; i < res.data.data.length; i++) {
              this.termTab.push({
                created_at: res.data.data[i].created_at,
                created_by_name: res.data.data[i].created_by_name,
                description: res.data.data[i].description,
                id: res.data.data[i].id,
                term: res.data.data[i].term,
                isEdit: false
              })
            }
          } else {
            this.termTab = []
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    sendToApproval() {
      const form = {
        id: this.form.id
      }
      axios
        .post(`${this.proc}submit_to_approve`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.initFormEdit(this.getProcListId)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    sendToDraft() {
      const form = {
        id: this.form.id
      }
      axios
        .post(`${this.proc}set_to_draft`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.initFormEdit(this.getProcListId)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    cancelApprovement() {
      const form = {
        id: this.form.id
      }
      axios
        .post(`${this.proc}cancel`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.initFormEdit(this.getProcListId)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    confirmApprovement() {
      const docId = this.detail.approval_matrix[0].document_id
      const form = {
        document_id: docId,
        status_approval: 1
      }
      axios
        .post(`${this.proc}confirm`, form)
        .then(res => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.initFormEdit(this.getProcListId)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    convertDate(raw) {
      const day = raw.slice(0, raw.indexOf('-'))
      const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
      const year = raw.slice(raw.lastIndexOf('-') + 1)

      // switch (month) {
      //   case '01':
      //     month = 'JAN'
      //     break
      //   case '02':
      //     month = 'FEB'
      //     break
      //   case '03':
      //     month = 'MAR'
      //     break
      //   case '04':
      //     month = 'APR'
      //     break
      //   case '05':
      //     month = 'MAY'
      //     break
      //   case '06':
      //     month = 'JUN'
      //     break
      //   case '07':
      //     month = 'JUL'
      //     break
      //   case '08':
      //     month = 'AGS'
      //     break
      //   case '09':
      //     month = 'SEP'
      //     break
      //   case '10':
      //     month = 'OKT'
      //     break
      //   case '11':
      //     month = 'NOV'
      //     break
      //   case '12':
      //     month = 'DES'
      //     break
      // }
      // console.log(year + ' ' + month + ' ' + day);
      return year + '-' + month + '-' + day
    },

    authorityCheck(data) {
      // if (data.approval_matrix !== null) {
      this.documentAuthority(data)
        .then(res => {
          console.log(res)
          if (res.status_code !== '-99') {
            if (res.is_allow_approve !== 0) {
              this.isAllow = true
            } else {
              this.isAllow = false
            }
          } else {
            this.isAllow = false
            // this.showMsgDialog('warning', res.status_msg, false)
          }
        })
        .catch(err => {
          this.isAllow = false
          this.showMsgDialog(
            'error',
            err
              ? 'Authority check error, please contact an Admin'
              : 'Authority check error, please contact an Admin'
          )
        })
      // } else {
      //   this.isAllow = false
      // }
    },
    matrixCheck(res) {
      this.approvalMatrixDocumentList(res)
        .then(res => {
          if (res.status_code === '00') {
            this.matrix = res.data
          } else {
            this.matrix = []
            // this.showMsgDialog('warning', res.status_msg, false)
          }
        })
        .catch(err => {
          this.matrix = []
          this.showMsgDialog(
            'error',
            err
              ? 'Authority check error, please contact an Admin'
              : 'Authority check error, please contact an Admin',
            false
          )
        })
    },

    rowClick(item) {
      console.log(item)
    },

    fetch() {
      this.matrix = []
      this.fetchMatrix(this.detail)
        .then(res => {
          if (res.status_code !== '-99') {
            this.showMsgDialog('success', res.status_msg, false)
            this.initFormEdit(this.getProcListId)
          } else {
            if (res.status_msg === 'Parameter value has problem') {
              this.showMsgDialog('error', res.error_msg[0].msg, false)
            } else {
              this.showMsgDialog('error', res.status_msg, false)
            }
          }
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, please contact an Admin'
              : 'Something went wrong, please contact an Admin',
            false
          )
        })
    },

    statusColor(val) {
      switch (val) {
        case 'Approved':
          return 'green'

        case 'Rejected':
          return 'red'

        default:
          return 'rgba(0,0,0,0.5)'
      }
    }
  }
}
</script>

<style lang="scss" src="">
#formProcurement {
  padding: 20px 10px;

  .form-pr-v-toolbar {
    padding: 10px 15px 20px 15px;
    width: 95%;
    margin-top: 30px;
  }
  .form-pr-input {
    border-radius: 3px;
    padding: 20px 30px 10px;
    width: 95%;
    min-height: 50vh;
    background: white;
  }
  .form-pr-items-table {
    border-radius: 3px;
    padding: 10px 30px;
    width: 95%;
    min-height: 50vh;
    background: white;
  }
}
</style>
