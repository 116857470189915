<template>
  <v-item-group multiple id="productCompare">
    <v-card elevation="5" class="compare">
      <div class="compare-title">
        <v-toolbar class="compare-title-tool">
          <v-icon style="margin-right: 10px;">mdi-compare</v-icon>
          <v-toolbar-title>Comparing Product</v-toolbar-title>
          <v-divider class="mx-6" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn color="grey" style="color:white;" @click="compareBack">
            <v-icon left>
              mdi-arrow-left-circle
            </v-icon>
            Back
          </v-btn>
        </v-toolbar>
      </div>
      <v-row class="compare-row1 justify-content-center">
        <v-col v-if="getLoadCompare === true" cols="12" class="compare-col1">
          <v-sheet v-if="perm.read_perm === 1" class="mx-auto compare-sheet">
            <v-slide-group
              v-model="model"
              class="pa-0 compare-group"
              show-arrows
            >
              <v-slide-item
                v-for="(item, index) in getCompareItems"
                :key="index"
                class="compare-item"
              >
                <v-hover>
                  <v-card class="mx-auto" color="grey lighten-4" width="246">
                    <v-img
                      aspect-ratio="1.7"
                      contain
                      :src="
                        `${getProductImageLink}products/photo_1/${photoFilter(
                          item.photo_1
                        )}`
                      "
                      :alt="item.name + 'image'"
                    >
                      <!-- <v-expand-transition>
                        <div
                          v-if="hover"
                          class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal display-3 white--text"
                          style="height: 100%;"
                        >
                          {{ '$14.99' }}
                        </div>
                      </v-expand-transition> -->
                    </v-img>
                    <v-card-text
                      class=" compare-card-text"
                      style="position: relative;"
                    >
                      <h6
                        class=" orange--text mb-2"
                        style="max-height:50px; overflow:auto; font-size:14px;"
                      >
                        {{ item.name }}
                      </h6>
                      <div class="title mb-2" style="">
                        <h6
                          style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                        >
                          Price: <br />
                          <p
                            style="font-size:14px; font-weight:500; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                          >
                            {{ '-' }}
                          </p>
                        </h6>
                      </div>
                      <div class=" title mb-2">
                        <h6
                          style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                        >
                          Code: <br />
                          <p
                            style="font-size:14px; font-weight:500; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                          >
                            {{ item.code !== null ? item.code : '-' }}
                          </p>
                        </h6>
                      </div>
                      <div class=" title mb-2">
                        <h6
                          style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                        >
                          Category: <br />
                          <p
                            style="font-size:14px; font-weight:400; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                          >
                            {{
                              item.category !== null ? item.category.name : '-'
                            }}
                          </p>
                        </h6>
                      </div>
                      <div class=" title mb-2">
                        <h6
                          style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                        >
                          Merk: <br />
                          <p
                            style="font-size:14px; font-weight:400; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                          >
                            {{ item.merk !== null ? item.merk : '-' }}
                          </p>
                        </h6>
                      </div>
                      <div class=" title mb-2">
                        <h6
                          style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 0 3px;"
                        >
                          Unit: <br />
                          <p
                            style="font-size:14px; font-weight:400; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                          >
                            {{ item.unit !== null ? item.unit.name : '-' }}
                          </p>
                        </h6>
                      </div>
                      <div class=" title mb-2">
                        <h6
                          style="font-size:12px; font-weight:bold; background:#E0E0E0; borderRadius:5px; padding: 2px 3px;"
                        >
                          Spesifikasi: <br />
                          <p
                            style="font-size:12px; font-weight:500; border-top:1px solid rgba(0,0,0,0.2); padding: 5px 0;"
                          >
                            {{
                              item.spesification !== null
                                ? item.spesification
                                : '-'
                            }}
                          </p>
                        </h6>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>

          <v-card
            flat
            height="70vh"
            v-else
            color="transparent"
            style="display:flex; justify-content:center; align-items:center;"
          >
            <v-toolbar-title class="overline">
              You Can't Access This Page</v-toolbar-title
            >
          </v-card>
        </v-col>

        <v-card
          v-else
          class="mx-auto"
          width="99%"
          height="260"
          style="display:flex; justify-content: center; align-items:center; font-size:12px;"
        >
          <v-progress-circular
            :size="170"
            :width="10"
            color="blue"
            indeterminate
            >Please wait</v-progress-circular
          >
        </v-card>
      </v-row>
    </v-card>
  </v-item-group>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  props: ['perm'],
  data: () => ({
    model: []
  }),
  watch: {},
  components: {},
  created() {},
  computed: {
    ...mapGetters([
      'getProductCompareStat',
      'getCompareItems',
      'getLoadCompare',
      'getProductImageLink'
    ])
  },
  methods: {
    ...mapActions([]),
    ...mapMutations(['productCompare', 'setCompareLoading']),
    compareBack() {
      this.productCompare([true, []])
      this.setCompareLoading(false)
    },
    photoFilter(link) {
      if (link === null || link === '') {
        return ''
      } else {
        const lastIndxOf = link.lastIndexOf('/')
        const str = link.substring(lastIndxOf + 1)
        if (str === 'null') {
          return ''
        } else {
          return str
        }
      }
    }
  }
}
</script>

<style lang="scss" src="../../assets/css/productManage.scss"></style>
