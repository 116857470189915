var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticStyle:{"position":"sticky","top":"0","z-index":"2"},attrs:{"color":"cyan","dark":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('router-link',{staticStyle:{"color":"white"},attrs:{"to":"/"}},[_c('v-btn',_vm._g(_vm._b({staticStyle:{"height":"45px","width":"45px","margin-right":"10px"},attrs:{"elevation":"5","fab":"","color":"white"},on:{"click":_vm.home}},'v-btn',attrs,false),on),[_c('v-img',{staticClass:" rounded-circle",staticStyle:{"height":"40px","width":"40px"},attrs:{"alt":"sanqua multi international","contain":"","src":require("../../../public/sanquamulti.png"),"transition":"scale-transition"}})],1)],1)]}}])},[_c('span',[_vm._v("Home")])]),_c('h5',{staticStyle:{"line-height":"20px","margin-top":"20px"}},[_c('h5',{staticStyle:{"font-size":"20px","width":"180px"}},[_vm._v(" Sanqua "),_c('br'),_c('h4',{staticStyle:{"width":"200px"}},[_vm._v(_vm._s(_vm.chosenpage))])])])],1),_c('v-tabs',{attrs:{"hide-slider":"","align-with-title":""}},[_c('v-tab',[_c('router-link',{staticStyle:{"color":"white","position":"absolute","top":"0","left":"0","bottom":"0","right":"0","display":"flex","justify-content":"center","align-items":"center"},attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('v-menu',{attrs:{"bottom":"","offset-y":"","rounded":'0'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({staticStyle:{"color":"white"}},'v-tab',attrs,false),on),[_vm._v(" Master ")])]}}])},[_c('v-list',_vm._l((_vm.ddmaster),function(item,i){return _c('v-list-item',{key:i,staticStyle:{"height":"40px"}},[_c('v-menu',{attrs:{"bottom":"","offset-x":"","rounded":'0'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({staticStyle:{"width":"220px","height":"100%","display":"flex","justify-content":"flex-start"}},'v-tab',attrs,false),on),[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,true)},[_c('v-list',_vm._l((item.sub_tabs),function(tabs,i){return _c('v-list-item',{key:i,staticStyle:{"height":"40px","min-width":"220px"}},[_c('v-tab',{staticStyle:{"font-size":"12px","display":"flex","justify-content":"flex-start","width":"100%","height":"100%"}},[_c('router-link',{staticStyle:{"width":"100%","height":"100%","display":"flex","justify-content":"flex-start","align-items":"center","text-align":"left"},attrs:{"to":tabs.path}},[_vm._v(" "+_vm._s(tabs.sub_title)+" ")])],1)],1)}),1)],1)],1)}),1)],1),_c('v-tab',{staticStyle:{"width":"150px"}},[_c('router-link',{staticStyle:{"color":"white","position":"absolute","top":"0","left":"0","bottom":"0","right":"0","display":"flex","justify-content":"center","align-items":"center"},attrs:{"to":"/e-procurement/procurement-list"}},[_vm._v("Procurement")])],1),_c('v-tab',{staticStyle:{"width":"80px"}},[_c('router-link',{staticStyle:{"color":"white","position":"absolute","top":"0","left":"0","bottom":"0","right":"0","display":"flex","justify-content":"center","align-items":"center"},attrs:{"to":"/fpb-list"}},[_vm._v("Fpb")])],1)],1),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_c('v-tab'),_c('v-divider',{staticStyle:{"margin-top":"0px"},attrs:{"vertical":""}})],1),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down",on:{"click":_vm.drawer}},[_c('v-tab',[_c('v-toolbar-title',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.getUserProfile !== null ? _vm.getUserProfile.username : 'Admin')+" "),_c('br'),_vm._v(" "+_vm._s(_vm.getUserProfile !== null ? '(' + _vm.getUserProfile.sanqua_company_name + ')' : 'Dept MIS')+" ")])],1),_c('v-divider',{staticStyle:{"margin-top":"0px"},attrs:{"vertical":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }